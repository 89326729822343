import { Injectable } from '@angular/core';
import { ApplicationDocument, DocumentUpload, DocumentTemplateUpload, DocuSignTemplate, ProducerDocumentUpload, ProducerDocument } from '../shared/models/document';
import { Observable } from 'rxjs';
import { IClientListDTO, IClientItemDTO } from '../shared/models/dto/common-result.dto';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends RestService {

    getDocumentLink(doc: ApplicationDocument, appId: string, bizUnitId: number): Observable<IClientListDTO<string>> {
        let relativeUrl = 'CloudStorage/Uris';

        let input = {
            'bizUnitId': null,
            'applicationID': appId,
            'fileType': 0,
            'fileNames': [
                doc.originalCopy
            ]
        };

        if (doc.type == "MustSign") {
            if (doc.signedTime == null) {
                input.fileType = 1;
                delete input.applicationID;
                input.bizUnitId = bizUnitId;
            }
            else {
                input.fileNames = [doc.updatedCopy]
            }
        }

        return this.post<IClientListDTO<string>>(relativeUrl, input);
    }

    getProducerDocumentLink(prodId: number, fileName: string): Observable<IClientListDTO<string>> {
        const relativeUrl = 'CloudStorage/ProducerFileUri/' + prodId;

        return this.post<IClientListDTO<string>>(relativeUrl, [fileName]);
    }

    uploadProducerFile(upload: ProducerDocumentUpload, prodId: number): Observable<IClientListDTO<ProducerDocument>> {
        let relativeUrl = "CloudStorage/Producer/Save/" + prodId;
        return this.post<IClientListDTO<ProducerDocument>>(relativeUrl, [upload]);
    }

    uploadApplicationFile(upload: DocumentUpload): Observable<IClientListDTO<number>> {
        let relativeUrl = "CloudStorage/Application/Save";
        return this.post<IClientListDTO<number>>(relativeUrl, upload);
    }

    uploadTemplateFile(upload: DocumentTemplateUpload): Observable<IClientListDTO<DocuSignTemplate>> {
        console.log('files');
        let relativeUrl = "CloudStorage/Template/Save";
        return this.post<IClientListDTO<DocuSignTemplate>>(relativeUrl, upload);
    }

    deleteDocumentTemplateFile(docTemplate: DocuSignTemplate): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'CloudStorage/DocuSignTemplate/Remove';
        return this.post<IClientItemDTO<boolean>>(relativeUrl, docTemplate);
    }

    deleteProducerDocument(doc: ProducerDocument, prodId: number): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'CloudStorage/DeleteProducerFile/' + prodId;
        return this.post<IClientItemDTO<boolean>>(relativeUrl, [doc.name]);
    }
}
