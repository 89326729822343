<div id="content" *ngIf="!userService.userRolesLoaded">
    <h2>Loading user permissions...</h2>
    <div class="loading-spinner"></div>
</div>
<div id="report-configuration" *ngIf="userService.userRolesLoaded">
    <h3>Report Configuration</h3>

    <form id="report-container" (submit)="runReport()">
        <div class="option">
            <mat-form-field class="full-width">
                <mat-label>Report Type</mat-label>
                <mat-select name="report-type" [(ngModel)]="reportType">
                    <mat-option *ngFor="let report of reportTypesList" [value]="report.type">{{report.display}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div [ngSwitch]="reportType">
            <div *ngSwitchCase="'regulatory'">
                <div class="blurb-desc">
                    This report provides a listing of all regulatory action for producers in a defined time frame. This information comes directly from the producer's PDB.
                </div>
                <div class="">
                    <mat-form-field class="date-picker">
                        <mat-label>On or After Date</mat-label>
                        <input name="reg-date" matInput [(ngModel)]="regulatoryActionInput.onOrAfterDate" [matDatepicker]="regDatePicker">
                        <mat-datepicker-toggle matSuffix [for]="regDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #regDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div *ngSwitchCase="'appointmentRenewal'">
                <div class="blurb-desc">
                    This report provides a listing of all producers who hold appointments or have been terminated with TMHCC carriers. This information comes directly from the producer's PDB and may contain duplicate listings on the report due to associations with multiple business units.
                </div>
                <div style="width: 100px;">
                    <pv-state-select [(state)]="appointmentRenewalInput.state" class=""></pv-state-select>
                </div>

                <pv-start-end-date [(startDate)]="appointmentRenewalInput.startDate" [(endDate)]="appointmentRenewalInput.endDate"></pv-start-end-date>

                <div class="faux-mat-form-field">
                    <mat-label>Filter on Carrier</mat-label>
                    <pv-carrier-multiselect [fromShell]="true"
                                            [(carriers)]="appointmentRenewalInput.carriers"
                                            [fullCarrierList]="allCarriers$ | async"></pv-carrier-multiselect>
                </div>
            </div>

            <div *ngSwitchCase="'terminatedOutsideProducers'">
                <div class="blurb-desc">
                    This report provides a listing of all producers who have been terminated with TMHCC carriers outside of IPA. This information comes directly from the producer's PDB and may not contain all terminations due to the information available from the PDB and terminations outside of IPA.
                </div>
                <div style="width: 100px;">
                    <pv-state-select [(state)]="producersTermedFromOutsideInput.state" class=""></pv-state-select>
                </div>

                <pv-start-end-date [(startDate)]="producersTermedFromOutsideInput.startDate" [(endDate)]="producersTermedFromOutsideInput.endDate"></pv-start-end-date>

                <div class="faux-mat-form-field">
                    <mat-label>Filter on Carrier</mat-label>
                    <pv-carrier-multiselect [fromShell]="true"
                                            [(carriers)]="producersTermedFromOutsideInput.carriers"
                                            [fullCarrierList]="allCarriers$ | async"></pv-carrier-multiselect>
                </div>
            </div>

            <div *ngSwitchCase="'licenseRenewal'">
                <div class="blurb-desc">
                    This report provides a listing of all producers based on the input parameters of the license expiration date. The information in this report comes directly from the producer's PDB.
                </div>
                <div class="radio-group faux-mat-form-field">
                    <mat-label>License Status:</mat-label>
                    <mat-radio-group name="reportRenewalLicenseType" [(ngModel)]="licenseRenewalInput.status">
                        <mat-radio-button value="active">Active</mat-radio-button>
                        <mat-radio-button value="inactive">Inactive</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="radio-group faux-mat-form-field">
                    <mat-label>Producer Type:</mat-label>
                    <mat-radio-group name="reportRenewalProducerType" [(ngModel)]="licenseRenewalInput.producerType">
                        <mat-radio-button [value]="null">Both</mat-radio-button>
                        <mat-radio-button value="Agent">Agent</mat-radio-button>
                        <mat-radio-button value="Agency">Agency</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="faux-mat-form-field">
                    <label>
                        <mat-checkbox name="include-missing-licenses" [(ngModel)]="licenseRenewalInput.missingStateLicense"></mat-checkbox>
                        <span>Show states without producer licenses</span>
                    </label>
                </div>
                <pv-start-end-date [(startDate)]="licenseRenewalInput.startDate" [(endDate)]="licenseRenewalInput.endDate" title="For 'Active', applies to Issue Date, for 'Inactive', applies to Expiration Date"></pv-start-end-date>
                <div class="faux-mat-form-field">
                    <mat-label>Filter on Business Unit</mat-label>
                    <pv-biz-unit-multiselect [fromShell]="true"
                                             [(bizUnits)]="licenseRenewalInput.bizUnits"
                                             [fullBuList]="userAllBizUnitRoles$ | async"></pv-biz-unit-multiselect>
                </div>
            </div>

            <div *ngSwitchCase="'untrackedProducers'">
                <div class="blurb-desc">
                    This report provides a listing of all producers who do not hold any TMHCC appointments and have been removed from the daily PDB updates from NIPR. The information on this report is manually tracked by users who update the producers and remove them from NIPR tracking.
                </div>
                <pv-start-end-date [(startDate)]="untrackedProducersInput.startDate" [(endDate)]="untrackedProducersInput.endDate"></pv-start-end-date>
            </div>

            <div *ngSwitchCase="'association'">
                <mat-form-field class="full-width" style="margin: 20px 0 10px;">
                    <mat-label>Type of Association</mat-label>
                    <mat-select name="assoc-type" [(ngModel)]="associationInput.type">
                        <mat-option value="associated">Producers Associated with BUs but not Appointed</mat-option>
                        <mat-option value="appointed">Producers Appointed with Carriers but not Associated</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngSwitchCase="'agencyAssociation'">
                <div class="blurb-desc">
                    This report will show by carrier the Agencies that have associations with an Agent. The information in this report is manually entered in IPA by individual users.
                </div>
                <div class="faux-mat-form-field">
                    <mat-label>Filter on Carrier</mat-label>
                    <pv-carrier-multiselect [fromShell]="true"
                                            [(carriers)]="agencyAssociationInput.carriers"
                                            [fullCarrierList]="allCarriers$ | async"></pv-carrier-multiselect>
                </div>
            </div>

            <div *ngSwitchCase="'incompleteOnboarding'">
                <div class="blurb-desc">
                    This report will show the onboarding application status off all applications not approved or denied. This information comes from the status of the application in IPA.
                </div>
                <div class="faux-mat-form-field">
                    <mat-label>Filter on Business Unit</mat-label>
                    <pv-biz-unit-multiselect [fromShell]="true"
                                             [(bizUnits)]="incompleteOnboardingInput.bizUnits"
                                             [fullBuList]="userAllBizUnitRoles$ | async"></pv-biz-unit-multiselect>
                </div>
                <div class="">
                    <mat-form-field class="full-width">
                        <mat-label>Application Status</mat-label>
                        <mat-select name="onboarding-status" [(ngModel)]="incompleteOnboardingInput.onboardingStatus">
                            <mat-option value="pending">Applications not yet Approved</mat-option>
                            <mat-option value="incomplete">Applications not yet Completed by External User</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <pv-start-end-date [(startDate)]="incompleteOnboardingInput.startDate" [(endDate)]="incompleteOnboardingInput.endDate"></pv-start-end-date>
            </div>

            <div *ngSwitchCase="'regedErrors'">
                <div class="blurb-desc">
                    This report provides a listing of all errors or rejections on appointments or terminations processed in IPA. The information for this report comes from the status of the appointment or termination submission.
                </div>
                <!-- NO INPUTS FOR RegEd Errors Report -->
            </div>

            <div *ngSwitchCase="'regedBilling'">
                <div class="blurb-desc">
                    This report provides a listing of all appointments or terminations submitted in IPA to NIPR by way of RegEd.
                </div>
                <pv-bu-carrier-shell [(bizUnits)]="regedBillingInput.bizUnits"
                                     [fullBuList]="userAllBizUnitRoles$ | async"
                                     [(carriers)]="regedBillingInput.carriers"
                                     [fullCarrierList]="allCarriers$ | async"></pv-bu-carrier-shell>

                <pv-start-end-date [(startDate)]="regedBillingInput.startDate" [(endDate)]="regedBillingInput.endDate"></pv-start-end-date>
            </div>

            <div *ngSwitchCase="'niprSeeding'">
                <div class="blurb-desc">
                    This report provides a listing of all producers who were added to IPA and who added them. Note that The user who adds the producer associates that producer with the business unit of that user.
                </div>
                <div class="faux-mat-form-field">

                    <pv-biz-unit-multiselect [fromShell]="true"
                                             [(bizUnits)]="niprSeedingInput.bizUnits"
                                             [fullBuList]="userAllBizUnitRoles$ | async"></pv-biz-unit-multiselect>
                </div>

                <pv-start-end-date [(startDate)]="niprSeedingInput.startDate" [(endDate)]="niprSeedingInput.endDate"></pv-start-end-date>
            </div>

            <div *ngSwitchCase="'enoExpiration'">
                <div class="blurb-desc">
                    This report will show the E&O expiration date for a producer. The information in this report is manually entered in IPA by individual users.
                </div>
                <pv-bu-carrier-shell [(bizUnits)]="enOInput.bizUnits"
                                     [fullBuList]="userAllBizUnitRoles$ | async"
                                     [(carriers)]="enOInput.carriers"
                                     [fullCarrierList]="allCarriers$ | async"></pv-bu-carrier-shell>

                <pv-start-end-date [(startDate)]="enOInput.startDate" [(endDate)]="enOInput.endDate"></pv-start-end-date>

                <div class="faux-mat-form-field">
                    <label>
                        <mat-checkbox name="include-null-toggle" [(ngModel)]="enOInput.includeNull"></mat-checkbox>
                        <span>Include Missing E&O Date</span>
                    </label>
                </div>
            </div>

            <div *ngSwitchCase="'auditCarrier'">
                <div class="blurb-desc">
                    This report provides a look into different sections on the Carrier Set Up page and provides details on when changes were made. This is an audit trail of changes that are done in IPA.
                </div>
                <mat-form-field class="half-width">
                    <mat-label>Audit Table</mat-label>
                    <mat-select name="audit-table" [(ngModel)]="auditCarrierInput.auditLogTable">
                        <mat-option *ngFor="let table of auditLogTables" [value]="table">{{table}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <pv-start-end-date [(startDate)]="auditCarrierInput.startDate" [(endDate)]="auditCarrierInput.endDate"></pv-start-end-date>

                <div class="faux-mat-form-field" *ngIf="auditCarrierInput.auditLogTable !== 'Authorized Signer'">
                    <mat-label>Filter on Carrier</mat-label>
                    <pv-carrier-multiselect [fromShell]="true"
                                            [(carriers)]="auditCarrierInput.carriers"
                                            [fullCarrierList]="allCarriers$ | async"></pv-carrier-multiselect>
                </div>
            </div>

            <div *ngSwitchCase="'bigBackgroundChecks'">
                <div class="blurb-desc">
                    This report provides a list of Background Checks sent through BIG
                </div>

                <pv-start-end-date [(startDate)]="bigBackgroundChecksInput.startDate" [(endDate)]="bigBackgroundChecksInput.endDate"></pv-start-end-date>

                <mat-form-field class="half-width">
                    <mat-label>Status</mat-label>
                    <mat-select name="bg-status" [(ngModel)]="bigBackgroundChecksInput.status">
                        <mat-option *ngFor="let status of bigBackgroundStatusList" [value]="status">{{status}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div *ngSwitchCase="'employeeLicenses'">
                <div class="blurb-desc">
                    This report provides a list of Employees Licenses
                </div>

                <div style="width: 100px;">
                    <pv-state-select [(state)]="employedProducerInput.state" class=""></pv-state-select>
                </div>

                <div class="faux-mat-form-field">
                    <label>
                        <mat-checkbox name="include-null-toggle" [(ngModel)]="employedProducerInput.include"></mat-checkbox>
                        <span>Include Inactive Licenses</span>
                    </label>
                </div>

                <pv-biz-unit-multiselect [(bizUnits)]="employedProducerInput.bizUnits"
                                         [fullBuList]="userAllBizUnitRoles$ | async"></pv-biz-unit-multiselect>

            </div>

            <div *ngSwitchCase="'employeeDlrp'">
                <div class="blurb-desc">
                    This report provides a list of DLRP Employee
                </div>

                <div style="width: 100px;">
                    <pv-state-select [(state)]="employedProducerInput.state" class=""></pv-state-select>
                </div>

                <div class="faux-mat-form-field">
                    <label>
                        <mat-checkbox name="include-null-toggle" [(ngModel)]="employedProducerInput.include"></mat-checkbox>
                        <span>Include Non-DLRP Producers</span>
                    </label>
                </div>

                <pv-biz-unit-multiselect [(bizUnits)]="employedProducerInput.bizUnits"
                                         [fullBuList]="userAllBizUnitRoles$ | async"></pv-biz-unit-multiselect>

            </div>
        </div>

        <button type="submit" class="button report-btn" [ngClass]="{'disabled': !userService.activeRoles.reports}" [disabled]="!userService.activeRoles.reports" title="{{userService.activeRoles.producerReportMessage}}">Generate Report</button>
    </form>

</div>
