<span class="alert" *ngIf="searchService.errorMessage">{{searchService.errorMessage}}</span>
<form (submit)="searchByFein()">
  <div>
      <mat-form-field class="full-width">
          <mat-label>FEIN</mat-label>
          <input name="fein" matInput type="text" id="fein-search" [(ngModel)]="searchService.searchInput.fein" />
          <mat-hint align="end">Required</mat-hint>
      </mat-form-field>
  </div>
  <div>
    <pv-state-select [(state)]="searchService.searchInput.stateCode" class="half-width first-half-option inline top" title="Resident state"></pv-state-select>
  </div>
  <button type="submit" class="button" id="search-producers" [disabled]="!userService.userRolesLoaded" [ngClass]="{'disabled': !userService.userRolesLoaded}" title="{{userService.userRolesLoaded ? '' : 'Loading User Roles...'}}">Search</button>
</form>

<div *ngIf="npnPassThrough">
    <div class="pv-modal">
        <div class="pv-modal-body">
            <div style="width: 100%; display: inline-block">
                <a class="button warning-btn float-right" (click)="close()" style="width: 100px; margin-top: 0; font-size: 1em;">Close</a>
            </div>
            <h3>
                NPN Found: {{npnPassThrough}}
            </h3>
            <a class="button" (click)="seedProducer()" style="font-size: 1em;">Add Producer</a>
        </div>
    </div>
    <div class="pv-modal-background"></div>
</div>
