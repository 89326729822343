<ng-container *ngIf="loading">
    <div class="loading-spinner"></div>
</ng-container>

<ng-container *ngIf="producerService.licenses">

    <mat-table *ngIf="producerService.licenses.length > 0" [dataSource]="dataSource">

        <ng-container matColumnDef="licenseNumber">
            <mat-header-cell *matHeaderCellDef style="flex: .8;">
                <span *ngIf="!showFilter">License Number</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 120px; padding: 10px 0;">
                    <mat-label class="white">License Number</mat-label>
                    <input matInput [formControl]="licenseNumberFilter" />
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: .8;">{{license.licenseNumber}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef style="flex: .4;">
                <span *ngIf="!showFilter">State</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 60px; padding: 10px 0;">
                    <mat-label class="white">State</mat-label>
                    <mat-select [formControl]="stateFilter">
                        <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: .4;">{{license.stateCode}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="class">
            <mat-header-cell *matHeaderCellDef style="flex: 1;">
                <span *ngIf="!showFilter">Class</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 100px; padding: 10px 0;">
                    <mat-label class="white">Class</mat-label>
                    <input matInput [formControl]="classFilter" />
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: 1;">{{license.class}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef style="flex: .5;">
                <span *ngIf="!showFilter">Status</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 80px; padding: 10px 0;">
                    <mat-label class="white">Status</mat-label>
                    <mat-select [formControl]="statusFilter">
                        <mat-option *ngFor="let status of staticStatusList" [value]="status">{{status}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: .5;"><div><p *ngFor="let status of license.statusArray">{{status}}</p></div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="loaCode">
            <mat-header-cell *matHeaderCellDef style="flex: 1;">
                <span *ngIf="!showFilter">Line of Authority</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 140px; padding: 10px 0;">
                    <mat-label class="white">Line of Authority</mat-label>
                    <mat-select [formControl]="loaFilter">
                        <mat-option *ngFor="let loa of lineOfAuthList" [value]="loa">{{loa}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: 1;"><div><p *ngFor="let det of license.details" title="{{det.description}} - {{det.code}}">{{det.description}}</p></div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="termination">
            <mat-header-cell *matHeaderCellDef style="flex: .9;">
                <span *ngIf="!showFilter">Termination Reason</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 100px; padding: 10px 0;">
                    <mat-label class="white">Termination Reason</mat-label>
                    <input matInput [formControl]="terminationFilter" />
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: .9;"><div><p *ngFor="let term of license.terminationArray" title="{{term}}">{{term ? term : '&nbsp;'}}</p></div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="effectiveDate">
            <mat-header-cell *matHeaderCellDef style="flex: .6;">Effective Date</mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: .6;">{{license.effectiveDateTime | date:'mediumDate'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
            <mat-header-cell *matHeaderCellDef style="flex: .6;">Expiration Date</mat-header-cell>
            <mat-cell *matCellDef="let license" style="flex: .6;">{{license.expirationDateTime | date:'mediumDate'}}</mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>
</ng-container>

<div [hidden]="!loading && (!producerService.licenses || producerService.licenses.length == 0)">
    <mat-paginator [pageSize]="50"
                   [pageSizeOptions]="[25, 50, 100]">></mat-paginator>
</div>

<div class="panel-no-results" *ngIf="!loading && ((producerService.licenses && producerService.licenses.length == 0) || producerService.producer.npn == null)">
    <h2>No Data Available</h2>
</div>
