export class BigOrderLog {
    id: number;

    producerId: number;
    producerName: string;
    bizUnitId: number;
    clientId: string;

    requesterEmail: string;
    requestId: string;
    createTime: Date;

    packageName: string[];

    releaseObtainedIndicator: boolean;
    subjectWantsCopyIndicator: boolean;

    //possible return data from BIG
    clientCaseId: string;
    status: string;
    result: string;
    redirectLink: string;
    errorDescription: string;
    requestReceivedDate: string;

    ipaStatus: string;
    ipaStatusEditTime: Date;

    get isCleared(): boolean {
        if (this.ipaStatus === 'Cleared') {
            return true;
        }
        return false;
    }

    get isPassed(): boolean {
        if (this.status && (this.status === 'Processed' || this.status === 'Completed') && this.result && this.result === 'pass') {
            return true;
        }
        return false;
    }

    get needsReview(): boolean {
        if (this.status && (this.status === 'Processed' || this.status === 'Completed') && this.result && this.result ===  'review') {
            return true;
        }
        return false;
    }

    get isFailed(): boolean {
        if (this.status && (this.status === 'Processed' || this.status === 'Completed') && this.result && this.result === 'fail') {
            return true;
        }
        return false;
    }

    get inProgress(): boolean {
        if (this.status && ((this.status === 'Processed' && this.result && this.result === 'pending') || (this.status === "In_Progress" || this.status === "Success"))) {
            return true;
        }
        return false;
    }

    get isErrored(): boolean {
        if (!this.status || this.status === "Error") {
            return true;
        }
        return false;
    }

    get unmappedStatus(): boolean {
        if (this.isPassed || this.needsReview || this.isFailed || this.inProgress || this.isErrored) {
            return false;
        }
        return true;
    }

    get isDuplicate(): boolean {
        if (this.isErrored && this.errorDescription.indexOf('is considered a duplicate') >= 0) {
            return true;
        }
        return false;
    }

    get isOutdated(): boolean {
        let oneYear = new Date();
        oneYear.setFullYear(oneYear.getFullYear() - 1);
        if (this.createTime < oneYear) {
            return true;
        }
        return false;
    }

    get displayStatus(): string {
        if (this.isDuplicate) {
            return "Duplicate";
        }
        if (this.isErrored) {
            return "Error";
        }
        switch (this.status) {
            case "In_Progress":
            case "Success": return "Pending";
            case "Complete":
            case "Completed":
            case "Processed": return this.result;
        }

        return this.status;
    }

    constructor(init?: Partial<BigOrderLog>) {
        this.id = init.id;

        this.producerId = init.producerId;
        this.producerName = init.producerName?.toLowerCase();
        this.bizUnitId = init.bizUnitId;
        this.clientId = init.clientId

        this.requesterEmail = init.requesterEmail;
        this.requestId = init.requestId;
        this.createTime = init.createTime;

        this.packageName = init.packageName;

        this.releaseObtainedIndicator = init.releaseObtainedIndicator;
        this.subjectWantsCopyIndicator = init.subjectWantsCopyIndicator;

        //possible return data from BIG
        this.clientCaseId = init.clientCaseId;
        this.status = init.status;
        this.result = init.result?.toLowerCase();
        this.redirectLink = init.redirectLink;
        this.errorDescription = init.errorDescription;
        this.requestReceivedDate = init.requestReceivedDate;

        this.ipaStatus = init.ipaStatus;
        this.ipaStatusEditTime = init.ipaStatusEditTime;
    }
}
