export class PdbAddress {
    line1: string;
    city: string;
    state: string;
    zip: string;
    editTime: Date;
    branchId: string;

    addressType: number;
    npn: number;

    stateCode: string;

    get oneLineAddress() {
        let display = '';

        if (this.line1) {
            display += this.line1;
        }

        display += " " + this.city + ", " + this.state + " " + this.zip;

        return display;
    }

    constructor(api?: Partial<PdbAddress>) {
        if (api) {
            this.line1 = api.line1
            this.city = api.city;
            this.state = api.state;
            this.zip = api.zip;
            this.editTime = api.editTime;
            this.branchId = api.branchId;

            this.addressType = api.addressType;
            this.npn = api.npn;
            this.stateCode = api.stateCode;
        }
    }
}

