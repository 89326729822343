<span class="alert" *ngIf="searchService.errorMessage">{{searchService.errorMessage}}</span>
<form (submit)="searchByNpn()">
    <div>
        <mat-form-field class="full-width">

            <mat-label>NPN</mat-label>
            <input name="npn" matInput type="text" id="npn-search" [(ngModel)]="searchService.searchInput.npn" />
            <mat-hint align="end">Required</mat-hint>
        </mat-form-field>
        <div>
            <pv-state-select [(state)]="searchService.searchInput.stateCode" [requiredNipr]="true" class="half-width first-half-option inline top"></pv-state-select>
        </div>
        <div class="faux-mat-form-field">
            <mat-label>Producer Type:</mat-label>
            <mat-radio-group name="searchByNpnLicenseType" [(ngModel)]="searchService.searchInput.type">
                <mat-radio-button value="Agent">Agent</mat-radio-button>
                <mat-radio-button value="Agency">Agency</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field class="full-width" *ngIf="searchService.searchInput.type == 'Agent'">
            <mat-label>Last Name</mat-label>
            <input name="last" matInput type="text" [(ngModel)]="searchService.searchInput.lastName" />
            <mat-hint align="end" title="Not required for local search">Required for NIPR Search</mat-hint>
        </mat-form-field>
    </div>
    <button type="submit" class="button" id="search-producers" [disabled]="!userService.userRolesLoaded" [ngClass]="{'disabled': !userService.userRolesLoaded}" title="{{userService.userRolesLoaded ? '' : 'Loading User Roles...'}}">Search</button>
</form>

<div *ngIf="seedNpnDirectly">
    <div class="pv-modal">
        <div class="pv-modal-body">
            <div style="width: 100%; display: inline-block">
                <a class="button warning-btn float-right" (click)="close()" style="width: 100px; margin-top: 0; font-size: 1em;">Close</a>
            </div>
            <h3 style="font-size: 1.4em;">NPN {{searchService.searchInput.npn}} was not found in the IPA database</h3>
            <h3 style="font-size: 1.1em;">To add the NPN to IPA select Add Producer below. To search NIPR without adding the Producer, enter additional search criteria</h3>
            <a class="button" (click)="seedProducer()" style="font-size: 1em;">Add Producer</a>
        </div>
    </div>
    <div class="pv-modal-background"></div>
</div>
