<div>
    <nav class="sub-navbar" *ngIf="currentUserSecurityRole$ | async as securityRole">
        <ul>
            <li><span><a routerLinkActive="active" [routerLink]="['./UserManagement']">Users</a></span></li>
            <li><span><a routerLinkActive="active" [routerLink]="['./Documents']">Documents</a></span></li>
            <li><span><a routerLinkActive="active" [routerLink]="['./SiteManagement']">Site Management</a></span></li>
            <li *ngIf="securityRole.systemAdmin"><span><a routerLinkActive="active" [routerLink]="['./RegEd']">RegEd</a></span></li>
            <li><span><a routerLinkActive="active" [routerLink]="['./CarrierSetup']">Carrier Setup</a></span></li>
        </ul>
    </nav>
    <div id="admin-contain">
        <router-outlet></router-outlet>
    </div>
</div>
