<h3>Appointment &amp; Termination Errors</h3>

<ng-container *ngIf="appointments">
    <div *ngIf="appointments.length > 0">
        <h4><a (click)="toggle('appts')">[{{showAppts?'-':'+'}}] Appointments - {{appts.length}}</a></h4>
        <ng-container *ngIf="showAppts">
            <div *ngFor="let appt of appts" class="appt-term-reject">
                <a [routerLink]="['/AppointProducer/' + appt.producerId + '/InProgressAppointment']" title="{{appt.displayStatus}}"><span class="uppercase">{{appt.producerName}}</span> - {{appt.carrierName}} - {{appt.stateCode}} <span *ngIf="appt.loa">- {{appt.loa}}</span></a><span *ngIf="appt.deleteAlert !== true && canClearAlerts" class="float-right"><a class="delete-btn" (click)="clearAlert(appt)"></a></span>
            </div>
        </ng-container>
        <h4><a (click)="toggle('terms')">[{{showTerms?'-':'+'}}] Terminations - {{terms.length}}</a></h4>
        <ng-container *ngIf="showTerms">
            <div *ngFor="let appt of terms" class="appt-term-reject">
                <a [routerLink]="['/AppointProducer/' + appt.producerId + '/InProgressAppointment']" title="{{appt.displayStatus}}"><span class="uppercase">{{appt.producerName}}</span> - {{appt.carrierName}} - {{appt.stateCode}} <span *ngIf="appt.loa">- {{appt.loa}}</span></a><span *ngIf="appt.deleteAlert !== true && canClearAlerts" class="float-right"><a class="delete-btn" (click)="clearAlert(appt)"></a></span>
            </div>
        </ng-container>
    </div>

    <div *ngIf="appointments.length === 0">
        <p>No current Rejections!</p>
    </div>
</ng-container>

<ng-container *ngIf="!appointments">
    <div class="loading-spinner"></div>
</ng-container>

