import { BizUnit, Agency, Agent } from '../general';
import { Comment } from '../comment';

export interface IPdbDetails {
    businessUnits: BizUnit[];
    masterAgency: string;
    branchAgencies: Agency[];
    agents: Agent[];
    designatedAgent: Agent;
    comments: Comment[];
}

export class IPdbAppointment {
    id: number;
    carrierId: number;
    companyCode: string;
    npn: number;
    fein: string;
    companyName: string;
    state: string;
    countyCode: string;
    loaCode: number;
    loaDescription: string;
    status: string;
    renewDate: string;
    renewDateTime: Date;
    branchID: string;
    firmName: string;
    individualName: string;
    effectiveDate: string;
    effectiveDateTime: Date;
    terminationReason: string;
    asOfDate: string;
    asOfDateTime: Date;

    producerId: number;
    dataSource: string;
    //isInNipr: boolean;
    isLocal: boolean;

    get displayStatus(): string {
        if (this.isLocal) {
            if (this.status === "Appointed") {
                return "Recorded";
            }
            else if(this.status === "Terminated") {
                return "Recorded - Terminated";
            }
        }

        return this.status;
    }

    constructor(init?: Partial<IPdbAppointment>) {
        Object.assign(this, init);

        this.renewDateTime = init.renewDate ? new Date(init.renewDate.replace('Z', '')) : null;
        this.effectiveDateTime = init.effectiveDate ? new Date(init.effectiveDate.replace('Z', '')) : null;
        this.asOfDateTime = init.asOfDate ? new Date(init.asOfDate.replace('Z', '')) : null;
    }
}

export interface IPdbDemographic {
    npn: number;
    branchID: string;
    phone: string;
    phoneUpdateDate: Date;
    email: string;
    emailUpdateDate: Date;
    fax: string;
    faxUpdateDate: Date;
    address: string;
    addressUpdateDate: Date;
    addressType: string;
}

export interface IPdbRegulatory {
    npn: number;
    actionId: number;
    state: string;
    origin: string;
    reason: string;
    disposition: string;
    dateOfAction: Date;
    effectiveDate: Date;
    entryDate: Date;
    fileRef: string;
    penalty: number;
    lengthOfOrder: string;
    asOfDate: Date;
}

