import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OAuthModuleConfig, OAuthModule } from 'angular-oauth2-oidc';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
//import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppConfigService } from './services/app-config.service';
import { authConfigFactory } from './auth.config';
import { AppComponent } from './app.component';
import { ProducerModule } from './producer/producer.module';
import { AdminModule } from './admin/admin.module';
import { ReportsModule } from './reports/reports.module';
import { UserService } from './services/user.service';
import { ModalComponent } from './modal/modal.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { FormsModule } from '@angular/forms';
//import { SsnMaskPipe } from './shared/form/ssn-mask.pipe';
import { SettingsModule } from './settings/settings.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { securityRolesReducer } from './user/state/security-role.reducer';
import { SecurityRoleEffects } from './user/state/security-role.effects';
import { ModalService } from './modal/modal.service';
import { ModalModule } from './shared/modules/modal/modal.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlertsComponent } from './dashboard/alerts/alerts.component';
import { ApptTermRejectionsComponent } from './dashboard/appt-term-rejections/appt-term-rejections.component';
import { OnboardingReadyReviewComponent } from './dashboard/onboarding-ready-review/onboarding-ready-review.component';
import { RemovedProducersComponent } from './dashboard/removed-producers/removed-producers.component';
import { ExternalLinksComponent } from './modal/external-links/external-links.component';
import { BackgroundChecksComponent } from './dashboard/background-checks/background-checks.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export function appInit(appConfigService: AppConfigService) {
    return () => appConfigService.load();
}

export const routes: Routes = [
    { path: '', redirectTo: 'Home', pathMatch: 'full' },
    { path: '**', redirectTo: 'Home', pathMatch: 'full' },
    { path: 'Home', component: DashboardComponent }
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
}

@NgModule({ declarations: [
        AppComponent,
        DashboardComponent,
        AlertsComponent,
        ApptTermRejectionsComponent,
        OnboardingReadyReviewComponent,
        RemovedProducersComponent,
        ExternalLinksComponent,
        BackgroundChecksComponent,
        //SsnMaskPipe,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        OAuthModule.forRoot(),
        RouterModule.forRoot(routes, routerOptions),
        ModalModule,
        ProducerModule,
        OnboardingModule,
        ReportsModule,
        SettingsModule,
        AdminModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({ 'securityRoles': securityRolesReducer }, {}),
        StoreDevtoolsModule.instrument({ name: 'IPA internal', maxAge: 25, logOnly: environment.production, connectInZone: true }),
        EffectsModule.forRoot([SecurityRoleEffects])],
    providers: [UserService, AppConfigService, ModalService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            multi: true,
            deps: [AppConfigService]
        },
        { provide: OAuthModuleConfig, useFactory: authConfigFactory, deps: [AppConfigService]},
        provideHttpClient(withInterceptorsFromDi()),
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    ]
})
export class AppModule { }
