
<pv-alerts [bizUnit]="bizUnit$ | async" [roles]="currentUserSecurityRole$ | async"></pv-alerts>


<pv-site-management #childSiteManagement
                    [errorMessage]="errorMessage$ | async"
                    [bizUnit]="bizUnit$ | async"
                    [bizUnitVerbiage]="bizUnitVerbiage$ | async"
                    (saveVerbiageEmitter)="saveVerbiage($any($event))">
</pv-site-management>


