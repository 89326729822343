import { Component, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { GlobalLists } from '../../lists';

@Component({
    selector: 'pv-state-select',
    templateUrl: './state-select.component.html'
})
export class StateSelectComponent {

    statesList: string[] = GlobalLists.stateList;

    @Input() required = false;

    @Input() requiredNipr = false;

    @Input() title = "State";

    @Input() disabled = false;

    @Input() state: string;

    @Output() stateChange: EventEmitter<string> = new EventEmitter<string>();

    constructor() { }

}
