import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { SettingsComponent } from './settings.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { NpnSeedingComponent } from './general/npn-seeding.component';
import { NpnUntrackComponent } from './general/npn-untrack.component';

export const routes: Routes = [
    {
        path: 'Settings', component: SettingsComponent, data: { title: 'Settings' }
    }
];

@NgModule({
  declarations: [SettingsComponent, PreferencesComponent, NpnSeedingComponent, NpnUntrackComponent],
  imports: [
      CommonModule,
      FormsModule,
      MatTableModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatCheckboxModule,
      CdkTableModule,
      RouterModule.forChild(routes)
  ]
})
export class SettingsModule { }
