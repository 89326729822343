import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { AdminComponent } from './admin.component';
import { UsersComponent } from './users/users.component';
import { UsersShellComponent } from './users/user-shell.component';
import { OnboardingDocumentsComponent } from './documents/onboarding-documents.component';
//import { CarrierSetupComponent } from './carrier-setup/carrier-setup.component';
import { CarrierSetupShellComponent } from './carrier-setup/carrier-setup-shell.component';
import { SiteManagementComponent } from './site-management/site-management.component';
import { AdminAuthGuard } from './admin-auth.guard';
import { VerbiagePreviewDialogComponent } from './site-management/verbiage-preview-dialog.component'
import { PageNoPermissionComponent } from './page-no-permission.component';
import { DocumentUploadComponent } from './documents/document-upload/document-upload.component';
import { SiteManagementShellComponent } from './site-management/site-management-shell.component';
import { SiteManagementChangesNotSavedGuard } from './site-management/site-management-changes-not-saved.guard';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { adminReducer } from './state/admin.reducer';
import { AdminEffects } from './state/admin.effects';
import { OnboardingDocumentsShellComponent } from './documents/onboarding-documents-shell.component';
import { EditCarrierComponent } from './carrier-setup/edit-carrier/edit-carrier.component';
import { StateSelectModule } from '../shared/modules/state-select/state-select.module';
import { PhoneMaskPipeModule } from '../shared/form/phone-mask.directive';
import { RegedComponent } from './reged/reged.component';
import { AlertsComponent } from './site-management/alerts/alerts.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

export const routes: Routes = [
    {
        path: 'Admin', component: AdminComponent, data: { title: 'Admin' }, children: [
            { path: 'CarrierSetup', canActivate: [AdminAuthGuard], component: CarrierSetupShellComponent },
            { path: 'RegEd', canActivate: [AdminAuthGuard], component: RegedComponent },
            { path: 'UserManagement', canActivate: [AdminAuthGuard], component: UsersShellComponent },
            { path: 'Documents', canActivate: [AdminAuthGuard], component: OnboardingDocumentsShellComponent },
            { path: 'SiteManagement', canActivate: [AdminAuthGuard], canDeactivate: [SiteManagementChangesNotSavedGuard], component: SiteManagementShellComponent },
            { path: 'NoPermission', component: PageNoPermissionComponent},
            { path: '', redirectTo: 'UserManagement', pathMatch: 'full' }
        ]
    }
];

@NgModule({
    declarations: [AdminComponent, UsersComponent, OnboardingDocumentsComponent, DocumentUploadComponent, SiteManagementComponent, VerbiagePreviewDialogComponent, PageNoPermissionComponent,
        CarrierSetupShellComponent,
        OnboardingDocumentsShellComponent,
        UsersShellComponent,
        SiteManagementShellComponent,
        EditCarrierComponent,
        RegedComponent,
        AlertsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatDialogModule,
        MatIconModule,
        CdkTableModule,
        DragDropModule,
        StateSelectModule,
        PhoneMaskPipeModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('admin', adminReducer),
        EffectsModule.forFeature([AdminEffects])
    ]
})
export class AdminModule { }
