import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProducerDataService } from '../producer-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../modal/modal.service';
import { CreateApplication, ExternalLogin } from '../../shared/models/application';
import { ApplicationDocument, DocuSignTemplate } from '../../shared/models/document';
import { DataService } from '../../services/data.service';
import { Producer } from '../../shared/models/pob/producer';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { getCurrentBizUnitSelector, getSelectedSecurityRolesSelector } from '../../user/state';
import { map } from 'rxjs/operators';
import { InputForOneRegedCreateOrder, LocalPobAppointmentCreateInput, PobAppointment } from '../../shared/models/pob/appointing-license';
import { forkJoin, Observable } from 'rxjs';
import { IPdbAppointment } from '../../shared/models/dto/pdb-result.dto';
import { License } from '../../shared/models/pob/license';
import { Carrier, CarrierRegedLicenseTypeDTO, CarrierRegedLoaDTO, RegedLicenseType, RegedLOA } from '../../shared/models/pob/carrier';
import { RegedOrderStatus } from '../../shared/enums/reged';
import { UserRoles } from '../../shared/models/user';

@Component({
    selector: 'pv-onboard',
    templateUrl: './onboard.component.html',
    styleUrls: ['./onboard.component.css']
})
export class OnboardComponent implements OnInit, OnDestroy {

    public onboardApplication: CreateApplication = new CreateApplication();
    public trackingOnly = false;

    localCopyOfBizUnitCarriers: Carrier[];
    currentUserSecurityRole$: Observable<UserRoles>;

    paramId = '';
    id: number = null;

    availableDocs: DocuSignTemplate[];

    bizLoaded = false;
    prodLoaded = false;
    public dataLoaded = false;

    get selectedCarriers(): Carrier[] {
        return this.localCopyOfBizUnitCarriers.filter(c => c.selected);
    }
    public selectedCarrier: Carrier;
    updatedCarrier: Carrier;
    appointments: PobAppointment[] = [];

    private buSubscription;

    constructor(public producerService: ProducerDataService, public userService: UserService, private dataService: DataService, private onboardService: OnboardingService, private store: Store<State>, private modal: ModalService, private route: ActivatedRoute, private router: Router) {
        this.currentUserSecurityRole$ = this.store.select(getSelectedSecurityRolesSelector);
    }

    ngOnInit() {
        document.title = "Onboard Producer";
        console.log("Onboard");

        this.onboardApplication.login = new ExternalLogin();
        this.onboardApplication.creator = this.userService.getUserEmail;

        this.paramId = this.route.snapshot.paramMap.get('id');
        const numberId = +this.paramId;
        this.id = numberId;

        if (this.producerService.producer && this.producerService.producer.id === this.id) {
            this.prodLoaded = true;
            
            if (!this.producerService.producer.licenses) {
                this.producerService.getLicensesByNpn(this.producerService.producer.npn).subscribe(resData => {
                    if (resData && resData.status === 0 && resData.dataList) {
                        this.producerService.producer.licenses = resData.dataList.map(x => new License(x));
                    }
                    this.populateApplication();
                });
            }
            else {
                this.populateApplication();
            }
        }
        else {
            this.producerService.pobAppointments = null;

            this.producerService.updateId(this.id);

            this.producerService.getProducer(this.id).subscribe({
                next: resData => {
                    if (resData) {
                        this.producerService.producer = new Producer(resData.item);
                        this.prodLoaded = true;
                        if (!this.producerService.producer.licenses) {
                            this.producerService.getLicensesByNpn(this.producerService.producer.npn).subscribe(resData => {
                                if (resData && resData.status === 0 && resData.dataList) {
                                    this.producerService.producer.licenses = resData.dataList.map(x => new License(x));
                                }
                                this.populateApplication();
                            });
                        }
                        else {
                            this.populateApplication();
                        }
                    }
                    else {
                        this.modal.message("Producer data not found");
                    }
                }
            });
        }

        this.buSubscription = this.store.select(getCurrentBizUnitSelector).pipe(
            map(value => value))
            .subscribe(bizUnit => {
                if (bizUnit) {
                    this.updateDocumentList(bizUnit.id);
                    this.onboardApplication.bizUnitId = bizUnit.id;

                    const ids = bizUnit.bizUnitCarriers.map(b => b.carrier.id);
                    if (this.producerService.pdbAppointmentsFullyLoaded) {
                        this.producerService.carrierAppointments = this.producerService.pdbAppointments.filter(a => ids.indexOf(a.carrierId) >= 0).map(a => new IPdbAppointment(a));
                    }
                    else {
                        this.producerService.getAppointmentsByProdId(this.id, []).subscribe(appData => {
                            if (appData && appData.status === 0 && appData.dataList) {
                                this.producerService.pdbAppointments = appData.dataList.map(a => new IPdbAppointment(a));
                                this.producerService.pdbAppointmentsFullyLoaded = true;
                                this.producerService.carrierAppointments = this.producerService.pdbAppointments.filter(a => ids.indexOf(a.carrierId) >= 0).map(a => new IPdbAppointment(a));
                            }
                        });
                    }

                    this.localCopyOfBizUnitCarriers = bizUnit.bizUnitCarriers.map(r => new Carrier(r.carrier));

                    this.bizLoaded = true;
                    this.populateApplication();
                }
            });
    }

    ngOnDestroy() {
        if (this.buSubscription) {
            this.buSubscription.unsubscribe();
        }
    }

    updateDocumentList(bizUnitId: number) {
        this.availableDocs = [];
        this.dataService.getDocumentListsByBizUnit(bizUnitId).subscribe({
            next: resData => {
                if (resData && resData.status === 0) {
                    this.availableDocs = resData.dataList.map(x => new DocuSignTemplate(x));
                }
                else {
                    console.log(resData.message);
                }
            }
        });
    }

    private populateApplication(): void {
        if (this.producerService.producer && !this.producerService.licenses) {
            this.producerService.licenses = this.producerService.producer.licenses;
        }
        if (!this.dataLoaded && this.prodLoaded && this.bizLoaded) {

            if (this.producerService.pobAppointments === null) {
                this.producerService.getPobAppointments(this.id).subscribe(resData => {
                    if (resData && resData.dataList) {
                        this.producerService.pobAppointments = resData.dataList.map(x => new PobAppointment(x, this.localCopyOfBizUnitCarriers));
                        this.dataService.initPobAppointments(this.producerService.licenses, this.producerService.pobAppointments);
                        //this.appointments = this.producerService.pobAppointments.filter(a => a.carrier && a.status === RegedOrderStatus[RegedOrderStatus.AttachedToApplication]);
                    }
                });
            }

            this.onboardApplication.producerType = this.producerService.producer.type.toString();

            if (this.onboardApplication.producerType === "Agent") {
                this.onboardApplication.login.firstName = this.producerService.producer.firstName.toLocaleLowerCase();
                this.onboardApplication.login.lastName = this.producerService.producer.lastName.toLocaleLowerCase();
            }

            if (environment.production) {
                this.onboardApplication.login.email = this.producerService.producer.contact.email;
            }

            this.onboardApplication.bizUnitId = this.userService.activeBusinessUnit.id;

            this.onboardApplication.producer = this.producerService.producer;

            this.dataLoaded = true;
        }
    }

    submitApplication() {
        this.onboardApplication.documents = this.availableDocs.filter(opt => opt.selected).map(x => ApplicationDocument.FromDocuSignTemplate(x, this.userService.userIdentifier));
        this.onboardApplication.carrierIds = this.localCopyOfBizUnitCarriers.filter(opt => opt.selected).map(x => x.id);

        if (this.validate()) {
            this.modal.loading(true);
            this.sendApplication();
        }
    }

    sendApplication() {
        console.log(this.onboardApplication);
        this.dataService.createNewApplication(this.onboardApplication).subscribe({
            next: resData => {
                this.modal.loading(false);
                if (resData && resData.status === 0) {
                    this.onboardService.updateOnboardingApplications([]);
                    if (!this.onboardApplication.allowToSelectAppointmentStates && this.appointments.length > 0) {
                        //add new Application ID to each appointment message
                        this.appointments.forEach(a => {
                            a.message = '{ "ApplicationId": "' + resData.item + '",' + a.message;
                            if (a.message.indexOf(",") === a.message.length - 1) {
                                a.message = a.message.slice(0, -1);//remove the last trailing comma we added below
                            }
                            a.message += " }";
                        });
                        this.producerService.AddOrUpdateCarrierProducerAppointment(this.appointments).subscribe(resData => {
                            if (resData && resData.status === 0 && resData.item) {
                                //success
                            }
                            this.router.navigate(['./ProducerOnboarding/Dashboard']);
                        });
                    }
                    else {
                        this.router.navigate(['./ProducerOnboarding/Dashboard']);
                    }
                }
            }
        });
    }

    validate(): boolean {
        if (!this.validateEmail(this.onboardApplication.login.email)) {
            return false;
        }

        if (this.onboardApplication.carrierIds.length === 0) {
            this.modal.message("Please select at least one Carrier for Appointment");
            return false;
        }

        if (this.onboardApplication.documents.length === 0) {
            this.modal.message("Please select at least one Document for signing");
            return false;
        }

        return true;
    }

    validateEmail(email: string) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
            return true;
        }
        this.modal.message("Please enter a valid email address!");
        return false;
    }

    handleApptSelection(selectedAppointments: InputForOneRegedCreateOrder[]) {
        selectedAppointments.forEach(a => {
            a.carrierLOAsMatrix.forEach(loa => {
                if (this.appointments.find(appts => appts.carrierId === loa.carrierId && appts.classCode === loa.classCode && appts.licenseId === loa.licenseId && appts.licenseDetailId === loa.licenseDetailId)) {
                    //do nothing
                }
                else {
                    loa.message = "";
                    if (a.regedOrderData.floridaCountyCode) {
                        loa.message += '"floridaCountyCode": "' + a.regedOrderData.floridaCountyCode + '",';
                    }
                    if (a.regedOrderData.licenseTypeText) {
                        loa.message += '"licenseTypeText": "' + a.regedOrderData.licenseTypeText + '",';
                    }
                    loa.status = RegedOrderStatus[RegedOrderStatus.AttachedToApplication];
                    this.appointments = this.appointments.concat(a.carrierLOAsMatrix);
                }
            });
        });
    }

    
    carrierChanged(): void {
        if (this.selectedCarrier && this.selectedCarrier.allowedLicenseTypes === null) {
            forkJoin(
                this.dataService.getCarrierAllowedRegedLicenseTypes(this.selectedCarrier.id),
                this.dataService.getCarrierAllowedRegedLoas(this.selectedCarrier.id)
            )
                .subscribe(([ltpData, loaData]) => {
                    if (ltpData.status === -1) {
                        alert("Unable to get Carrier Allowed License Types. API error: \n\r" + ltpData.message);
                        return;
                    }
                    if (loaData.status === -1) {
                        alert("Unable to get Carrier Allowed LOAs. API error: \n\r" + loaData.message);
                        return;
                    }
                    if (ltpData && ltpData.status === 0 && ltpData.dataList) {
                        this.selectedCarrier.allowedLicenseTypes = ltpData.dataList.map(x => new CarrierRegedLicenseTypeDTO(x));
                    }
                    else {
                        this.selectedCarrier.allowedLicenseTypes = [];
                    }

                    if (loaData && loaData.status === 0 && loaData.dataList) {
                        this.selectedCarrier.allowedLoas = loaData.dataList.map(x => new CarrierRegedLoaDTO(x));
                    }
                    else {
                        this.selectedCarrier.allowedLoas = [];
                    }
                    this.updateCarrierForChild(this.selectedCarrier);
                });
        }
        else {
            this.updateCarrierForChild(this.selectedCarrier);
        }
    }

    updateCarrierForChild(carrier: Carrier) {
        this.updatedCarrier = carrier;
    }
}
