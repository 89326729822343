<span class="alert" *ngIf="searchService.errorMessage">{{searchService.errorMessage}}</span>
<form (submit)="searchByAgencyName()">
  <div>
      <mat-form-field class="full-width">
          <mat-label>Agency Name</mat-label>
          <input name="name" matInput type="text" id="agency-name-search" [(ngModel)]="searchService.searchInput.companyName" />
          <mat-hint align="end">Required</mat-hint>
      </mat-form-field>
  </div>
  <div>
    <pv-state-select [(state)]="searchService.searchInput.stateCode" class="half-width first-half-option inline top"></pv-state-select>
  </div>
  <button type="submit" class="button" id="search-producers" [disabled]="!userService.userRolesLoaded" [ngClass]="{'disabled': !userService.userRolesLoaded}" title="{{userService.userRolesLoaded ? '' : 'Loading User Roles...'}}">Search</button>
</form>
