<div id="content" *ngIf="!userService.userRolesLoaded">
    <h2>Loading user permissions...</h2>
    <div class="loading-spinner"></div>
</div>
<div *ngIf="userService.userRolesLoaded">
    <div id="settings-contain">
        <pv-npn-seeding></pv-npn-seeding>
        <pv-npn-untrack></pv-npn-untrack>
        <pv-preferences></pv-preferences>
    </div>
</div>
