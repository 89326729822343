
<h3>Producer Search</h3>

<div id="search">
  <nav class="sub-navbar">
    <ul>
      <li><span><a routerLinkActive="active" [routerLink]="['./ByName']">By Name</a></span></li>
      <li><span><a routerLinkActive="active" [routerLink]="['./ByLicense']">By License</a></span></li>
      <li><span><a routerLinkActive="active" [routerLink]="['./ByAgency']">By Agency</a></span></li>
      <li><span><a routerLinkActive="active" [routerLink]="['./ByNpn']">By NPN</a></span></li>
      <li><span><a routerLinkActive="active" [routerLink]="['./ByFein']">By FEIN</a></span></li>
    </ul>
  </nav>
  <div id="search-contain">
    <div class="search-option">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-container *ngIf="hasSearched">
  <div id="search-results">
    <pv-biographic-search-results [results]="pdbSearchResults"></pv-biographic-search-results>
  </div>
</ng-container>
