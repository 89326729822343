
export function InputStateColorCode(el: HTMLElement, resData: any): void {
    const origColor = el.style.backgroundColor;

    if (resData.status === 0) {
        //success
        el.style.backgroundColor = '#b5f1b5';
    }
    else {
        el.style.backgroundColor = '#ff6464';
    }

    setTimeout(() => {
        el.style.backgroundColor = origColor;
    }, 3500);
}
