import { Component, OnInit, ViewChild } from '@angular/core';
import { ProducerDataService } from '../producer-data.service';
import { ActivatedRoute } from '@angular/router';
import { Producer } from '../../shared/models/pob/producer';
import { ModalService } from '../../modal/modal.service';
import { UserService } from '../../services/user.service';
import { License } from '../../shared/models/pob/license';
import { Observable, forkJoin, of } from 'rxjs';
import { IClientListDTO } from '../../shared/models/dto/common-result.dto';
import { IPdbAppointment } from '../../shared/models/dto/pdb-result.dto';
import { DataService } from '../../services/data.service';
import { Carrier } from '../../shared/models/pob/carrier';
import { PobAppointment } from '../../shared/models/pob/appointing-license';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Address } from '../../shared/models/pob/address';
import { Contact } from '../../shared/models/pob/contact';
import { PhoneNumberFormat } from '../../shared/utils/string-transform';
import { GlobalConsts } from '../../shared/utils/constants';


@Component({
    selector: 'pv-appoint',
    templateUrl: './appoint.component.html',
    styleUrls: ['./appoint.component.css']
})
export class AppointComponent implements OnInit {

    paramId = '';
    id: number = null;

    public loaded = false;
    producerCopy: Producer;
    phoneNeeded = false;
    taxIdNeeded = false;
    dobNeeded = false;
    resStateNeeded = false;
    addressNeeded = false;
    newAddress: Address;
    get anythingNeeded(): boolean {
        if (this.phoneNeeded || this.taxIdNeeded || this.dobNeeded || this.resStateNeeded || this.addressNeeded) {
            return true;
        }
        return false;
    }

    public activeLicenses: License[];
    bizUnitCarriers: Carrier[];
    showMask = true;

    @ViewChild('producerDetails') producerDetailsForm: NgForm;

    constructor(private producerService: ProducerDataService, public userService: UserService, private dataService: DataService, private modal: ModalService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        document.title = "IPA - Appoint Producer";
        console.log('appoint');
        this.paramId = this.route.snapshot.paramMap.get('id');
        const numberId = +this.paramId;
        this.id = numberId;

        if (this.producerService.producer && this.id === this.producerService.producer.id) {
            this.getAllDataLists(this.producerService.producer.npn);           
        }
        else {
            this.producerService.updateId(this.id);
            this.producerService.getProducer(this.id).pipe(
                map(value => value)).subscribe({
                next: resData => {
                    if (resData && resData.item) {
                        this.producerService.producer = new Producer(resData.item);
                        this.getAllDataLists(resData.item.npn);
                    }
                    else {
                        this.modal.message("Producer data not found");
                    }
                }
            });
        }
    }

    getAllDataLists(npn: number) {
        this.bizUnitCarriers = this.userService.activeBizUnitCarriers;
        if (this.bizUnitCarriers) {
            forkJoin(this.getLicenses(npn), this.getAppointments(), this.getPobAppointments()).subscribe(([licData, appData, pobData]) => {
                if (licData && licData.status === 0 && licData.dataList) {
                    this.producerService.licenses = licData.dataList.map(x => new License(x));
                }

                if (appData && appData.status === 0 && appData.dataList) {
                    this.producerService.pdbAppointments = appData.dataList.map(a => new IPdbAppointment(a));
                    this.producerService.pdbAppointmentsFullyLoaded = true;
                    const ids = this.bizUnitCarriers.map(b => b.id);
                    this.producerService.carrierAppointments = this.producerService.pdbAppointments.filter(a => ids.indexOf(a.carrierId) >= 0);//.map(a => new IPdbAppointment(a));
                }

                if (pobData && pobData.dataList) {
                    this.producerService.pobAppointments = pobData.dataList.map(a => new PobAppointment(a, this.userService.activeBizUnitCarriers));

                    this.dataService.initPobAppointments(this.producerService.licenses, this.producerService.pobAppointments);
                }

                this.populateApplication();
            });
        }
    }

    //In progress RegEd Appointments
    getPobAppointments(): Observable<IClientListDTO<PobAppointment>> {
        return this.producerService.getPobAppointments(this.id);
    }

    getLicenses(npn: number): Observable<IClientListDTO<License>> {
        if (!this.producerService.licenses) {
            if (npn) {
                return this.producerService.getLicensesByNpn(npn);
            }
        }
        else {
            return of({ status: -1, message: "", dataList: [] });
        }
    }

    //Gets all Local and NIPR appointments (all real / completed appts)
    getAppointments(): Observable<IClientListDTO<IPdbAppointment>> {
        if (this.producerService.pdbAppointmentsFullyLoaded) {
            const ids = this.bizUnitCarriers.map(b => b.id);
            this.producerService.carrierAppointments = this.producerService.pdbAppointments.filter(a => ids.indexOf(a.carrierId) >= 0);//.map(a => new IPdbAppointment(a));
            return of({ status: -1, message: "", dataList: [] });
        }
        else {
            return this.producerService.getAppointmentsByProdId(this.id, []);
        }
    }

    private checkWhatFieldsNeeded(): void {
        (this.producerCopy.contact.bizPhone) ? this.phoneNeeded = false : this.phoneNeeded = true;
        if (this.producerService.producer.type === 'Agent') {
            this.producerCopy.ssn ? this.taxIdNeeded = false : this.taxIdNeeded = true;
            this.producerCopy.dateOfBirth ? this.dobNeeded = false : this.dobNeeded = true;

            if (!this.producerCopy.contact.addresses || !this.producerCopy.contact.residentialAddress.id) {
                this.newAddress = new Address({ isResidentialAddress: true });
                this.addressNeeded = true;
            }
            else {
                this.addressNeeded = false;
            }
        }
        else {
            this.producerCopy.fein ? this.taxIdNeeded = false : this.taxIdNeeded = true;

            if (!this.producerCopy.contact.addresses || !this.producerCopy.contact.businessAddress.id) {
                this.newAddress = new Address({ isBusinessAddress: true });
                this.addressNeeded = true;
            }
            else {
                this.addressNeeded = false;
            }
        }
        this.producerCopy.stateCode ? this.resStateNeeded = false : this.resStateNeeded = true;

        
    }

    private populateApplication(): void {
        this.producerCopy = Producer.shortProducer(this.producerService.producer);

        this.checkWhatFieldsNeeded();

        this.loaded = true;
    }

    getLikelyResidentialAddress(el: HTMLElement) {
        el.style.visibility = "hidden";
        this.modal.loading(true);
        let addType = (this.newAddress.isResidentialAddress ? "Residence" : "Business");
        this.producerService.getProducerMostRecentAddress(this.producerService.producer.id, addType).subscribe(resData => {
            if (resData && resData.item) {
                resData.item.id = null; //this was a PDB id, not POB
                this.newAddress = new Address(resData.item);
                this.producerDetailsForm.form.markAsDirty();
                this.modal.message("We found a likely " + addType + " Address.  Please Confirm!");
            }
            else {
                this.modal.message("We were unable to find any potential " + addType + " Address for this Producer.");
            }
        });
    }

    updateProducer() {
        if (this.taxIdNeeded) {
            if (this.producerService.producer.type === "Agent") {
                const valid = GlobalConsts.ssnRegex.test(this.producerCopy.ssn);
                if (!valid) {
                    alert('SSN format is incorrect (###-##-####)');
                    return;
                }
            }
            else {
                const valid = GlobalConsts.feinRegex.test(this.producerCopy.fein);
                if (!valid) {
                    alert('FEIN format is incorrect (##-#######)');
                    return;
                }
            }
        }

        if (this.phoneNeeded) {
            const val = PhoneNumberFormat(this.producerCopy.contact.bizPhone);
            const valid = GlobalConsts.phoneNumRegex.test(val);
            if (!valid) {
                alert('Phone number format is incorrect (###-###-####)');
                return;
            }
            this.producerCopy.contact.bizPhone = val;
        }
        if (this.addressNeeded) {
            if (!(this.newAddress.line1 && this.newAddress.city && this.newAddress.state && this.newAddress.zip)) {
                alert('Required Address fields are missing');
                return;
            }
            else if (!GlobalConsts.zipRegex.test(this.newAddress.zip)) {
                alert('Invalid Zip Code format! Zip code must be in either ##### or #####-#### format.');
                return;
            }
            else {
                this.newAddress.editTime = new Date();
            }
        }

        this.modal.loading(true);
        this.producerService.updateProducer(this.producerCopy).subscribe(resData => {
            if (resData && resData.item) {
                if (this.addressNeeded || this.phoneNeeded) {
                    const saveCopy = new Contact(this.producerCopy.contact);
                    if (this.addressNeeded) {
                        this.newAddress.editor = this.userService.getUserEmail;
                        this.newAddress.editTime = new Date();
                        this.newAddress.dataSource = 'UI';
                        saveCopy.addresses.push(this.newAddress);
                    }
                    saveCopy.editor = this.userService.getUserEmail;
                    this.producerService.updateContact(saveCopy, this.id).subscribe(resData => {
                        //success
                        if (resData && resData.status === 0) {
                            if (this.addressNeeded) {
                                this.producerCopy.contact.addresses.push(this.newAddress);
                                if (this.producerCopy.type === "Agent") {
                                    this.producerCopy.contact.residentialAddress = this.newAddress;
                                    this.producerCopy.contact.residentialAddress.id = -1;
                                }
                                else {
                                    this.producerCopy.contact.businessAddress = this.newAddress;
                                    this.producerCopy.contact.businessAddress.id = -1;
                                }
                            }
                            this.updateDtoModel(this.producerCopy, this.producerService.producer);
                            this.checkWhatFieldsNeeded();
                            if (this.producerDetailsForm) {
                                this.producerDetailsForm.form.markAsPristine();
                            }
                        }
                        this.modal.loading(false);
                    });
                }
                else {
                    this.updateDtoModel(this.producerCopy, this.producerService.producer);
                    this.checkWhatFieldsNeeded();
                    if (this.producerDetailsForm) {
                        this.producerDetailsForm.form.markAsPristine();
                    }
                    this.modal.loading(false);
                }
            }
        });
    }

    updateDtoModel(source: Producer, dest: Producer): void {
        dest.contact.bizPhone = source.contact.bizPhone;
        dest.firstName = source.firstName;
        dest.lastName = source.lastName;
        dest.companyName = source.companyName;
        dest.stateCode = source.stateCode;
        dest.ssn = source.ssn;
        dest.dateOfBirth = source.dateOfBirth;
        dest.fein = source.fein;
        dest.contact.addresses = source.contact.addresses;
        dest.contact.residentialAddress = source.contact.residentialAddress;
        dest.contact.businessAddress = source.contact.businessAddress;
    }
}
