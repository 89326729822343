<div style="margin-bottom: 20px;">
    <ng-container *ngIf="!fromShell">
        <label>
            <mat-checkbox name="carrier-select-toggle" [(ngModel)]="showCarrierList" (change)="updateCarrierList()"></mat-checkbox>
            Filter on Carrier
        </label>
    </ng-container>

    <div *ngIf="showCarrierList" style="margin: 10px 0 0 20px;">
        <div *ngFor="let carrier of carrierList" class="form-list-item">
            <label>
                <mat-checkbox name="carrier-{{carrier.name}}" (change)="updateCarrierList()" [(ngModel)]="carrier.selected"></mat-checkbox>
                <span>{{carrier.name}}</span>
            </label>
        </div>
    </div>
</div>
