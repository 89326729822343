import { Component, OnInit } from '@angular/core';
import { CECourseCategory } from '../../../../shared/enums/general';
import { CECourse } from '../../../../shared/models/pob/producer';
import { InputStateColorCode } from '../../../../shared/utils/ui-mod';
import { ProducerDataService } from '../../../producer-data.service';

@Component({
    selector: 'pv-continuing-education-courses',
    templateUrl: './continuing-education-courses.component.html',
    styleUrls: ['../details.component.css']
})
export class ContinuingEducationCoursesComponent implements OnInit {

    courses: CECourse[];
    columns: string[] = ['courseNumber', 'courseName', 'courseStatus', 'courseHours', 'courseCategory', 'courseCompletionDate', 'courseProviderId', 'courseProviderName', 'courseAction'];

    courseCategories = CECourseCategory;
    courseCatKeys: string[];

    editCourseHold: CECourse;

    constructor(private producerService: ProducerDataService) {
        this.courseCatKeys = Object.keys(this.courseCategories);
    }

    ngOnInit(): void {
        if (!this.producerService.producerCECourses) {
            this.producerService.loadProducerCECourses().subscribe(resData => {
                if (resData.status === 0) {
                    this.producerService.producerCECourses = resData.dataList.map(c => new CECourse(c));
                }
                else if (resData.status === -404) {
                    this.producerService.producerCECourses = [];
                }

                this.courses = this.producerService.producerCECourses.slice();
            });
        }
        else {
            this.courses = this.producerService.producerCECourses.slice();
        }
        console.log('course');
    }

    addCourse(): void {
        let course = new CECourse({});
        course.isEditing = true;
        this.producerService.producerCECourses.push(course);
        this.courses = this.producerService.producerCECourses.slice();
    }

    editCourse(course: CECourse): void {
        course.isEditing = true;
        this.editCourseHold = Object.assign({}, course);
    }

    cancelEditCourse(course: CECourse): void {
        course.isEditing = false
        if (!course.id) {
            //never saved, just delete local
            const index = this.producerService.producerCECourses.findIndex(c => c.id === course.id);
            if (index >= 0) {
                this.producerService.producerCECourses.splice(index, 1);
                this.courses = this.producerService.producerCECourses.slice();
            }
        }
        else {
            course.assign(this.editCourseHold);
        }
    }

    updateCourse(course: CECourse, target: any): void {
        if (!(course.courseName)) {
            alert("Please enter a Course Name!");
            return;
        }

        target.disabled = true;
        if (!course.producerId) {
            course.producerId = this.producerService.producer.id;
        }
        this.producerService.updateProducerCECourse(course).subscribe(resData => {
            if (resData.status === 0) {
                course.id = resData.item.id;
                course.isEditing = false;
            }
            else {
                course.isEditing = true;
            }

            target.disabled = false;
            InputStateColorCode(target.parentElement.parentElement, resData);
        });
    }

    deleteCourse(course: CECourse, target: any): void {
        target.disabled = true;
        this.producerService.deleteProducerCECourse(course.id).subscribe(resData => {
            if (resData.status === 0) {
                const index = this.producerService.producerCECourses.findIndex(c => c.id === course.id);
                if (index >= 0) {
                    this.producerService.producerCECourses.splice(index, 1);
                    this.courses = this.producerService.producerCECourses.slice();
                }
            }
        });
    }
}
