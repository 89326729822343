<h3 class="center">Onboarding Application Status</h3>

<div class="center pointer" (click)="navigateToApplications()" *ngIf="count !== null">
    <h4>Ready or in Review</h4>

    <h1 style="font-size: 2.7em;">{{count}}</h1>
</div>

<ng-container *ngIf="count === null">
    <div class="loading-spinner"></div>
</ng-container>
