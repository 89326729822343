import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ModalService } from '../../modal/modal.service';
import { UserPreference, User } from '../../shared/models/user';
import { PreferenceType } from '../../shared/enums/general';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { State } from '../../state/app.state';
import { Store } from '@ngrx/store';
import { getAllBizUnitCarriersSelector, getCurrentUserPreferencesSelector, getErrorSelector } from '../../user/state';
import { SecurityRolePageActions } from '../../user/state/actions';
import { MatTableDataSource } from '@angular/material/table';
import { Carrier } from '../../shared/models/pob/carrier';

@Component({
    selector: 'pv-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

    carriers: MatTableDataSource<Carrier> = new MatTableDataSource<Carrier>();
    
    loading: boolean = false;

    columns: string[] = ['carrierName', 'carrierFein', 'carrierAction'];
    userCarrierPrefRef: UserPreference;
    userCarrierIds: number[] = [];

    errorMessage$: Observable<string>;

    constructor(private dataService: DataService, private store: Store<State>, private modal: ModalService) {
    }

    ngOnInit() {
        this.loading = true;
        this.errorMessage$ = this.store.select(getErrorSelector);

        this.store.select(getCurrentUserPreferencesSelector)
            .pipe(tap((userData: User) => {
                if (userData) {
                    console.log("getCurrentUserPreferencesSelector 333333", userData);
                    if (userData.userCarriersPreference) {
                        this.userCarrierPrefRef = { ...userData.userCarriersPreference };
                        this.userCarrierIds = this.userCarrierPrefRef.value.split(',').map(i => +i);
                    }
                    else {
                        this.userCarrierPrefRef = new UserPreference({ id: null, userId: userData.id, preferenceType: PreferenceType.PreferredCarriers, value: "" });
                    }
                }
            })).subscribe();

        //get carriers from dataservice if we've already loaded them
        if (this.dataService.allCarriers) {
            this.processCarriers();
        }
        else {
            this.store.select(getAllBizUnitCarriersSelector).subscribe({
                next: res => {
                    if (res && res.length > 0) {
                        let carriers = res.map(x => new Carrier(x));
                        
                        if (this.userCarrierIds.length > 0) {
                            carriers.forEach(c => {
                                if (this.userCarrierIds.indexOf(c.id) >= 0) {
                                    c.selected = true;
                                }
                            });
                        }
                        this.dataService.allCarriers = carriers;
                    }

                    this.processCarriers();
                },
                error: err => {
                    this.loading = false;
                }
            });
        }
    }

    processCarriers() {
        this.carriers.data = this.dataService.allCarriers;

        this.loading = false;
    }

    addCarrier(carrier: Carrier) {
        this.modal.loading(true);

        if (this.userCarrierIds.indexOf(carrier.id) < 0) {
            this.userCarrierIds.push(carrier.id);
            this.userCarrierPrefRef.value = this.userCarrierIds.join();
        }

        //dispatch update command, if error, errorMessage$ will catch it.
        this.store.dispatch(SecurityRolePageActions.updateUserPreferenceAction({ input: this.userCarrierPrefRef }));

        carrier.selected = true;
        this.carriers.filter = " ";
        this.modal.loading(false);
    }


    removeCarrier(carrier: Carrier) {
        this.modal.loading(true);
        let index = this.userCarrierIds.findIndex(x => x === carrier.id);
        this.userCarrierIds.splice(index, 1);
        this.userCarrierPrefRef.value = this.userCarrierIds.join();

        //dispatch update command, if error, errorMessage$ will catch it.
        this.store.dispatch(SecurityRolePageActions.updateUserPreferenceAction({ input: this.userCarrierPrefRef }));

        carrier.selected = false;
        this.modal.loading(false);
    }
}
