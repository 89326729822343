import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ProfileComponent } from './profile.component';
import { SearchComponent } from './search.component';
import { BiographicSearchResultsComponent } from './search/biographic-search-results/biographic-search-results.component';

import { ByNameComponent } from './search/by-name/by-name.component';
import { ByLicenseComponent } from './search/by-license/by-license.component';
import { ByAgencyComponent } from './search/by-agency/by-agency.component';
import { ByNpnComponent } from './search/by-npn/by-npn.component';
import { LicenseInformationComponent } from './profile/license-information/license-information.component';
import { AppointmentsComponent } from './profile/appointments/appointments.component';
import { RegAndDiscipInfoComponent } from './profile/reg-and-discip-info/reg-and-discip-info.component';
import { ByFeinComponent } from './search/by-fein/by-fein.component';
import { DetailsComponent } from './profile/details/details.component';
import { ProducerApplicationsComponent } from './profile/applications/producer-applications.component';
import { OnboardComponent } from './onboard/onboard.component';
import { StateSelectModule } from '../shared/modules/state-select/state-select.module';
import { ProdCommentsModule } from '../shared/modules/comments/prod-comments.module';
import { ApplicationDocumentsModule } from '../shared/modules/app-documents/app-documents.module';
import { AppointComponent } from './appoint/appoint.component';
import { NewAppointmentComponent } from './appoint/new-appointment/new-appointment.component';
import { NewAppointmentShellComponent } from './appoint/new-appointment/new-appointment-shell.component';
import { InProgressApptComponent } from './appoint/in-progress/in-progress.component';
import { TerminateComponent } from './appoint/terminate/terminate.component';
import { ModalModule } from '../shared/modules/modal/modal.module';
import { ModalComponent } from '../modal/modal.component';
import { PhoneMaskPipeModule } from '../shared/form/phone-mask.directive';
import { DocumentsComponent } from './profile/documents/documents.component';
import { ContinuingEducationReqsComponent } from './profile/details/continuing-education-reqs/continuing-education-reqs.component';
import { ContinuingEducationCoursesComponent } from './profile/details/continuing-education-courses/continuing-education-courses.component';
import { PdbComponent } from './pdb/pdb.component';
import { SsnMaskModule } from '../shared/modules/ssn-mask/ssn-mask.module';
import { FeinMaskModule } from '../shared/modules/fein-mask/fein-mask.module';


export const routes: Routes = [
    {
        path: 'ProducerSearch', component: SearchComponent, data: { title: 'Producer Search' }, children: [
            { path: 'ByName', component: ByNameComponent, data: { title: 'By Name' } },
            { path: 'ByLicense', component: ByLicenseComponent, data: { title: 'By License' } },
            { path: 'ByAgency', component: ByAgencyComponent, data: { title: 'By Agency' } },
            { path: 'ByNpn', component: ByNpnComponent, data: { title: 'By NPN' } },
            { path: 'ByFein', component: ByFeinComponent, data: { title: 'By FEIN' } },
            { path: '', redirectTo: 'ByName', pathMatch: 'full' }
        ]
    },
    {
        path: 'Producer/:id', component: ProfileComponent, data: { title: 'Producer Profile' }, children: [
            { path: '', redirectTo: 'LicenseInfo', pathMatch: 'full' },
            { path: 'Details', component: DetailsComponent, pathMatch: 'full' },
            { path: 'LicenseInfo', component: LicenseInformationComponent, pathMatch: 'full' },
            { path: 'Appointments', component: AppointmentsComponent, pathMatch: 'full' },
            { path: 'Regulatory', component: RegAndDiscipInfoComponent, pathMatch: 'full' },
            { path: 'Documents', component: DocumentsComponent, pathMatch: 'full' },
            { path: 'Applications', component: ProducerApplicationsComponent, pathMatch: 'full' }
        ]
    },
    {
        path: 'ProducerPdb/:id', component: PdbComponent
    },
    {
        path: 'OnboardProducer/:id', component: OnboardComponent
    },
    {
        path: 'AppointProducer/:id', component: AppointComponent, children: [
            { path: '', redirectTo: 'NewAppointment', pathMatch: 'full' },
            { path: 'NewAppointment', component: NewAppointmentShellComponent, pathMatch: 'full' },
            { path: 'InProgressAppointment', component: InProgressApptComponent, pathMatch: 'full' }
        ]
    }
];
@NgModule({
    declarations: [ProfileComponent,
        SearchComponent,
        BiographicSearchResultsComponent,
        ByNameComponent,
        ByLicenseComponent,
        ByAgencyComponent,
        ByNpnComponent,
        LicenseInformationComponent,
        AppointmentsComponent,
        RegAndDiscipInfoComponent,
        ByFeinComponent,
        DetailsComponent,
        ProducerApplicationsComponent,
        DocumentsComponent,
        OnboardComponent,
        AppointComponent,
        NewAppointmentComponent,
        NewAppointmentShellComponent,
        InProgressApptComponent,
        TerminateComponent,
        PdbComponent,
        DocumentsComponent,
        ContinuingEducationReqsComponent,
        ContinuingEducationCoursesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSortModule,
        CdkTableModule,
        MatPaginatorModule,
        MatDatepickerModule,
        StateSelectModule,
        ProdCommentsModule,
        ApplicationDocumentsModule,
        ModalModule,
        //PhoneMaskDirectiveModule,
        PhoneMaskPipeModule,
        SsnMaskModule,
        FeinMaskModule,
        RouterModule.forChild(routes)
    ]
})
export class ProducerModule { }
