<div id="content" *ngIf="!userService.userRolesLoaded">
    <h2>Loading user permissions...</h2>
    <div class="loading-spinner"></div>
</div>
<div id="management" *ngIf="userService.userRolesLoaded">
    <!--
        CURRENTLY NOT SHOWING THESE TABS
        <nav class="sub-navbar">
      <ul>
        <li><span><a routerLinkActive="active" [routerLink]="['./ProducerOnboarding']">Producer Onboardings</a></span></li>
        <li><span><a routerLinkActive="active" [routerLink]="['./CreateProducer']">Create New Producer</a></span></li>
      </ul>
    </nav>-->
    <router-outlet></router-outlet>
</div>
