import { Injectable } from '@angular/core';
import { RestService } from '../services/rest.service';
import { Observable, throwError } from 'rxjs';
import { IClientItemDTO, IClientListDTO } from '../shared/models/dto/common-result.dto';
import { IBizUnitVerbiage } from '../shared/models/general';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DocuSignTemplate, DocumentTemplateUpload } from '../shared/models/document';
import { AuthorizedSigner, Carrier, CarrierDetail, CarrierRegedLicenseTypeDTO, CarrierRegedLoaDTO, RegedLicenseType, RegedLOA } from '../shared/models/pob/carrier';
import { Address } from '../shared/models/pob/address';


@Injectable({
    providedIn: 'root'
})
export class AdminDataService extends RestService {
    
    //storage
    authorizedSigners: AuthorizedSigner[];

    getBizUnitVerbiage(bizUnitId: number): Observable<IBizUnitVerbiage> {
        let relativeUrl = 'POB/BizUnitVerbiage/' + bizUnitId;
        return this.get<IClientItemDTO<IBizUnitVerbiage>>(relativeUrl)
            .pipe(
                map((resData: IClientItemDTO<IBizUnitVerbiage>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getBizUnitVerbiage(): ${resData.message}`);
                    }
                    return resData.item;
                }),
                catchError(this.handleError)
            );
    }

    saveBizUnitVerbiage(data: IBizUnitVerbiage): Observable<IBizUnitVerbiage> {
        let relativeUrl = 'POB/BizUnitVerbiage';
        return this.post<IClientItemDTO<IBizUnitVerbiage>>(relativeUrl, data)
            .pipe(
                map((resData: IClientItemDTO<IBizUnitVerbiage>) => {
                    if (resData.status !== 0) {
                        throw new Error(`saveBizUnitVerbiage(): ${resData.message}`);
                    }
                    resData.item.isUpdateSuccessful = true;
                    return resData.item;
                }),
                catchError(this.handleError)
            );
    }

    getDocumentListsByBizUnit(bizUnitId: number): Observable<DocuSignTemplate[]> {
        let relativeUrl = 'POB/DocuSignTemplate/' + bizUnitId;
        return this.get<IClientListDTO<DocuSignTemplate>>(relativeUrl)
            .pipe(
                map((resData: IClientListDTO<DocuSignTemplate>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getDocumentListsByBizUnit(): ${resData.message}`);
                    }
                    return resData.dataList;
                }),
                catchError(this.handleError)
            );
    }

    updateDocumentTemplates(data: DocuSignTemplate[]): Observable<DocuSignTemplate[]> {
        let relativeUrl = 'POB/DocuSignTemplate/AddOrUpdate';
        return this.post<IClientListDTO<DocuSignTemplate>>(relativeUrl, data)
            .pipe(
                map((resData: IClientListDTO<DocuSignTemplate>) => {
                    if (resData.status !== 0) {
                        throw new Error(`updateDocumentTemplates(): ${resData.message}`);
                    }
                    return resData.dataList;
                }),
                catchError(this.handleError)
            );
    }

    uploadDocusignTemplateFile(data: DocumentTemplateUpload): Observable<DocuSignTemplate> {
        let relativeUrl = 'CloudStorage/Template/Save';
        return this.post<IClientListDTO<DocuSignTemplate>>(relativeUrl, data)
            .pipe(
                map((resData: IClientListDTO<DocuSignTemplate>) => {
                    if (resData.status !== 0) {
                        throw new Error(`uploadDocusignTemplateFile(): ${resData.message}`);
                    }
                    return resData.dataList[0];
                }),
                catchError(this.handleError)
            );
    }

    deleteDocusignTemplateFile(data: DocuSignTemplate): Observable<number> {
        let relativeUrl = 'CloudStorage/DocuSignTemplate/Remove';
        return this.post<IClientItemDTO<boolean>>(relativeUrl, data)
            .pipe(
                map((resData: IClientItemDTO<boolean>) => {
                    if (resData.status !== 0) {
                        throw new Error(`deleteDocusignTemplateFile(): ${resData.message}`);
                    }
                    return resData.item ? data.id : -1;
                }),
                catchError(this.handleError)
            );
    }

    associateCarrierWithBU(carrier: Carrier, bizUnitId: number): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'POB/BizUnitCarriers';
        let input = {
            bizUnitId: bizUnitId,
            carrierId: carrier.id,
            preference: true
        };
        return this.post<IClientItemDTO<boolean>>(relativeUrl, [input]);
    }

    disassociateCarrierWithBU(carrier: Carrier, bizUnitId: number): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'POB/BizUnitCarriers/Delete';
        let input = {
            bizUnitId: bizUnitId,
            carrierId: carrier.id,
            preference: false
        }
        return this.post<IClientItemDTO<boolean>>(relativeUrl, [input]);
    }

    getCarrierDetails(carrierId: number): Observable<IClientItemDTO<Carrier>> {
        const relativeUrl = 'POB/CarrierDetail/' + carrierId;

        return this.get<IClientItemDTO<Carrier>>(relativeUrl);
    }

    getCarrierAddresses(): Observable<IClientListDTO<Carrier>> {
        const relativeUrl = 'POB/GetAllCarrierAddress';

        return this.get<IClientListDTO<Carrier>>(relativeUrl);
    }

    getAuthorizedSigners(): Observable<IClientListDTO<AuthorizedSigner>> {
        const relativeUrl = 'POB/Reged/AuthorizedSigner';

        return this.get<IClientListDTO<AuthorizedSigner>>(relativeUrl);
    }

    getRegedLicenseTypes(stateCode: string): Observable<IClientListDTO<RegedLicenseType>> {
        const relativeUrl = 'POB/Reged/LicenseTypesDto/' + stateCode;
        return this.get<IClientListDTO<RegedLicenseType>>(relativeUrl);
    }
    getRegedLOAs(stateCode: string): Observable<IClientListDTO<RegedLOA>> {
        const relativeUrl = 'POB/Reged/LOAs/' + stateCode;
        return this.get<IClientListDTO<RegedLOA>>(relativeUrl);
    }

    updateCarrierAddress(carrier: Carrier): Observable<IClientItemDTO<Address>> {
        const relativeUrl = 'POB/AddOrUpdateCarrierAddress/' + carrier.id;
        return this.post<IClientItemDTO<Address>>(relativeUrl, carrier.address);
    }

    updateAuthorizedSigner(authSigner: AuthorizedSigner): Observable<IClientItemDTO<AuthorizedSigner>> {
        const relativeUrl = 'POB/Reged/AddOrUpdateAuthorizedSigner';
        return this.post<IClientItemDTO<AuthorizedSigner>>(relativeUrl, authSigner);
    }

    updateCarrierDetails(carrierDets: CarrierDetail): Observable<IClientItemDTO<CarrierDetail>> {
        const relativeUrl = 'POB/AddOrUpdateCarrierDetail';
        return this.post<IClientItemDTO<CarrierDetail>>(relativeUrl, carrierDets);
    }

    updateCarrierAllowedLicenseType(regedLtp: CarrierRegedLicenseTypeDTO): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/AddOrUpdateRegedLicenseTypeCarrierAllowed';

        const input = [{
            "carrierId": regedLtp.carrierId,
            "regedLicenseTypeId": regedLtp.regedLicenseTypeId,
            "producerType": regedLtp.producerType
        }];

        return this.post<IClientItemDTO<number>>(relativeUrl, input);
    }

    deleteCarrierAllowedLicenseType(regedLtp: CarrierRegedLicenseTypeDTO): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/DeleteRegedLicenseTypeCarrierAllowed';

        const input = [{
            "carrierId": regedLtp.carrierId,
            "regedLicenseTypeId": regedLtp.regedLicenseTypeId,
        }];

        return this.delete<IClientItemDTO<number>>(relativeUrl, input);
    }

    updateCarrierAllowedLOA(regedLoa: CarrierRegedLoaDTO): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/AddOrUpdateRegedLOACarrierAllowed';

        const input = [{
            "carrierId": regedLoa.carrierId,
            "regedLoaId": regedLoa.regedLOAId,
            "producerType": regedLoa.producerType
        }];

        return this.post<IClientItemDTO<number>>(relativeUrl, input);
    }

    deleteCarrierAllowedLOA(regedLoa: CarrierRegedLoaDTO): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/DeleteRegedLOACarrierAllowed';

        const input = [{
            "carrierId": regedLoa.carrierId,
            "regedLoaId": regedLoa.regedLOAId,
        }];

        return this.delete<IClientItemDTO<number>>(relativeUrl, input);
    }

    updateRegedLicenseType(regedLtp: RegedLicenseType): Observable<IClientItemDTO<RegedLicenseType>> {
        const relativeUrl = 'POB/Reged/AddOrUpdateLicenseType';

        return this.post<IClientItemDTO<RegedLicenseType>>(relativeUrl, regedLtp);
    }

    deleteRegedLicenseType(regedLtp: RegedLicenseType): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/Reged/DeleteLicenseType/' + regedLtp.id;

        return this.delete<IClientItemDTO<number>>(relativeUrl);
    }

    updateRegedLOA(regedLoa: RegedLOA): Observable<IClientItemDTO<RegedLOA>> {
        const relativeUrl = 'POB/Reged/AddOrUpdateLOA';

        return this.post<IClientItemDTO<RegedLOA>>(relativeUrl, regedLoa.get());
    }

    deleteRegedLOA(regedLoa: RegedLOA): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/Reged/DeleteLOA/' + regedLoa.id;

        return this.delete<IClientItemDTO<number>>(relativeUrl);
    }
    

    private handleError(err: any) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            errorMessage = `An error occurred: ${err.error.message}`;
        }
        else if (err instanceof HttpErrorResponse) {
            if (err.status === 403) {
                errorMessage = `${err.status}, Access defined.`;
            }
        }
        else if (err instanceof Error) {
            errorMessage = err.message;
        }
        else {
            errorMessage = `Backend returned error ${err?.status}: ${err?.body?.error}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

}
