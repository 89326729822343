
export class PdbContact {
    npn: number;
    branchID: string;

    phone: string;
    phoneUpdateDate: Date;
    fax: string;
    faxUpdateDate: Date;
    email: string;
    emailUpdateDate: Date;

    contactStateCode: string;
    stateCoId: string;
    text: string;

    constructor(api?: Partial<PdbContact>) {
        if (api) {
            this.npn = api.npn;
            this.branchID = api.branchID;

            this.phone = api.phone;
            this.phoneUpdateDate = api.phoneUpdateDate;
            this.fax = api.fax;
            this.faxUpdateDate = api.faxUpdateDate;
            this.email = api.email;
            this.emailUpdateDate = api.emailUpdateDate;
            this.contactStateCode = api.contactStateCode;
            this.stateCoId = api.stateCoId;
            this.text = api.text;
        }
    }
}
