import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../services/rest.service';
import { IReportRegulatoryInput, ReportApptRenewalInput, ReportLicenseRenewalInput, IReportAssociationInput, ReportIncompleteOnboardingInput, ReportRegedBillingInput, ReportNIPRSeedingInput, ReportEnOInput, IReportAgencyAssociationInput, ReportAgencyAssociationInput, ReportRegedErrorsInput, ReportUntrackedProducersInput, ReportAuditCarrierInput, ReportBigBackgroundChecksInput, ReportEmployedProducerInput } from './report.input.dto';
import { IClientItemDTO } from '../shared/models/dto/common-result.dto';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends RestService {

    getRegulatoryReport(input: IReportRegulatoryInput): Observable<string> {
        let relativeUrl = 'ProducerReport/RegulatoryAction.Excel';

        return this.post<IClientItemDTO<Blob>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Regulatory Actions Report");
        }));
    }

    getApptRenewalReport(input: ReportApptRenewalInput): Observable<string> {
        let relativeUrl = 'ProducerReport/AppointmentRenewals.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Appointment Renewal Report");
        }));
    }

    getLicenseRenewalReport(input: ReportLicenseRenewalInput): Observable<string> {
        let relativeUrl = 'ProducerReport/LicenseRenewal.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "License Renewal Report");
        }));
    }

    getUntrackedProducersReport(input: ReportUntrackedProducersInput): Observable<string> {
        let relativeUrl = 'ProducerReport/UntrackedProducers.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Producers Removed from NIPR Tracking Report");
        }));
    }

    getAssociationReport(input: IReportAssociationInput): Observable<string> {
        let relativeUrl = 'ProducerReport/ProducerAssociation.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Producer " + input.type + " Report");
        }));
    }

    getAgencyAssociationReport(input: ReportAgencyAssociationInput): Observable<string> {
        let relativeUrl = 'ProducerReport/AgencyAssociation.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Agency Association Report");
        }));
    }

    getIncompleteOnboardingReport(input: ReportIncompleteOnboardingInput): Observable<string> {
        let relativeUrl = 'ProducerReport/IncompleteOnboarding.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Incomplete Onboarding Report");
        }));
    }

    getRegedErrorsReport(): Observable<string> {
        let relativeUrl = 'ProducerReport/RegedErrorsReport.Excel';

        return this.get<IClientItemDTO<any>>(relativeUrl).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Reged Errors Report");
        }));
    }

    getRegedBillingReport(input: ReportRegedBillingInput): Observable<string> {
        let relativeUrl = 'ProducerReport/RegedOrderReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Reged Order Report");
        }));
    }

    getNiprSeedingReport(input: ReportNIPRSeedingInput): Observable<string> {
        let relativeUrl = 'ProducerReport/NiprSeedingReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Nipr Seeding Report");
        }));
    }

    getEnOReport(input: ReportEnOInput): Observable<string> {
        let relativeUrl = 'ProducerReport/EnOReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "E&O Expiration Report");
        }));
    }

    getProdsTermedOutsideIPAReport(input: ReportApptRenewalInput): Observable<string> {
        let relativeUrl = 'ProducerReport/GetProducersTerminatedOutsideIPAReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Producers Terminated Outside of IPA Report");
        }));
    }

    getAuditCarrierReport(input: ReportAuditCarrierInput): Observable<string> {

        let relativeUrl = 'ProducerReport/';

        if (input.auditLogTable === "Carrier Detail") {
            relativeUrl += 'AuditCarrierDetailReport.Excel';
        }
        else if (input.auditLogTable === "Authorized Signer") {
            relativeUrl += 'AuditCarrierAuthSignerReport.Excel';
        }
        else if (input.auditLogTable === "Carrier Address") {
            relativeUrl += 'AuditCarrierAddressReport.Excel';
        }
        else {
            relativeUrl += 'AuditCarrierLtpLoaAllowedReport.Excel';
        }

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Audit Carrier " + input.auditLogTable + " Report");
        }));
    }

    getBigBackgroundChecks(input: ReportBigBackgroundChecksInput): Observable<string> {
        let relativeUrl = 'ProducerReport/BigBackgroundCheckReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "BIG Background Checks Report");
        }));
    }

    getEmployeeLicenses(input: ReportEmployedProducerInput): Observable<string> {
        let relativeUrl = 'ProducerReport/EmployeeLicenseReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Employee License Report");
        }));
    }

    getEmployeeDlrps(input: ReportEmployedProducerInput): Observable<string> {
        let relativeUrl = 'ProducerReport/EmployeeDlrpReport.Excel';

        return this.post<IClientItemDTO<any>>(relativeUrl, input).pipe(map((data: IClientItemDTO<any>) => {
            return this.addLinkAndClickToDownload(data, "Employee DLRP Report");
        }));
    }

    private addLinkAndClickToDownload(resData: IClientItemDTO<any>, filename: string): string {
        if (resData) {
            if (resData.status === 0 && resData.item) {
                const data = this.base64ToArrayBuffer(resData.item);
                const blob = new Blob([data], {
                    type: 'application/octet-stream' //Excel
                });
                
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename + '.xlsx';
                link.click();
                window.URL.revokeObjectURL(link.href);
            }
            else {
                return resData.message;
            }
        }
        else {
            return "An error occurred generating the report";
        }
    }

    private base64ToArrayBuffer(base64): Uint8Array {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
}
