import { Component, OnInit} from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { ProducerDataService } from '../../../producer/producer-data.service';
import { ProducerComment } from '../../models/pob/producer';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'pv-prod-comments',
    templateUrl: './producer-comments.component.html',
    styleUrls: ['./producer-comments.component.css']
})
export class ProducerCommentsComponent implements OnInit {

    private _comments = new BehaviorSubject<ProducerComment[]>([]);
    public set comments(v: ProducerComment[]) { this._comments.next(v); }
    public get comments(): ProducerComment[] { return this._comments.value; }

    canComment: boolean = false;

    comment: ProducerComment;

    constructor(private prodService: ProducerDataService, private userService: UserService) { }

    ngOnInit() {
        this.resetComment();
        this.comments = this.prodService.producer.comments;

        //console.log("userService.activeBusinessUnit 11111111", this.userService.activeBusinessUnit);
        this.canComment = this.userService.activeRoles.comment;
        this.userEmail = this.userService.getUserEmail;
        this.comment.bizUserIdentifier = this.userEmail;
    }

    userEmail: string;

    addComment() {
        this.prodService.addComment(this.comment).subscribe(resData => {
            if (resData && resData.status == 0) {
                this.comment.id = resData.item;
                let comments = this.comments.slice();
                comments.push(this.comment);
                this.comments = comments;
                this.prodService.producer.comments = comments;
                this.resetComment();
            }
        });
    }

    resetComment() {
        this.comment = new ProducerComment({ message: '', bizUserIdentifier: this.userEmail });
    }
}
