import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'pv-start-end-date',
    templateUrl: './start-end-date.component.html',
    styleUrls: ['./start-end-date.component.css']
})
export class StartEndDateComponent implements OnInit {

    @Input() startDate: Date;
    @Input() endDate: Date;

    @Output() startDateChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() endDateChange: EventEmitter<Date> = new EventEmitter<Date>();

    constructor() { }

    ngOnInit(): void {
    }

}
