import { createAction, props } from '@ngrx/store';

import { IBizUnitVerbiage } from '../../../shared/models/general';
import { DocuSignTemplate, DocumentTemplateUpload } from '../../../shared/models/document';


export const getBizUnitVerbiageAction = createAction(
    `[Admin Page] Get bizUnit verbiage`
);

export const saveBizUnitVerbiageAction = createAction(
    `[Admin Page] Save bizUnit verbiage`,
    props<{ buVerbiage: IBizUnitVerbiage }>()
);

export const getDocumentListsByBizUnitAction = createAction(
    `[Admin Page] Get bizUnit template document list`
);

export const updateDocumentTemplatesAction = createAction(
    `[Admin Page] Save bizUnit template document list`,
    props<{ buDocumentTemplates: DocuSignTemplate[] }>()
);

export const uploadDocusignTemplateFileAction = createAction(
    `[Admin Page] Upload docusign template document`,
    props<{ documentTemplate: DocumentTemplateUpload }>()
);

export const deleteDocusignTemplateFileAction = createAction(
    `[Admin Page] Delete docusign template document`,
    props<{ documentTemplate: DocuSignTemplate }>()
);


