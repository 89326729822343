import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnboardingService } from '../../onboarding.service';
import { Application } from '../../../shared/models/application';
import { AuditLogStatus } from '../../../shared/enums/audit-log';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pv-in-progress-app',
  templateUrl: './in-progress-app.component.html',
})
export class InProgressAppComponent implements OnInit, OnDestroy {

    applications: Application[];

    appSubscription: Subscription;

    constructor(private onboardService: OnboardingService) { }

    ngOnInit() {
        this.appSubscription = this.onboardService.onboardingApplications$.subscribe(apps => {
            this.applications = apps.filter(x => x.status === AuditLogStatus.AppReviewing);
        });
    }

    ngOnDestroy() {
        if (this.appSubscription) {
            this.appSubscription.unsubscribe();
        }
    }
}
