<div *ngIf="producerCopy && userService.userRolesLoaded && (userService.activeRoles) as securityRole">

    <button type="button" class="button" [routerLink]="['/Producer/' + producerCopy.id]" title="">Back to Producer Profile</button>

    <ng-container *ngIf="securityRole.onboard || securityRole.applicationManager || securityRole.owner">

        <h2 style="margin: 15px 0;">Producer Details</h2>

        <form #producerDetails="ngForm" *ngIf="anythingNeeded">
            <div class="half-width" style="margin-right: 4%;">
                <ng-container *ngIf="producerCopy.type == 'Agent'">
                    <mat-form-field class="full-width">
                        <mat-label>First Name</mat-label>
                        <input name="firstName" matInput type="text" required disabled [(ngModel)]="producerCopy.firstName" />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>Last Name</mat-label>
                        <input name="lastName" matInput type="text" required disabled [(ngModel)]="producerCopy.lastName" />
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="producerCopy.type == 'Agency'">
                    <mat-form-field class="full-width">
                        <mat-label>Company Name</mat-label>
                        <input name="companyName" matInput type="text" required disabled [(ngModel)]="producerCopy.companyName" />
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="full-width">
                    <mat-label>Phone</mat-label>
                    <input name="phone" matInput type="text" required [disabled]="!phoneNeeded" (ngModelChange)="producerCopy.contact.bizPhone = $event" [ngModel]="producerCopy.contact.bizPhone | phoneMaskPipe" />
                </mat-form-field>
                <pv-state-select [(state)]="producerCopy.stateCode" required [disabled]="!resStateNeeded" [title]="'Resident State'" class="half-width first-half-option inline top"></pv-state-select>
            </div>

            <div class="half-width top">
                <mat-form-field class="full-width">
                    <mat-label>NPN</mat-label>
                    <input name="npn" matInput type="text" required disabled [(ngModel)]="producerCopy.npn" />
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="producerCopy.type == 'Agent'">
                    <mat-label>SSN</mat-label>
                    <input name="taxId" matInput type="text" (blur)="showMask = true" (focus)="showMask = false" required [disabled]="!taxIdNeeded" (ngModelChange)="producerCopy.ssn = $event" [ngModel]="producerCopy.ssn | ssnMask:showMask" pattern="[0-9\*]{3}-[0-9\*]{2}-[0-9]{4}" />
                </mat-form-field>

                <mat-form-field class="full-width" *ngIf="producerCopy.type == 'Agency'">
                    <mat-label>FEIN</mat-label>
                    <input name="taxId" matInput type="text" required [disabled]="!taxIdNeeded" (ngModelChange)="producerCopy.fein = $event" [ngModel]="producerCopy.fein | feinMask" pattern="[0-9]{2}-[0-9]{7}" />
                </mat-form-field>

                <mat-form-field class="date-picker" *ngIf="producerCopy.type == 'Agent'">
                    <input name="dob" matInput [(ngModel)]="producerCopy.dateOfBirth" [matDatepicker]="dobDatePicker" required [disabled]="!dobNeeded" placeholder="Date of Birth">
                    <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dobDatePicker></mat-datepicker>
                </mat-form-field>

                <button *ngIf="!addressNeeded" class="button" [ngClass]="{ 'disabled': producerDetails.form.pristine }" [disabled]="producerDetails.form.pristine || !producerDetails.form.valid" (click)="updateProducer()">Update Producer</button>
            </div>

            <div *ngIf="addressNeeded">
                <div class="full-width">
                    <div style="width: 70%;">
                        <h4 class="inline">Producer is missing {{(producerCopy.type === 'Agent' ? 'Residential' : 'Business' )}} Address for their Domicile State</h4><input id="auto-fill-address" type="button" class="button" style="margin: 0 0 0 20px;" value="Attempt Auto-Fill" (click)="getLikelyResidentialAddress($event.target)" title="Will attempt to populate with likely Residential Address if possible" />
                        <mat-form-field class="full-width mat-form-field-no-padding">
                            <input name="line1" matInput type="text" [(ngModel)]="newAddress.line1" class="proper-name" placeholder="Street Address" />
                        </mat-form-field>
                        <mat-form-field class="full-width mat-form-field-no-padding">
                            <input name="line2" matInput type="text" [(ngModel)]="newAddress.line2" class="proper-name" placeholder="Address Line 2" />
                        </mat-form-field>

                        <mat-form-field class="half-width first-half-option mat-form-field-no-padding">
                            <input name="city" matInput type="text" [(ngModel)]="newAddress.city" class="proper-name" placeholder="City" />
                        </mat-form-field>

                        <div class="half-width second-half-option">
                            <pv-state-select [(state)]="newAddress.state" class="half-width first-half-option inline mat-form-field-no-padding"></pv-state-select>

                            <mat-form-field class="half-width second-half-option mat-form-field-no-padding">
                                <input name="zip" matInput type="text" [(ngModel)]="newAddress.zip" placeholder="Zip" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <button class="button" [ngClass]="{ 'disabled': producerDetails.form.pristine }" [disabled]="producerDetails.form.pristine" (click)="updateProducer()">Update Producer</button>
            </div>
            
            <h4 style="margin-top: 20px;">Please update all required fields for the Producer Details before starting an Appointment</h4>
        </form>

        <div *ngIf="!anythingNeeded" id="producer-info">
            <div style="margin-right: 10%;">
                <div>
                    <span class="label">Name</span>
                    <span class="proper-name">{{producerCopy.fullName}}</span>
                </div>
                <div>
                    <span class="label">Phone</span>
                    <span>{{producerCopy.contact.bizPhone}}</span>
                </div>
                <div>
                    <span class="label">Resident State</span>
                    <span>{{producerCopy.stateCode}}</span>
                </div>
            </div>
            <div class="top">
                <div>
                    <span class="label">NPN</span>
                    <span>{{producerCopy.npn}}</span>
                </div>
                <ng-container *ngIf="producerCopy.type == 'Agent'">
                    <div>
                        <span class="label">SSN</span>
                        <span class="ssn">{{producerCopy.ssn | ssnMask:true}}</span>
                    </div>
                    <div>
                        <span class="label">Date of Birth</span>
                        <span>{{producerCopy.dateOfBirth | date:'mediumDate'}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="producerCopy.type == 'Agency'">
                    <div>
                        <span class="label">FEIN</span>
                        <span>{{producerCopy.fein}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="full-width" *ngIf="loaded && !anythingNeeded" style="margin-bottom: 50px;">
            <nav class="sub-navbar">
                <ul>
                    <li><span><a routerLinkActive="active" [routerLink]="['./NewAppointment']">Start New Appointment</a></span></li>
                    <li><span><a routerLinkActive="active" [routerLink]="['./InProgressAppointment']">In Progress Appointments</a></span></li>
                </ul>
            </nav>
            <router-outlet></router-outlet>
        </div>

        <ng-container *ngIf="!loaded">
            <div class="loading-spinner"></div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!(securityRole.onboard || securityRole.applicationManager || securityRole.owner)">
        <h3>Access Denied</h3>
        <p>Your profile settings do not allow you to access this page. Please contact your manager</p>
    </ng-container>
</div>
<ng-container *ngIf="!(producerCopy && userService.userRolesLoaded)">
    <div class="loading-spinner"></div>
</ng-container>
