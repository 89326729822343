import { Injectable } from '@angular/core';

import { mergeMap, map, catchError, concatMap, withLatestFrom, tap, switchMap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { SecurityRoleService } from '../security-role.service';

/* NgRx */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SecurityRolePageActions, SecurityRoleApiActions } from './actions';
import { BusinessUnitRoles, UserRolesDto } from '../../shared/models/user';

import { Store, select } from '@ngrx/store';
import { State } from '../../state/app.state';
import { getCurrentBizUnitSelector, getCurrentBizUnitIdSelector } from '.';
import { BizUnit, BizUnitCarrier } from '../../shared/models/general';

@Injectable()
export class SecurityRoleEffects {

    constructor(private actions$: Actions, private store: Store<State>, private dataService: SecurityRoleService) { }

    //for api call: getCurrentUserRoles()
    getCurrentUserRoles$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.getUserSecurityRolesAction),
                concatMap(action => {
                    return forkJoin(this.dataService.getBizUnits(), this.dataService.getCurrentUserRoles(action.input));
                }),
                map(resDto => {
                    let buData = resDto[0].map(b => new BizUnit(b));
                    let roleData = resDto[1];
                    let uiData = roleData.map(x => new BusinessUnitRoles(x, buData));

                    let currRoleId = uiData[0].id;
                    let tempId = localStorage.getItem('bizUnitRoleId');
                    if (+tempId) {
                        let resRole = uiData.find(x => x.id === +tempId);
                        if (resRole) {
                            currRoleId = resRole.id;
                        }
                    }

                    return SecurityRoleApiActions.getCurrentUserRolesSuccess({ userRoles: uiData, currentRoleId: currRoleId });
                }),
                catchError(err =>of(SecurityRoleApiActions.getCurrentUserRolesFailure({ error: err })))
            );
    });


    //for api call: getUserList()
    getUserList$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.getAllUserRolesAction),
                withLatestFrom(this.store.pipe(select(getCurrentBizUnitSelector))),
                switchMap(([action, selectedBizUnit]) => {
                    //console.log("selectedBizUnit: ", selectedBizUnit);
                    return forkJoin(of(selectedBizUnit), this.dataService.getUserList(selectedBizUnit));
                }),
                map(([buData, roleData]) => {
                    let uiData = roleData.map(x => new BusinessUnitRoles(x, [buData]));
                    return SecurityRoleApiActions.getUserListSuccess({ userRoles: uiData });
                }),
                catchError(err => of(SecurityRoleApiActions.getUserListFailure({ error: err })))
            );
    });


    //for api call: addUserRoles()
    addUserRoles$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.AddUserRolesAction),
                withLatestFrom(this.store.pipe(select(getCurrentBizUnitSelector))),
                switchMap(([action, selectedBizUnit]) => {
                    return forkJoin(of([selectedBizUnit]), this.dataService.updateUserRoles([action.input]));
                }),
                map(([buData, roleData]) => {
                    let uiData = new BusinessUnitRoles(roleData[0], buData);
                    return SecurityRoleApiActions.addUserRolesSuccess({ addedUsersRoles: uiData })
                }),
                catchError(err => of(SecurityRoleApiActions.addUserRolesFailure({ error: err })))
            );
    });


    //for api call: updateUserRoles()
    updateUserRoles$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.UpdateUserRolesAction),
                withLatestFrom(this.store.pipe(select(getCurrentBizUnitSelector))),
                switchMap(([action, selectedBizUnit]) => {
                    return forkJoin(of([selectedBizUnit]), this.dataService.updateUserRoles(action.input));
                }),
                map(([buData, roleData]) => {
                    let uiData = roleData.map(r => new BusinessUnitRoles(r, buData));
                    uiData.forEach(r => r.isUpdateSuccessful = true);
                    return SecurityRoleApiActions.updateUserRolesSuccess({ updatedUsersRoles: uiData })
                }),
                catchError(err => of(SecurityRoleApiActions.updateUserRolesFailure({ error: err })))
            );
    });


    //for api call: deleteUserFromBU()
    deleteUserFromBU$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.deleteUserFromBUAction),
                concatMap(action => this.dataService.deleteUserFromBU(action.input)),
                map(resDto => SecurityRoleApiActions.deleteUserFromBUSuccess({ deletedUserId: resDto })),
                catchError(err => of(SecurityRoleApiActions.deleteUserFromBUFailure({ error: err })))
            );
    });


    //for api call: getCurrentUserPreferences()
    getCurrentUserPreferences$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.getCurrentUserPreferencesAction),
                concatMap(action => this.dataService.getCurrentUserPreferences(action.input)),
                map(resDto => SecurityRoleApiActions.getCurrentUserPreferencesSuccess({ userPreferences: resDto })),
                catchError(err => of(SecurityRoleApiActions.getCurrentUserPreferencesFailure({ error: err })))
            );
    });


    //for api call: updateUserPreference()
    updateUserPreference$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.updateUserPreferenceAction),
                concatMap(action => this.dataService.updateUserPreference(action.input)),
                map(resDto => SecurityRoleApiActions.updateUserPreferenceSuccess({ userPreference: resDto })),
                catchError(err => of(SecurityRoleApiActions.updateUserPreferenceFailure({ error: err })))
            );
    });


    //for api call: getAllCarriersList()
    getAllCarriersList$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.getAllCarriersListAction),
                concatMap(() => this.dataService.getAllCarriersList()),
                map(resDto => SecurityRoleApiActions.getAllCarriersListSuccess({ allCarriers: resDto })),
                catchError(err => of(SecurityRoleApiActions.getAllCarriersListFailure({ error: err })))
            );
    });

    //for api call: getBizUnitCarrierIds()
    getBizUnitCarrierIds$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.getBizUnitCarrierIdsAction),
                withLatestFrom(this.store.pipe(select(getCurrentBizUnitIdSelector))),
                concatMap(([action, bizUnitId]) => {
                    return this.dataService.getBizUnitCarrierIds(bizUnitId);
                }),
                map(resDto => SecurityRoleApiActions.getBizUnitCarrierIdsSuccess({ buCarrierIds: resDto })),
                catchError(err => of(SecurityRoleApiActions.getBizUnitCarrierIdsFailure({ error: err })))
            );
    });

    //for api call: addBizUnitCarrier()
    addBizUnitCarrier$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.addBizUnitCarrierAction),
                withLatestFrom(this.store.pipe(select(getCurrentBizUnitIdSelector))),
                concatMap(([action, bizUnitId]) => {
                    let dataDto = new BizUnitCarrier({ carrier: action.carrierToAdd, bizUnitId: bizUnitId });
                    return this.dataService.addBizUnitCarrier(dataDto);
                }),
                map(resDto => SecurityRoleApiActions.addBizUnitCarrierSuccess({ addedCarrierId: resDto })),
                catchError(err => of(SecurityRoleApiActions.addBizUnitCarrierFailure({ error: err })))
            );
    });

    //for api call: deleteBizUnitCarrier()
    deleteBizUnitCarrier$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(SecurityRolePageActions.deleteBizUnitCarrierAction),
                withLatestFrom(this.store.pipe(select(getCurrentBizUnitIdSelector))),
                concatMap(([action, bizUnitId]) => {
                    let dataDto = new BizUnitCarrier({ carrier: action.carrierToDelete, bizUnitId: bizUnitId });
                    return this.dataService.deleteBizUnitCarrier(dataDto);
                }),
                map(resDto => SecurityRoleApiActions.deleteBizUnitCarrierSuccess({ deletedCarrierId: resDto })),
                catchError(err => of(SecurityRoleApiActions.deleteBizUnitCarrierFailure({ error: err })))
            );
    });

}
