<span class="alert" *ngIf="searchService.errorMessage">{{searchService.errorMessage}}</span>
<form (submit)="searchByName()">
  <div>
    <mat-form-field class="full-width">
        <mat-label>First Name</mat-label>
      <input name="first" matInput type="text" id="first-name-search" [(ngModel)]="searchService.searchInput.firstName" />
      <mat-hint align="end" title="Not required for local search">Required for NIPR Search</mat-hint>
    </mat-form-field>
  </div>
  <div>
      <mat-form-field class="full-width">
          <mat-label>Last Name</mat-label>
          <input name="last" matInput type="text" id="last-name-search" [(ngModel)]="searchService.searchInput.lastName" />
          <mat-hint align="end" title="Not required for local search">Required for NIPR Search</mat-hint>
      </mat-form-field>

  </div>
  <div>
    <pv-state-select [(state)]="searchService.searchInput.stateCode" class="half-width first-half-option inline top" style="vertical-align: unset;"></pv-state-select>
    <mat-form-field class="half-width second-half-option">
        <mat-label>Last 4 of SSN</mat-label>
        <input name="ssn4" matInput type="text" [(ngModel)]="searchService.searchInput.ssn" />
        <mat-hint align="end" title="Not required for local search">Required for NIPR Search</mat-hint>
    </mat-form-field>
  </div>

  <button class="button" type="submit" id="search-producers" [disabled]="!userService.userRolesLoaded" [ngClass]="{'disabled': !userService.userRolesLoaded}" title="{{userService.userRolesLoaded ? '' : 'Loading User Roles...'}}">Search</button>
</form>
