import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'pv-onboarding-ready-review',
    templateUrl: './onboarding-ready-review.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class OnboardingReadyReviewComponent implements OnInit {

    @Input() count: number;

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    navigateToApplications() {
        this.router.navigate(['/ProducerOnboarding/Dashboard/Completed']);
    }

}
