<div class="comments">
  <div class="comment" *ngFor="let comment of comments">
    <span><span class="comment-author">{{comment.bizUserIdentifier}}</span> - <span class="comment-details">{{comment.editTime | date:'short'}}</span></span>
    <p class="comment-message">{{comment.message}}</p>
  </div>
  <p *ngIf="comments.length == 0" class="italics">No comments yet...</p>
</div>
<div style="margin: 10px 0 30px;" *ngIf="canComment">
  <input type="text" class="comment-box" [(ngModel)]="comment.message" /> <a class="button comment-btn" (click)="addComment()">Add Comment</a>
</div>
