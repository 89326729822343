<div id="drop-target" (drop)="onDropFile($event)" (dragover)="onDragOverFile($event)" (dragleave)="onDragOutFile($event)">
    <ng-container *ngIf="!uploading">
        <h4>Drop file here</h4>
        <p>{{uploadMsg}}</p>
    </ng-container>
    <ng-container *ngIf="uploading">
        <h4>Uploading...</h4>
    </ng-container>
</div>
Or:<br /><br />
<input type="file" accept=".pdf" id="file-browse-button" value="Browse" (change)="browseFile($event)" />
