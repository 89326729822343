import { LicenseDetail } from './license-details';
import { License } from './license';
import { Carrier } from './carrier';
import { RegedOrderStatus } from '../../enums/reged';
import { AppointmentStatusDisplayFromEnum } from '../../utils/string-transform';
import { GlobalLists } from '../../lists';

//intermediate class between license and appointment
export class AppointingLicense {
    appointmentId: number;

    licenseId: number;
    licenseNumber: string;
    details: AppointingLicenseDetail[];
    class: string;
    classCode: number;
    active: boolean;
    stateCode: string;
    editTime: Date;
    effectiveDate: Date;
    expirationDate: Date;
    residencyStatus: string;
    branchId: string;

    selected: boolean;
    appointed: boolean;
    //dataSource: PdbAppointmentDataSource = null;
    isLocal: boolean = null;
    filingType: string;
    inProgress: boolean;
    isErrorStatus: boolean;
    apptStatus: RegedOrderStatus | string;
    loaNotReq: boolean;

    floridaCountyCode: number;
    //for SC Local/General selection
    licenseTypeText = 0;

    //for storing the appt effective date on the license for comparing the appt date from a license is older or newer than the reged log date
    localAppointmentDate: Date;

    get loasSelectedDescription() {
        if (this.details && this.details.length > 0) {
            return Array.from(this.details.filter(x => x.selected === true).map(x => x.description)).join(', ');
        }
        else {
            return "LOA Not Required";
        }
    }

    get displayStatus(): RegedOrderStatus | string {
        return AppointmentStatusDisplayFromEnum(this.apptStatus, "Appointment");
    }

    get loaArray() {
        if (this.details) {
            return Array.from(this.details.map(x => x.description));
        }
    }

    get statusArray() {
        if (this.details) {
            return Array.from(this.details.map(x => x.status));
        }
    }

    get isDisabled() {
        //disable appt on expired licenses
        const today = new Date();
        if (this.expirationDate !== null && this.expirationDate < today) {
            return true;
        }
        //FL county requires a county code to enable appt.
        if (this.stateCode === 'FC') {
            if (!this.floridaCountyCode || this.floridaCountyCode === 0) {
                return true;
            }
        }
        //SC User must specifiy Appointment Type = Local or General, per RegEd prior to selecting for appointment
        if (this.stateCode === 'SC') {
            if (!this.licenseTypeText || this.licenseTypeText < 1) {
                return true;
            }
        }
        return false;
    }

    constructor(init?: Partial<AppointingLicense>) {
        this.licenseId = init.licenseId;
        this.licenseNumber = init.licenseNumber;
        this.details = init.details ? init.details.map(x => new AppointingLicenseDetail(x)).sort((x, y) => {
            if (x.description > y.description) return 1; else return -1;
        }) : null;
        this.class = init.class;
        this.classCode = init.classCode;
        this.active = init.active;
        this.stateCode = init.stateCode;
        this.effectiveDate = init.effectiveDate;
        this.expirationDate = init.expirationDate;
        this.branchId = init.branchId;
        this.residencyStatus = init.residencyStatus;

        this.floridaCountyCode = init.floridaCountyCode;

        this.selected = false;
        this.inProgress = false;
        this.isErrorStatus = false;
        this.appointed = false;
        this.loaNotReq = false;
    }

    static fromLicense(init?: Partial<License>) {
        const lic = new AppointingLicense({});
        lic.licenseId = init.id;
        lic.licenseNumber = init.licenseNumber;
        lic.details = init.details ? init.details.map(x => new AppointingLicenseDetail(x)).sort((x, y) => {
            if (x.description > y.description) return 1; else return -1;
        }) : null;
        lic.class = init.class;
        lic.classCode = init.classCode;
        lic.active = init.active;
        lic.stateCode = init.stateCode;
        lic.effectiveDate = init.effectiveDateTime;
        lic.expirationDate = init.expirationDateTime;
        lic.branchId = init.branchID;
        lic.residencyStatus = init.residencyStatus;
        lic.floridaCountyCode = null;

        lic.selected = false;
        lic.inProgress = false;
        lic.isErrorStatus = false;
        lic.appointed = false;
        lic.loaNotReq = false;

        return lic;
    }
}

export class AppointingLicenseDetail extends LicenseDetail {
    id: number;
    code: number;
    description: string;
    status: string;
    statusReason: string;
    statusReasonDate: Date;
    issueDate: Date;

    selected: boolean;
    appointed: boolean;
    appointmentId: number;
    isLocal: boolean = null;
    inProgress: boolean;
    filingType: string;
    isErrorStatus: boolean;
    apptStatus: RegedOrderStatus | string;

    localAppointmentDate: Date;

    //for storing any associated appt license type data for SC in case of doing a termination, it needs to be sent along with the term. req.
    licenseTypeText = 0;

    //for storing the matched appointment code for linking multiple details together (ie: for FL terminations 'health' ,'life', etc all need to go together)
    appointmentCode: number;

    get displayStatus(): RegedOrderStatus | string {
        return AppointmentStatusDisplayFromEnum(this.apptStatus, "Appointment");
    }

    constructor(init?: Partial<AppointingLicenseDetail>) {
        super(init);

        this.selected = init.selected === true ? init.selected : false;
        this.appointed = init.appointed === true ? init.appointed : false;
        this.inProgress = init.inProgress === true ? init.inProgress : false;
        this.isErrorStatus = init.isErrorStatus === true ? init.isErrorStatus : false;
        this.apptStatus = init.apptStatus;
        this.appointmentCode = init.appointmentCode;
    }
}

function checkIfErrorStatus(apptStatus: RegedOrderStatus | string): boolean {
    if (apptStatus) {
        const status: RegedOrderStatus = (RegedOrderStatus as any)[apptStatus];
        if (status === RegedOrderStatus.IPAInternalError
            || status === RegedOrderStatus.NiprValidationError
            || status === RegedOrderStatus.RegedError
            || status === RegedOrderStatus.RegedValidationError
            || status === RegedOrderStatus.CompletedAsNiprRejected) {
            return true;
        }
    }
    return false;
}

export class PobAppointment {
    id: number;

    producerId: number;

    //full carrier for UI only, API needs id
    carrier: Carrier;
    carrierId: number;

    licenseId: number;

    licenseNumber: string;
    license: License;
    licenseDetailId: number;

    class: string;
    classCode: number;
    stateCode: string;

    //loaCode: number;
    loaDescription: string;

    status: RegedOrderStatus | string;
    message: string;

    isCheckedByInternalUser: boolean;
    isCheckedByExternalUser: boolean;

    effectiveDate: Date;

    appointed: boolean;

    //Termination or Appointment
    filingType: string;

    floridaCountyCode: number[];
    floridaCountyName: string;

    regedCreateOrderLog: RegedCreateOrderLog[];

    creator: string;
    createTime: Date;
    createDate: Date;

    get isErrorStatus(): boolean {
        let isError = checkIfErrorStatus(this.status);

        if (isError) {
            const waitPeriod = new Date();
            waitPeriod.setMinutes(waitPeriod.getMinutes() - 10);
            //check if appointment was more recent than 10 min ago
            if (this.createTime > waitPeriod) {
                console.log('waiting period');
                this.status = RegedOrderStatus[RegedOrderStatus.RegedOrderSubmited];
                isError = false;
            }
        }

        return isError;
    }

    get displayStatus(): RegedOrderStatus | string {
        return AppointmentStatusDisplayFromEnum(this.status, this.filingType);
    }

    get licenseDisplay(): string {
        if (this.licenseDetailId !== 0) {
            return this.loaDescription;
        }
        else {
            return this.class;
        }
    }

    constructor(init: Partial<PobAppointment>, carriers?: Carrier[], licenses?: License[]) {
        this.id = init.id;
        this.producerId = init.producerId;
        this.carrierId = init.carrierId;
        this.carrier = init.carrier ? init.carrier : null;
        this.licenseId = init.licenseId;
        this.licenseNumber = init.licenseNumber;
        this.licenseDetailId = init.licenseDetailId;
        this.class = init.class;
        this.classCode = init.classCode;
        this.stateCode = init.stateCode;
        this.loaDescription = init.loaDescription;
        this.status = init.status;
        this.message = init.message;
        if (this.message === "InvalidInputParameters") {
            this.message = "Contact IPA Support";
        }
        this.isCheckedByExternalUser = init.isCheckedByExternalUser;
        this.isCheckedByInternalUser = init.isCheckedByInternalUser;
        this.effectiveDate = init.effectiveDate;
        this.appointed = init.appointed;
        this.filingType = init.filingType;
        this.floridaCountyCode = init.floridaCountyCode;

        if (carriers) {
            const carrier = carriers.filter(c => c.id === this.carrierId);
            if (carrier && carrier.length === 1) {
                this.carrier = carrier[0];
            }
        }

        if (init.regedCreateOrderLog) {
            this.regedCreateOrderLog = init.regedCreateOrderLog.map(l => new RegedCreateOrderLog(l));

            const maxLog = this.regedCreateOrderLog.reduce((prev, cur) => (cur.id > prev.id) ? cur : prev);
            this.regedCreateOrderLog = [maxLog];
            if (maxLog) {
                this.creator = maxLog.creator;
                this.filingType = maxLog.filingType;
                if (maxLog.createTime) {
                    this.createTime = new Date(maxLog.createDateTime);
                    this.createDate = new Date(this.createTime);
                    this.createDate.setHours(0);
                    this.createDate.setMinutes(0);
                    this.createDate.setSeconds(0);
                    this.createDate.setMilliseconds(0);
                }
                this.floridaCountyCode = [maxLog.floridaCountyCode];
            }
        }

        ////check for waiting period on 'errors'
        //if (this.isErrorStatus) {
        //    const waitPeriod = new Date();
        //    waitPeriod.setHours(waitPeriod.getHours() - 2);
        //    //check if appointment was more recent than 2 hours ago
        //    if (this.createTime > waitPeriod) {
        //        this.status = RegedOrderStatus[RegedOrderStatus.WaitingPeriodHolding];
        //    }
        //}

        if (licenses) {
            const lic = licenses.find(l => l.id === this.licenseId);
            if (lic) {
                this.class = lic.class;
                this.classCode = lic.classCode;
                this.licenseNumber = lic.licenseNumber;
                const det = lic.details.find(d => d.id === this.licenseDetailId);
                if (det) {
                    this.loaDescription = det.description;
                }
            }
        }
    }

    static fromLicense(prodId: number, carrier: Carrier, lic: AppointingLicense, det?: AppointingLicenseDetail) {
        let app = new PobAppointment({});

        app.producerId = prodId;

        app.carrier = carrier;
        app.carrierId = carrier.id;

        app.licenseId = lic.licenseId;
        app.licenseNumber = lic.licenseNumber;
        app.class = lic.class;
        app.classCode = lic.classCode;
        app.stateCode = lic.stateCode;

        if (det) {
            //app = det.code;
            app.loaDescription = det.description;
            app.licenseDetailId = det.id;
            app.status = RegedOrderStatus[det.apptStatus];
        }
        else if (lic.loaNotReq) {
            app.loaDescription = "LOA Not Required";
            app.status = RegedOrderStatus[lic.apptStatus];
            app.licenseDetailId = 0;
        }

        app.filingType = "Appointment";
        app.isCheckedByInternalUser = true;

        if (lic.floridaCountyCode) {
            app.floridaCountyCode = [lic.floridaCountyCode];
        }

        return app;
    }
}


export class LocalPobAppointmentCreateInput {

    producerId: number;
    carrierId: number;

    stateCode: string;
    loaCode: number;
    loaDescription: string;

    countyCode: string;

    effectiveDate: Date;

    //Terminated or Appointed
    filingType: string;

    //isInNipr: boolean;
    isLocal = true;

    creator: string;
    createDate: Date;

    constructor(init: Partial<LocalPobAppointmentCreateInput>) {
        Object.assign(this, init);
    }

    static fromLicense(prodId: number, carrier: Carrier, lic: AppointingLicense, det?: AppointingLicenseDetail) {
        let app = new LocalPobAppointmentCreateInput({});

        app.producerId = prodId;
        app.carrierId = carrier.id;
        app.stateCode = lic.stateCode;

        if (det) {
            app.loaCode = det.code;
            app.loaDescription = det.description;
        }
        else if (lic.loaNotReq) {
            app.loaDescription = "LOA Not Required";
        }

        app.filingType = "Appointed";
        //app.isInNipr = false;

        return app;
    }
}


export class RegedCreateOrderLog {
    id: number;

    loaType: string;
    loAs: string[] = [];

    licenseType: string;

    filingType = "Appointment";
    effectiveDate: string;

    creator: string;
    createTime: string;
    createDateTime: Date;

    bizUnitId: number;

    terminationCode: string;
    terminationReason: string;
    isTerminationForCause: boolean;

    clientOrderNumber: string;

    status: RegedOrderStatus | string;
    get isErrorStatus(): boolean {
        return checkIfErrorStatus(this.status);
    }
    message: string;

    floridaCountyCode: number;

    get floridaCountyName(): string {
        if(this.floridaCountyCode) {
            return GlobalLists.floridaCountyList.find(c => c.code === this.floridaCountyCode)?.name;
        }
    };

    licenseTypeText: number;

    constructor(init?: Partial<RegedCreateOrderLog>) {
        Object.assign(this, init);
        if (init && init.createTime) {
            if (init.createTime.indexOf("Z") < 0) {
                init.createTime = init.createTime + "Z";
            }
            this.createTime = init.createTime;
            this.createDateTime = new Date(this.createTime)
        }
        this.loAs = this.loAs.map(l => l.toLowerCase());
    }
}

export class RegedCreateOrderLogInDetail {
    orderId: number;

    filingType: string;
    creator: string;
    createTime: Date;
    status: RegedOrderStatus | string;

    loa: string;
    licenseType: string;
    stateCode: string;
    licenseId: number;
    licenseDetailId: number;

    producerId: number;
    producerName: string;

    carrierName: string;
    carrierId: number;

    deleteAlert: boolean;

    get displayStatus(): RegedOrderStatus | string {
        return AppointmentStatusDisplayFromEnum(this.status, this.filingType);
    }

    constructor(init?: Partial<RegedCreateOrderLogInDetail>) {
        Object.assign(this, init);
        this.producerName = this.producerName.toLowerCase();
    }
}

export class InputForOneRegedCreateOrder {

    carrierLOAsMatrix: PobAppointment[];

    regedOrderData: RegedCreateOrderLog;
}

export class RegedOrderStatusShort {
    regedCreateOrderId: number;
    status: RegedOrderStatus;
    statusDescription: string;
}
