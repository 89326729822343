import { IBizUnitVerbiage } from '../../shared/models/general';
import { createReducer, on } from '@ngrx/store';
import { AdminApiActions } from './actions';
import * as AppState from '../../state/app.state';
import { DocuSignTemplate } from '../../shared/models/document';


//In case admin module is lazy loaded, we can't add IAdminState to app.state.ts directly
export interface State extends AppState.State {
    adminSettings: IAdminState;
}

//Admin state
export interface IAdminState {
    bizUnitVerbiage: IBizUnitVerbiage;
    dbDocumentTemplates: DocuSignTemplate[];
    error: string;
}

const initialState: IAdminState = {
    bizUnitVerbiage: null,
    dbDocumentTemplates: null,
    error: ''
};

export const adminReducer = createReducer<IAdminState>(
    initialState,

    /////////////////////
    //For API
    /////////////////////

    //for api call: getBizUnitVerbiage()
    on(AdminApiActions.getBizUnitVerbiageSuccess, (state, action): IAdminState => {
        return {
            ...state,
            bizUnitVerbiage: action.buVerbiage,
            error: ''
        };
    }),
    on(AdminApiActions.getBizUnitVerbiageFailure, (state, action): IAdminState => {
        return {
            ...state,
            bizUnitVerbiage: null,
            error: action.error
        };
    }),


    //for api call: saveBizUnitVerbiage()
    on(AdminApiActions.saveBizUnitVerbiageSuccess, (state, action): IAdminState => {
        return {
            ...state,
            bizUnitVerbiage: action.buVerbiage,
            error: ''
        };
    }),
    on(AdminApiActions.saveBizUnitVerbiageFailure, (state, action): IAdminState => {
        return {
            ...state,
            bizUnitVerbiage: null,
            error: action.error
        };
    }),


    //for api call: getDocumentListsByBizUnit()
    on(AdminApiActions.getDocumentListsByBizUnitSuccess, (state, action): IAdminState => {
        return {
            ...state,
            dbDocumentTemplates: action.buDocumentTemplates,
            error: ''
        };
    }),
    on(AdminApiActions.getDocumentListsByBizUnitFailure, (state, action): IAdminState => {
        return {
            ...state,
            dbDocumentTemplates: null,
            error: action.error
        };
    }),


    //for api call: updateDocumentTemplates() - update existing template attributes
    on(AdminApiActions.updateDocumentTemplatesSuccess, (state, action): IAdminState => {
        const updatedData = state.dbDocumentTemplates.map(item => {
            let updatedDbDocTemplate = action.buDocumentTemplates.find(r => r.id === item.id);
            return updatedDbDocTemplate ? updatedDbDocTemplate : item;
        });
        return {
            ...state,
            dbDocumentTemplates: updatedData,
            error: ''
        };
    }),
    on(AdminApiActions.updateDocumentTemplatesFailure, (state, action): IAdminState => {
        return {
            ...state,
            dbDocumentTemplates: null,
            error: action.error
        };
    }),

    //for api call: uploadDocusignTemplateFile() -- add a new template
    on(AdminApiActions.uploadDocusignTemplateFileSuccess, (state, action): IAdminState => {
        return {
            ...state,
            dbDocumentTemplates: [...state.dbDocumentTemplates, action.docusignTemplate],
            error: ''
        };
    }),
    on(AdminApiActions.uploadDocusignTemplateFileFailure, (state, action): IAdminState => {
        return {
            ...state,
            error: action.error
        };
    }),


    //for api call: deleteDocusignTemplateFile()
    on(AdminApiActions.deleteDocusignTemplateFileSuccess, (state, action): IAdminState => {
        return {
            ...state,
            dbDocumentTemplates: state.dbDocumentTemplates.filter(item => item.id !== action.deletedID),
            error: ''
        };
    }),
    on(AdminApiActions.deleteDocusignTemplateFileFailure, (state, action): IAdminState => {
        return {
            ...state,
            error: action.error
        };
    }),

);
