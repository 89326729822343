import { ApplicationProgress } from "../shared/enums/general";
import { BizUnit } from "../shared/models/general";
import { Carrier } from "../shared/models/pob/carrier";

export interface IReportRegulatoryInput {
    onOrAfterDate: Date;
}

export interface IReportApptRenewalInput {
    startDate: Date;
    endDate: Date;
    //status: string;
    //includeRecordOnly: boolean;
    state: string;
    bizUnits: BizUnit[];
    carriers: Carrier[];
}
export class ReportApptRenewalInput {
    constructor(input: IReportApptRenewalInput) {
        this.startDate = input.startDate;
        this.endDate = input.endDate;
        //this.status = input.status;
        //this.includeRecordOnly = input.includeRecordOnly;
        this.state = input.state;
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
        if (input.carriers) {
            this.carrierIds = input.carriers.map(b => b.id);
        }
    }
    startDate: Date;
    endDate: Date;
    //status: string;
    //includeRecordOnly: boolean;
    state: string;
    bizUnitIds: number[];
    carrierIds: number[];
}

export interface IReportLicenseRenewalInput {
    startDate: Date;
    endDate: Date;
    status: string;
    missingStateLicense: boolean;
    bizUnits: BizUnit[];
    producerType: string;
}

export class ReportLicenseRenewalInput {
    constructor(input: IReportLicenseRenewalInput) {
        this.startDate = input.startDate;
        this.endDate = input.endDate;
        this.active = (input.status === "active" ? true : input.status === "inactive" ? false : null);
        this.missingStateLicense = input.missingStateLicense;
        this.producerType = input.producerType;
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
    }
    startDate: Date;
    endDate: Date;
    active: boolean;
    missingStateLicense: boolean;
    bizUnitIds: number[];
    producerType: string;
}

export interface IReportUntrackedProducersInput {
    startDate: Date;
    endDate: Date;
}

export class ReportUntrackedProducersInput {
    constructor(input: IReportUntrackedProducersInput) {
        this.startDate = input.startDate;
        this.endDate = input.endDate;
    }
    startDate: Date;
    endDate: Date;
}

export interface IReportAssociationInput {
    type: string;
}
export class ReportAssociationInput {
    constructor(input: IReportAssociationInput) {
        this.type = input.type;
    }
    type: string;
}

export interface IReportAgencyAssociationInput {
    carriers: BizUnit[];
}
export class ReportAgencyAssociationInput {
    constructor(input: IReportAgencyAssociationInput) {
        if (input.carriers) {
            this.carrierIds = input.carriers.map(b => b.id);
        }
    }
    carrierIds: number[];
}

export interface IReportIncompleteOnboardingInput {
    bizUnits: BizUnit[];
    onboardingStatus: string;
    startDate: Date;
    endDate: Date;
}
export class ReportIncompleteOnboardingInput {
    constructor(input: IReportIncompleteOnboardingInput) {
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
        this.onboardingStatus = input.onboardingStatus;
        this.startDate = input.startDate;
        this.endDate = input.endDate;
    }
    bizUnitIds: number[];
    onboardingStatus: string;
    startDate: Date;
    endDate: Date;
}

export interface IReportRegedErrorsInput {
    bizUnits: BizUnit[];
    carriers: Carrier[];
    startDate: Date;
    endDate: Date;
}
export class ReportRegedErrorsInput {
    constructor(input: IReportRegedErrorsInput) {
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
        if (input.carriers) {
            this.carrierIds = input.carriers.map(b => b.id);
        }
        this.startDate = input.startDate;
        this.endDate = input.endDate;
    }
    bizUnitIds: number[];
    carrierIds: number[];
    startDate: Date;
    endDate: Date;
}

export interface IReportRegedBillingInput {
    bizUnits: BizUnit[];
    carriers: Carrier[];
    startDate: Date;
    endDate: Date;
}
export class ReportRegedBillingInput {
    constructor(input: IReportRegedBillingInput) {
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
        if (input.carriers) {
            this.carrierIds = input.carriers.map(b => b.id);
        }
        this.startDate = input.startDate;
        this.endDate = input.endDate;
    }
    bizUnitIds: number[];
    carrierIds: number[];
    startDate: Date;
    endDate: Date;
}

export interface IReportNIPRSeedingInput {
    bizUnits: BizUnit[];
    startDate: Date;
    endDate: Date;
}
export class ReportNIPRSeedingInput {
    constructor(input: IReportNIPRSeedingInput) {
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
        this.startDate = input.startDate;
        this.endDate = input.endDate;
    }
    bizUnitIds: number[];
    startDate: Date;
    endDate: Date;
}

export interface IReportEnOInput {
    bizUnits: BizUnit[];
    carriers: Carrier[];
    startDate: Date;
    endDate: Date;
    includeNull: boolean;
}
export class ReportEnOInput {
    constructor(input: IReportEnOInput) {
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
        if (input.carriers) {
            this.carrierIds = input.carriers.map(b => b.id);
        }
        this.startDate = input.startDate;
        this.endDate = input.endDate;
        this.includeNull = input.includeNull;
    }
    bizUnitIds: number[];
    carrierIds: number[];
    startDate: Date;
    endDate: Date;
    includeNull: boolean;
}

export interface IReportAuditCarrierInput {
    startDate: Date;
    endDate: Date;
    carriers: Carrier[];
    auditLogTable: string;
}
export class ReportAuditCarrierInput {
    constructor(input: IReportAuditCarrierInput) {
        this.startDate = input.startDate;
        this.endDate = input.endDate;
        if (input.carriers) {
            this.carrierIds = input.carriers.map(b => b.id);
        }
        this.auditLogTable = input.auditLogTable;
    }
    startDate: Date;
    endDate: Date;
    carrierIds: number[];
    auditLogTable: string;
}

export interface IReportBigBackgroundChecksInput {
    startDate: Date;
    endDate: Date;
    status: string;
}
export class ReportBigBackgroundChecksInput {
    constructor(input: IReportBigBackgroundChecksInput) {
        this.startDate = input.startDate;
        this.endDate = input.endDate;
        this.status = input.status;
    }
    startDate: Date;
    endDate: Date;
    status: string;
}

export interface IReportEmployedProducerInput {
    state: string;
    bizUnits: BizUnit[];
    include: boolean;
}
export class ReportEmployedProducerInput {
    constructor(input: IReportEmployedProducerInput) {
        this.state = input.state;
        this.include = input.include;
        if (input.bizUnits) {
            this.bizUnitIds = input.bizUnits.map(b => b.id);
        }
    }
    state: string;
    bizUnitIds: number[];
    include: boolean;
}

export class ReportType {
    display: string;
    type: string;

    public constructor(init?: Partial<ReportType>) {
        Object.assign(this, init);
    }
}
