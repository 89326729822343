
<div class="flex">
    <div style="width: 60%;">
        <ng-container *ngIf="!documents || documents.length === 0">
            <h4>No documents have been uploaded for this producer</h4>
        </ng-container>

        <ng-container *ngIf="documents && documents.length > 0">
            <div *ngFor="let doc of documents">
                <div class="doc-contain">
                    <a *ngIf="isBUOwner || activeUser === doc.editor" data-cy="hrefRemoveDoc" class="delete-btn" (click)="removeProducerDocument(doc)" title="Delete Document"></a>
                    <span *ngIf="!(isBUOwner || activeUser === doc.editor)" class="no-delete-spacer"></span>
                    <a data-cy="hrefDocUrl" (click)="getProducerDocumentLink(doc)" class="doc-title"><span title="{{doc.editor}} - {{doc.editTime | date:'mediumDate'}}">{{doc.name}}</span></a>
                </div>
            </div>
        </ng-container>
    </div>
    <div>
        <div id="drop-target" (drop)="onDropFile($event)" (dragover)="onDragOverFile($event)" (dragleave)="onDragOut($event)">
            <ng-container *ngIf="!uploading">
                <h4>Drop file here</h4>
            </ng-container>
            <ng-container *ngIf="uploading">
                <h4>{{uploadMsg}}</h4>
            </ng-container>
        </div>
        Or:<br /><br />
        <input type="file" accept=".pdf" id="file-browse-button" value="Browse" (change)="browseFile($event)" />
    </div>
</div>
<div class="flex" style="margin-top: 20px;">
    <div style="width: 60%;">
        <h4>Business Unit Producer Documents</h4>
        <p>Documents uploaded to this section are only visable to members of this business unit.</p>
        <ng-container *ngIf="bizUnitDocuments && bizUnitDocuments.length > 0">
            <div *ngFor="let doc of bizUnitDocuments">
                <div class="doc-contain">
                    <a *ngIf="isBUOwner || activeUser === doc.editor" data-cy="hrefRemoveDoc" class="delete-btn" (click)="removeProducerDocument(doc)" title="Delete Document"></a>
                    <span *ngIf="!(isBUOwner || activeUser === doc.editor)" class="no-delete-spacer"></span>
                    <a data-cy="hrefDocUrl" (click)="getProducerDocumentLink(doc)" class="doc-title"><span title="{{doc.editor}} - {{doc.editTime | date:'mediumDate'}}">{{doc.name}}</span></a>
                </div>
            </div>
        </ng-container>
    </div>
    <div>
        <div id="drop-target-bizunit" (drop)="onDropFile($event, true)" (dragover)="onDragOverFile($event, true)" (dragleave)="onDragOut($event, true)">
            <ng-container *ngIf="!uploading">
                <h4>Drop Business Unit file here</h4>
            </ng-container>
            <ng-container *ngIf="uploadingBizUnit">
                <h4>{{uploadMsg}}</h4>
            </ng-container>
        </div>
        Or:<br /><br />
        <input type="file" accept=".pdf" id="file-browse-button" value="Browse" (change)="browseFile($event, true)" />
    </div>
</div>

