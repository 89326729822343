import { HttpErrorResponse } from '@angular/common/http';

export function GetHttpErrorMessage(err: any): string {
    let msg = "";

    if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        msg = `An error occurred: ${err.error.message}`;
    }
    else if (err instanceof HttpErrorResponse) {
        if (err.error) {
            if (err.error instanceof String) {
                msg = err.error + "";
                //chop off stack trace for display message purpose, clean up message for user consumption
                msg = "An error occurred: " + msg.substr(0, msg.indexOf("at MasterDbApi")).substr(0, msg.indexOf("For detail, please")).replace("System.Exception:", "").trim();
            }
            else {
                msg = err.error.title;
                if (err.error.errors != null) {
                    Object.keys(err.error.errors).forEach((key) => {
                        let e = err.error.errors[key];
                        if (e && e.length > 0) {
                            msg += "<br /><br />" + e[0];
                        }
                    });
                }
            }
        }
        else if (err.status === 403) {
            msg = `Access Denied (error 403), please contact your manager.`;
        }
        else if (err.status === 401) {
            msg = `Session timeout, please log in again.`;
        }
    }


    if (msg == "" || msg == undefined) {
        msg = err.message;
    }

    return msg;
}

export function GetDtoErrorMessage(err: any): string {
    if (err.message) {
        return err.message;
    }
}

export function CheckClientDtoFailStatus(dto: any): boolean {
    if (dto.status > 0) {
        console.log(dto);
    }
    return dto.status < 0 && dto.status !== -404;
}
