import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { IPdbAppointment } from '../../../shared/models/dto/pdb-result.dto';
import { State } from '../../../state/app.state';
import { Store } from '@ngrx/store';
import { getCurrentUserPreferencesSelector } from '../../../user/state';
import { User } from '../../../shared/models/user';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'pv-appointments',
    templateUrl: './appointments.component.html',
})
export class AppointmentsComponent implements OnInit, OnDestroy {

    private prodIdSubscription;
    private prodId;

    columns: string[] = ['companyName', 'fein', 'state', 'loaCode', 'status', 'termination', 'effectiveDate', 'expirationDate'];
    dataSource;
    loading: boolean = false;

    filterValues = {
        companyName: '',
        fein: '',
        state: '',
        status: '',
        termination: '',
        loa: null
    };

    showFilter: boolean = true;
    stateList: string[] = [];
    lineOfAuthList: string[] = [];
    staticStatusList: string[] = ["[X]", "Appointed", "Terminated", "Record Only"];

    userHasDefaultSet: boolean = false;
    defaultPrefFilterActive: boolean = true;
    userDefaultCarrierFilter: number[] = [];

    companyNameFilter = new FormControl('');
    feinFilter = new FormControl('');
    stateFilter = new FormControl('');
    statusFilter = new FormControl('');
    terminationFilter = new FormControl('');
    loaFilter = new FormControl<string|null>(null);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    //@ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(public producerService: ProducerDataService, private store: Store<State>, private modal: ModalService) {
        console.log('appointments');
    }

    ngOnInit() {
        this.store.select(getCurrentUserPreferencesSelector)
            .pipe(tap((userData: User) => {
                if (userData) {
                    //console.log("222222", userData);
                    let userPref = userData.userCarriersPreference;
                    if (userPref && userPref.value != "") {
                        this.userHasDefaultSet = true;
                        this.userDefaultCarrierFilter = userPref.value.split(',').map(c => +c);
                    }
                }
            })).subscribe();

        if (this.producerService.producer) {
            this.prodId = this.producerService.producer.id;
            if (!this.producerService.pdbAppointments || !this.producerService.pdbAppointmentsFullyLoaded) {
                this.getAppointments();
            }
            else {
                //appointments were loaded already
                this.dataSource = new MatTableDataSource(this.producerService.pdbAppointments.filter(app => (this.userDefaultCarrierFilter && this.userDefaultCarrierFilter.length === 0) || this.userDefaultCarrierFilter.indexOf(app.carrierId) !== -1));
            }
        }

        this.prodIdSubscription = this.producerService.id$.subscribe(newId => {
            if (this.prodId != newId) {
                this.prodId = newId;
                this.getAppointments();
            }
        });

        if (this.dataSource) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.tableFilter();

            this.fillLists(this.dataSource._data._value);
        }

        this.companyNameFilter.valueChanges
            .subscribe(
                name => {
                    this.filterValues.companyName = name;
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
        this.feinFilter.valueChanges
            .subscribe(
                fein => {
                    this.filterValues.fein = fein;
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
        this.stateFilter.valueChanges
            .subscribe(
                state => {
                    if (state === "[X]") {
                        this.filterValues.state = "";
                        this.stateFilter.setValue("");
                    }
                    else {
                        this.filterValues.state = state;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
        this.loaFilter.valueChanges
            .subscribe(
                loa => {
                    if (loa === "[X]") {
                        this.filterValues.loa = null;
                        this.loaFilter.setValue(null);
                    }
                    else {
                        this.filterValues.loa = loa;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.statusFilter.valueChanges
            .subscribe(
                status => {
                    if (status === "[X]") {
                        this.filterValues.status = "";
                        this.statusFilter.setValue("");
                    }
                    else {
                        this.filterValues.status = status;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.terminationFilter.valueChanges
            .subscribe(
                term => {
                    this.filterValues.termination = term;
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
    }

    ngOnDestroy() {
        if (this.prodIdSubscription) {
            this.prodIdSubscription.unsubscribe();
        }
    }

    getAppointments(): void {
        let prodId = this.prodId;
        if (prodId) {
            this.loading = true;
            let carrierIds: number[] = [];
            if (this.defaultPrefFilterActive) {
                carrierIds = this.userDefaultCarrierFilter;
            }
            this.producerService.getAppointmentsByProdId(prodId, carrierIds).subscribe({
                next: resData => {
                    if (resData && resData.status == 0 && resData.dataList) {
                        this.producerService.pdbAppointments = resData.dataList.map(x => new IPdbAppointment(x));

                        this.fillLists(resData.dataList);
                        if (!this.defaultPrefFilterActive) {
                            this.producerService.pdbAppointmentsFullyLoaded = true;
                        }

                        this.dataSource = new MatTableDataSource(this.producerService.pdbAppointments);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.filterPredicate = this.tableFilter();
                        //this.dataSource.sort = this.sort;
                    }

                    this.loading = false;
                },
                error: err => {
                    this.loading = false;
                }
            });
        }
    }

    tableFilter(): (data: IPdbAppointment, filter: string) => boolean {
        const filterFunction = function (data: IPdbAppointment, filter: string): boolean {
            const searchTerms = JSON.parse(filter);

            const name: string = data.companyName.toLocaleLowerCase().replace(/&/g, 'and').replace(/[\.\,]/g, '');
            const searchName: string = searchTerms.companyName.toLocaleLowerCase().replace(/&/g, 'and').replace(/[\.\,]/g, '');
            return (searchName === '' || name.indexOf(searchName) !== -1)
                && (searchTerms.fein === '' || data.fein.indexOf(searchTerms.fein) !== -1)
                && (searchTerms.status === '' || data.displayStatus === searchTerms.status)
                && (searchTerms.termination === '' || data.terminationReason?.toLowerCase().indexOf(searchTerms.termination.toLowerCase()) >= 0)
                && (searchTerms.state === '' || data.state === searchTerms.state)
                && (searchTerms.loa === null || data.loaDescription === searchTerms.loa);
        }
        return filterFunction;
    }

    fillLists(data: IPdbAppointment[]): void {
        this.stateList = [];
        this.lineOfAuthList = [];

        data.forEach((x, index) => {
            if (this.stateList.indexOf(x.state) < 0) {
                this.stateList.push(x.state);
            }

            let loa = x.loaDescription;
            if (loa === null) {
                loa = "";
                data[index].loaDescription = "";
            }
            if (this.lineOfAuthList.indexOf(loa) < 0) {
                this.lineOfAuthList.push(loa);
            }
        });

        //this.stateList = data.map(item => item.state).filter((value, index, self) => self.indexOf(value) === index);
        this.stateList.sort();
        this.stateList.unshift("[X]");//clear filter val

        //this.lineOfAuthList = data.map(item => item.loaDescription).filter((value, index, self) => self.indexOf(value) === index);
        this.lineOfAuthList.sort();
        this.lineOfAuthList.unshift("[X]");
    }

    toggleCarrierPreference() {
        this.defaultPrefFilterActive = !this.defaultPrefFilterActive;
        //check if we've already loaded the full unfiltered list
        if (this.producerService.pdbAppointmentsFullyLoaded) {
            let data: IPdbAppointment[];
            if (this.defaultPrefFilterActive) { //filter on the previously loaded full list
                let carrierIds = this.userDefaultCarrierFilter;
                data = this.producerService.pdbAppointments.filter(app => carrierIds.indexOf(app.carrierId) >= 0);
            }
            else { //apply the loaded full list
                data = this.producerService.pdbAppointments;
            }

            this.fillLists(data);
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.tableFilter();
        }
        else {
            this.getAppointments();
        }
    }
}
