import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationCommentsComponent } from '../../comments/app-comments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    declarations: [ApplicationCommentsComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        ApplicationCommentsComponent
    ]
})
export class CommentsModule { }
