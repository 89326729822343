import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';

import { IClientListDTO, IClientItemDTO } from '../shared/models/dto/common-result.dto'
import { IPdbAppointment, IPdbRegulatory, IPdbDetails } from '../shared/models/dto/pdb-result.dto'
import { IPdbSearchByNpn, IPdbSearchLicense } from './pdb-search.input'
import { RestService } from '../services/rest.service';
import { Producer, ProducerRelationship, ProducerComment, CECourse, CERequirement, ProducerCompanyLog } from '../shared/models/pob/producer';
import { License } from '../shared/models/pob/license';
import { Contact } from '../shared/models/pob/contact';
import { BizUnitProducer } from '../shared/models/general';
import { ShortApplication } from '../shared/models/application';
import { InputForOneRegedCreateOrder, LocalPobAppointmentCreateInput, PobAppointment, RegedOrderStatusShort } from '../shared/models/pob/appointing-license';
import { Carrier } from '../shared/models/pob/carrier';
import { map } from 'rxjs/operators';
import { PdbContact } from '../shared/models/pdb/pdb-contact';
import { PdbAddress } from '../shared/models/pdb/pdb-address';
import { BigOrderLog } from '../shared/models/big/order-log';
import { Address } from '../shared/models/pob/address';

@Injectable({
    providedIn: 'root'
})
export class ProducerDataService extends RestService {

    producer: Producer = null;
    producerHierarchy: Producer;
    producerResultFromNipr = false;

    private id: Subject<number> = new Subject<number>();
    get id$() {
        return this.id.asObservable();
    }
    updateId(val: number) {
        this.clearProducerData();
        if (!isNaN(val)) {
            this.id.next(val);
        }
    }
    setId(val: number) {
        if (!isNaN(val)) {
            this.id.next(val);
        }
    }

    pdbAppointments: IPdbAppointment[] = null;
    //Indicates if the Appts loaded was a filtered set based on User Preferences
    pdbAppointmentsFullyLoaded = false;
    licenses: License[] = null;
    pdbRegulatories: IPdbRegulatory[] = null;
    applications: ShortApplication[] = null;

    //store just the appointments from carriers related to our active BU (new prod appointment page)
    carrierAppointments: IPdbAppointment[] = null;
    pobAppointments: PobAppointment[] = null;

    producerCECourses: CECourse[];
    producerCEReqs: CERequirement[];

    clearProducerData() {
        this.producer = null;
        this.producerHierarchy = null;
        this.pdbAppointments = null;
        this.pdbAppointmentsFullyLoaded = false;
        this.licenses = null;
        this.pdbRegulatories = null;
        this.applications = null;
        this.updateSearchResults([]);
        this.producerResultFromNipr = false;
        this.carrierAppointments = null;
        this.pobAppointments = null;

        this.producerCECourses = null;
        this.producerCEReqs = null;
    }

    populateDataFromNipr(resData: IClientItemDTO<Producer>): string {
        if (resData) {
            if (resData.status == 0 && resData.item) {
                this.producerResultFromNipr = true;
                this.producer = new Producer(resData.item);
                this.licenses = resData.item.licenses.map(x => new License(x));
                this.setId(resData.item.id);
            }
            else {
                return resData.message;
            }
        }
        else {
            return "NIPR API returned an error";
        }
    }

    private allSearchResults: Subject<Producer[]> = new Subject<Producer[]>();

    get searchResults$() {
        return this.allSearchResults.asObservable();
    }

    updateSearchResults(data: Producer[]) {
        this.allSearchResults.next(data);
    }

    //getLicensesFromNiprResult(input: INiprLicenseDto[]): License[] {
    //    let len = input.length;
    //    let res: License[] = []
    //    for (let i = 0; i < len; i++) {
    //        res.push(new License.fromNiprResponse(input[i]));
    //    }

    //    return res;
    //}

    getProducer(id: number): Observable<IClientItemDTO<Producer>> {
        let relativeUrl = 'pob/Producer/' + id;

        return this.get<IClientItemDTO<Producer>>(relativeUrl);
    }

    //Get total appointments count
    getAppointmentCounts(input: IPdbSearchByNpn): Observable<number> {
        return this.post<number>('pdb/AppointmentCounts', input);
    }

    // Get appointments data
    getAppointments(input: IPdbSearchByNpn): Observable<IClientListDTO<IPdbAppointment>> {
        return this.post<IClientListDTO<IPdbAppointment>>('pdb/Appointments', input);
    }

    // Get appointments data with signle npn
    getAppointmentsByProdId(input: number, carrierIds: number[]): Observable<IClientListDTO<IPdbAppointment>> {
        let relativeUrl = 'pdb/AppointmentByProducerId/' + input;
        let localApptsUrl = 'pob/GetLocalAppointments';
        return forkJoin(this.post<IClientListDTO<IPdbAppointment>>(relativeUrl, carrierIds), this.post<IClientListDTO<IPdbAppointment>>(localApptsUrl, { "producerId": input }))
            .pipe(
                map(res => {
                    res[0].dataList?.forEach(a => a.isLocal = false);
                    res[1].dataList?.forEach(a => a.isLocal = true);
                    //join the lists together if the main list is ok, filtering out IsInNipr from locals, as they're overridden by nipr appt
                    if (res[0].status === 0) {
                        if (res[1].status === 0) {
                            res[0].dataList = res[0].dataList.concat(res[1].dataList);
                        }
                        return res[0];
                    }//else return the local list if it's ok
                    else if (res[1].status === 0) {
                        //res[1].dataList = res[1].dataList;
                        return res[1];
                    }//else return the main list error
                    return res[0];
                }));
    }

    // Get Licenses data by a single npn
    getLicensesByNpn(input: number): Observable<IClientListDTO<License>> {
        let relativeUrl = 'pob/Licenses/' + input;
        return this.get<IClientListDTO<License>>(relativeUrl);
    }

    // Get Licenses data
    getLicenses(input: IPdbSearchLicense): Observable<IClientListDTO<License>> {
        return this.post<IClientListDTO<License>>('pob/Licenses', input);
    }

    // Get Regulatory data
    getRegulatory(input: number): Observable<IClientListDTO<IPdbRegulatory>> {
        let relativeUrl = 'pdb/Regulatory/' + input;
        return this.get<IClientListDTO<IPdbRegulatory>>(relativeUrl);
    }

    getDetailsByNpn(input: number): Observable<IClientItemDTO<IPdbDetails>> {
        let relativeUrl = 'pdb/Details/' + input;
        return this.get<IClientItemDTO<IPdbDetails>>(relativeUrl);
    }

    getContacts(npn: number): Observable<IClientListDTO<PdbContact>> {
        let relativeUrl = 'pdb/contact/' + npn;
        return this.get<IClientListDTO<PdbContact>>(relativeUrl);
    }

    getAddresses(npn: number): Observable<IClientListDTO<PdbAddress>> {
        let relativeUrl = 'pdb/contact/address/' + npn;
        return this.get<IClientListDTO<PdbAddress>>(relativeUrl);
    }

    getProducerMostRecentAddress(prodId: number, addressType: string): Observable<IClientItemDTO<Address>> {
        const relativeUrl = 'POB/GetProducerMostRecentAddress/' + prodId + '/' + addressType;
        return this.get<IClientItemDTO<Address>>(relativeUrl);
    }

    getApplications(prodId: number, bizUnitId: number): Observable<IClientListDTO<ShortApplication>> {
        let relativeUrl = 'pob/Producer/' + prodId + '/Applications';
        if (bizUnitId) {
            relativeUrl += '?pBizUnitId=' + bizUnitId;
        }
        return this.get<IClientListDTO<ShortApplication>>(relativeUrl);
    }

    submitPobAppointmentInBulk(input: InputForOneRegedCreateOrder[]): Observable<IClientListDTO<string>> {
        let relativeUrl = 'reged/CreateOrderInBulk';

        return this.post<IClientListDTO<string>>(relativeUrl, input);
    }

    AddOrUpdateCarrierProducerAppointment(appts: PobAppointment[]): Observable<IClientItemDTO<boolean>> {
        const relativeUrl = 'POB/AddOrUpdateCarrierProducerAppointment';

        return this.post<IClientItemDTO<boolean>>(relativeUrl, appts);
    }

    // IN PROGRESS / RegEd appointments
    getPobAppointments(prodId: number, carriers?: Carrier[]): Observable<IClientListDTO<PobAppointment>> {
        let relativeUrl = 'POB/SearchCarrierProducerAppointment';

        let input = { "producerId": prodId, "carriers": null, "bIncludeRegedCreateOrderLog": true };
        if (carriers) {
            input.carriers = carriers.map(c => c.id);
        }

        return this.post<IClientListDTO<PobAppointment>>(relativeUrl, input);
    }

    getAppointmentStatus(appt: PobAppointment): Observable<IClientItemDTO<RegedOrderStatusShort>> {
        const relativeUrl = 'RegEd/OrderStatus';

        return this.post<IClientItemDTO<RegedOrderStatusShort>>(relativeUrl, appt, false);
    }

    AddOrUpdateLocalPobAppointment(appt: LocalPobAppointmentCreateInput, bizUnitId: number): Observable<IClientItemDTO<IPdbAppointment>> {
        let relativeUrl = 'POB/AddOrUpdateLocalPdbAppointment';
        if (bizUnitId) {
            relativeUrl += '?pBizUnitId=' + bizUnitId;
        }
        return this.post<IClientItemDTO<IPdbAppointment>>(relativeUrl, appt);
    }

    getBigPackages(bizUnitId: number): Observable<IClientListDTO<string>> {
        let relativeUrl = 'pob/BIG/PackageNames/' + bizUnitId;

        return this.get<IClientListDTO<string>>(relativeUrl);
    }

    getBigBackgroundLogs(bizUnitId: number, producerId: number): Observable<IClientListDTO<BigOrderLog>> {
        let relativeUrl = 'pob/BIG/OrderLogs/' + bizUnitId + '/';

        if (producerId) {
            relativeUrl += producerId;
        }

        return this.get<IClientListDTO<BigOrderLog>>(relativeUrl);
    }

    getBigBackgroundLogsByBizUnit(bizUnitId: number): Observable<IClientListDTO<BigOrderLog>> {
        let relativeUrl = 'pob/BIG/OrderLogs/' + bizUnitId;

        return this.get<IClientListDTO<BigOrderLog>>(relativeUrl);
    }

    submitBigBackgroundOrder(bizUnitId: number, producerId: number, userEmail: string, packages: string[]): Observable<IClientItemDTO<BigOrderLog>> {
        let relativeUrl = 'BIG/SubmitOrder';

        let input = {
            'producerId': producerId,
            'bizUnitId': bizUnitId,
            'requesterEmail': userEmail,
            'packageName': packages
        }

        return this.post<IClientItemDTO<BigOrderLog>>(relativeUrl, input);
    }

    updateBigOrderIPAStatus(orderId: number, status: string): Observable<IClientItemDTO<BigOrderLog>> {
        const relativeUrl = 'POB/BIG/OrderLogs/' + orderId + '/' + status;

        return this.post<IClientItemDTO<BigOrderLog>>(relativeUrl, null);
    }

    addBuProducerAssociation(buId: number, prodId: number): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/BizUnit/' + buId + '/AddBizUnitProducer';

        return this.post<IClientItemDTO<number>>(relativeUrl, [prodId]);
    }

    removeBuProducerAssociation(buId: number, prodId: number): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/DeleteBizUnitProducer';

        const input = {
            bizUnitId: buId,
            producerId: prodId
        }

        return this.delete<IClientItemDTO<number>>(relativeUrl, input);
    }

    getProducerHierarchy(id?: number): Observable<IClientItemDTO<Producer>> {
        if (!id) {
            id = this.producer.id;
        }
        let relativeUrl = 'pob/Producer/Hierarchy/' + id;
        return this.get<IClientItemDTO<Producer>>(relativeUrl);
    }

    addProducerRelationship(input: ProducerRelationship): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'pob/AddProducerRelation';
        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }

    removeProducerRelationship(input: ProducerRelationship): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'pob/RemoveProducerRelation';
        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }

    updateBizUnitProducerExternalId(buProd: BizUnitProducer): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'pob/Producer/ExternalId';
        const input = {
            bizUnitId: buProd.bizUnitId,
            producerId: buProd.producerId,
            externalProducerId: buProd.externalProducerId
        }
        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }

    createNewProducer(producer: Producer): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Producer/New';
        let input = new Producer(producer);
        input.contact = null;
        input.parentProducerList = null;
        return this.post<IClientItemDTO<number>>(relativeUrl, input);
    }

    updateContact(contact: Contact, producerId: number): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Producer/' + producerId + '/Contact';
        return this.post<IClientItemDTO<number>>(relativeUrl, contact);
    }

    updateProducer(prodDto: Producer): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'POB/Producer/Update';

        const prodCopy = Producer.shortProducer(prodDto);
        prodCopy.contact = null;

        return this.post<IClientItemDTO<boolean>>(relativeUrl, prodCopy);
    }

    updateProducerCompany(dto: ProducerCompanyLog): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Producer/UpdateCompany';
        return this.post<IClientItemDTO<number>>(relativeUrl, dto);
    }

    addComment(comment: ProducerComment): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Producer/Comment';

        comment.producerId = this.producer.id;
        comment.editTime = new Date();

        return this.post<IClientItemDTO<number>>(relativeUrl, comment);
    }

    loadProducerCECourses(): Observable<IClientListDTO<CECourse>> {
        const relativeUrl = 'POB/Producer/CECourses/' + this.producer.id;
        return this.get<IClientListDTO<CECourse>>(relativeUrl);
    }

    updateProducerCECourse(course: CECourse): Observable<IClientItemDTO<CECourse>> {
        const relativeUrl = 'POB/Producer/AddOrUpdateCECourse';
        return this.post<IClientItemDTO<CECourse>>(relativeUrl, course);
    }

    deleteProducerCECourse(courseId: number): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/Producer/DeleteCECourse/' + courseId;
        return this.delete<IClientItemDTO<number>>(relativeUrl);
    }

    loadProducerCERequirements(): Observable<IClientListDTO<CERequirement>> {
        const relativeUrl = 'POB/Producer/CERequirements/' + this.producer.id;
        return this.get<IClientListDTO<CERequirement>>(relativeUrl);
    }

    updateProducerCERequirement(req: CERequirement): Observable<IClientItemDTO<CERequirement>> {
        const relativeUrl = 'POB/Producer/AddOrUpdateCERequirement';
        return this.post<IClientItemDTO<CERequirement>>(relativeUrl, req);
    }

    deleteProducerCERequirement(courseId: number): Observable<IClientItemDTO<number>> {
        const relativeUrl = 'POB/Producer/DeleteCERequirement/' + courseId;
        return this.delete<IClientItemDTO<number>>(relativeUrl);
    }
}
