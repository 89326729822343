import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeinMaskPipe } from '../../form/fein-mask.pipe';


@NgModule({
    declarations: [
        FeinMaskPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FeinMaskPipe
    ]
})
export class FeinMaskModule { }
