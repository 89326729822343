import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FileService } from '../../../services/file.service';
import { UserService } from '../../../services/user.service';
import { ProducerDocument, ProducerDocumentUpload } from '../../../shared/models/document';
import { ReadFile } from '../../../shared/utils/file';
import { ProducerDataService } from '../../producer-data.service';

@Component({
    selector: 'pv-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

    uploading = false;
    uploadingBizUnit = false;
    uploadMsg = "";
    documents: ProducerDocument[];
    bizUnitDocuments: ProducerDocument[];
    isBUOwner = false;
    activeUser: string;

    constructor(private producerService: ProducerDataService, private userService: UserService, private fileService: FileService) { }

    ngOnInit(): void {
        console.log('docs');
        this.updateDocumentLists();
        this.isBUOwner = this.userService.activeRoles.owner;
        this.activeUser = this.userService.getUserEmail;
    }

    // At the drag drop area
    // (drop)="onDropFile($event)"
    onDropFile(event: DragEvent, bizUnitSpecific = false) {
        event.preventDefault();
        if (bizUnitSpecific) {
            this.uploadingBizUnit = true;
        }
        else {
            this.uploading = true;
        }
        const id = 'drop-target' + (bizUnitSpecific ? '-bizunit' : '');
        document.getElementById(id).style.backgroundColor = "#ffe062";
        this.uploadFile(event.dataTransfer.files, bizUnitSpecific);
    }

    // At the drag drop area
    // (dragover)="onDragOverFile($event)"
    onDragOverFile(event, bizUnitSpecific = false) {
        event.stopPropagation();
        event.preventDefault();
        const id = 'drop-target' + (bizUnitSpecific ? '-bizunit' : '');
        document.getElementById(id).style.backgroundColor = "#bce2f7";
    }

    onDragOut(event, bizUnitSpecific = false) {
        event.stopPropagation();
        event.preventDefault();
        const id = 'drop-target' + (bizUnitSpecific ? '-bizunit' : '');
        document.getElementById(id).style.backgroundColor = "#DDD";
    }

    browseFile(event: any, bizUnitSpecific = false) {
        this.uploadFile(event.target.files, bizUnitSpecific);
    }

    uploadFile(files: FileList, bizUnitSpecific: boolean) {
        if (files.length === 0) {
            console.log("No file selected!");
            return
        }
        if (!files[0].name.endsWith(".pdf")) {
            alert("Only PDF files can be uploaded!");
            const id = 'drop-target' + (bizUnitSpecific ? '-bizunit' : '');
            document.getElementById(id).style.backgroundColor = "#DDD";
            this.uploading = false;
            this.uploadingBizUnit = false;
            return;
        }

        this.uploadMsg = "";
        if (bizUnitSpecific) {
            this.uploadingBizUnit = true;
        }
        else {
            this.uploading = true;
        }

        let upload: ProducerDocumentUpload;

        ReadFile(files[0]).then(data => {
            const name = files[0].name;
            const check = this.producerService.producer.documents.find(x => x.name === name);
            if (check) {
                if (this.isBUOwner || check.editor === this.activeUser) {
                    if (confirm('There is already a file with this name attached to this Producer, are you sure you want to overwrite it?')) {
                        check.editor = this.activeUser;
                        check.editTime = new Date();
                        upload = new ProducerDocumentUpload({ document: check, data: data });

                        this.doFileUpload(upload, bizUnitSpecific);
                    }
                }
                else {
                    alert("Can't overwrite existing file " + name + "!\r\nPlease rename the file and try again!");
                }
            }
            else {
                const doc = new ProducerDocument();
                doc.name = name;
                doc.editor = this.activeUser;
                doc.editTime = new Date();
                doc.producerId = this.producerService.producer.id;
                if (bizUnitSpecific) {
                    doc.bizUnitId = this.userService.activeBusinessUnit.id;
                }

                upload = new ProducerDocumentUpload({ document: doc, data: data });
                this.doFileUpload(upload, bizUnitSpecific);
            }
        });
    }

    doFileUpload(docUpload: ProducerDocumentUpload, bizUnitSpecific: boolean) {
        this.uploadMsg = "Uploading file...";
        const id = 'drop-target' + (bizUnitSpecific ? '-bizunit' : '');
        this.fileService.uploadProducerFile(docUpload, this.producerService.producer.id)
            .subscribe(
                res => {
                    if (res.status === 0) {
                        
                        const drop = document.getElementById(id);
                        drop.style.backgroundColor = "#95CC95"
                        for (let i = 0; i < res.dataList.length; i++) {
                            const doc = docUpload.document;
                            doc.id = res.dataList[0].id;
                            this.producerService.producer.documents.push(doc);
                            this.updateDocumentLists();
                            this.uploadMsg = doc.name + " Uploaded!";
                        }

                        setTimeout(() => {
                            this.uploadMsg = "";
                            this.uploading = false;
                            this.uploadingBizUnit = false;
                            drop.style.backgroundColor = "#DDD";
                        }, 5000);
                    }
                    else {
                        this.uploadMsg = res.message;
                    }
                },
                err => {
                    console.log("Upload Error:", err);
                    document.getElementById(id).style.backgroundColor = "#ff6464";
                    this.uploadMsg = err.error;
                    this.uploading = false;
                    this.uploadingBizUnit = false;
                }
            );
    }

    removeProducerDocument(doc: ProducerDocument) {
        if (!confirm("Are you sure you want to DELETE this document? This CANNOT be undone!")) {
            return;
        }

        this.fileService.deleteProducerDocument(doc, this.producerService.producer.id).subscribe(resData => {
            if (resData && resData.status === 0) {
                const index = this.producerService.producer.documents.indexOf(doc);
                this.producerService.producer.documents.splice(index, 1);
                this.updateDocumentLists();
            }
        });
    }

    getProducerDocumentLink(doc: ProducerDocument) {
        if (doc.uri) {
            window.open(doc.uri, '_blank');
        }
        else {
            this.fileService.getProducerDocumentLink(this.producerService.producer.id, doc.name).subscribe(resData => {
                if (resData && resData.status === 0 && resData.dataList[0] !== "about:blank") {
                    doc.uri = resData.dataList[0];
                    window.open(doc.uri, '_blank');
                }
                else {
                    console.log(resData.message);
                }
            });
        }
    }

    updateDocumentLists() {
        this.documents = this.producerService.producer.documents.filter(x => !x.bizUnitId);
        this.bizUnitDocuments = this.producerService.producer.documents.filter(x => x.bizUnitId === this.userService.activeBusinessUnit.id);
    }
}
