<span class="alert" *ngIf="searchService.errorMessage">{{searchService.errorMessage}}</span>
<form (submit)="searchByLicense()">
  <div>
      <mat-form-field class="full-width">
          <mat-label>License Number</mat-label>
          <input name="license" matInput type="text" [(ngModel)]="searchByLicenseInput.licenseNumber" (ngModelChange)="uppercaseInput(searchByLicenseInput.licenseNumber)" />
          <mat-hint align="end">Required</mat-hint>
      </mat-form-field>
  </div>
  <div>
    <pv-state-select [(state)]="searchByLicenseInput.state" [requiredNipr]="true" class="half-width first-half-option inline top"></pv-state-select>
  </div>
  <div class="faux-mat-form-field">
    <mat-label>License Type:</mat-label>
    <mat-radio-group name="searchByLicenseLicenseType" [(ngModel)]="searchByLicenseInput.licenseType">
      <mat-radio-button value="Agent">Agent</mat-radio-button>
      <mat-radio-button value="Agency">Agency</mat-radio-button>
    </mat-radio-group>
  </div>
  <button type="submit" class="button" id="search-producers" [disabled]="!userService.userRolesLoaded" [ngClass]="{'disabled': !userService.userRolesLoaded}" title="{{userService.userRolesLoaded ? '' : 'Loading User Roles...'}}">Search</button>
</form>

<div *ngIf="npnPassThrough">
    <div class="pv-modal">
        <div class="pv-modal-body">
            <div style="width: 100%; display: inline-block">
                <a class="button warning-btn float-right" (click)="close()" style="width: 100px; margin-top: 0; font-size: 1em;">Close</a>
            </div>
            <h3>
                NPN Found: {{npnPassThrough}}
            </h3>
            <h3 style="font-size: 1.0em;">A full match on a producer was not found with the search criteria, but a possible NPN match was returned.</h3>
            <a class="button" (click)="seedProducer()" style="font-size: 1em;">Add Producer</a>
        </div>
    </div>
    <div class="pv-modal-background"></div>
</div>
