<div *ngIf="producerCopy && userService.userRolesLoaded && (userService.activeRoles) as securityRole">

    <button type="button" class="button" [routerLink]="['/Producer/' + producerCopy.id]">Back to Producer Profile</button>

    <h2 class="center" style="margin-top: -25px;">Producer PDB Report</h2>
    <hr />
    <p>
        <label>Name:</label><span class="uppercase" style="font-size: 1.4em;">{{producerCopy.fullName}}</span>
    </p>
    <div>
        <div class="half-width">
            <p>
                <label>FEIN:</label><span>{{producerCopy.fein}}</span>
            </p>
            <p>
                <label>NPN:</label><span>{{producerCopy.npn}}</span>
            </p>
            <p>
                <label>Report Type:</label><span>All Current Information</span>
            </p>
            <p>
                <label>Report Date:</label><span>{{currentDate | date:'MM/dd/yyyy'}}</span>
            </p>
        </div>
        <div class="half-width">
            <label>&nbsp;</label><span class="underline">Last Updated</span>
            <p>
                <label>Demographics:</label><span>{{producerCopy.contact.editTime | date:'MM/dd/yyyy'}}</span>
            </p>
            <p>
                <label>Producer Licensing:</label><span>{{licenseDate | date:'MM/dd/yyyy'}}</span>
            </p>
            <p>
                <label>Appointments:</label><span>{{appointmentDate | date:'MM/dd/yyyy'}}</span>
            </p>
            <p *ngIf="regulatory">
                <label><a href="#regulatory" [routerLink]="" [fragment]="'regulatory'">RIRS:</a></label><span>{{regulatoryDate | date:'MM/dd/yyyy'}}</span>
            </p>
        </div>
    </div>
    <div>
        <p>
            <label class="wide-label">Active Resident State: </label><span class="state-link">{{producerCopy.stateCode}}</span>
        </p>
        <p>
            <label class="wide-label">Resident Licensed State: </label><span class="long-line"><a *ngFor="let state of residentLicenseStates" href="javascript:void(0);" [routerLink]="['./']" [fragment]="'lic-state-'+state" class="state-link">{{state}}</a></span>
        </p>
        <p>
            <label class="wide-label">Non-Resident Licensed State: </label><span class="long-line"><a *ngFor="let state of nonResLicStates" href="javascript:void(0);" [routerLink]="['./']" [fragment]="'lic-state-'+state" class="state-link">{{state}}</a></span>
        </p>
    </div>
    <br />
    <br />
    <div *ngFor="let state of activeLicenseStates" id="lic-state-{{state}}" class="license">
        <div *ngIf="stateArray[state].licenses && stateArray[state].licenses[0] as lic">
            <hr />
            <h2 class="center page-break" *ngIf="lic.residencyStatus === 'R'">Resident Licensed State(s)</h2>
            <h2 class="center page-break" *ngIf="lic.residencyStatus !== 'R'">Non-Resident Licensed State(s)</h2>
            <div class="third-width">
                <h3>Summary For State: {{lic.stateCode}}</h3>
            </div>
            <div class="third-width">
                <label class="small-label">NPN: </label><span>{{producerCopy.npn}}</span>
            </div>
            <div class="third-width">
                <label class="small-label">Date: </label><span>{{lic.effectiveDateTime | date:'MM/dd/yyyy'}}</span>
            </div>
            <h3 class="uppercase" style="margin: 20px 0;">{{producerCopy.fullName}}</h3>
            <div class="third-width">
                <h4>Demographics</h4>
            </div>
            <div class="third-width">
                <label class="small-label">NPN: </label><span>{{producerCopy.npn}}</span>
            </div>
            <div class="third-width">
                <label class="small-label">Date: </label><span>{{lic.effectiveDateTime | date:'MM/dd/yyyy'}}</span>
            </div>
            <h3 style="margin: 20px 0;">State: {{lic.stateCode}}</h3>
            <p *ngIf="producerCopy.type === 'Agent'">
                <label class="small-label">DOB: </label><span>{{producerCopy.dateOfBirth | date:'MM/dd/yyyy'}}</span>
            </p>
            <p *ngIf="producerCopy.type === 'Agency'">
                <span *ngIf="stateArray[state].contact">
                    <label class="small-label">State Co Id: </label><span>{{stateArray[state].contact.stateCoId}}</span>
                </span>
            </p>
            <p>
                <label class="small-label">Name: </label><span class="uppercase">{{producerCopy.fullName}}</span>
            </p>

            <div *ngIf="stateArray[state].bizAddress as biz">
                <p><span class="contact-updated underline bold">Date Updated</span><span class="underline bold">Business Address</span></p>
                <p><span class="contact-updated">{{biz.editTime | date:'MM/dd/yyyy'}}</span><span>{{biz.oneLineAddress}}</span></p>
            </div>
            <ng-container *ngIf="stateArray[state].contact as contact">
                <div *ngIf="contact.email">
                    <p><span class="contact-updated underline bold">Date Updated</span><span class="underline bold">Business Email</span></p>
                    <p><span class="contact-updated">{{contact.emailUpdateDate | date:'MM/dd/yyyy'}}</span><span>{{contact.email}}</span></p>
                </div>
                <div *ngIf="contact.phone">
                    <p><span class="contact-updated underline bold">Date Updated</span><span class="underline bold">Business Phone</span></p>
                    <p><span class="contact-updated">{{contact.phoneUpdateDate | date:'MM/dd/yyyy'}}</span><span>{{contact.phone}}</span></p>
                </div>
                <div *ngIf="contact.fax">
                    <p><span class="contact-updated underline bold">Date Updated</span><span class="underline bold">Fax</span></p>
                    <p><span class="contact-updated">{{contact.faxUpdateDate | date:'MM/dd/yyyy'}}</span><span>{{contact.fax}}</span></p>
                </div>
            </ng-container>
            <div *ngIf="stateArray[state].mailAddress as mail">
                <p><span class="contact-updated underline bold">Date Updated</span><span class="underline bold">Mailing Address</span></p>
                <p><span class="contact-updated">{{mail.editTime | date:'MM/dd/yyyy'}}</span><span>{{mail.oneLineAddress}}</span></p>
            </div>
            <div *ngIf="stateArray[state].resAddress as res">
                <p><span class="contact-updated underline bold">Date Updated</span><span class="underline bold">Residence Address</span></p>
                <p><span class="contact-updated">{{res.editTime | date:'MM/dd/yyyy'}}</span><span>{{res.oneLineAddress}}</span></p>
            </div>

            <!-- Contact info here -->
            <!--<div *ngIf="stateArray[state].contacts && stateArray[state].contacts as conts">
        <div *ngFor="let contact of conts">
            <div *ngIf="contact.businessAddress">
                <p><span class="contact-updated underline">Date Updated</span><span class="underline">Business Address</span></p>
                <p><span class="contact-updated">{{contact.businessAddressUpdated}} </span><span>{{contact.businessAddress}}</span></p>
            </div>
            <div *ngIf="contact.businessEmail">
                <p><span class="contact-updated underline">Date Updated</span><span class="underline">Business Email</span></p>
                <p><span class="contact-updated">{{contact.businessEmailUpdated}} </span><span>{{contact.businessEmail}}</span></p>
            </div>
            <div *ngIf="contact.businessPhone">
                <p><span class="contact-updated underline">Date Updated</span><span class="underline">Business Phone</span></p>
                <p><span class="contact-updated">{{contact.businessPhoneUpdated}} </span><span>{{contact.businessPhone}}</span></p>
            </div>
            <div *ngIf="contact.fax">
                <p><span class="contact-updated underline">Date Updated</span><span class="underline">Fax</span></p>
                <p><span class="contact-updated">{{contact.faxUpdated}} </span><span>{{contact.fax}}</span></p>
            </div>
            <div *ngIf="contact.mailingAddress">
                <p><span class="contact-updated underline">Date Updated</span><span class="underline">Mailing Address</span></p>
                <p><span class="contact-updated">{{contact.mailingAddressUpdated}} </span><span>{{contact.mailingAddress}}</span></p>
            </div>
            <div *ngIf="contact.resAddress">
                <p><span class="contact-updated underline">Date Updated</span><span class="underline">Residence Address</span></p>
                <p><span class="contact-updated">{{contact.resAddressUpdated}} </span><span>{{contact.resAddress}}</span></p>
            </div>
        </div>
    </div>-->
            <!-- Contact info here -->
            <div style="margin: 25px 0 8px;">
                <div class="fourth-width">
                    <h3>License Summary</h3>
                </div>
                <div class="fourth-width">
                    <h3 class="uppercase">{{producerCopy.fullName}}</h3>
                </div>
                <div class="fourth-width">
                    <label>NPN: </label><span>{{producerCopy.npn}}</span>
                </div>
                <div class="fourth-width">
                    <label>Date: </label><span>{{currentDate | date:'MM/dd/yyyy'}}</span>
                </div>
            </div>
        </div>
        <h3>State {{state}}</h3>
        <div *ngFor="let lic of stateArray[state].licenses">
            <div style="margin: 15px 0;">
                <div class="fourth-width">
                    <label>License #: </label><span>{{lic.licenseNumber}}</span>
                </div>
                <div class="fourth-width">
                    <label>Issue Date: </label><span>{{lic.effectiveDateTime | date:'MM/dd/yyyy'}}</span>
                </div>
                <div class="fourth-width">
                    <label>Expiration Date: </label><span>{{lic.expirationDateTime | date:'MM/dd/yyyy'}}</span>
                </div>
                <div class="fourth-width">
                    <label>Last Updated: </label><span>{{lic.editTime | date:'MM/dd/yyyy'}}</span>
                </div>
            </div>
            <div>
                <div class="fourth-width">
                    <label style="width: unset">Class: </label> <span> {{lic.class}}</span>
                </div>
                <div class="fourth-width">
                    <label>Residency: </label><span>{{lic.residencyStatus}}</span>
                </div>
                <div class="fourth-width">
                    <label>Active: </label><span>{{lic.active ? 'Yes' : 'No'}}</span>
                </div>
                <div class="fourth-width">
                    <span>* Indicates current LOA status</span>
                </div>
            </div>
            <br />
            <div style="margin-top: 30px;">
                <label>Designated Home State: </label><span>None</span>
            </div>
            <div style="margin: 15px 0;">
                <div class="third-width">
                    <label>CE Compliance: </label>
                </div>
                <div class="third-width">
                    <label>CE Credits Needed: </label>
                </div>
                <div class="third-width">
                    <label>CE Renewal Date: </label>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Line Of Authority</th>
                        <th>Authority<br />Issue Date</th>
                        <th>Status</th>
                        <th>Status Reason</th>
                        <th>Status Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of lic.details">
                        <td class="uppercase">{{detail.description}}</td>
                        <td>{{detail.issueDate | date: 'MM/dd/yyyy'}}</td>
                        <td>{{detail.status}}</td>
                        <td>{{detail.statusReason}}</td>
                        <td>{{detail.statusReasonDate | date: 'MM/dd/yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="margin-top: 30px;">
            <div class="fourth-width">
                <h3>Appointments</h3>
            </div>
            <div class="fourth-width">
                <h3 class="uppercase">{{producerCopy.fullName}}</h3>
            </div>
            <div class="fourth-width">
                <label>NPN: </label><span>{{producerCopy.npn}}</span>
            </div>
            <div class="fourth-width">
                <label>Date: </label><span>{{currentDate | date:'MM/dd/yyyy'}}</span>
            </div>
        </div>
        <h3>State {{state}}</h3>
        <div *ngIf="stateArray[state]">
            <div *ngIf="stateArray[state].appointmentKeys.length > 0">
                <div *ngFor="let key of stateArray[state].appointmentKeys; index as i;">
                    <hr *ngIf="i !== 0" />

                    <div *ngIf="stateArray[state].appointments[key].companyAppts as coAppts">
                        <div class="flex" style="margin: 20px 30px 0 30px;">
                            <div>
                                <label class="tiny-label">Company Name: </label><div class="appt-co-name">{{coAppts[0].companyName}}</div>
                            </div>
                            <div>
                                <label class="tiny-label">FEIN: </label><span>{{coAppts[0].fein}}</span>
                            </div>
                            <div>
                                <label class="tiny-label">Cocode: </label><span>{{coAppts[0].companyCode}}</span>
                            </div>
                            <div>
                                <label class="tiny-label">Last Updated: </label><span>{{coAppts[0].asOfDateTime | date:'MM/dd/yyyy'}}</span>
                            </div>
                        </div>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width: 30%;">Line Of Authority</th>
                                        <th style="width: 20%;">Status</th>
                                        <th style="width: 20%;">Termination Reason</th>
                                        <th style="width: 20%;">Current Appt/Term<br />Effective Date</th>
                                        <th style="width: 10%;">Renewal Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let appt of coAppts">
                                        <td>{{appt.loaDescription}}</td>
                                        <td>{{appt.status}}</td>
                                        <td>{{appt.terminationReason}}</td>
                                        <td>{{appt.effectiveDateTime | date:'MM/dd/yyyy'}}</td>
                                        <td>{{appt.renewDateTime | date:'MM/dd/yyyy'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="stateArray[state].appointmentKeys.length === 0">
                No Information Available
            </div>
        </div>
    </div>
    <!-- END OF LICENSES -->
    <!-- Regulatory -->
    <div *ngIf="regulatory">
        <hr />
        <br />
        <div id="regulatory" class="page-break">
            <br />
            <hr />
            <div class="third-width">
                <h3>Regulatory Actions</h3>
            </div>
            <div class="third-width">
                <label>NPN:</label><span>{{producerCopy.npn}}</span>
            </div>
            <div class="third-width">
                <label>Date:</label><span>{{currentDate | date:'MM/dd/yyyy'}}</span>
            </div>
        </div>
        <h3 class="uppercase" style="margin: 20px 0 10px">{{producerCopy.fullName}}</h3>
        <div *ngFor="let reg of regulatory">
            <h3 style="margin-top: 30px;">State of Action: {{reg.state}}</h3>
            <div class="reg-section">
                <div class="third-width">
                    <label>Action Id:</label><span>{{reg.actionId}}</span>
                </div>
                <div class="third-width">
                    <label>Entity Role:</label><span></span>
                </div>
                <div class="third-width">
                    <label>File Ref:</label><span>{{reg.fileRef}}</span>
                </div>
            </div>
            <div class="reg-section">
                <div class="third-width">
                    <label>Date of Action:</label><span>{{reg.dateOfAction | date:'MM/dd/yyyy'}}</span>
                </div>
                <div class="third-width">
                    <label>Enter Date:</label><span>{{reg.entryDate | date:'MM/dd/yyyy'}}</span>
                </div>
                <div class="third-width">
                    <label>Effective Date:</label><span>{{reg.effectiveDate | date:'MM/dd/yyyy'}}</span>
                </div>
            </div>
            <div class="reg-section">
                <div class="third-width">
                    <label>Penalty/Fine/<br />Forfeiture:</label><span>${{reg.penalty | number}}</span>
                </div>
                <div class="third-width">
                    <label>Restitution<br />Amount:</label><span></span>
                </div>
                <div class="third-width">
                    <label>Time/Length of<br />Order (Days):</label><span>{{reg.lengthOfOrder}}</span>
                </div>
            </div>
            <p>
                <label>State RIRS Identifier: </label><span></span>
            </p>
            <p>
                <label>Last Updated: </label><span>{{reg.asOfDate | date:'MM/dd/yyyy'}}</span>
            </p>
            <div style="margin-left: 30px;">
                <p>
                    <label>Origin of Action: </label><span>{{reg.origin}}</span>
                </p>
                <p>
                    <label>Reason for Action: </label><span>{{reg.reason}}</span>
                </p>
                <p>
                    <label>Disposition: </label><span>{{reg.disposition}}</span>
                </p>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="!(producerCopy && userService.userRolesLoaded)">
    <div class="loading-spinner"></div>
</ng-container>
