
<p *ngIf="!(userService.activeRoles.onboard || userService.isApplicationManager)">You need Onboarding permission to view an application. Contact <a href="mailto:{{userService.activeBusinessUnit.email}}">{{userService.activeBusinessUnit.admin}}</a> for assistance</p>

<div id="application" *ngIf="loaded">

    <h2>Information and Contact</h2>
    <div class="column-container">
        <div class="small-column inline top">
            <p>
                <label>Producer Name</label>
                <span class="proper-name"><a [routerLink]="['/Producer/' + producer.id]" title="Producer Profile">{{producer.fullName}}</a></span>
            </p>
            <p *ngIf="producer.type == 'Agency'">
                <label>FEIN</label>
                <span>{{producer.fein}}</span>
            </p>
            <p *ngIf="producer.type == 'Agent'">
                <label>SSN</label>
                <span>{{producer.ssn | ssnMask:true}}</span>
            </p>
            <p>
                <label>E-mail</label>
                <span><a class="external-link" href="mailto:{{producer.contact.email}}">{{producer.contact.email}}</a></span>
            </p>
            <p *ngIf="producer.contact.website">
                <label>Website</label>
                <span>{{producer.contact.website}}</span>
            </p>
        </div>
        <div class="small-column inline top">
            <p>
                <label>NPN</label>
                <span>{{producer.npn}}</span>
            </p>
            <p>
                <label>Resident State</label>
                <span>{{producer.stateCode}}</span>
            </p>
            <p>
                <label>License Number</label>
                <span>{{producer.licenseNumber}}</span>
            </p>
        </div>

        <div class="small-column inline top">
            <p>
                <label>Business Phone</label>
                <span>{{producer.contact.bizPhone}}</span>
            </p>
            <p>
                <label>Cell Phone</label>
                <span>{{producer.contact.cellPhone}}</span>
            </p>
            <p>
                <label>Other Phone</label>
                <span>{{producer.contact.otherPhone}}</span>
            </p>
            <p>
                <label>Preferred Contact</label>
                <span>{{producer.contact.preferredContact}}</span>
            </p>
        </div>
    </div>

    <div class="" style="">
        <div class="address-container" *ngIf="producer.contact.businessAddress.id">
            <label>Business Address</label>
            <span class="address">
                <span class="proper-name">{{producer.contact.businessAddress.oneLineAddress}}, {{producer.contact.businessAddress.city}}</span>, {{producer.contact.businessAddress.state}} {{producer.contact.businessAddress.zip}}
            </span>
        </div>
        <div class="address-container" *ngIf="producer.contact.mailingAddress.id != producer.contact.businessAddress.id">
            <label>Mailing Address</label>
            <span class="address">
                <span class="proper-name">{{producer.contact.mailingAddress.oneLineAddress}}, {{producer.contact.mailingAddress.city}}</span>, {{producer.contact.mailingAddress.state}} {{producer.contact.mailingAddress.zip}}
            </span>
        </div>
        <div class="address-container" *ngIf="producer.contact.residentialAddress.id != producer.contact.mailingAddress.id">
            <label>Residential Address</label>
            <span class="address">
                <span class="proper-name">{{producer.contact.residentialAddress.oneLineAddress}}, {{producer.contact.residentialAddress.city}}</span>, {{producer.contact.residentialAddress.state}} {{producer.contact.residentialAddress.zip}}
            </span>
        </div>
    </div>

    <div id="list-sections">
        <div>
            <h2>Carrier{{(application.carriers.length > 1) ? 's': ''}}</h2>
            <p *ngFor="let carrier of application.carriers">
                <span class="med-gray">{{carrier.name}}</span>
            </p>
        </div>
        <div>
            <h2>Appointments</h2>
            <ng-container *ngIf="application.appointments.length > 0">
                <p *ngFor="let appt of application.appointments">
                    <span>{{appt.carrier.name}}</span> - <span class="resident-info" [ngClass]="(appt.residencyStatus == 'R') ? 'resident-state' : ''">{{appt.stateCode}} - <span class="proper-name" style="display:inline;">{{appt.licenseDisplay}}</span></span>
                </p>
                <ng-container *ngIf="approved">
                    <p style="margin-top: 20px;">To view appointment status, please see "In Progress Appointments" under Manage Appointments or <a [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']">Click here</a></p>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="application.allowToSelectAppointmentStates && application.appointmentStates.length > 0">
                <h4>Producer Selected States</h4>
                <p *ngFor="let appState of application.appointmentStates">
                    <span class="med-gray">{{appState.stateCode}} - <span class="proper-name">{{appState.loaDescription}}</span></span>
                </p>
            </ng-container>

            <ng-container *ngIf="application.appointments.length === 0 && application.appointmentStates.length === 0">
                <p>No Appointments were selected for this Application. To Appoint this Producer, click here:</p>
                <p><a [routerLink]="['/AppointProducer/' + producer.id]">Manage Appointments</a></p>
            </ng-container>
        </div>

        <div *ngIf="producer.type == 'Agency'">
            <h2>Agents</h2>
            <p *ngFor="let agent of producer.childProducerList">
                <span class="proper-name"><a [routerLink]="['/Producer/' + agent.id]" title="Agent Profile - NPN: {{agent.npn}}">{{agent.fullName}}</a></span>
            </p>
            <p *ngIf="!producer.childProducerList">
                <span class="italics">None</span>
            </p>
        </div>
    </div>

    <div>
        <h2>Manage Business Documents</h2>
        <pv-app-documents [documents]="application.documents" [appId]="application.id" [bizUnitId]="application.bizUnit.id"></pv-app-documents>
        <ng-container *ngIf="!locked && canUploadDocuments">
            <ng-container *ngIf="uploadMsg != ''">
                <span>{{uploadMsg}}</span>
            </ng-container>
            <ng-container *ngIf="!uploading">
                <input class="button" type="button" (click)="fileUploadButton.click()" value="Add Document" />
                <input type="file" class="hidden" #fileUploadButton (change)="browseFile($event)" />
            </ng-container>
        </ng-container>
    </div>

    <div>
        <h2>Comments</h2>
        <pv-comments [(logs)]="application.logs" [appId]="application.id" [userEmail]="email"></pv-comments>
    </div>

    <div id="application-review">

        <h2 style="margin-bottom: 25px;">Review Process</h2>
        <ng-container *ngIf="!allowReview">
            <p>Application Status is with Producer, no changes can be made to this application</p>
            <input type="button" class="button" style="margin-top:30px;" (click)="archive()" value="Archive Application" />
        </ng-container>
        <ng-container *ngIf="allowReview">

            <div *ngIf="packageNames">
                <h3>Background Check</h3>
                <div *ngIf="needSsn">
                    <p>Producer is missing SSN required for background check</p>
                    <mat-form-field>
                        <mat-label>SSN</mat-label>
                        <input name="taxId" matInput type="text" (blur)="showMask = true" (focus)="showMask = false" required (ngModelChange)="producer.ssn = $event" [ngModel]="producer.ssn | ssnMask:showMask" />
                    </mat-form-field>
                    <input type="button" class="button" style="font-size: 1em; margin-left: 30px;" value="Update SSN" (click)="updateSsn()" />
                </div>
                <div *ngIf="!needSsn">
                    <div *ngIf="backgroundCheckAllowed">
                        <a class="button inline" (click)="actionBackgroundCheck('NotRequired')">Not Required</a>
                        <a class="button inline" (click)="sendBackgroundCheck()">Order from BIG</a>
                        <span *ngIf="packageNames.length > 1">
                            <span style="margin: 0 0 0 15px;">Select background check package:</span>
                            <span *ngFor="let package of packageNames" style="margin-left: 15px;"><label><mat-checkbox name="package-{{package.item}}" [(ngModel)]="package.selected"></mat-checkbox> {{package.item}} </label></span>
                        </span>
                        <ng-container *ngIf="backgroundCheck && backgroundCheck.isErrored">
                            <p style="margin-top: 10px;">
                                The previous background check resulted in an Error: <br /><span class="alert-color">{{backgroundCheck.errorDescription}}</span>
                            </p>
                            <p>
                                If you continue to have this issue, please contact support.
                            </p>
                        </ng-container>
                    </div>
                    <div *ngIf="!backgroundCheckAllowed">
                        <ng-container *ngIf="backgroundAudit">
                            <div *ngIf="backgroundAudit.details == 'NotRequired'">
                                <p>The background check was marked Not Required on {{backgroundAudit.editTime | date: 'mediumDate'}} by {{backgroundAudit.editor}}</p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="backgroundCheck">
                            <ng-container *ngIf="backgroundCheck.isCleared">
                                <p>The background check was marked as Reviewed on {{backgroundCheck.ipaStatusEditTime | date: 'mediumDate'}}</p>
                            </ng-container>
                            <ng-container *ngIf="!backgroundCheck.isCleared">
                                <div *ngIf="backgroundCheck.inProgress">
                                    <p>The background check status is currently {{backgroundCheck.displayStatus}}. Requested on {{backgroundCheck.createTime | date: 'mediumDate'}} by {{backgroundCheck.requesterEmail}}</p>
                                </div>
                                <div *ngIf="backgroundCheck.needsReview">
                                    <p>The background check has returned as "Needs Review". Requested on {{backgroundCheck.createTime | date: 'mediumDate'}} by {{backgroundCheck.requesterEmail}}</p>
                                </div>
                                <div *ngIf="backgroundCheck.isFailed">
                                    <p>The background check has returned as "Failed". Requested on {{backgroundCheck.createTime | date: 'mediumDate'}} by {{backgroundCheck.requesterEmail}}</p>
                                </div>
                                <div *ngIf="backgroundCheck.needsReview || backgroundCheck.isFailed">
                                    <p><input type="button" class="button" (click)="clearBackgroundCheckReview()" value="Reviewed - OK" /></p>
                                </div>
                                <div *ngIf="backgroundCheck.isPassed">
                                    <p>The background check has returned as "Passed". Requested on {{backgroundCheck.createTime | date: 'mediumDate'}} by {{backgroundCheck.requesterEmail}}</p>
                                </div>
                            </ng-container>
                            <p *ngIf="backgroundCheck.redirectLink"><a href="{{backgroundCheck.redirectLink}}" target="_blank">View in BIG</a></p>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div>
                <h3 style="margin: 50px 0 30px;">Approval</h3>
                <ng-container *ngIf="locked">
                    <p>This application is locked.</p>
                    <ng-container *ngIf="rejected">
                        <p>It was rejected on {{rejected.editTime | date: 'mediumDate'}} by {{rejected.editor}}</p>
                        <a *ngIf="userService.isApplicationManager && !application.isArchived" class="button" (click)="unReject()">Undo Rejection</a>
                    </ng-container>
                    <ng-container *ngIf="approved">
                        <p>It was approved on {{approved.editTime | date: 'mediumDate'}} by {{approved.editor}}</p>
                        <ng-container *ngIf="application.needAppointment">
                            <!--<ng-container *ngIf="sentToState">
                                <p>The application was submitted to the state on {{sentToState.editTime | date: 'mediumDate'}} by {{sentToState.editor}}</p>
                            </ng-container>-->
                            <!--<ng-container *ngIf="!sentToState && !application.isArchived">
                                <p style="margin: 35px 0 20px;">Once the application has been submitted to the state, click below:</p>
                                <a class="button" (click)="updateNeedsAppointment(false)">No Appointment Sent to State / No Appointment Needed</a>
                            </ng-container>-->
                        </ng-container>
                        <!--<ng-container *ngIf="!application.needAppointment && !application.isArchived">
                            <p style="margin: 35px 0 20px;">This application has been approved as not needing appointment, for tracking only.</p>
                        </ng-container>-->
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="!locked">
                    <div *ngIf="secondReview">
                        <p>{{secondReview.editor}} has indicated that this application requires a Second Review</p>
                        <p *ngIf="userService.isApplicationManager">
                            <input type="button" class="button warning-btn" (click)="reject()" value="Reject Application" />
                            <input type="button" class="button {{appRequirementsMet ? 'elevated-btn' : 'disabled'}}" [disabled]="!appRequirementsMet" (click)="approve()" value="Final Approval" title="{{approvalMessage}}" />
                        </p>
                        <p *ngIf="!userService.isApplicationManager">
                            <span>This Application has been marked as needing a Second Review by a Manager by {{secondReview.editor}}</span>
                        </p>
                    </div>

                    <div *ngIf="!secondReview">
                        <p *ngIf="userService.activeRoles.onboard || userService.isApplicationManager">
                            <input type="button" class="button" (click)="needsReview()" value="Needs Second Review" *ngIf="!trackingOnly" />
                            <input type="button" class="button warning-btn" (click)="reject()" value="Reject Application" />
                            <input type="button" class="button {{appRequirementsMet ? 'elevated-btn' : 'disabled'}}" [disabled]="!appRequirementsMet" (click)="approve()" value="Final Approval" title="{{approvalMessage}}" />
                        </p>
                    </div>
                </ng-container>
            </div>

        </ng-container>
    </div>
</div>
