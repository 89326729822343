import { BizUnit } from './general';
import { PreferenceType } from '../enums/general';

export class User {
    id: number;
    //name: string;

    //email
    userIdentifier: string;
    active: boolean;
    //roles: UserRoles;
    preferences: UserPreference[];

    userPreference(type: PreferenceType): UserPreference {
        return this.preferences.find(x => x.preferenceType == type.toString());
    }

    get userCarriersPreference(): UserPreference {
        return this.userPreference(PreferenceType.PreferredCarriers)
    }

    setUserPreference(type: PreferenceType, pref: UserPreference) {
        let existingPref = this.userPreference(type);
        if (existingPref) {
            existingPref.value = pref.value;
        }
        else {
            this.preferences.push(pref);
        }
    }

    constructor(init?: Partial<User>) {
        this.id = init.id;
        //this.name = init.name;
        this.userIdentifier = init.userIdentifier;
        this.active = init.active;
        //this.roles = new UserRoles(init.roles);
        this.preferences = init.preferences ? init.preferences.map(x => new UserPreference(x)) : [];
    }
}

export class BusinessUnitRoles {
    businessUnit: BizUnit;
    userRoles: UserRoles = new UserRoles();

    userIdentifier: string; // e-mail
    id: number;

    isUpdateSuccessful: boolean = false;

    constructor(roles: UserRolesDto, bizUnits: BizUnit[]) {
        this.userRoles.comment = roles.roleComments;
        this.userRoles.documents = roles.roleDocument;
        this.userRoles.onboard = roles.roleOnboarding;
        this.userRoles.owner = roles.roleBizUnitOwner;
        this.userRoles.applicationManager = roles.roleManager;
        this.userRoles.reports = roles.roleReports;
        this.userRoles.seed = roles.roleSeeding;
        this.userRoles.systemAdmin = roles.roleSystemAdmin;

        this.businessUnit = bizUnits.find(x => x.id == roles.bizUnitId);

        this.userIdentifier = roles.userIdentifier;
        this.id = roles.id;

        //this.userRoles.IsCorporate = this.businessUnit.id === 9;
    }
}

export class UserRoles {
    search: boolean = true;
    owner: boolean = false;
    applicationManager: boolean = false;
    comment: boolean = false;
    reports: boolean = false;
    onboard: boolean = false;
    documents: boolean = false;
    seed: boolean = false;
    //IsCorporate: boolean = false;
    systemAdmin: boolean = false;

    constructor(init?: Partial<UserRoles>) {
        Object.assign(this, init);
    }

    public get producerSeedMessage(): string {
        if (this.seed) {
            return "Seed the producer to the Master Database";
        }
        else {
            return "You do not have permission to seed producers to the database";
        }
    }

    public get producerReportMessage(): string {
        if (!this.reports) {
            return "You do not have permission to run reports";
        }
    }
}

export class UserRolesDto {
    bizUnitId: number;
    userIdentifier: string;
    id: number; //user id
    roleSearch: boolean;
    roleComments: boolean;
    roleManager: boolean;
    roleReports: boolean;
    roleOnboarding: boolean;
    roleDocument: boolean;
    roleBizUnitOwner: boolean;
    roleSeeding: boolean;
    roleSystemAdmin: boolean;

    constructor(init?: Partial<UserRolesDto>) {
        if (init) {
            this.bizUnitId = init.bizUnitId;
            this.userIdentifier = init.userIdentifier;
            this.roleSearch = true;
            this.roleComments = init.roleComments;
            this.roleManager = init.roleManager;
            this.roleReports = init.roleReports;
            this.roleOnboarding = init.roleOnboarding;
            this.roleDocument = init.roleDocument;
            this.roleBizUnitOwner = init.roleBizUnitOwner;
            this.roleSeeding = init.roleSeeding;
            this.roleSystemAdmin = init.roleSystemAdmin;
        }
    }

    static fromBizUnitRoles(obj: BusinessUnitRoles) {
        let userRoles = new UserRolesDto();
        userRoles.id = obj.id;
        userRoles.bizUnitId = obj.businessUnit.id;
        userRoles.userIdentifier = obj.userIdentifier;
        userRoles.roleSearch = true;
        userRoles.roleComments = obj.userRoles.comment;
        userRoles.roleManager = obj.userRoles.applicationManager;
        userRoles.roleReports = obj.userRoles.reports;
        userRoles.roleOnboarding = obj.userRoles.onboard;
        userRoles.roleDocument = obj.userRoles.documents;
        userRoles.roleBizUnitOwner = obj.userRoles.owner;
        userRoles.roleSeeding = obj.userRoles.seed;

        return userRoles;
    }
}

export class UserPreference {
    id: number;
    userId: number;
    preferenceType: string;
    value: string;

    constructor(init?: Partial<UserPreference>) {
        this.id = init.id;
        this.userId = init.userId;
        this.preferenceType = init.preferenceType;
        this.value = init.value;
    }
}
