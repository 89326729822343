import { Component, OnDestroy } from '@angular/core';
import { ProducerDataService } from '../../producer-data.service';

@Component({
  selector: 'pv-reg-and-discip-info',
  templateUrl: './reg-and-discip-info.component.html'
})
export class RegAndDiscipInfoComponent implements OnDestroy {

    private npnSubscription;
    private npn;
    loading = false;

    constructor(public producerService: ProducerDataService) {
        if (producerService.producer) {
            if (!producerService.pdbRegulatories) {
                this.getRegulatory(producerService.producer.npn);
                this.npn = producerService.producer.npn;
            }
        }

        this.npnSubscription = producerService.id$.subscribe(newNpn => {
            if (this.npn != newNpn) {
                this.npn = newNpn;
                this.getRegulatory(newNpn);
            }
        });
    }

    ngOnDestroy() {
        if (this.npnSubscription) {
            this.npnSubscription.unsubscribe();
        }
    }

    getRegulatory(npn: number): void {
        if (npn) {
            this.loading = true;
            this.producerService.getRegulatory(npn).subscribe(resData => {
                if (resData && resData.status === 0) {
                    this.producerService.pdbRegulatories = resData.dataList;
                }
                this.loading = false;
            });
        }
    }
}
