<header>
  <div class="container">
      <nav>
          <div id="login">
              <span *ngIf="!userService.isAuthenticated">
                  <a class="button" id="login-button" (click)="userService.login()">Please Login</a>
              </span>
              <span *ngIf="userService.isAuthenticated">
                  <span class="nav-text text-dark" title="{{userService.getUserEmail}}">Hello, {{userService.getUserPerferredName}}</span>
                  <span>
                      <ng-container *ngIf="currentUserSecurityRole$ | async as selectedRole">
                          &#64;
                          <select [ngModel]="selectedRole" (ngModelChange)="businessUnitChanged($event)" name="selectedRole">
                              <option *ngFor="let role of userAllBizUnitRoles$ | async as roles" [ngValue]="role">{{role.businessUnit.name}}</option>
                          </select>
                      </ng-container>
                  </span>
                  <a class="button" (click)="userService.logout()">Sign out</a>
                  <a class="button elevated-btn" (click)="showExternalLinks()">Links</a>
              </span>
          </div>
          <div id="logo-contain">
              <a href="./"><img id="logo" src="./assets/images/logo-tmhcc.svg" alt="Tokio Marine HCC" /></a>
              <h1 id="page-title" title="Intuitive Producer Administration">{{pageTitle}}</h1>
          </div>
      </nav>
  </div>
</header>

<div class="container">
  <nav class="navbar">
      <ul>
          <li><a routerLinkActive="active" [routerLink]="['/Home']">Home</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/ProducerSearch']">Producer Search</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/ProducerOnboarding']">Producer Onboarding</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/Reports']">Reports</a></li>
          <li><a routerLinkActive="active" [routerLink]="['/Settings']">Settings</a></li>
          <ng-container *ngIf="userService.isOwnerOrCorporate">
              <li><a routerLinkActive="active" [routerLink]="['/Admin']">Admin</a></li>
          </ng-container>
      </ul>
  </nav>

  <div *ngIf="userService.isAuthenticated">
    <div id="content">
      <div>
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="!userService.validUser && userService.userRolesLoaded">
        <h2>You do not have permission to access the site.</h2>
      </div>
    </div>
  </div>
  <div *ngIf="!userService.isAuthenticated">
    <h2 style="margin-top: 200px; text-align: center;">Please Login!</h2>
  </div>
</div>

<pv-modal id="loading-overlay" class="no-background">
  <div id="loading-container">
    <div class="loading-spinner"></div>
  </div>
</pv-modal>

<pv-modal id="message-overlay">
  <span [innerHtml]="message"></span>
</pv-modal>

<pv-external-links></pv-external-links>
