import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BizUnit } from '../../shared/models/general';

/* NgRx */
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { getCurrentBizUnitSelector, getErrorSelector, getAllBizUnitCarriersSelector, getCurrentBizUnitCarriersSelector } from '../../user/state';
import { SecurityRolePageActions } from '../../user/state/actions';
import { Carrier } from '../../shared/models/pob/carrier';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'pv-carrier-setup-shell',
  templateUrl: './carrier-setup-shell.component.html',
})
export class CarrierSetupShellComponent implements OnInit {

    //errorMessage$: Observable<string>;
    //bizUnit$: Observable<BizUnit>;
    allCarriers$: Observable<Carrier[]>;
    //currentBizUnitCarriers$: Observable<Carrier[]>;


    constructor(private store: Store<State>, private userService: UserService) { }

    ngOnInit(): void {

        //this.bizUnit$ = this.store.select(getCurrentBizUnitSelector);

        //this.errorMessage$ = this.store.select(getErrorSelector);

        this.allCarriers$ = of(this.userService.allCarriers);
            //this.store.select(getAllBizUnitCarriersSelector);
        //this.currentBizUnitCarriers$ = this.store.select(getCurrentBizUnitCarriersSelector);

    }

    //addCarrier(data: Carrier) {
    //    this.store.dispatch(SecurityRolePageActions.addBizUnitCarrierAction({ carrierToAdd: data }));
    //}

    //deleteCarrier(data: Carrier) {
    //    this.store.dispatch(SecurityRolePageActions.deleteBizUnitCarrierAction({carrierToDelete: data }));
    //}

}
