import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { Application } from '../../shared/models/application';
import { ModalService } from '../../modal/modal.service';
import { BusinessUnitRoles } from '../../shared/models/user';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'pv-producer-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class ProducerDashboardComponent implements OnInit {

    constructor(public onboardService: OnboardingService, private userService: UserService, private modal: ModalService) { }

    ngOnInit() {
        this.loadApplications(this.userService.activeBusinessUnitRoles);
    }

    loadApplications(buRoles: BusinessUnitRoles) {
        let id = buRoles.businessUnit.id;

        if (this.userService.isCorporate) { //Corporate Compliance
            id = null;
        }

        this.modal.loading(true);
        this.onboardService.getOnboardingApplications(id).subscribe({
            next: resData => {
                if (resData && resData.status == 0 && resData.dataList.length > 0) {
                    let apps = resData.dataList.map(x => new Application(x));
                    apps.sort((x, y) => {
                        if (x.displayID > y.displayID) return 1; else return -1;
                    });
                    this.onboardService.updateOnboardingApplications(apps);
                }
                else if (resData && resData.status == -404) {
                    this.onboardService.updateOnboardingApplications([]);
                }
                this.modal.loading(false);

            },
            error: err => {
                this.modal.loading(false);
                console.log(err);
            }
        });

    }
}
