import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IAdminState } from './admin.reducer';


//feature selector
const getAdminFeatureState = createFeatureSelector<IAdminState>('admin');

export const getBizUnitVerbiageForEmailSelector = createSelector(
    getAdminFeatureState,
    state => state.bizUnitVerbiage ? state.bizUnitVerbiage.forEmail : null
);

export const getBizUnitVerbiageForDocumentSelector = createSelector(
    getAdminFeatureState,
    state => state.bizUnitVerbiage ? state.bizUnitVerbiage.forDocument : null
);


export const getBizUnitVerbiageSelector = createSelector(
    getAdminFeatureState,
    state => state.bizUnitVerbiage
);

export const getDbDocumentTemplatesSelector = createSelector(
    getAdminFeatureState,
    state => state.dbDocumentTemplates
);

export const getAdminErrorSelector = createSelector(
    getAdminFeatureState,
    state => state.error
);
