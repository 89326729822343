import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { Observable, of, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../state/app.state';
import { getSelectedSecurityRolesSelector } from '../user/state';
import { CanActivate, CanLoad } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate, CanLoad {

    constructor(private store: Store<State>, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.checkPermission(state.url);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkPermission(route.path);
    }

    checkPermission(url: string): Observable<boolean> {

        return this.store.select(getSelectedSecurityRolesSelector)
            .pipe(
                map(currentUserRole => {
                    //Not clear about the corporate user now, will do later
                    let bRet = false;
                    if (Boolean(currentUserRole)) {
                        if (url.indexOf('Admin/CarrierSetup') > -1 && currentUserRole.owner) {
                            bRet = true;
                        }
                        else if (url.indexOf('Admin/RegEd') > -1 && currentUserRole.systemAdmin) {
                            bRet = true;
                        }
                        else if (url.indexOf('/Admin/UserManagement') > -1 && currentUserRole.owner) {
                            bRet = true;
                        }
                        else if (url.indexOf('/Admin/Documents') > -1
                            && (currentUserRole.applicationManager || currentUserRole.onboard)) {
                            bRet = true;
                        }
                        else if (url.indexOf('/Admin/SiteManagement') > -1 && currentUserRole.owner) {
                            bRet = true;
                        }
                    }
                    if (!bRet) {
                        this.router.navigate(['/Admin/NoPermission']);
                    }
                    return bRet;
                })
            );
    }

}
