import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BizUnit, IBizUnitVerbiage } from '../../shared/models/general';

/* NgRx */
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { getCurrentBizUnitSelector, getSelectedSecurityRolesSelector } from '../../user/state';
import { getBizUnitVerbiageSelector, getAdminErrorSelector } from '../state';
import { AdminPageActions } from '../state/actions';
import { SiteManagementComponent } from './site-management.component';
import { UserRoles } from '../../shared/models/user';

@Component({
    selector: 'pv-site-management-shell',
    templateUrl: './site-management-shell.component.html'
})
export class SiteManagementShellComponent implements OnInit {

    errorMessage$: Observable<string>;
    bizUnit$: Observable<BizUnit>;
    bizUnitVerbiage$: Observable<IBizUnitVerbiage>;
    currentUserSecurityRole$: Observable<UserRoles>;

    @ViewChild('childSiteManagement') childSiteManagement: SiteManagementComponent;

    constructor(private store: Store<State>) { }

    ngOnInit(): void {
        this.store.dispatch(AdminPageActions.getBizUnitVerbiageAction());
        this.bizUnit$ = this.store.select(getCurrentBizUnitSelector);
        this.bizUnitVerbiage$ = this.store.select(getBizUnitVerbiageSelector);
        this.errorMessage$ = this.store.select(getAdminErrorSelector);
        this.currentUserSecurityRole$ = this.store.select(getSelectedSecurityRolesSelector);
    }

    get isAnyUserChangeNotSaved(): boolean {
        return this.childSiteManagement.isAnyUserChangeNotSaved;
    }

    saveVerbiage(data: IBizUnitVerbiage) {
        this.store.dispatch(AdminPageActions.saveBizUnitVerbiageAction({ buVerbiage: data }));
    }

}
