<pv-modal id="terminate-popup" (modalClosed)="modalClosed($event)">
    <h3>Terminating Appointment for:</h3>

    <h4>{{carrierName}}</h4>
    <h4>State: {{license.stateCode}}</h4>

    <ng-container *ngIf="detail">
        <h4>Line of Authority: <span class="uppercase">{{detail.description}}</span></h4>
    </ng-container>
    <ng-container *ngIf="!detail">
        <h4>License Type: <span class="uppercase">{{license.class}}</span></h4>
    </ng-container>

    <div style="margin: 25px 0 0 20px; padding-right: 20px;">
        <mat-form-field class="full-width" *ngIf="trNeeded">
            <mat-label>Termination Reason</mat-label>
            <mat-select name="termReason" [(ngModel)]="order.terminationCode">
                <mat-option *ngFor="let reason of terminationReasons" [value]="reason.terminationReason">{{reason.terminationReason}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="radio-group faux-mat-form-field">
            <mat-label>Is the applicant being terminated for cause?</mat-label>
            <mat-radio-group name="terminationForCause" [(ngModel)]="order.isTerminationForCause">
                <mat-radio-button value="true">Yes</mat-radio-button>
                <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
        </div>

        <mat-form-field class="full-width">
            <mat-label>Provide the reason for termination</mat-label>
            <textarea matInput [(ngModel)]="order.terminationReason" rows="3"></textarea>
        </mat-form-field>
    </div>

    <input type="button" class="button warning-btn float-right" [ngClass]="{'disabled': !terminateEnabled()}" [disabled]="!terminateEnabled()" (click)="terminate()" value="Terminate" />
</pv-modal>
