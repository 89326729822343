import { Injectable } from '@angular/core';
import { Application } from '../shared/models/application';
import { Agency, AuditLog } from '../shared/models/general';
import { RestService } from '../services/rest.service';
import { IClientListDTO, IClientItemDTO } from '../shared/models/dto/common-result.dto';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuditLogStatus } from '../shared/enums/audit-log';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService extends RestService {
    
    private _onboardingApplications = new BehaviorSubject<Application[]>([]);
    public updateOnboardingApplications(v: Application[]) { this._onboardingApplications.next(v); }
    public get onboardingApplications() { return this._onboardingApplications.value; }
    public get onboardingApplications$() { return this._onboardingApplications.asObservable(); }

    public agencies: Agency[];

    getOnboardingApplications(businessUnitId: number) {
        let relativeUrl = 'POB/Application/ByBizUnit/';
        if (businessUnitId) {
            relativeUrl += businessUnitId;
        }
        return this.get<IClientListDTO<Application>>(relativeUrl);
    }

    getApplication(id: number): Observable<IClientItemDTO<Application>> {
        let relativeUrl = 'POB/Application/' + id;
        return this.get<IClientItemDTO<Application>>(relativeUrl);
    }

    addCommentLog(comment: AuditLog, appId: string): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Application/' + appId + '/Audit';

        comment.editTime = new Date();

        return this.post<IClientItemDTO<number>>(relativeUrl, comment);
    }

    updateApplicationStatus(status: AuditLogStatus, appId: string, user: string): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Application/UpdateStatus';

        let input = {
            'applicationId': appId,
            'editor': user,
            'status': status
        };

        return this.post<IClientItemDTO<number>>(relativeUrl, input);
    }

    updateApplicationNeedsAppointment(needAppt: boolean, appId: string, email: string): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'POB/Application/NeedAppointment';

        let input = {
            'applicationId': appId,
            'editor': email,
            'needAppointment': needAppt
        };

        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }

    archive(appId: string, user: string): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'POB/Application/Archive';

        let input = {
            'applicationId': appId,
            'editor': user
        };

        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }
}
