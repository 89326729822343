import { LicenseDetail } from './license-details';

export class License {
    id: number;
    npn: number;
    licenseNumber: string;
    details: LicenseDetail[];
    class: string;
    classCode: number;
    active: boolean;
    stateCode: string;
    editTime: Date;
    effectiveDate: string;
    effectiveDateTime: Date;
    expirationDate: string;
    expirationDateTime: Date;
    branchID: string;
    residencyStatus: string;

    //for UI / Application creation
    selected: boolean = false;

    get loaDescription() {
        if (this.details) {
            return Array.from(this.details.map(x => x.description)).join(', ');
        }
    }

    get loaArray() {
        if (this.details) {
            return Array.from(this.details.map(x => x.description));
        }
    }

    get terminationReason(): string {
        if (this.details) {
            return this.details.map(x => x.statusReason).join(',');
        }
    }

    get terminationArray() {
        if (this.details) {
            return Array.from(this.details.map(x => x.statusReason));
        }
    }

    get status(): string {
        if (this.details) {
            return this.details.map(x => x.status).join(',');
        }
    }
    get statusArray() {
        if (this.details) {
            return Array.from(this.details.map(x => x.status));
        }
    }

    //static fromNiprResponse(api: INiprLicenseDto) {
    //    let res = new License();
    //    res.licenseNumber = api.licenseNumber;
    //    res.stateCode = api.state;
    //    res.active = api.active;
    //    res.class = api.class;
    //    res.effectiveDateTime = api.issueDate ? new Date(api.issueDate.replace('Z', '')) : null;
    //    res.expirationDateTime = api.expirationDate ? new Date(api.expirationDate.replace('Z', '')) : null;
    //}

    constructor(init?: Partial<License>) {
        this.id = init.id;
        this.licenseNumber = init.licenseNumber;
        this.npn = init.npn;
        this.details = init.details ? init.details.map(x => new LicenseDetail(x)).sort((x, y) => {
            if (x.description > y.description) return 1; else return -1;
        }) : null;
        this.class = init.class;
        this.classCode = init.classCode;
        this.active = init.active;
        this.stateCode = init.stateCode;
        this.editTime = init.editTime;
        this.effectiveDateTime = init.effectiveDate ? new Date(init.effectiveDate.replace('Z', '')) : null;
        this.expirationDateTime = init.expirationDate ? new Date(init.expirationDate.replace('Z', '')) : null;
        this.branchID = init.branchID;
        this.residencyStatus = init.residencyStatus;
    }
}
