import { OAuthModuleConfig, AuthConfig } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { AppConfigService } from './services/app-config.service';

export const DiscoveryDocumentConfig = {
    url: "https://login.microsoftonline.com/59744b1f-0945-4a40-984c-c30b382e5dec/v2.0/.well-known/openid-configuration"
}

export function authConfigFactory(service: AppConfigService): OAuthModuleConfig {
    return {
        resourceServer: {
            allowedUrls: service.authAllowedUrls,
            sendAccessToken: true,
        }
    };
}

@Injectable({
    providedIn: 'root'
})
export class PVAuthService {
    constructor(private config: AppConfigService) { }

    public getAuthConfig(): AuthConfig {

        let retData: AuthConfig = {
            redirectUri: window.location.origin + "/index.html",
            responseType: "code",
            issuer: "https://login.microsoftonline.com/" + this.config.azureADTenantID + "/v2.0",
            strictDiscoveryDocumentValidation: false,
            tokenEndpoint: "https://login.microsoftonline.com/" + this.config.azureADTenantID + "/oauth2/v2.0/token",
            loginUrl: "https://login.microsoftonline.com/" + this.config.azureADTenantID + "/oauth2/v2.0/authorize",
            clientId: this.config.azureClientId,
            scope: "openid profile " + this.config.azureTargetAPIScope,
            //scope: "openid profile https://tmtechniprwebapi-dev.azurewebsites.net/AccessLiveNiprDev",
            //skipIssuerCheck: true,
            clearHashAfterLogin: true,
            //oidc: true,
            showDebugInformation: true,

            //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
            //useSilentRefresh: true,
        };

        return retData;
    }
}
