import { Component, OnInit } from '@angular/core';
import { ApplicationProgress, DocumentRequirementType } from '../../shared/enums/general';
import { OnboardingService } from '../onboarding.service';
import { Application } from '../../shared/models/application';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../modal/modal.service';
import { ProducerDataService } from '../../producer/producer-data.service';
import { Producer } from '../../shared/models/pob/producer';
import { AuditLog, CheckboxOption } from '../../shared/models/general';
import { UserService } from '../../services/user.service';
import { AuditLogCategory, AuditLogStatus } from '../../shared/enums/audit-log';
import { FileService } from '../../services/file.service';
import { ApplicationDocument, DocumentUpload, DocumentFileBody } from '../../shared/models/document';
import { ReadFile } from '../../shared/utils/file';
import { InputForOneRegedCreateOrder, PobAppointment, RegedCreateOrderLog } from '../../shared/models/pob/appointing-license';
import { RegedOrderStatus } from '../../shared/enums/reged';
import { forkJoin } from 'rxjs';
import { BigOrderLog } from '../../shared/models/big/order-log';

@Component({
    selector: 'pv-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

    paramId = '';
    id: number = null;

    application: Application;
    producer: Producer;

    email: string;

    uploadMsg: string;
    uploading = false;
    canUploadDocuments(): boolean { // App Manager is roles manager, owner, or corporate
        return this.userService.isApplicationManager || this.userService.activeRoles.documents;
    }

    public trackingOnly = false;

    allowReview = false;
    backgroundCheck: BigOrderLog;
    needSsn = false;
    showMask = true
    packageNames: CheckboxOption[];
    backgroundAudit: AuditLog;
    secondReview: AuditLog;
    approved: AuditLog;
    rejected: AuditLog;
    sentToState: AuditLog;
    locked = false;

    approvalMessage: string;

    loaded = false;

    constructor(private onboardService: OnboardingService, private producerService: ProducerDataService, public userService: UserService, private fileService: FileService, private modal: ModalService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
        this.email = this.userService.getUserEmail;

        this.paramId = this.route.snapshot.paramMap.get('id');
        if (this.paramId && (this.userService.activeRoles.onboard || this.userService.isApplicationManager)) {
            const numberId = +this.paramId;
            this.id = numberId;
            this.modal.loading(true);
            this.onboardService.getApplication(this.id).subscribe(resData => {
                if (resData && resData.status === 0) {
                    this.application = new Application(resData.item);
                    if (this.application.bizUnit.id !== this.userService.activeBusinessUnit.id) {
                        this.onboardService.updateOnboardingApplications([]);
                        this.router.navigate(['/ProducerOnboarding']);
                        return;
                    }

                    forkJoin(
                        this.producerService.getProducer(this.application.producer.id),
                        this.producerService.getPobAppointments(this.application.producer.id, this.application.carriers)
                    ).subscribe(([prodData, appData]) => {
                        if (prodData && prodData.status === 0) {
                            this.producer = new Producer(prodData.item);
                            this.loadProducerHierarchy();
                            this.setupStatus();
                        }
                        if (appData && appData.status === 0 && appData.dataList) {
                            appData.dataList.forEach(app => {
                                if (app.status === RegedOrderStatus[RegedOrderStatus.AttachedToApplication] && app.message.indexOf("ApplicationId") >= 0) {
                                    try {
                                        const jsonObj = JSON.parse(app.message);
                                        const apptAppId = jsonObj.ApplicationId;
                                        if (apptAppId === this.application.id) {
                                            this.application.appointments.push(new PobAppointment(app, this.application.carriers, this.producer.licenses));
                                        }
                                    }
                                    catch (e) {
                                        console.log(e);
                                    }
                                }
                            });
                        }

                        this.setupAppointments();
                    });
                }
            });
        }
    }

    loadProducerHierarchy(): void {
        this.producerService.getProducerHierarchy(this.producer.id).subscribe(resData => {
            if (resData && resData.status === 0) {
                this.producerService.producerHierarchy = new Producer(resData.item);
                this.producer.childProducerList = this.producerService.producerHierarchy.childProducerList.filter(x => x.type === "Agent");
            }
        });
    }

    browseFile(event) {
        this.uploadFile(event.target.files);
    }

    uploadFile(files: FileList) {
        if (files.length === 0) {
            console.log("No file selected!");
            return
        }

        this.uploadMsg = "";

        const upload = new DocumentUpload();
        upload.applicationID = this.application.id;
        upload.bizUnitId = this.application.bizUnit.id;
        upload.files = [];

        this.uploading = true;

        ReadFile(files[0]).then(data => {
            const name = files[0].name;
            const check = this.application.documents.find(x => x.originalCopy === name);
            if (check) {
                alert('There is already a file named "' + name + '", please change the name or select a differene file?');
            }
            else {
                const doc = new ApplicationDocument();
                doc.title = name;
                doc.originalCopy = name;
                doc.type = DocumentRequirementType.InternalUserUploaded;
                doc.editor = this.userService.getUserEmail;

                upload.files.push(new DocumentFileBody({ document: doc, data: data }));
                this.doFileUpload(upload);
            }
        });
    }

    doFileUpload(docUpload: DocumentUpload) {
        this.uploadMsg = "Uploading file...";
        this.fileService.uploadApplicationFile(docUpload)
            .subscribe(
                res => {
                    if (res.status === 0) {
                        for (let i = 0; i < res.dataList.length; i++) {
                            const doc = docUpload.files[i].document;
                            doc.id = res.dataList[0];
                            this.application.documents.push(doc)
                            this.uploadMsg = doc.originalCopy + " Uploaded Successfully!";
                        }

                        setTimeout(() => {
                            this.uploadMsg = "";
                            this.uploading = false;
                        }, 5000);
                    }
                    else {
                        this.uploadMsg = res.message;
                    }
                },
                err => {
                    console.log("Upload Error:", err);
                    this.uploadMsg = err.error;
                    this.uploading = false;
                }
            );
    }

    get appRequirementsMet(): boolean {
        this.approvalMessage = "";
        //if (!(this.backgroundCheck && this.backgroundCheck.isPassed) || !(this.backgroundAudit && this.backgroundAudit.details === 'NotRequired')) {
        //    this.approvalMessage += "Background Check not completed. ";
        //}
        if (this.secondReview && !this.userService.activeRoles.applicationManager) {
            this.approvalMessage += "This application requires approval from an Application Manager. ";
        }

        if (this.approvalMessage !== "") {
            return false;
        }
        else {
            this.approvalMessage = "Finalize Application Approval";
            return true;
        }
    }

    setupStatus() {
        if (!this.producer.ssn) {
            this.needSsn = true;
        }
        forkJoin(this.producerService.getBigBackgroundLogs(this.userService.activeBusinessUnit.id, this.producer.id),
            this.producerService.getBigPackages(this.userService.activeBusinessUnit.id))
            .subscribe(([logData, packData]) => {
                if (logData && logData.status === 0) {
                    const bgs = logData.dataList.filter(x => x.bizUnitId === this.userService.activeBusinessUnit.id).map(x => new BigOrderLog(x)).filter(x => !x.isDuplicate)
                    if (bgs) {
                        this.backgroundCheck = bgs.pop();
                    }
                }
                if (packData && packData.status === 0) {
                    this.packageNames = packData.dataList.map(x => new CheckboxOption(x));
                    if (this.packageNames.length === 1) {
                        this.packageNames[0].selected = true;
                    }
                }
            });

        if (this.application.progressStatus === ApplicationProgress.Completed) {
            this.allowReview = true;
        }

        const logs = this.application.logs.filter(x => x.category === AuditLogCategory.Status);

        const bg = logs.filter(x => x.message === AuditLogStatus[AuditLogStatus.AppBackgroundCheck]);
        if (bg.length > 0) {
            this.backgroundAudit = bg.pop();
        }

        const second = logs.filter(x => x.message === AuditLogStatus[AuditLogStatus.AppSecondReview]);
        if (second.length > 0) {
            this.secondReview = second.pop();
        }

        const approved = logs.filter(x => x.message === AuditLogStatus[AuditLogStatus.AppApprove]);
        if (approved && approved.length > 0) {
            this.approved = approved.pop();
            this.locked = true;

            if (this.application.needAppointment) {
                const state = logs.filter(x => x.message === AuditLogStatus[AuditLogStatus.AppTransmit]);
                if (state.length > 0) {
                    this.sentToState = state.pop();
                }
            }
        }
        else {
            const rejected = logs.filter(x => x.message === AuditLogStatus[AuditLogStatus.AppDecline]);
            if (rejected && rejected.length > 0 && this.application.status === AuditLogStatus.AppDecline) {
                this.rejected = rejected.pop();
                this.locked = true;
            }
        }

        if (this.application.isArchived) {
            this.locked = true;
        }
    }

    setupAppointments() {
        const apptStates = this.application.appointmentStates;
        const licenses = this.producer.licenses;
        if (apptStates) {
            const len = apptStates.length;
            for (let i = 0; i < len; i++) {
                const lic = licenses.find(x => x.id === apptStates[i].licenseId);
                if (lic) {
                    apptStates[i].loaDescription = lic.loaDescription;
                }
            }
        }

        this.loaded = true;
        this.modal.loading(false);
    }

    get backgroundCheckAllowed(): boolean {
        //If marked "Not Required" or there is already a background check that's not in an Error state (unless it's a duplicate), then no more checks allowed at this time.
        if (!this.producer.ssn || (this.backgroundAudit && this.backgroundAudit.details === 'NotRequired') || (this.backgroundCheck && !this.backgroundCheck.isErrored)) {
            return false;
        }
        else {
            return true;
        }
    }

    updateSsn(): void {
        if (this.producer.ssn) {
            const regex = /^\d{3}-?\d{2}-?\d{4}$/;
            const valid = regex.test(this.producer.ssn);
            if (!valid) {
                alert('SSN format is incorrect');
                return;
            }
            this.modal.loading(true);
            this.producerService.updateProducer(this.producer).subscribe(resData => {
                if (resData && resData.item) {
                    this.needSsn = false;
                }
                this.modal.loading(false);
            });
        }
    }

    sendBackgroundCheck(): void {
        const packages = this.packageNames.filter(x => x.selected === true).map(x => x.item);
        if (packages.length === 0) {
            alert("Please select at least one Background Check package!");
            return;
        }
        this.modal.loading(true);
        this.backgroundCheck = new BigOrderLog({});
        if (confirm("By sending this information to BIG, you have verified that we have signed consent from the Producer to perform a background check")) {
            this.producerService.submitBigBackgroundOrder(this.userService.activeBusinessUnit.id, this.producer.id, this.email, packages).subscribe(resData => {
                if (resData && resData.status === 0) {
                    this.backgroundCheck = resData.item;
                }
                this.modal.loading(false);
            });
        }
    }

    actionBackgroundCheck(status: string): void {
        const log = new AuditLog();
        log.id = null;
        log.editor = this.email;
        log.details = status;
        log.category = AuditLogCategory.Status;
        log.message = AuditLogStatus[AuditLogStatus.AppBackgroundCheck];
        this.onboardService.addCommentLog(log, this.application.id).subscribe(resData => {
            if (resData && resData.status === 0) {
                log.id = resData.item;
                this.backgroundAudit = log;
            }
        });
    }

    clearBackgroundCheckReview() {
        this.modal.loading(true);
        if (this.backgroundCheck && this.backgroundCheck.needsReview) {
            this.producerService.updateBigOrderIPAStatus(this.backgroundCheck.id, 'Cleared').subscribe(resData => {
                if (resData && resData.status === 0) {
                    this.backgroundCheck.ipaStatus = 'Cleared';
                    this.backgroundCheck.ipaStatusEditTime = resData.item.ipaStatusEditTime;
                }
                this.modal.loading(false);
            });
        }
    }

    needsReview() {
        const log = new AuditLog();
        log.editor = this.email;
        log.category = AuditLogCategory.Status;
        log.message = AuditLogStatus[AuditLogStatus.AppSecondReview];
        this.onboardService.addCommentLog(log, this.application.id).subscribe(resData => {
            if (resData && resData.status === 0) {
                log.id = resData.item;
                this.secondReview = log;
            }
        });
    }

    approve() {
        if (this.appRequirementsMet) {
            this.modal.loading(true);
            const log = new AuditLog();
            log.editor = this.email;
            log.category = AuditLogCategory.Status;
            log.message = AuditLogStatus[AuditLogStatus.AppApprove];
            this.onboardService.updateApplicationStatus(AuditLogStatus.AppApprove, this.application.id, this.email).subscribe(resData => {
                if (resData && resData.status === 0) {
                    const application = this.onboardService.onboardingApplications.find(x => x.id === this.application.id);
                    if (application) {
                        application.status = AuditLogStatus.AppApprove;
                    }
                    this.application.status = AuditLogStatus.AppApprove;

                    this.rejected = null;
                    this.approved = log;
                    this.locked = true;

                    this.clearBackgroundCheckReview();

                    if (this.application.appointments && this.application.appointments.length > 0) {
                        const orders: InputForOneRegedCreateOrder[] = [];
                        this.application.appointments.forEach(app => {
                            const a = new InputForOneRegedCreateOrder();
                            a.regedOrderData = new RegedCreateOrderLog();
                            a.regedOrderData.status = RegedOrderStatus.Initiated;
                            a.regedOrderData.message = "Queued for sending to RegEd";
                            a.regedOrderData.effectiveDate = new Date().toLocaleDateString("en-US");
                            a.regedOrderData.creator = this.userService.userIdentifier;
                            a.regedOrderData.createDateTime = new Date();
                            a.regedOrderData.createTime = a.regedOrderData.createDateTime.toISOString();
                            a.regedOrderData.bizUnitId = this.userService.activeBusinessUnit.id;
                            a.regedOrderData.loAs = [app.loaDescription];

                            app.status = RegedOrderStatus[RegedOrderStatus.Initiated];
                            app.creator = this.userService.userIdentifier;
                            app.createTime = new Date();
                            a.carrierLOAsMatrix = [app];

                            try {
                                const json = JSON.parse(app.message);
                                a.regedOrderData.floridaCountyCode = json.floridaCountCode;
                                a.regedOrderData.licenseTypeText = json.licenseTypeText;
                            }
                            catch (err) {
                                //do nothing, just didn't have a JSON blob in the message
                            }
                            
                            orders.push(a);
                        });

                        this.producerService.submitPobAppointmentInBulk(orders).subscribe(resData => {
                            if (resData && resData.status === 0) {
                                this.modal.loading(false);
                            }
                        });
                    }
                    else {
                        this.modal.loading(false);
                    }

                }
            });
        }
    }

    reject() {
        this.modal.loading(true);
        const log = new AuditLog();
        log.editor = this.email;
        log.category = AuditLogCategory.Status;
        log.message = AuditLogStatus[AuditLogStatus.AppDecline];
        this.onboardService.updateApplicationStatus(AuditLogStatus.AppDecline, this.application.id, this.email).subscribe(resData => {
            if (resData && resData.status === 0) {
                const application = this.onboardService.onboardingApplications.find(x => x.id === this.application.id);
                if (application) {
                    application.status = AuditLogStatus.AppDecline;
                }
                this.application.status = AuditLogStatus.AppDecline;
                this.rejected = log;
                this.locked = true;
                this.modal.loading(false);
                this.clearBackgroundCheckReview();
            }
        });
    }

    unReject() {
        this.modal.loading(true);
        const log = new AuditLog();
        log.editor = this.email;
        log.category = AuditLogCategory.Status;
        log.message = AuditLogStatus[AuditLogStatus.AppCreate];
        this.onboardService.updateApplicationStatus(AuditLogStatus.AppCreate, this.application.id, this.email).subscribe(resData => {
            if (resData && resData.status === 0) {
                const application = this.onboardService.onboardingApplications.find(x => x.id === this.application.id);
                if (application) {
                    application.status = AuditLogStatus.AppDecline;
                }
                this.application.status = AuditLogStatus.AppDecline;
                this.rejected = log;
                this.locked = false;
                this.modal.loading(false);
            }
        });
    }

    updateNeedsAppointment(val: boolean) {
        this.modal.loading(true);
        this.onboardService.updateApplicationNeedsAppointment(val, this.application.id, this.email).subscribe(resData => {
            if (resData && resData.status === 0 && resData.item) {
                this.application.needAppointment = val;
                if (val) {
                    this.markSentToState();
                }
                else {
                    this.modal.loading(false);
                }
            }
        });
    }

    archive() {
        if (confirm("Are you sure you want to ARCHIVE this application? This operation cannot be undone!")) {
            this.modal.loading(true);
            this.onboardService.archive(this.application.id, this.email).subscribe(resData => {
                if (resData && resData.item) {
                    this.locked = true;
                    this.application.isArchived = true;
                    this.clearBackgroundCheckReview();
                }

                this.modal.loading(false);
            });
        }
    }

    markSentToState() {
        const log = new AuditLog();
        log.editor = this.email;
        log.category = AuditLogCategory.Status;
        log.message = AuditLogStatus[AuditLogStatus.AppTransmit];
        this.onboardService.addCommentLog(log, this.application.id).subscribe(resData => {
            if (resData && resData.status === 0) {
                log.id = resData.item;
                this.sentToState = log;
            }
            this.modal.loading(false);
        });
    }
}
