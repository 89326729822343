import { Component, OnInit } from '@angular/core';
import { IPdbSearchByLicense } from '../../pdb-search.input';
import { SearchService } from '../../search.service';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { LiveNiprDataService } from '../../live-nipr-data.service';
import { Producer } from '../../../shared/models/pob/producer';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'pv-by-license',
    templateUrl: './by-license.component.html',
    styleUrls: ['../../search.component.css']
})
export class ByLicenseComponent implements OnInit {

    searchByLicenseInput: IPdbSearchByLicense = {
        licenseNumber: null,
        state: null,
        licenseType: 'Agent'
    };

    npnPassThrough: number;

    constructor(public searchService: SearchService, public userService: UserService, private producerService: ProducerDataService, private niprService: LiveNiprDataService, private modal: ModalService) { }

    ngOnInit() {
        this.searchService.errorMessage = '';
        console.log('fein');
    }

    searchByLicense() {
        this.producerService.clearProducerData();
        this.searchService.errorMessage = '';
        if (!this.searchByLicenseInput.licenseNumber) {
            this.searchService.errorMessage = "Please enter a License Number";
        }
        else {
            this.modal.loading(true);
            this.searchByLicenseInput.licenseNumber = this.searchByLicenseInput.licenseNumber.trim();
            this.searchService.getProducersByLicense(this.searchByLicenseInput).subscribe({
                next: resData => {
                    if (resData && resData.dataList && resData.dataList.length > 0) {
                        if (resData.dataList.length == 1 && this.checkForUniqueResultCombination()) {
                            this.producerService.producer = new Producer(resData.dataList[0]);
                            this.producerService.updateId(resData.dataList[0].id);
                        }
                        else {
                            this.producerService.updateSearchResults(resData.dataList);
                        }
                        this.modal.loading(false);
                    }
                    else {
                        //Call NIPR if we can
                        if (this.checkForUniqueResultCombination()) {
                            this.niprService.getDataByLicense(this.searchByLicenseInput).subscribe({
                                next: resData => {
                                    if (resData.status === -1 && resData.item && resData.item.npn > 0) {
                                        this.npnPassThrough = resData.item.npn;
                                    }
                                    else {
                                        let msg = this.producerService.populateDataFromNipr(resData);
                                        if (msg) {
                                            this.searchService.errorMessage = msg.toLowerCase();
                                        }
                                    }
                                    this.modal.loading(false);
                                }
                            });
                        }
                        else {
                            this.searchService.errorMessage = "No results found locally, please provide more search criteria to search NIPR";
                            this.modal.loading(false);
                        }
                    }
                }
            });
        }
    }

    checkForUniqueResultCombination() {
        return this.searchByLicenseInput.licenseNumber && this.searchByLicenseInput.licenseType && this.searchByLicenseInput.state;
    }

    uppercaseInput(value: string) {
        this.searchByLicenseInput.licenseNumber = value.toUpperCase();
    }


    close(): void {
        this.npnPassThrough = null;
    }

    seedProducer(): void {
        this.modal.loading(true);
        this.niprService.submitForSeeding(this.npnPassThrough).subscribe({
            next: resData => {
                if (resData && resData.status == 0 && resData.dataList && resData.dataList.length > 0) {
                    window.location.href = window.location.protocol + "//" + window.location.host + "/Producer/" + resData.dataList[0];
                }
                else {
                    this.modal.message("NPN Failed to import" + (resData ? ": " + resData.message : ''));
                }
            }
        });
    }
}
