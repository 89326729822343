import { createReducer, on } from '@ngrx/store';
import { SecurityRolePageActions, SecurityRoleApiActions } from './actions';

import { ISecurityRolesState } from '.';
import { User } from '../../shared/models/user';
import { PreferenceType } from '../../shared/enums/general';


const initialState: ISecurityRolesState = {
    currentUserRoleId: null,
    currentUserRoles: [],
    buUsersRoles: [],
    allCarriers: [],
    currentBizUnitCarrierIds: [],
    userPreferences: null,
    error: ''
};

export const securityRolesReducer = createReducer<ISecurityRolesState>(
    initialState,

    /////////////////////
    //For Page
    /////////////////////

    on(SecurityRolePageActions.changeUserBizUnitRoleAction, (state, action): ISecurityRolesState => {
        localStorage.setItem('bizUnitRoleId', action.bizUnitUserRoleId.toString());

        let buCarrierIds = null;
        if (action.bizUnitUserRoleId && state.currentUserRoles) {
            let currrentUserRole = state.currentUserRoles.find(r => r.id == action.bizUnitUserRoleId);

            if (currrentUserRole && currrentUserRole.businessUnit && currrentUserRole.businessUnit.bizUnitCarriers) {
                buCarrierIds = currrentUserRole.businessUnit.bizUnitCarriers.map(r => r.carrier.id);
                localStorage.setItem('bizUnitId', currrentUserRole.businessUnit.id.toString());
            }
        }

        return {
            ...state,
            currentUserRoleId: action.bizUnitUserRoleId,
            currentBizUnitCarrierIds: buCarrierIds,
        };
    }),

    /////////////////////
    //For API
    /////////////////////

    //for api call: getCurrentUserRoles()
    on(SecurityRoleApiActions.getCurrentUserRolesSuccess, (state, action): ISecurityRolesState => {
        let buCarrierIds = null;
        if (action.currentRoleId && action.userRoles) {
            let currrentUserRole = action.userRoles.find(r => r.id == action.currentRoleId);

            if (currrentUserRole && currrentUserRole.businessUnit && currrentUserRole.businessUnit.bizUnitCarriers) {
                buCarrierIds = currrentUserRole.businessUnit.bizUnitCarriers.map(r => r.carrier.id);
            }
        }
        return {
            ...state,
            currentUserRoleId: action.currentRoleId,
            currentUserRoles: action.userRoles,
            currentBizUnitCarrierIds: buCarrierIds,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.getCurrentUserRolesFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentUserRoleId: null,
            currentUserRoles: [],
            currentBizUnitCarrierIds: [],
            error: action.error,
        }
    }),


    //for api call: getAllCarriersList()
    on(SecurityRoleApiActions.getAllCarriersListSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            allCarriers: action.allCarriers,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.getAllCarriersListFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            allCarriers: [],
            error: action.error,
        }
    }),

    //for api call: getBizUnitCarrierIds()
    on(SecurityRoleApiActions.getBizUnitCarrierIdsSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentBizUnitCarrierIds: action.buCarrierIds,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.getBizUnitCarrierIdsFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentBizUnitCarrierIds: [],
            error: action.error,
        }
    }),

    //for api call: addBizUnitCarrier()
    on(SecurityRoleApiActions.addBizUnitCarrierSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentBizUnitCarrierIds: [...state.currentBizUnitCarrierIds, action.addedCarrierId],
            error: ''
        };
    }),
    on(SecurityRoleApiActions.addBizUnitCarrierFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentBizUnitCarrierIds: [],
            error: action.error,
        }
    }),


    //for api call: deleteBizUnitCarrier()
    on(SecurityRoleApiActions.deleteBizUnitCarrierSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentBizUnitCarrierIds: state.currentBizUnitCarrierIds.filter(item => item !== action.deletedCarrierId),
            error: ''
        };
    }),
    on(SecurityRoleApiActions.deleteBizUnitCarrierFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            currentBizUnitCarrierIds: [],
            error: action.error,
        }
    }),


    //for api call: getUserList()
    on(SecurityRoleApiActions.getUserListSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            buUsersRoles: action.userRoles,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.getUserListFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            buUsersRoles: [],
            error: action.error,
        }
    }),

    //for api call: updateUserRoles() -- add a new user
    on(SecurityRoleApiActions.addUserRolesSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            buUsersRoles: [...state.buUsersRoles, action.addedUsersRoles],
            error: ''
        };
    }),
    on(SecurityRoleApiActions.addUserRolesFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            error: action.error,
        }
    }),


    //for api call: updateUserRoles() -- update an existing user
    on(SecurityRoleApiActions.updateUserRolesSuccess, (state, action): ISecurityRolesState => {
        const updatedData = state.buUsersRoles.map(item => {
            let updatedRole = action.updatedUsersRoles.find(r => r.id === item.id);
            return updatedRole ? updatedRole : item;
        });
        return {
            ...state,
            buUsersRoles: updatedData,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.updateUserRolesFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            error: action.error,
        }
    }),


    //for api call: deleteUserFromBU()
    on(SecurityRoleApiActions.deleteUserFromBUSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            buUsersRoles: state.buUsersRoles.filter( item => item.id !== action.deletedUserId ),
            error: ''
        };
    }),
    on(SecurityRoleApiActions.deleteUserFromBUFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            error: action.error,
        }
    }),


    //for api call: getCurrentUserPreferences()
    on(SecurityRoleApiActions.getCurrentUserPreferencesSuccess, (state, action): ISecurityRolesState => {
        return {
            ...state,
            userPreferences: action.userPreferences,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.getCurrentUserPreferencesFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            userPreferences: null,
            error: action.error,
        }
    }),


    //for api call: updateUserPreference()
    on(SecurityRoleApiActions.updateUserPreferenceSuccess, (state, action): ISecurityRolesState => {
        let updateData = new User(state.userPreferences);
        if (state.userPreferences.preferences && state.userPreferences.preferences.length > 0) {
            updateData.preferences = state.userPreferences.preferences.filter(r => r.id != action.userPreference.id);
        }
        updateData.setUserPreference(PreferenceType.PreferredCarriers, action.userPreference);
        console.log("updateUserPreferenceSuccess", updateData);
        return {
            ...state,
            userPreferences: updateData,
            error: ''
        };
    }),
    on(SecurityRoleApiActions.updateUserPreferenceFailure, (state, action): ISecurityRolesState => {
        return {
            ...state,
            userPreferences: null,
            error: action.error,
        }
    }),

);
