<div *ngIf="appointments && appointments.length > 0">
    <div>
        <input type="button" value="Recheck Status" class="button float-right" style="margin: 5px 0 10px 0; font-size: medium;" [ngClass]="{'disabled': refreshing}" [disabled]="refreshing" (click)="refreshStatuses()" />
    </div>
    <mat-table [dataSource]="dataSource" style="clear:both;">

        <ng-container matColumnDef="carrierName">
            <mat-header-cell *matHeaderCellDef>
                <span>Carrier Name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment">{{appointment.carrier.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="creator">
            <mat-header-cell *matHeaderCellDef>
                <span>Creator</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment" class="lowercase">{{appointment.creator}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createTime">
            <mat-header-cell *matHeaderCellDef style="flex: .5">
                <span>Created</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment" style="flex: .5">{{appointment.createTime | date:'mediumDate'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="licenseNumber">
            <mat-header-cell *matHeaderCellDef style="flex: .5">
                <span>License Number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment" style="flex: .5">{{appointment.licenseNumber}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef style="flex: .5">
                <span>State</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment" style="flex: .5">{{appointment.stateCode}}{{appointment.floridaCountyName ? ' - ' + appointment.floridaCountyName : ''}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="class">
            <mat-header-cell *matHeaderCellDef>
                <span>Class</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment">{{appointment.class}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="loaCode">
            <mat-header-cell *matHeaderCellDef>
                <span>Line of Authority</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment" class="uppercase">{{appointment.loaDescription}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span>Status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let appointment"><span title="{{appointment.status}} - {{appointment.message}}"><a class="underline" href="javascript:void(0);" (click)="showMessage = appointment;">{{appointment.displayStatus}}<svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></a></span></mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>

</div>

<div *ngIf="showMessage">
    <div class="pv-modal">
        <div class="pv-modal-body">
            <a class="button warning-btn float-right" (click)="showMessage = null;">Close</a>
            <h3>Message:</h3>
            <div style="margin: 20px;">{{showMessage.message}}</div>
            <a href="mailto:ipa-support@hccins.onmicrosoft.com?subject=IPA User Report&body={{getEmailMessageBody()}}">Send Email</a>
        </div>
    </div>
    <div class="pv-modal-background"></div>
</div>

<ng-container *ngIf="appointments === null || appointments.length === 0">
    <h3 style="margin-top: 50px;">No Appointments In Progress!</h3>
</ng-container>
