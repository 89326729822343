import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigService {

    public version: string;
    public urlToMasterDbApi: string;
    public azureADTenantID: string;
    public azureClientId: string;
    public azureTargetAPIScope: string;
    public authAllowedUrls: string[];

    public activeBizUnitId: number;

    constructor(private http: HttpClient) { }

    load(): Promise<any> {
        const promise = this.http.get('/assets/config/app.config.json')
            .toPromise()
            .then(data => {
                Object.assign(this, data);
                return data;
            });

        return promise;
    }
}
