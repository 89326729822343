
export class LicenseDetail {
    id: number;
    code: number;
    description: string;
    status: string;
    statusReason: string;
    statusReasonDate: Date;
    issueDate: Date;

    selected: boolean;
    
    constructor(init?: Partial<LicenseDetail>) {
        this.id = init.id;
        this.code = init.code;
        this.description = init.description.toLocaleLowerCase();
        this.status = init.status;
        this.statusReason = init.statusReason;
        this.statusReasonDate = init.statusReasonDate;
        this.issueDate = init.issueDate;
        this.selected = init.selected === true ? init.selected : false;
    }
}
