
<ng-container *ngIf="loading">
  <div class="loading-spinner"></div>
</ng-container>

<ng-container *ngIf="!producerService.producerResultFromNipr">

  <mat-table *ngIf="producerService.pdbRegulatories && producerService.pdbRegulatories.length > 0" [dataSource]="producerService.pdbRegulatories">

    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.state}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="origin">
      <mat-header-cell *matHeaderCellDef>Origin</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.origin}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="reason">
      <mat-header-cell *matHeaderCellDef>Reason</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.reason}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="disposition">
      <mat-header-cell *matHeaderCellDef>Disposition</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.disposition}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateOfAction">
      <mat-header-cell *matHeaderCellDef>Date Of Action</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.dateOfAction | date:'shortDate'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="effectiveDate">
      <mat-header-cell *matHeaderCellDef>Effective Date</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.effectiveDate | date:'shortDate'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="entryDate">
      <mat-header-cell *matHeaderCellDef>Entry Date</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.entryDate | date:'shortDate'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="penalty">
      <mat-header-cell *matHeaderCellDef>Penalty</mat-header-cell>
      <mat-cell *matCellDef="let reg">${{reg.penalty | number}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lengthOfOrder">
      <mat-header-cell *matHeaderCellDef>Length Of Order</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.lengthOfOrder}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileRef">
      <mat-header-cell *matHeaderCellDef>File Ref</mat-header-cell>
      <mat-cell *matCellDef="let reg">{{reg.fileRef}}</mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="['state', 'origin', 'reason', 'disposition', 'dateOfAction', 'effectiveDate', 'entryDate', 'penalty', 'lengthOfOrder', 'fileRef']"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: ['state', 'origin', 'reason', 'disposition', 'dateOfAction', 'effectiveDate', 'entryDate', 'penalty', 'lengthOfOrder', 'fileRef']; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

  </mat-table>

</ng-container>

<div class="panel-no-results" *ngIf="!producerService.producerResultFromNipr && ((producerService.pdbRegulatories && producerService.pdbRegulatories.length == 0) || producerService.producer.npn == null)">
  <h2>No Regulatory Data Available</h2>
</div>

<ng-container *ngIf="producerService.producerResultFromNipr">
  <div class="panel-no-results">
    <h2>Results are from NIPR, information not available. Add producer to see information.</h2>
  </div>
</ng-container>
