import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'feinMask'
})
export class FeinMaskPipe implements PipeTransform {

    transform(value: string): string {
        if (value) {
            let newVal = value.replace(/[^0-9]/g, '');
            const len = newVal.length;
            if (len > 2) {
                const end = Math.min(len - 2, 7);
                newVal = newVal.substr(0, 2) + '-' + newVal.substr(2, end);
            }
            return newVal;
        }
    }
}
