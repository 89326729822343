import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { License } from '../../../shared/models/pob/license';
import { AppointingLicense, AppointingLicenseDetail, InputForOneRegedCreateOrder, LocalPobAppointmentCreateInput, PobAppointment, RegedCreateOrderLog } from '../../../shared/models/pob/appointing-license';
import { IPdbAppointment } from '../../../shared/models/dto/pdb-result.dto';
import { Carrier } from '../../../shared/models/pob/carrier';
import { RegedOrderStatus } from '../../../shared/enums/reged';
import { Producer } from '../../../shared/models/pob/producer';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalLists } from '../../../shared/lists';
import { LicenseDetail } from '../../../shared/models/pob/license-details';


@Component({
    selector: 'pv-new-appointment',
    templateUrl: './new-appointment.component.html',
    styleUrls: ['./new-appointment.component.css']
})
export class NewAppointmentComponent implements OnInit, OnChanges {

    @Input() carrier: Carrier;
    @Input() licenses: License[];
    @Input() carrierAppointments: IPdbAppointment[];
    @Input() pobAppointments: PobAppointment[];
    @Input() producer: Producer;
    @Input() bizUnitId: number;
    @Input() showInactive = false;
    @Input() buttonText = "Appoint Selected";
    @Input() onboarding = false;

    @Output() appointmentsSelected: EventEmitter<InputForOneRegedCreateOrder[]> = new EventEmitter<InputForOneRegedCreateOrder[]>();
    @Output() terminationSubmitted: EventEmitter<InputForOneRegedCreateOrder> = new EventEmitter<InputForOneRegedCreateOrder>();

    @Output() localAppointmentsSelected: EventEmitter<LocalPobAppointmentCreateInput[]> = new EventEmitter<LocalPobAppointmentCreateInput[]>();

    activeLicenses: AppointingLicense[];
    filteredCarrierAppointments: IPdbAppointment[];
    filteredPobAppointments: PobAppointment[];
    loading = false;

    statesWithNoLTPs: string[] = [];
    statesWithNoLTPsAgent: string[] = ['CA', 'GA', 'ID', 'MI', 'MN', 'SD', 'TX', 'UT', 'VT', 'VA', 'WY'];
    statesWithNoLTPsAgency: string[] = ['CA', 'GA', 'HI', 'ID', 'MI', 'ND', 'SC', 'SD', 'TX', 'UT', 'VA', 'WY'];
    statesWithNoLoas: string[] = [];
    //AR, MS and PA are special, they use a default LOA from the LPT table
    stateWithNoLoasAgent: string[] = ['AR', 'CT', 'DE', 'DC', 'GA', 'IA', 'KS', 'ME', 'MS', 'NE', 'NH', 'NJ', 'NY', 'ND', 'PA', 'SD', 'TN', 'WA'];
    stateWithNoLoasAgency: string[] = ['AR', 'CT', 'DC', 'KS', 'ME', 'NH', 'NJ', 'ND', 'NY', 'PA', 'SD', 'WA'];

    stateWithLoaForTermButNotAppt: string[] = ['CT'];

    localOnlyStates: string[] = [];
    notImplementedStates: string[] = ['VI', 'PR'];

    floridaCountyList;

    columns: string[] = ['licenseNumber', 'state', 'class', 'effectiveDate', 'expirationDate', 'loaCode', 'appoint'];
    dataSource;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    filterValues = {
        licenseNumber: '',
        state: '',
        loa: null,
        classInput: '',
    };

    showFilter = true;
    stateList: string[] = [];
    lineOfAuthList: string[] = [];

    licenseNumberFilter = new FormControl('');
    stateFilter = new FormControl('');
    loaFilter = new FormControl<string|null>(null);
    classFilter = new FormControl('');

    //properties for Terminate Child Component inputs
    terminating = false;
    terminatingLicense: AppointingLicense;
    terminatingLicenseDetail: AppointingLicenseDetail;
    terminateOrder: RegedCreateOrderLog;

    constructor() {
        console.log('new-appt');
        this.floridaCountyList = GlobalLists.floridaCountyList;
    }

    loadStateLists(): void {
        if (this.statesWithNoLoas.length === 0) {
            if (this.producer.type === "Agent") {
                this.statesWithNoLTPs = this.statesWithNoLTPsAgent;
                this.statesWithNoLoas = this.stateWithNoLoasAgent;
                this.localOnlyStates = GlobalLists.localOnlyStatesAgent;
                this.statesWithNoLoas = this.statesWithNoLoas.concat(this.localOnlyStates);
            }
            else {
                this.statesWithNoLTPs = this.statesWithNoLTPsAgency;
                this.statesWithNoLoas = this.stateWithNoLoasAgency;
                this.localOnlyStates = GlobalLists.localOnlyStatesAgency;
                this.statesWithNoLoas = this.statesWithNoLoas.concat(this.localOnlyStates);
            }
        }
    }

    ngOnInit(): void {
        //only have terminate column on the Appoint page, not Onboarding
        if (!this.onboarding) {
            this.columns.push("terminate");
        }

        this.loadStateLists();

        this.licenseNumberFilter.valueChanges
            .subscribe(
                license => {
                    this.filterValues.licenseNumber = license.toLowerCase();
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.stateFilter.valueChanges
            .subscribe(
                state => {
                    if (state === "[X]") {
                        this.filterValues.state = "";
                        this.stateFilter.setValue("");
                    }
                    else {
                        this.filterValues.state = state;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.loaFilter.valueChanges
            .subscribe(
                loa => {
                    if (loa === "[X]") {
                        this.filterValues.loa = null;
                        this.loaFilter.setValue(null);
                    }
                    else {
                        this.filterValues.loa = loa;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.classFilter.valueChanges
            .subscribe(
                classInput => {
                    this.filterValues.classInput = classInput.toLowerCase();
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
    }

    ngOnChanges(): void {
        this.loadStateLists();
        this.dataSource = new MatTableDataSource([]);
        this.loading = true;
        if (this.licenses && this.carrier && this.carrier.allowedLicenseTypes !== null) {
            this.licenses.forEach(l => {
                l.details.forEach(d => {
                    d.selected = false;
                });
                l.selected = false;
            });

            this.filteredCarrierAppointments = [];
            if (this.carrierAppointments) {
                this.filteredCarrierAppointments = this.carrierAppointments.filter(a => a.carrierId === this.carrier.id && a.id);// && a.status !== "Terminated");
            }
            this.filteredPobAppointments = [];
            if (this.pobAppointments) {
                this.filteredPobAppointments = this.pobAppointments.filter(a => a.carrierId === this.carrier.id);// && (this.onboarding === false && a.status !== RegedOrderStatus[RegedOrderStatus.AttachedToApplication]));// && a.status !== RegedOrderStatus[RegedOrderStatus.CompletedAsNiprRejected]);
            }

            const today = new Date();
            const lic: AppointingLicense[] = [];
            this.licenses.forEach(l => {
                if ((l.active || this.showInactive) && l.details !== null && l.details.length > 0 && this.notImplementedStates.indexOf(l.stateCode) < 0) {
                    const ret = AppointingLicense.fromLicense(l);


                    if (this.showInactive || (ret.expirationDate === null || ret.expirationDate > today)) {

                        if (!this.showInactive) {
                            //filter out inactives
                            ret.details = ret.details.filter(d => d.status === "Active");//.map(d => new AppointingLicenseDetail(d));
                            if (ret.details.length === 0) {
                                return;
                            }
                        }

                        if (ret.stateCode === 'FL') {
                            this.runFLSetup(ret);
                        }

                        //filter out invalid LTPs
                        const nonLTPState = this.statesWithNoLTPs.indexOf(l.stateCode) >= 0;
                        if (!nonLTPState) {
                            const validStateCodeTypes = this.carrier.allowedLicenseTypes.filter(l => l.regedLicenseType.stateCode === ret.stateCode && (l.producerType === 'All' || l.producerType === this.producer.type)).map(l => l.regedLicenseType.niprClassCode);
                            if (validStateCodeTypes.indexOf(ret.classCode) < 0) {
                                return;
                            }
                        }

                        //set loa requirement
                        const nonLoaState = this.statesWithNoLoas.indexOf(l.stateCode) >= 0;
                        let defaultLoa: string;
                        let altCodes = [];
                        if (nonLoaState) {
                            ret.loaNotReq = true;
                            defaultLoa = this.carrier.allowedLicenseTypes.find(l => l.regedLicenseType.stateCode === ret.stateCode && l.regedLicenseType.licenseType === ret.class)?.regedLicenseType.defaultLOAText;
                        }
                        else {
                            //filter out invalid loas for Carriers
                            const validStates = this.carrier.allowedLoas.filter(x => x.regedLOA.stateCode === ret.stateCode && (x.producerType === 'All' || x.producerType === this.producer.type));
                            const validCodesForState = validStates.map(l => l.regedLOA.niprCode);
                            altCodes = validStates.filter(l => l.regedLOA.niprAppointmentCode.length > 0 && l.regedLOA.niprAppointmentCode.some(a => a.valueOf() > 0)).map(l => l.regedLOA.niprAppointmentCode);
                            ret.details = ret.details.filter(d => validCodesForState.indexOf(d.code) >= 0);
                            if (ret.details.length === 0) {
                                return;
                            }
                        }

                        this.checkExistingAppointments(ret, nonLoaState, altCodes, defaultLoa);

                        this.checkInProgressAppointments(ret, nonLoaState);

                        if (l.details.length === 0 || ret.details.length > 0 || !nonLoaState) {
                            lic.push(ret);
                        }
                    }
                }
            });

            const isFLResAgent = this.producer.type === "Agent" && this.producer.stateCode === "FL";

            lic.forEach(l => {
                let recheck = false;
                let altCodes = [];
                if (l.stateCode === "FL") {

                    const details = [];
                    l.details.forEach(d => {
                        if (!isFLResAgent || d.appointed === false) {
                            details.push(Object.assign({}, d));
                        }
                    });
                    if (isFLResAgent && !l.details.some(d => d.appointed)) { // don't want to show the FL row if there's no Appointments there to terminate for FL Residents.
                        //Res Agents in FL can ONLY hold a FL Appointment by going through County Appointment, no Direct FL appt allowed. So just convert the FL lic to FC instead of adding a second lic
                        l.stateCode = "FC";
                        l.floridaCountyCode = 0;
                    }
                    else if (details.length > 0) { // don't want to show the FC row in the table if there's no LOAs available there to appoint to
                        if (isFLResAgent) {
                            const floridaCounty = new AppointingLicense(Object.assign({ details: details }, l));
                            floridaCounty.stateCode = "FC";
                            floridaCounty.floridaCountyCode = 0;
                            lic.push(floridaCounty);
                        }
                        l.floridaCountyCode = 0;

                        //Again, FL Res can not be appointed directly on the state level, remove those options, leaving the appointed ones, as they can only be terminated at the state level..... sigh
                        if (isFLResAgent) {
                            l.details = l.details.filter(d => d.appointed);
                        }

                        //this.recheckFCAppointments();
                    }
                }


                //split MI Travel/64 into Travel Accident and Travel Baggage options, appointment codes 92 and 234
                //reuse the travel detail and add one additional detail
                //if (l.stateCode === "MI" && l.details.some(d => d.code === 64)) {
                //    const travel = l.details.find(d => d.code === 64);
                //    travel.inProgress = false;
                //    travel.code = 129;
                //    travel.description = "Travel Accident";
                //    const trav2 = new AppointingLicenseDetail(Object.assign({}, travel));
                //    trav2.code = 130;
                //    trav2.description = "Travel Baggage";
                //    l.details.push(trav2);
                //    recheck = true;
                //    altCodes = [92, 234];
                //    //this.checkExistingAppointments(l, false, [92, 234], '');
                //    //this.checkInProgressAppointments(l, false);
                //}

                //Fix for Surety in SC, BU's Surety-10, USSIC-2
                const det = l.details.find(d => d.code === 11);
                if (det && det.appointed !== true) {
                    //if the producer actually has Surety lic, don't need to add the 'dummy' LOA
                    const suretyDet = l.details.find(d => d.code === 30);
                    if (!suretyDet) {
                        if (l.stateCode === "SC" && (this.bizUnitId === 10 || this.bizUnitId === 2) && this.carrier.allowedLoas.findIndex(x => x.regedLOA.stateCode === 'SC' && x.regedLOA.loa === 'Surety') >= 0) {
                            let newDet = new AppointingLicenseDetail(Object.assign({}, det));
                            newDet.description = "Surety";
                            newDet.code = 30;
                            l.details.push(newDet);
                            recheck = true;
                        } //Additional fix for Surety in MT, BU Surety-10
                        else if (l.stateCode === "MT" && (this.bizUnitId === 10) && this.carrier.allowedLoas.findIndex(x => x.regedLOA.stateCode === 'MT' && x.regedLOA.loa === 'Surety') >= 0) {
                            let newDet = new AppointingLicenseDetail(Object.assign({}, det));
                            newDet.description = "Surety";
                            newDet.code = 30;
                            l.details.push(newDet);
                            recheck = true;
                        }
                        altCodes = [30];
                    }
                }

                if (recheck) {
                    this.checkExistingAppointments(l, false, altCodes, '');
                    this.checkInProgressAppointments(l, false);
                }
            });

            this.activeLicenses = lic.sort((x, y) => {
                if (x.stateCode > y.stateCode) return 1; else return -1;
            });
            this.recheckFCAppointments();
            this.loading = false;
        }
        else {
            this.activeLicenses = [];
            this.loading = false;
        }

        this.dataSource = new MatTableDataSource(this.activeLicenses);
        this.dataSource.filterPredicate = this.tableFilter();
        this.dataSource.paginator = this.paginator;
        this.fillLists(this.dataSource._data._value);
    }

    //Look through the NIPR Appointments to try to match up with a license
    checkExistingAppointments(ret: AppointingLicense, nonLoaState: boolean, altCodes: number[], defaultLoa: string) {
        //check for existing appointment match
        this.filteredCarrierAppointments.forEach(a => {
            if (a.state === 'FL' && a.countyCode !== 'State') {
                return;
            }
            if (a.state === ret.stateCode) {
                if (!nonLoaState) {
                    ret.details.forEach(d => {
                        let alt;
                        if (altCodes.length > 0) {
                            alt = this.carrier.allowedLoas.find(l => l.regedLOA.niprCode === d.code && l.regedLOA.niprAppointmentCode.indexOf(a.loaCode) >= 0)?.regedLOA.niprCode;
                        }
                        if (!alt && a.state === 'FL') {
                            alt = this.getFLAltCodeFromAppt(a.loaCode, d.code);
                        }
                        if (d.code === a.loaCode || (alt && alt !== 0 && d.code === alt)) {
                            if (a.status === "Terminated") {
                                d.appointed = false;
                            }
                            else {
                                d.appointed = true;

                                //SC Appointments contain the license type text in the LOA desc. which is required to be sent along with a Termination request. Make note of it here on the license detail
                                if (ret.stateCode === 'SC') {
                                    if (a.loaDescription.startsWith("GENERAL")) {
                                        d.licenseTypeText = 4;
                                    }
                                    else if (a.loaDescription.startsWith("LOCAL")) {
                                        d.licenseTypeText = 1;
                                    }
                                }
                                d.appointmentCode = a.loaCode;
                            }
                            d.isLocal = a.isLocal;
                            d.localAppointmentDate = a.effectiveDateTime;
                        }
                    });
                }
                else if (a.loaCode === null || a.loaCode === undefined || a.loaCode === 0 || a.loaDescription === "No LOA Needed" || (this.stateWithLoaForTermButNotAppt.indexOf(a.state) >= 0) || (defaultLoa && a.loaDescription.toLowerCase() === defaultLoa.toLowerCase())) {
                    if (a.status === "Terminated") {
                        ret.appointed = false;
                    }
                    else {
                        ret.appointed = true;
                    }
                    ret.isLocal = a.isLocal;
                    ret.localAppointmentDate = a.effectiveDateTime;
                }
            }
        });
    }

    //Look through the RegEd logs to see if something new applies / matches with this license
    checkInProgressAppointments(ret: AppointingLicense, nonLoaState: boolean) {
        //Check for in-progress appointment match
        this.filteredPobAppointments.forEach(a => {
            if (a.licenseId === ret.licenseId && a.carrierId === this.carrier.id && (a.stateCode === ret.stateCode/* || (a.floridaCountyName !== 'State' &&  a.stateCode === 'FC' && ret.stateCode === 'FL')*/)) {//state code matching for FL/FC
                if (nonLoaState) {
                    //checking if the most recent reged order was the cause of the current appointment status
                    //local appointments happening after the latest error overrides the error
                    if ((!ret.localAppointmentDate || a.createDate > ret.localAppointmentDate) && ((ret.appointed && a.filingType === "Termination") || (!ret.appointed && a.filingType === "Appointment"))) {
                        ret.inProgress = true;
                    }
                    ret.apptStatus = a.status;
                    ret.isErrorStatus = a.isErrorStatus;
                    ret.filingType = a.filingType;
                }
                else {
                    ret.details.forEach(d => {
                        if (d.id === a.licenseDetailId) {
                            if ((!d.localAppointmentDate || a.createDate > d.localAppointmentDate) && ((d.appointed && a.filingType === "Termination") || (!d.appointed && a.filingType === "Appointment"))) {
                                //FL is a LOA req state
                                if (a.stateCode === 'FL') { // || a.stateCode === 'MI') {
                                    if (a.regedCreateOrderLog && a.regedCreateOrderLog[0].loAs.indexOf(d.description.toLowerCase()) >= 0) {
                                        d.inProgress = true;
                                        d.apptStatus = a.status;
                                        d.isErrorStatus = a.isErrorStatus;
                                        d.filingType = a.filingType;
                                    }
                                }
                                //else if (a.stateCode === 'MI' && a.loaDescription.startsWith("Travel")) {
                                //    if (a.loaDescription === d.description) {
                                //        d.inProgress = true;
                                //        d.apptStatus = a.status;
                                //        d.isErrorStatus = a.isErrorStatus;
                                //        d.filingType = a.filingType;
                                //    }
                                //}
                                else {
                                    d.inProgress = true;
                                    d.apptStatus = a.status;
                                    d.isErrorStatus = a.isErrorStatus;
                                    d.filingType = a.filingType;
                                }
                            }
                            else {
                                console.log("Update the status?");
                            }
                        }
                    });
                }
            }
        });
    }

    appointDisabled(): boolean {
        const selLic = this.activeLicenses.filter(l => l.selected).length > 0;
        const selDet = this.activeLicenses.filter(l => l.details.filter(d => d.selected).length > 0).length > 0;
        if (this.loading || !(selLic || selDet)) {
            return true;
        }
        return false;
    }

    tableFilter(): (data: AppointingLicense, filter: string) => boolean {
        const filterFunction = function (data: AppointingLicense, filter: string): boolean {
            const searchTerms = JSON.parse(filter);

            return data.licenseNumber.indexOf(searchTerms.licenseNumber) !== -1
                && (searchTerms.state === '' || data.stateCode === searchTerms.state)
                && (searchTerms.classInput === '' || data.class.toLowerCase().indexOf(searchTerms.classInput) >= 0)
                && (searchTerms.loa === null || data.loaArray.indexOf(searchTerms.loa) >= 0);
        }
        return filterFunction;
    }

    fillLists(data: AppointingLicense[]): void {
        this.stateList = data.map(item => item.stateCode).filter((value, index, self) => self.indexOf(value) === index);
        this.stateList.sort();
        this.stateList.unshift("[X]");//clear filter val

        const loas = [];
        data.forEach(item => item.details.forEach(x => loas.push(x.description)));
        this.lineOfAuthList = loas.filter((value, index, self) => self.indexOf(value) === index);
        this.lineOfAuthList.sort();
        this.lineOfAuthList.unshift("[X]");
    }

    //determine if local or nipr appointment, call accordingly
    terminateProducerBySource(license: AppointingLicense, detail?: AppointingLicenseDetail) {
        if (detail) {
            if (detail.isLocal) {
                this.terminateLocalAppointment(license, detail);
            }
            else {
                this.terminateProducer(license, detail);
            }
        }
        else {
            if (license.isLocal) {
                this.terminateLocalAppointment(license, detail);
            }
            else {
                this.terminateProducer(license, detail);
            }
        }
    }
    terminateProducer(license: AppointingLicense, detail?: AppointingLicenseDetail) {
        this.terminatingLicense = license;
        this.terminatingLicenseDetail = detail;
        this.terminateOrder = new RegedCreateOrderLog();
        this.terminating = true;
    }

    //this destroys the child component
    terminateConfirmed(event) {
        let order = new InputForOneRegedCreateOrder();
        order.regedOrderData = this.terminateOrder;
        order.regedOrderData.licenseType = this.getRegedTypeTextFromNiprCode(this.terminatingLicense);

        order.carrierLOAsMatrix = [];
        const appt = PobAppointment.fromLicense(this.producer.id, this.carrier, this.terminatingLicense, this.terminatingLicenseDetail);
        appt.filingType = "Termination";

        if (this.terminatingLicense.stateCode === 'SC' && this.terminatingLicenseDetail.licenseTypeText > 0) {
            order.regedOrderData.licenseTypeText = this.terminatingLicenseDetail.licenseTypeText;
        }

        let regedText = this.getRegedLoATextFromNiprCode(this.terminatingLicense, this.terminatingLicenseDetail);
        const FlHLandVaCodes = [16, 39, 40];
        if (this.terminatingLicense.stateCode === 'FL' && FlHLandVaCodes.indexOf(this.terminatingLicenseDetail.code) >= 0) {
            order.regedOrderData.loAs = this.getFlLoasFromAppointmentCode(this.terminatingLicenseDetail.appointmentCode, regedText);
            appt.loaDescription = order.regedOrderData.loAs.join(', ');
            order.carrierLOAsMatrix.push(appt)
        }
        //else if (this.stateWithLoaForTermButNotAppt.indexOf(this.terminatingLicense.stateCode) >= 0) {
        //    order.regedOrderData.loAs = [];
        //    this.terminatingLicense.details.forEach(d => {
        //        order.regedOrderData.loAs.push(d.description);
        //        const t = PobAppointment.fromLicense(this.producer.id, this.carrier, this.terminatingLicense, d);
        //        order.carrierLOAsMatrix.push(t);
        //    });
        //}
        else {
            if (regedText) {
                order.regedOrderData.loAs = [];
                order.regedOrderData.loAs.push(regedText);
            }
            appt.loaDescription = regedText;
            order.carrierLOAsMatrix.push(appt)
        }
        
        this.terminatingLicense.inProgress = true;
        this.terminatingLicense.apptStatus = RegedOrderStatus[RegedOrderStatus.Initiated];
        if (this.terminatingLicenseDetail) {
            this.terminatingLicenseDetail.inProgress = true;
            this.terminatingLicenseDetail.apptStatus = RegedOrderStatus[RegedOrderStatus.Initiated];
        }
        this.terminationSubmitted.emit(order);
        this.terminating = false;
    }

    terminateLocalAppointment(license: AppointingLicense, detail?: AppointingLicenseDetail) {
        const bus = this.producer.bizUnitProducers.filter(b => b.bizUnitId !== this.bizUnitId);
        let message = "Are you sure you want to TERMINATE this LOCAL Appointment?";
        if (bus.length > 0) {
            const names = bus.map(b => b.bizUnit.name);
            message = "This producer is currently associated with these other Business Units:\r\n\r\n" + names.join(',\r\n') + "\r\n\r\n" + message;
        }
        if (confirm(message)) {
            const appt = LocalPobAppointmentCreateInput.fromLicense(this.producer.id, this.carrier, license, detail);
            appt.loaDescription = this.getRegedLoATextFromNiprCode(license, detail);
            appt.filingType = "Terminated";
            this.localAppointmentsSelected.emit([appt]);
        }
    }

    appointSelected() {
        this.loading = true;
        const selectedAppointments: InputForOneRegedCreateOrder[] = [];

        //Do RegEd/NIPR appointments first:
        this.activeLicenses.forEach(s => {
            if (this.localOnlyStates.indexOf(s.stateCode) >= 0) {
                return;
            }
            if (!s.loaNotReq) {
                if (s.details.some(d => d.selected)) {
                    const appt: InputForOneRegedCreateOrder = new InputForOneRegedCreateOrder();
                    appt.regedOrderData = new RegedCreateOrderLog();
                    appt.regedOrderData.licenseType = this.getRegedTypeTextFromNiprCode(s);
                    appt.regedOrderData.floridaCountyCode = s.floridaCountyCode;
                    if (s.licenseTypeText > 0) {
                        appt.regedOrderData.licenseTypeText = s.licenseTypeText;
                    }
                    appt.carrierLOAsMatrix = [];

                    s.details.forEach(d => {
                        if (d.selected) {
                            d.selected = false;
                            d.inProgress = true;
                            const l = PobAppointment.fromLicense(this.producer.id, this.carrier, s, d);
                            l.loaDescription = this.getRegedLoATextFromNiprCode(s, d);

                            //1031: prevent duplicate LOA caused by mapping
                            if (appt.carrierLOAsMatrix.findIndex(x => x.loaDescription === l.loaDescription) < 0) {
                                // Send MI Travel LOA as 2 LOA appointments
                                if (s.stateCode === "MI" && d.code === 64) {
                                    appt.regedOrderData.loAs.push("Travel Accident");
                                    appt.regedOrderData.loAs.push("Travel Baggage");
                                }
                                else {
                                    appt.regedOrderData.loAs.push(l.loaDescription);
                                }
                            }

                            //prevents duplicate order for SC Surety LOA which is duplicated off of the Casualty detail
                            if (appt.carrierLOAsMatrix.findIndex(x => x.licenseDetailId === l.licenseDetailId) < 0) {
                                appt.carrierLOAsMatrix.push(l);
                            }
                            d.apptStatus = RegedOrderStatus[RegedOrderStatus.Initiated];
                            d.isErrorStatus = false;
                        }
                    });
                    selectedAppointments.push(appt);
                }
            }
            else {
                if (s.selected) {
                    const appt: InputForOneRegedCreateOrder = new InputForOneRegedCreateOrder();
                    appt.regedOrderData = new RegedCreateOrderLog();
                    appt.regedOrderData.licenseType = this.getRegedTypeTextFromNiprCode(s);
                    appt.regedOrderData.floridaCountyCode = s.floridaCountyCode;
                    appt.carrierLOAsMatrix = [];
                    s.selected = false;
                    s.inProgress = true;
                    s.apptStatus = RegedOrderStatus[RegedOrderStatus.Initiated];
                    s.isErrorStatus = false;
                    const l = PobAppointment.fromLicense(this.producer.id, this.carrier, s);
                    l.loaDescription = this.getRegedLoATextFromNiprCode(s, null);
                    if (this.stateWithLoaForTermButNotAppt.indexOf(l.stateCode) >= 0) {
                        l.loaDescription = "LOA Not Required";
                    }
                    appt.regedOrderData.loAs.push(l.loaDescription);
                    appt.carrierLOAsMatrix.push(l);
                    selectedAppointments.push(appt);
                }
            }
        });

        if (selectedAppointments.length > 0) {
            this.appointmentsSelected.emit(selectedAppointments);
        }


        //Reset for the Local Record Only
        const selectedLocalAppointments: LocalPobAppointmentCreateInput[] = [];

        this.activeLicenses.forEach(s => {
            if (this.localOnlyStates.indexOf(s.stateCode) < 0) {
                return;
            }
            if (!s.loaNotReq) {
                if (s.details.filter(d => d.selected).length > 0) {
                    s.details.forEach(d => {
                        if (d.selected) {
                            d.selected = false;
                            const l = LocalPobAppointmentCreateInput.fromLicense(this.producer.id, this.carrier, s, d);
                            l.loaDescription = this.getRegedLoATextFromNiprCode(s, d);
                            selectedLocalAppointments.push(l);
                        }
                    });
                }
            }
            else {
                if (s.selected) {
                    s.selected = false;
                    const l = LocalPobAppointmentCreateInput.fromLicense(this.producer.id, this.carrier, s);
                    l.loaDescription = "LOA Not Required";
                    selectedLocalAppointments.push(l);
                }
            }
        });

        if (this.onboarding) {
            selectedLocalAppointments.forEach(a => { a.filingType = "Temporary"; a.effectiveDate = new Date(); });
        }
        else {
            selectedLocalAppointments.forEach(a => { a.filingType = "Appointed"; });
        }
        if (selectedLocalAppointments.length > 0) {
            this.localAppointmentsSelected.emit(selectedLocalAppointments);
        }

        this.loading = false;
    }

    getRegedTypeTextFromNiprCode(lic: AppointingLicense): string {
        let state = lic.stateCode;
        if (state === 'FC') {
            state = 'FL';
        }
        const search = this.carrier.allowedLicenseTypes.find(a => a.regedLicenseType.stateCode === state && a.regedLicenseType.niprClassCode === lic.classCode);
        if (search) {
            return search.regedLicenseType.licenseType;
        }
    }

    getRegedLoATextFromNiprCode(lic: AppointingLicense, detail: AppointingLicenseDetail): string {
        if (detail) {
            let state = lic.stateCode;
            if (state === 'FC') {
                state = 'FL';
            }
            const search = this.carrier.allowedLoas.find(a => a.regedLOA.stateCode === state && a.regedLOA.niprCode === detail.code);
            if (search) {
                let loa = search.regedLOA.loa;
                //if (search.regedLOA.niprCode === 11 && state === "SC" && this.bizUnitId === 10) {
                //    loa += ",Surety";
                //}
                return loa;
            }
        }
        else {
            const search = this.carrier.allowedLicenseTypes.find(a => a.regedLicenseType.stateCode === lic.stateCode);
            if (search && search.regedLicenseType.defaultLOAText) {
                return search.regedLicenseType.defaultLOAText;
            }
            else {
                return "LOA Not Required";
            }
        }
    }

    countyChanged() {
        let el = document.getElementById('florida-county-select');
        el.removeAttribute('class');

        this.recheckFCAppointments();
    }

    recheckFCAppointments() {
        let lic = this.activeLicenses?.find(l => l.stateCode === 'FC');
        if (!lic) {
            return;
        }
        lic.details.forEach(d => {
            d.selected = false;
            d.inProgress = false;
            d.appointed = false;
            d.isLocal = false;
            d.isErrorStatus = false;
        });
        if (lic && lic.floridaCountyCode > 0) {
            const countyPair = this.floridaCountyList.find(l => l.code === lic.floridaCountyCode)?.name.toLowerCase();
            const altCodes = this.carrier.allowedLoas.filter(x => x.regedLOA.stateCode === 'FL' && x.regedLOA.niprAppointmentCode.length > 0 && x.regedLOA.niprAppointmentCode.some(y => y.valueOf() > 0)).map(l => l.regedLOA.niprAppointmentCode);
            const fcCarAppts = this.filteredCarrierAppointments.filter(a => a.state === 'FL' && a.countyCode !== 'State');
            fcCarAppts.forEach(a => {
                if (a.carrierId === this.carrier.id && countyPair.indexOf(a.countyCode.toLowerCase()) >= 0) {
                    lic.details.forEach(d => {
                        let alt;
                        if (altCodes.length > 0) {
                            alt = this.carrier.allowedLoas.find(l => l.regedLOA.niprAppointmentCode.indexOf(a.loaCode) >= 0)?.regedLOA.niprCode;
                        }
                        if (d.code === a.loaCode || (alt && alt !== 0 && d.code === alt)) {
                            if (a.status === "Terminated") {
                                d.appointed = false;
                            }
                            else {
                                d.appointed = true;
                            }
                            d.isLocal = false;
                        }
                    });
                }
            });

            const fcPobAppts = this.filteredPobAppointments.filter(a => a.stateCode === 'FC' && a.floridaCountyCode && a.floridaCountyCode.length > 0);
            //Check for in-progress appointment match
            fcPobAppts.forEach(a => {
                if (a.licenseId === lic.licenseId && a.carrierId === this.carrier.id && a.floridaCountyCode && a.floridaCountyCode.indexOf(lic.floridaCountyCode) >= 0) {
                    let log = a.regedCreateOrderLog.find(l => l.floridaCountyCode === lic.floridaCountyCode);
                    lic.details.forEach(d => {
                        if (d.id === a.licenseDetailId && ((d.appointed && a.filingType === "Termination") || (!d.appointed && a.filingType === "Appointment"))) {
                            d.inProgress = true;
                            d.apptStatus = log.status;
                            d.isErrorStatus = log.isErrorStatus;
                            d.filingType = log.filingType;
                        }
                    });
                }
            });
        }
    }

    ///
    /// This function is to address the way FL Life, Health, and Variable Annuities LOAs are handled / appointed by
    /// RegEd. They are grouped together on the License, but need to be sent individually to RegEd: 16, 39, 40
    ///
    runFLSetup(appLic: AppointingLicense) {
        //First check "Life with Variable Annuities" code 41:
        let FLLifeHealthVAnnLOA = appLic.details.find(d => d.code === 41);
        if (FLLifeHealthVAnnLOA) {
            //remove the grouped LOA and add the new LOAs if they're not already in the list
            const ind = appLic.details.indexOf(FLLifeHealthVAnnLOA);
            appLic.details.splice(ind, 1);

            if (!appLic.details.find(d => d.code === 16)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 16;
                det.description = "Life";
                det.appointmentCode = 41;
                appLic.details.push(det);
            }
            if (!appLic.details.find(d => d.code === 40)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 40;
                det.description = "Variable Annuities";
                det.appointmentCode = 41;
                appLic.details.push(det);
            }
        }
        //Second check "Life & Health" - code 42:
        FLLifeHealthVAnnLOA = appLic.details.find(d => d.code === 42);
        if (FLLifeHealthVAnnLOA) {
            const ind = appLic.details.indexOf(FLLifeHealthVAnnLOA);
            appLic.details.splice(ind, 1);

            if (!appLic.details.find(d => d.code === 16)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 16;
                det.description = "Life";
                det.appointmentCode = 42;
                appLic.details.push(det);
            }
            if (!appLic.details.find(d => d.code === 39)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 39;
                det.description = "Health";
                det.appointmentCode = 42;
                appLic.details.push(det);
            }
        }
        //Finally check "Life, Health & Variable Ann." - code 45:
        FLLifeHealthVAnnLOA = appLic.details.find(d => d.code === 45);
        if (FLLifeHealthVAnnLOA) {
            const ind = appLic.details.indexOf(FLLifeHealthVAnnLOA);
            appLic.details.splice(ind, 1);

            if (!appLic.details.find(d => d.code === 16)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 16;
                det.description = "Life";
                det.appointmentCode = 45;
                appLic.details.push(det);
            }
            if (!appLic.details.find(d => d.code === 39)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 39;
                det.description = "Health";
                det.appointmentCode = 45;
                appLic.details.push(det);
            }
            if (!appLic.details.find(d => d.code === 40)) {
                let det = new AppointingLicenseDetail(FLLifeHealthVAnnLOA);
                det.code = 40;
                det.description = "Variable Annuities";
                det.appointmentCode = 45;
                appLic.details.push(det);
            }
        }
    }


    ///
    /// Essentially a manual mapping of FL appointment codes to their applied license codes
    ///
    getFLAltCodeFromAppt(apptCode: number, detailCode: number): number {
        let match: number = null;
        const lifeApptCodes = [1542, 1543, 1544, 1545, 1611, 1612, 1614];
        const healthApptCodes = [1543, 1545, 1555, 1614, 1612, 1615];
        const vaApptCodes = [1542, 1543, 1611, 1612];
        switch (detailCode) {
            case 16: (lifeApptCodes.indexOf(apptCode) >= 0 ? match = 16 : null); break;
            case 39: (healthApptCodes.indexOf(apptCode) >= 0 ? match = 39 : null); break;
            case 40: (vaApptCodes.indexOf(apptCode) >= 0 ? match = 40 : null); break;
        }

        return match;
    }

    ///
    /// For FL appointments that can lead to mismatches with the licenses, we must send all assoc. loas along with the termination that are a part of the appointment in one transaction.
    ///
    getFlLoasFromAppointmentCode(apptCode: number, def: string) {
        switch (apptCode) {
            case 1613: 
            case 1544: return ["Life"];
            case 1615: //non res health
            case 1555: return ["Health"];
            case 1614:  
            case 1545: return ["Life", "Health"];
            case 1612:
            case 1543: return ["Life", "Variable Annuities", "Health"];
            case 1611:
            case 1542: return ["Life", "Variable Annuities"]
            default: return [def];
        }

    }
}
