import { Component, OnInit } from '@angular/core';
import { LiveNiprDataService } from '../../producer/live-nipr-data.service';
import { ModalService } from '../../modal/modal.service';

import { State } from '../../state/app.state';
import { Store } from '@ngrx/store';
import { getSelectedSecurityRolesSelector } from '../../user/state';
import { UserRoles } from '../../shared/models/user';
import { Observable } from 'rxjs';

@Component({
    selector: 'pv-npn-untrack',
    templateUrl: './npn-untrack.component.html',
    styleUrls: ['./npn-seeding.component.css']
})

export class NpnUntrackComponent implements OnInit {

    producerNpn: string;
    results: Array<resultStatus> = [];

    currentUserSecurityRole$: Observable<UserRoles>;

    constructor(private niprService: LiveNiprDataService, private store: Store<State>, private modal: ModalService) { }

    ngOnInit(): void {
        this.currentUserSecurityRole$ = this.store.select(getSelectedSecurityRolesSelector);
    }

    untrackNpn() {
        if (this.producerNpn) {
            let inputNpns = this.producerNpn.split(',');
            this.producerNpn = "";
            this.results = [];
            for (let i = 0; i < inputNpns.length; i++) {
                let input = inputNpns[i].trim();
                let npn: number = +input;
                let res: resultStatus = {
                    npn: input, msg: "Validating...", class: "message"
                }
                this.results.push(res);
                if (isNaN(npn)) {
                    this.updateResult(input, "Not a valid NPN, value must be a number", true);
                }
                else {
                    this.removeProducerNpn(npn);
                }
            }
        }
    }

    removeProducerNpn(npn: number): void {
        this.niprService.untrackProducer(npn).subscribe({
            next: resData => {
                console.log(resData);
                if (resData.status === 0) {
                    this.updateResult(npn.toString(), "Submitted successfully!");
                }
                else {
                    this.updateResult(npn.toString(), "NPN was not found in the database. Please check the NPN and try again.", true);
                }
            },
            error: err => {
                console.log(err);
                this.updateResult(npn.toString(), "NIPR API returned an error: " + err.error, true);
            }
        });
    }

    updateResult(npn: string, message: string, error: boolean = false) {
        let res = this.results.find(x => x.npn == npn);
        res.msg = message;
        if (error) {
            res.class = "alert"
        }
    }
}

class resultStatus {
    npn: string;
    msg: string;
    class: string;
}
