import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IClientItemDTO, IClientListDTO } from '../shared/models/dto/common-result.dto'
import { INiprInputLicenseId, INiprInputSSN4, INiprInputSSN, INiprInputNpn } from './live-nipr-search.input';

import { RestService } from '../services/rest.service';
import { IPdbSearchByLicense, IPdbSearchByFein } from './pdb-search.input';
import { SearchProducer, Producer } from '../shared/models/pob/producer';


@Injectable({
    providedIn: 'root'
})
export class LiveNiprDataService extends RestService {

    // Get data by license
    getDataByLicense(input: IPdbSearchByLicense): Observable<IClientItemDTO<Producer>> {
        let typedInput: INiprInputLicenseId = {
            licenseid: input.licenseNumber,
            producertype: input.licenseType.toString() == "Agency" ? "firm" : "individual",
            stateCode: input.state
        }

        let relativeUrl = 'nipr/DataByLicense';
        return this.postWrapped<IClientItemDTO<Producer>>(relativeUrl, typedInput);
    }

    // Get data by last four digit of ssn
    getDataBySSN4(input: SearchProducer): Observable<IClientItemDTO<Producer>> {
        let typedInput: INiprInputSSN4 = {
            firstName: input.firstName,
            lastName: input.lastName,
            ssn4: input.ssn.toString(),
            stateCode: input.stateCode
        }

        let relativeUrl = 'nipr/DataBySSN4';
        return this.postWrapped<IClientItemDTO<Producer>>(relativeUrl, typedInput);
    }

    // Get data by full ssn
    getDataBySSN(input: INiprInputSSN): Observable<IClientItemDTO<Producer>> {
        let relativeUrl = 'nipr/DataBySSN';
        return this.postWrapped<IClientItemDTO<Producer>>(relativeUrl, input);
    }

    // Get data by npn
    getDataByNpn(input: SearchProducer): Observable<IClientItemDTO<Producer>> {
        let typedInput: INiprInputNpn = {
            npn: input.npn,
            stateCode: input.stateCode,
            producerType: input.type.toString() == "Agency" ? "firm" : "individual",
            lastName: input.lastName
        }

        let relativeUrl = 'nipr/DataByNpn';
        return this.postWrapped<IClientItemDTO<Producer>>(relativeUrl, typedInput);
    }

    // Get data by fein
    getDataByFein(input: IPdbSearchByFein): Observable<IClientItemDTO<Producer>> {
        let relativeUrl = 'nipr/DataByFein';
        return this.postWrapped<IClientItemDTO<Producer>>(relativeUrl, input);
    }

    // submit npns for seeding
    submitForSeeding(npn: number): Observable<IClientListDTO<number>> {
        let relativeUrl = 'nipr/Seed';
        return this.post<IClientListDTO<number>>(relativeUrl, [npn.toString()], false);
    }

    // submit npns for reseeding
    submitForReSeeding(npn: number): Observable<IClientListDTO<number>> {
        let relativeUrl = 'nipr/ReSeed/' + npn;
        return this.post<IClientListDTO<number>>(relativeUrl, null);
    }

    // submit npns for untracking
    untrackProducer(npn: number, alert = false): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'nipr/Untrack/' + npn;
        return this.post<IClientItemDTO<boolean>>(relativeUrl, null, alert);
    }
}
