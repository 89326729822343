<div style="margin-top: 20px;">
    <h3>Producer Onboarding</h3>

    <nav class="sub-navbar">
      <ul>
        <li><span><a routerLinkActive="active" [routerLink]="['./External']">External Application Status</a></span></li>
        <li><span><a routerLinkActive="active" [routerLink]="['./Completed']">Ready For Review</a></span></li>
        <li><span><a routerLinkActive="active" [routerLink]="['./InProgress']">In Progress</a></span></li>
        <li><span><a routerLinkActive="active" [routerLink]="['./Approved']">Approved</a></span></li>
        <li><span><a routerLinkActive="active" [routerLink]="['./Rejected']">Rejected</a></span></li>
      </ul>
    </nav>

    <router-outlet></router-outlet>
  </div>
