<h3>Preferred Carriers Filter</h3>
<div class="panel">
    <div class="panel-contain">
        <ng-container *ngIf="loading">
            Loading Carriers...
        </ng-container>

        <mat-table *ngIf="!loading" [dataSource]="carriers">
            <ng-container matColumnDef="carrierName">
                <mat-header-cell *matHeaderCellDef>Carrier Name</mat-header-cell>
                <mat-cell *matCellDef="let carrier">{{carrier.name}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="carrierFein">
                <mat-header-cell *matHeaderCellDef>Carrier FEIN</mat-header-cell>
                <mat-cell *matCellDef="let carrier">{{carrier.fein}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="carrierAction">
                <mat-header-cell *matHeaderCellDef>Remove</mat-header-cell>
                <mat-cell *matCellDef="let carrier">
                    <button class="button" *ngIf="carrier.selected" (click)="removeCarrier(carrier)">Remove</button>
                    <button class="button" *ngIf="!carrier.selected" (click)="addCarrier(carrier)">Add</button>
                </mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>
        </mat-table>
    </div>
</div>
