import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { UserService } from '../../../services/user.service';
import { Alert } from '../../../shared/models/dto/dashboard';
import { BizUnit } from '../../../shared/models/general';
import { UserRoles } from '../../../shared/models/user';

@Component({
    selector: 'pv-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

    @Input() bizUnit: BizUnit;
    @Input() roles: UserRoles;

    existingAlerts: Alert[];

    alert: Alert;
    alertTypesList: string[] = ["Alert", "Maintenance"];

    constructor(private dashService: DashboardService, private userService: UserService) { }

    ngOnInit(): void {
        this.dashService.getSystemAlerts().subscribe(resData => {
            if (resData && resData.status === 0) {
                this.existingAlerts = resData.dataList.map(x => new Alert(x));
            }
        });
        this.resetAlert();
    }

    messageFieldsEntered() {
        if (this.alert.startDate && this.alert.endDate && this.alert.message) {
            if (this.alert.endDate > this.alert.startDate) {
                return "";
            }
            return "Expiration Date must be later than Start Date";
        }

        return "Please enter all fields";
    }

    addOrUpdateAlert() {
        this.alert.editor = this.userService.getUserEmail

        if (this.alert.id) {
            this.alert.updateTime = new Date();
        }

        this.dashService.addNewAlert(this.alert).subscribe(resData => {
            if (resData && resData.status === 0) {
                if (!this.existingAlerts) {
                    this.existingAlerts = [];
                }

                if (this.alert.id) {
                    //update existing
                    const alert = this.existingAlerts.find(a => a.id === this.alert.id);
                    alert.category = resData.item.category;
                    alert.editor = resData.item.editor;
                    alert.startDate = this.alert.startDate;
                    alert.endDate = this.alert.endDate;
                    alert.message = resData.item.message;
                }
                else {
                    this.existingAlerts.push(new Alert(resData.item));
                }
                this.resetAlert();
            }
        });
    }

    editAlert(alert: Alert) {
        this.alert = new Alert(alert);
    }

    deleteAlert(alert: Alert) {
        if (confirm("Are you sure you want to DELETE this Alert?")) {
            this.dashService.deleteAlert(alert.id).subscribe(resData => {
                if (resData && resData.status === 0) {
                    if (this.alert.id === alert.id) {
                        this.resetAlert();
                    }
                    const index = this.existingAlerts.findIndex(a => a.id = alert.id);
                    this.existingAlerts.splice(index, 1);
                }
            });
        }
    }

    resetAlert() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.alert = new Alert(
            {
                category: "Alert",
                startDate: today,
                endDate: null,
                createTime: null,
                updateTime: null,
                message: null,
                id: null
            });
    }
}
