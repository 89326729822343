

export function ReadFile(file: File): Promise<string> {
    return new Promise(resolve => {

        var reader = new FileReader();
        // Read file content on file loaded event
        reader.onload = function (event: any) {
            resolve(event.target.result.toString().replace(/^data:.+;base64,/, ''));
        };

        // Convert data to base64 
        reader.readAsDataURL(file);
    });
}
