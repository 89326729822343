import { Component, OnInit } from '@angular/core';
import { ProducerDataService } from '../../producer/producer-data.service';
import { UserService } from '../../services/user.service';
import { BigOrderLog } from '../../shared/models/big/order-log';

@Component({
    selector: 'pv-background-checks',
    templateUrl: './background-checks.component.html',
    styleUrls: ['./background-checks.component.css', '../dashboard.component.css']
})
export class BackgroundChecksComponent implements OnInit {

    canRunBackgroundCheck = false;
    backgroundChecks: BigOrderLog[];

    constructor(private userService: UserService, private producerService: ProducerDataService) {
        this.canRunBackgroundCheck = (this.userService.activeRoles.onboard || this.userService.isApplicationManager || this.userService.isOwnerOrCorporate);
    }

    ngOnInit(): void {
        if (this.canRunBackgroundCheck) {
            this.producerService.getBigBackgroundLogsByBizUnit(this.userService.activeBusinessUnit.id).subscribe(resData => {
                if (resData && resData.status === 0) {
                    this.backgroundChecks = resData.dataList.filter(x => x.ipaStatus !== 'Cleared').map(x => new BigOrderLog(x));
                }
            });
        }
    }

}
