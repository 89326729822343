import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { ShortApplication } from '../../../shared/models/application';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'pv-producer-applications',
    templateUrl: './producer-applications.component.html',
    styleUrls: ['./producer-applications.component.css']
})
export class ProducerApplicationsComponent implements OnInit, OnDestroy {

    private idSubscription;
    private prodId;
    loading = false;

    columns: string[] = ['id', 'status', 'lastEditor', 'editTime', 'documents'];

    constructor(public producerService: ProducerDataService, private userService: UserService, private modal: ModalService) { }

    ngOnInit() {
        if (this.producerService.producer) {
            this.prodId = this.producerService.producer.id;
            if (!this.producerService.applications && !this.userService.isCorporate) {
                this.getApplications();

            }
        }
        this.idSubscription = this.producerService.id$.subscribe(newId => {
            if (this.producerService.producer && this.prodId !== newId) {
                this.prodId = newId;
                this.getApplications();
            }
        });
    }

    ngOnDestroy() {
        if (this.idSubscription) {
            this.idSubscription.unsubscribe();
        }
    }

    getApplications = (): void => {
        this.loading = true;
        this.producerService.applications = [];
        let bizUnitId: number = null;
        if (this.userService.activeRoles && !this.userService.isCorporate) {
            bizUnitId = this.userService.activeBusinessUnit.id;
        }
        this.producerService.getApplications(this.prodId, bizUnitId).subscribe({
            next: resData => {
                if (resData && resData.status === 0) {
                    this.producerService.applications = resData.dataList.map(x => new ShortApplication(x));
                }
                this.loading = false;
            },
            error: () => { this.loading = false; }
        });
    }
}
