import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { BusinessUnitRoles, UserRolesDto, User, UserRoles } from '../../shared/models/user';
import { ClonerService } from '../../services/clone.service';
import { Subject, Subscription, interval } from 'rxjs';
import { debounce, map, bufferWhen, windowWhen, groupBy, mergeMap, concatMap, last, toArray } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'pv-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnChanges, OnDestroy {

    columns: string[] = ['email', 'businessUnit', 'comment', 'reports', 'seed', 'documents', 'onboard', 'app-manager', 'owner', 'remove'];

    addingUser: boolean = false;

    @Input() errorMessage: string;
    @Input() bizUnitAllUsers: BusinessUnitRoles[];
    @Input() currentUserRole: BusinessUnitRoles;

    @Output() updateUserEmitter = new EventEmitter<UserRolesDto[]>();
    @Output() addUserEmitter = new EventEmitter<UserRolesDto>();
    @Output() removeUserEmitter = new EventEmitter<Number>();

    localCopyOfAllUsers: BusinessUnitRoles[];

    //User action stream
    private userActionOnRolesSubject: Subject<UserRolesDto> = new Subject<UserRolesDto>();
    private userActionOnRolesSubscription: Subscription;

    constructor(private userService: UserService, private cloneService: ClonerService, private snackBar: MatSnackBar) { }

    ngOnInit() {
        if (this.userService.isCorporate && this.currentUserRole.userRoles.systemAdmin) {
            const index = this.columns.indexOf('remove');
            this.columns.splice(index, 0, "system-admin");
        }
        //this.userActionOnRolesSubscription = this.userActionOnRolesSubject.asObservable()
        //    .pipe(
        //        //debounce(() => interval(4000))
        //        bufferWhen(() => interval(4000))
        //    )
        //    .subscribe(rolesFromUserEvents => {
        //        if (rolesFromUserEvents && rolesFromUserEvents.length > 0) {
        //            rolesFromUserEvents.reverse();
        //            console.log("finalChangedRoles 11111", rolesFromUserEvents);

        //            let distinctRoles: UserRolesDto[] = [];
        //            rolesFromUserEvents.forEach(eventRole => {
        //                if (distinctRoles.findIndex(x => x.id === eventRole.id) < 0) {
        //                    //Not found
        //                    distinctRoles.push(eventRole);
        //                }
        //            });
        //            console.log("distinctRoles 2222", distinctRoles);

        //            this.updateUserEmitter.next(distinctRoles);
        //        }
        //    });

        this.userActionOnRolesSubscription = this.userActionOnRolesSubject.asObservable()
            .pipe(
                //debounce(() => interval(3000))
                windowWhen(() => interval(3000)),
                concatMap(manyUserRoleEvents$ => manyUserRoleEvents$.pipe(
                    groupBy(data => data.id),
                    mergeMap(oneUserRoleEvents$ => oneUserRoleEvents$.pipe(last())),
                    toArray()
                ))
            )
            .subscribe(distinctRoles => {
                if (distinctRoles && distinctRoles.length > 0) {
                    this.updateUserEmitter.next(distinctRoles);
                }
            });
    }

    ngOnDestroy() {
        if (this.userActionOnRolesSubscription) {
            this.userActionOnRolesSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        // get latest data from the store
        if (changes.bizUnitAllUsers) {
            let incomingData = changes.bizUnitAllUsers.currentValue as BusinessUnitRoles[];
            this.localCopyOfAllUsers = this.cloneService.deepClone<BusinessUnitRoles[]>(incomingData);
            if (incomingData.some(r => r.isUpdateSuccessful)) {
                this.openSnackBar('Your change is saved successfully.');
            }
        }
    }

    showAddUser(): void {
        this.addingUser = true;
    }

    closeSearch(): void {
        this.addingUser = false;
    }

    searchUser(currUser: BusinessUnitRoles, inputUserEmail: string) {
        //console.log("currUser 11", currUser);
        //console.log("currUser 1111", inputUserEmail);

        //Currently no search is performed, we add the email directly to the table
        let user = new UserRolesDto({
            bizUnitId: currUser.businessUnit.id,
            userIdentifier: inputUserEmail,
            roleSearch: true
        });
        if (this.userService.isCorporate) {
            user.roleBizUnitOwner = true;
            user.roleComments = true;
            user.roleDocument = true;
            user.roleManager = true;
            user.roleOnboarding = true;
            user.roleReports = true;
            user.roleSeeding = true;
        }

        this.addUserEmitter.next(user);
        this.closeSearch();
    }

    removeUser(userId: number) {
        this.removeUserEmitter.next(userId);
    }

    updateUserRoles(user: BusinessUnitRoles, role: string, data: any) {

        let userRoleDto = UserRolesDto.fromBizUnitRoles(user);

        if (role == 'comment') {
            userRoleDto.roleComments = data;
        }
        else if (role === 'reports') {
            userRoleDto.roleReports = data;
        }
        else if (role === 'seed') {
            userRoleDto.roleSeeding = data;
        }
        else if (role === 'documents') {
            userRoleDto.roleDocument = data;
        }
        else if (role === 'onboard') {
            userRoleDto.roleOnboarding = data;
        }
        else if (role === 'applicationManager') {
            userRoleDto.roleManager = data;
        }
        else if (role === 'owner') {
            userRoleDto.roleBizUnitOwner = data;
        }
        else if (role === 'systemAdmin') {
            userRoleDto.roleSystemAdmin = data;
        }

        this.userActionOnRolesSubject.next(userRoleDto);
    }

    openSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            verticalPosition: 'top'
        });
    }
}
