import { Component, OnDestroy, OnInit } from '@angular/core';

import {
    IReportRegulatoryInput, IReportApptRenewalInput, IReportLicenseRenewalInput, IReportUntrackedProducersInput,
    ReportType, ReportApptRenewalInput, ReportLicenseRenewalInput, ReportUntrackedProducersInput, IReportAssociationInput, IReportIncompleteOnboardingInput, ReportIncompleteOnboardingInput, IReportRegedBillingInput, ReportRegedBillingInput, IReportNIPRSeedingInput, ReportNIPRSeedingInput, IReportEnOInput, ReportEnOInput, IReportAgencyAssociationInput, ReportAgencyAssociationInput, IReportRegedErrorsInput, ReportRegedErrorsInput, IReportAuditCarrierInput, ReportAuditCarrierInput, IReportBigBackgroundChecksInput, ReportBigBackgroundChecksInput, IReportEmployedProducerInput, ReportEmployedProducerInput
} from './report.input.dto';
import { ModalService } from '../modal/modal.service';
import { ReportService } from './report.service';
import { UserService } from '../services/user.service';
import { BusinessUnitRoles } from '../shared/models/user';
import { Observable } from 'rxjs';
import { State } from '../state/app.state';
import { Store } from '@ngrx/store';
import { getAllBizUnitCarriersSelector, getCurrentUserAllBizUnitRolesSelector } from '../user/state';
import { Carrier } from '../shared/models/pob/carrier';

@Component({
    selector: 'pv-report-config',
    templateUrl: './report-config.component.html',
    styleUrls: ['./report-config.component.css']
})
export class ReportConfigComponent implements OnInit, OnDestroy {

    reportTypesList: ReportType[] = [
        new ReportType({ display: "Appointment and Termination", type: "appointmentRenewal" }),
        new ReportType({ display: "Appointment and Termination - Errors and Rejections", type: "regedErrors" }),
        new ReportType({ display: "Carrier, Agency and Agent Association", type: "agencyAssociation" }),
        new ReportType({ display: "E&O Expiration Inquiry", type: "enoExpiration" }),
        new ReportType({ display: "Incomplete Onboarding", type: "incompleteOnboarding" }),
        new ReportType({ display: "License Renewal and Expiration", type: "licenseRenewal" }),
        new ReportType({ display: "NIPR Seeding", type: "niprSeeding" }),
        new ReportType({ display: "Producer Association", type: "association" }),
        new ReportType({ display: "RegEd Orders", type: "regedBilling" }),
        new ReportType({ display: "Regulatory Action", type: "regulatory" }),
        new ReportType({ display: "Producers Removed from NIPR Tracking", type: "untrackedProducers" }),
        new ReportType({ display: "Producers Terminated Outside of IPA", type: "terminatedOutsideProducers" }),
        new ReportType({ display: "Audit - Carrier Setup", type: "auditCarrier" }),
        new ReportType({ display: "BIG Background Checks", type: "bigBackgroundChecks" }),
    ];

    reportType = "appointmentRenewal";

    regulatoryActionInput: IReportRegulatoryInput = {
        onOrAfterDate: null
    };

    appointmentRenewalInput: IReportApptRenewalInput = {
        startDate: null,
        endDate: null,
        //status: 'appointed',
        //includeRecordOnly: false,
        state: null,
        bizUnits: [],
        carriers: []
    };

    licenseRenewalInput: IReportLicenseRenewalInput = {
        startDate: null,
        endDate: null,
        status: "active",
        missingStateLicense: false,
        bizUnits: [],
        producerType: null
    };

    untrackedProducersInput: IReportUntrackedProducersInput = {
        startDate: null,
        endDate: null,
    };

    associationInput: IReportAssociationInput = {
        type: 'associated'
    }

    agencyAssociationInput: IReportAgencyAssociationInput = {
        carriers: []
    }

    incompleteOnboardingInput: IReportIncompleteOnboardingInput = {
        bizUnits: [],
        onboardingStatus: 'pending',
        startDate: null,
        endDate: null
    }

    regedErrorsInput: IReportRegedErrorsInput = {
        bizUnits: [],
        carriers: [],
        startDate: null,
        endDate: null
    }

    regedBillingInput: IReportRegedBillingInput = {
        bizUnits: [],
        carriers: [],
        startDate: null,
        endDate: null
    }

    niprSeedingInput: IReportNIPRSeedingInput = {
        bizUnits: [],
        startDate: null,
        endDate: null
    }

    enOInput: IReportEnOInput = {
        bizUnits: [],
        carriers: [],
        startDate: null,
        endDate: null,
        includeNull: false
    }

    producersTermedFromOutsideInput: IReportApptRenewalInput = {
        startDate: null,
        endDate: null,
        state: null,
        bizUnits: [],
        carriers: []
    }

    auditLogTables: string[] = ["Carrier Detail", "Authorized Signer", "Carrier Address", "Carrier LTPs Allowed", "Carrier LOAs Allowed"]

    auditCarrierInput: IReportAuditCarrierInput = {
        startDate: null,
        endDate: null,
        carriers: [],
        auditLogTable: this.auditLogTables[0]
    };

    bigBackgroundStatusList: string[] = ["All", "Error", "Complete", "In_Progress"]
    bigBackgroundChecksInput: IReportBigBackgroundChecksInput = {
        startDate: null,
        endDate: null,
        status: "All",
    }

    employedProducerInput: IReportEmployedProducerInput = {
        state: null,
        include: false,
        bizUnits: []
    }

    userAllBizUnitRoles$: Observable<BusinessUnitRoles[]>;
    allCarriers$: Observable<Carrier[]>;
    private subscription;

    constructor(private reportService: ReportService, private modal: ModalService, public userService: UserService, private store: Store<State>) { }

    ngOnInit() {
        console.log('reports');
        document.title = "IPA - Reports";
        this.userAllBizUnitRoles$ = this.store.select(getCurrentUserAllBizUnitRolesSelector);
        this.allCarriers$ = this.store.select(getAllBizUnitCarriersSelector);
        this.subscription = this.store.select(getAllBizUnitCarriersSelector).subscribe(x => {
            var check = this.reportTypesList.find(x => x.type === "employeeLicenses");
            if (!check && this.userService.activeBusinessUnit && this.userService.activeBusinessUnit.name !== "FICOH") {
                this.reportTypesList.push(new ReportType({ display: "Employee Licenses", type: "employeeLicenses" }));
                this.reportTypesList.push(new ReportType({ display: "Employee DLRPs", type: "employeeDlrp" }));
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    reportResultHandler = (res) => {
        this.modal.loading(false);

        if (res) {
            this.modal.message(res);
        }
    };

    runReport() {
        this.modal.loading(true);
        switch (this.reportType) {
            case "regulatory":
                console.log(this.regulatoryActionInput.onOrAfterDate);
                this.reportService.getRegulatoryReport(this.regulatoryActionInput).subscribe(this.reportResultHandler);
                break;
            case "appointmentRenewal":
                if (this.appointmentRenewalInput.carriers.length === 0) {
                    this.modal.message("Please select at least one Carrier!");
                    break;
                }
                this.reportService.getApptRenewalReport(new ReportApptRenewalInput(this.appointmentRenewalInput)).subscribe(this.reportResultHandler);
                break;
            case "licenseRenewal":
                if (this.licenseRenewalInput.bizUnits.length === 0) {
                    this.modal.message("Please select at least one Business Unit!");
                    break;
                }
                this.reportService.getLicenseRenewalReport(new ReportLicenseRenewalInput(this.licenseRenewalInput)).subscribe(this.reportResultHandler);
                break;
            case "untrackedProducers":
                this.reportService.getUntrackedProducersReport(new ReportUntrackedProducersInput(this.untrackedProducersInput)).subscribe(this.reportResultHandler);
                break;
            case "association":
                //if (this.associationInput.bizUnits.length === 0) {
                //    this.modal.message("Please select at least one Business Unit!");
                //    break;
                //}
                this.reportService.getAssociationReport(this.associationInput).subscribe(this.reportResultHandler);
                break;
            case "agencyAssociation":
                if (this.agencyAssociationInput.carriers.length === 0) {
                    this.modal.message("Please select at least one Carrier!");
                    break;
                }
                this.reportService.getAgencyAssociationReport(new ReportAgencyAssociationInput(this.agencyAssociationInput)).subscribe(this.reportResultHandler);
                break;
            case "incompleteOnboarding":
                if (this.incompleteOnboardingInput.bizUnits.length === 0) {
                    this.modal.message("Please select at least one Business Unit!");
                    break;
                }
                this.reportService.getIncompleteOnboardingReport(new ReportIncompleteOnboardingInput(this.incompleteOnboardingInput)).subscribe(this.reportResultHandler);
                break;
            case "regedErrors":
                this.reportService.getRegedErrorsReport().subscribe(this.reportResultHandler);
                break;
            case "regedBilling":
                if (!(this.regedBillingInput.bizUnits.length > 0 || this.regedBillingInput.carriers.length > 0)) {
                    this.modal.message("Please select either a Business Unit or a Carrier!");
                    break;
                }
                this.reportService.getRegedBillingReport(new ReportRegedBillingInput(this.regedBillingInput)).subscribe(this.reportResultHandler);
                break;
            case "niprSeeding":
                if (this.niprSeedingInput.bizUnits.length === 0) {
                    this.modal.message("Please select at least one Business Unit!");
                    break;
                }
                this.reportService.getNiprSeedingReport(new ReportNIPRSeedingInput(this.niprSeedingInput)).subscribe(this.reportResultHandler);
                break;
            case "enoExpiration":
                if (!(this.enOInput.bizUnits.length > 0 || this.enOInput.carriers.length > 0)) {
                    this.modal.message("Please select either a Business Unit or a Carrier!");
                    break;
                }
                this.reportService.getEnOReport(new ReportEnOInput(this.enOInput)).subscribe(this.reportResultHandler);
                break;
            case "auditCarrier":
                if (this.auditCarrierInput.carriers.length === 0) {
                    this.modal.message("Please select at least one Carrier!");
                    break;
                }
                this.reportService.getAuditCarrierReport(new ReportAuditCarrierInput(this.auditCarrierInput)).subscribe(this.reportResultHandler);
                break;
            case "bigBackgroundChecks":
                this.reportService.getBigBackgroundChecks(new ReportBigBackgroundChecksInput(this.bigBackgroundChecksInput)).subscribe(this.reportResultHandler);
                break;
            case "terminatedOutsideProducers":
                if (this.producersTermedFromOutsideInput.carriers.length === 0) {
                    this.modal.message("Please select at least one Carrier!");
                    break;
                }
                this.reportService.getProdsTermedOutsideIPAReport(new ReportApptRenewalInput(this.producersTermedFromOutsideInput)).subscribe(this.reportResultHandler);
                break;
            case "employeeLicenses":
                this.reportService.getEmployeeLicenses(new ReportEmployedProducerInput(this.employedProducerInput)).subscribe(this.reportResultHandler);
                break;
            case "employeeDlrp":
                this.reportService.getEmployeeDlrps(new ReportEmployedProducerInput(this.employedProducerInput)).subscribe(this.reportResultHandler);
                break;
        }
    }

    //containsEitherFeinOrNpn(input: IReportReconciliationInput) {
    //    return (input.npns !== null || this.containsFein(input));
    //}

    //containsFein(input: IReportReconciliationInput) {
    //    return input.feins !== null && input.feins !== "";
    //}

    formatDate(date: Date) {
        if (date) {
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        }
        return null;
    }
}
