import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProducerDataService } from './producer-data.service';
import { ModalService } from '../modal/modal.service';
import { LiveNiprDataService } from './live-nipr-data.service';
import { Producer, ProducerCompanyLog } from '../shared/models/pob/producer';
import { State } from '../state/app.state';
import { Store } from '@ngrx/store';
import { getSelectedBizUnitSecurityRolesSelector, getSelectedSecurityRolesSelector } from '../user/state';
import { NgForm } from '@angular/forms';
import { UserRoles } from '../shared/models/user';
import { Observable } from 'rxjs';
import { GlobalConsts } from '../shared/utils/constants';
import { UserService } from '../services/user.service';

@Component({
    selector: 'pv-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

    paramId: string = '';
    id: number = null;
    private npnSubscription;
    loading = false;

    isEmployee: boolean;

    currentUserSecurityRole$: Observable<UserRoles>;
    canReSeed = false;

    editingSSN = false;
    newSSN: string = '';
    showMask = true;
    @ViewChild('producerSSN') producerSSNForm: NgForm;

    constructor(public producerService: ProducerDataService, private userService: UserService, private niprService: LiveNiprDataService, private store: Store<State>, private modal: ModalService, private route: ActivatedRoute, private router: Router) {
        this.npnSubscription = producerService.id$.subscribe(newId => {
            if (this.id !== newId) {
                this.id = newId;
                this.loadProducerData();
            }
        });

        this.currentUserSecurityRole$ = this.store.select(getSelectedSecurityRolesSelector);
    }

    ngOnInit() {
        document.title = "IPA - Producer Profile";
        console.log('profile');
        this.paramId = this.route.snapshot.paramMap.get('id');

        this.store.select(getSelectedBizUnitSecurityRolesSelector).subscribe(resData => {
            if (resData) {

                this.isEmployee = (this.producerService.producer?.companyId ? true : false);
                this.canReSeed = this.userService.activeRoles.seed || this.userService.activeRoles.onboard || this.userService.isOwnerOrCorporate;

                if (this.paramId && this.paramId != "null") {
                    if (this.paramId == "FromNipr") {
                        if (!this.producerService.producerResultFromNipr) {
                            //page was reloaded, go back to search page...
                            this.router.navigate(['ProducerSearch/ByName']);
                        }
                    }
                    else {
                        const numberId = +this.paramId;
                        this.id = numberId;

                        if (!this.producerService.producerResultFromNipr) {
                            //check if we've already stashed the biographic info in the producerdataservice
                            if (!this.producerService.producer || this.id !== this.producerService.producer.id) {
                                this.producerService.updateId(this.id);
                                this.loadProducerData();
                            }
                        }
                    }

                }
            }
        });
    }

    ngOnDestroy() {
        if (this.npnSubscription) {
            this.npnSubscription.unsubscribe();
        }
    }

    loadProducerData() {
        this.loading = true;
        this.producerService.getProducer(this.id).subscribe({
            next: resData => {
                if (resData && resData.item) {
                    this.producerService.producer = new Producer(resData.item);
                    this.producerService.setId(this.id);
                    this.isEmployee = (this.producerService.producer.companyId ? true : false);
                }
                else {
                    this.modal.message("Producer data not found");
                }
                this.loading = false;
            }
        });
    }

    isEmpLock(): boolean {
        return !(this.userService.activeRoles.onboard || this.userService.activeRoles.applicationManager || this.userService.activeRoles.owner);
    }

    isEmployeeToggled($event) {
        let dto = new ProducerCompanyLog({preference: this.isEmployee, producerId: this.id })
        if (confirm("Are you sure you want to change the employment status of this producer?")) {
            this.modal.loading(true);
            this.producerService.updateProducerCompany(dto).subscribe(res => {
                if (res.status === 0) {
                    this.producerService.producer.companyId = res.item;
                }
                this.modal.loading(false);
            });
        }
        else {
            this.isEmployee = !this.isEmployee;
            $event.source.checked = this.isEmployee;
        }
    }

    seedNpn() {
        this.modal.loading(true);
        if (!this.producerService.producer.trackedInNIPR && !this.producerService.producerResultFromNipr) {
            //retrack
            this.niprService.submitForReSeeding(this.producerService.producer.npn).subscribe({
                next: resData => {
                    if (resData && resData.status === 0) {
                        window.location.reload();
                    }
                }
            });
        }
        else {
            this.niprService.submitForSeeding(this.producerService.producer.npn).subscribe({
                next: resData => {
                    if (resData && resData.status == 0 && resData.dataList && resData.dataList.length > 0) {
                        window.location.href = window.location.protocol + "//" + window.location.host + "/Producer/" + resData.dataList[0];
                    }
                    else {
                        this.modal.message("NPN Failed to import" + (resData ? ": " + resData.message : ''));
                    }
                }
            });
        }
    }

    reseedProducer() {
        if (confirm("You are opting to pull a new PDB on this producer. A PDB fee will apply. Do you want to continue?")) {
            this.modal.loading(true);
            //retrack
            this.niprService.submitForReSeeding(this.producerService.producer.npn).subscribe({
                next: resData => {
                    if (resData && resData.status === 0) {
                        window.location.reload();
                    }
                }
            });
        }
    }

    untrackProducer() {
        if (confirm("Are you sure you want to REMOVE this Producer from the NIPR Alerts?\r\n\r\nSelecting this removes the producer from NIPR alerts. This should be used if the producer is no longer Active with any TMHCC business units, and the producer should not hold any appointments to HCC. The producer information will no longer be updated and the producer will need to be added back to IPA")) {
            this.modal.loading(true);
            this.niprService.untrackProducer(this.producerService.producer.npn, true).subscribe(resData => {
                if (resData.status === 0 && resData.item === true) {
                    this.producerService.producer.trackedInNIPR = false;
                }
                this.modal.loading(false);
            });
        }
    }

    updateSSN() {
        const valid = GlobalConsts.ssnRegex.test(this.newSSN);
        if (!valid) {
            alert('SSN format is incorrect!');
            return;
        }

        if (confirm("Are you sure you want to modify the Producer's SSN?")) {
            let producerCopy = Producer.shortProducer(this.producerService.producer);
            producerCopy.ssn = this.newSSN;

            this.modal.loading(true);
            this.producerService.updateProducer(producerCopy).subscribe(resData => {
                if (resData && resData.item) {
                    this.producerService.producer.ssn = this.newSSN;
                }
                this.editingSSN = false;
                this.modal.loading(false);
            });
        }
    }
}
