import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ssnMask'
})
export class SsnMaskPipe implements PipeTransform {

    transform(value: string, showMask: boolean): string {
        if (value) {
            let newVal = value.replace(/[^0-9]/g, '');
            const len = newVal.length;
            if (!showMask) {
                if (len > 3) {
                    if (len <= 5) {
                        const end = len - 3;
                        newVal = newVal.substr(0, 3) + '-' + newVal.substr(3, end);
                    }
                    else if (newVal.length <= 9) {
                        const end = Math.min(len - 5, 4);
                        newVal = newVal.substr(0, 3) + '-' + newVal.substr(3, 2) + '-' + newVal.substr(5, end);
                    }
                }
                return newVal;
            }
            else if (len === 9) {
                return '***-**-' + value.substr(-4);
            }
            else {
                return newVal;
            }
        }
    }
}
