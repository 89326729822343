import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, OnInit } from '@angular/core';

import { IBizUnitVerbiage, BizUnit } from '../../shared/models/general';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserRoles } from '../../shared/models/user';

@Component({
    selector: 'pv-site-management',
    templateUrl: './site-management.component.html',
    styleUrls: ['./site-management.component.css']
})
export class SiteManagementComponent implements OnInit, OnChanges {

    @Input() errorMessage: string;
    @Input() bizUnit: BizUnit;
    @Input() bizUnitVerbiage: IBizUnitVerbiage;
    @Input() currentUserRole: UserRoles;

    @Output() saveVerbiageEmitter = new EventEmitter<IBizUnitVerbiage>();

    @ViewChild('docVerbiage') docVerbiageElement: ElementRef;
    @ViewChild('appCompleteVerbiage') appCompleteVerbiageElement: ElementRef;
    @ViewChild('emailSubjectVerbiage') emailSubjectVerbiageElement: ElementRef;
    @ViewChild('emailVerbiage') emailVerbiageElement: ElementRef;

    appCompletePlaceholder: string;


    get isAnyUserChangeNotSaved(): boolean {
        let bRet = false;
        if (this.docVerbiageElement && this.bizUnitVerbiage
            && (this.docVerbiageElement.nativeElement.value !== "" && this.bizUnitVerbiage.forDocument === undefined)
            && this.docVerbiageElement.nativeElement.value !== this.bizUnitVerbiage.forDocument) {
            bRet = true;
        }
        else if (this.appCompleteVerbiageElement && this.bizUnitVerbiage
            && (this.appCompleteVerbiageElement.nativeElement.value !== "" && this.bizUnitVerbiage.forApplicationComplete === undefined)
            && this.appCompleteVerbiageElement.nativeElement.value !== this.bizUnitVerbiage.forApplicationComplete) {
            bRet = true;
        }
        else if (this.emailSubjectVerbiageElement && this.bizUnitVerbiage
            && (this.emailSubjectVerbiageElement.nativeElement.value !== "" && this.bizUnitVerbiage.forEmailSubject === undefined)
            && this.emailSubjectVerbiageElement.nativeElement.value !== this.bizUnitVerbiage.forEmailSubject) {
            bRet = true;
        }
        else if (this.emailVerbiageElement && this.bizUnitVerbiage
            && (this.emailVerbiageElement.nativeElement.value !== "" && this.bizUnitVerbiage.forEmail === undefined)
            && this.emailVerbiageElement.nativeElement.value !== this.bizUnitVerbiage.forEmail) {
            bRet = true;
        }
        return bRet;
    }

    constructor(private snackBar: MatSnackBar) { console.log('site'); }

    ngOnInit(): void {
        this.appCompletePlaceholder = "Default Text: Thank you for completing the Producer Onboarding Application for Tokio Marine "
            + this.bizUnit.name + ". Please allow 10 business days once all information is received to complete the process.";
    }

    ngOnChanges(changes: SimpleChanges): void {
        // get latest data from the store
        if (changes.bizUnitVerbiage) {
            let latestData = (changes.bizUnitVerbiage.currentValue) as IBizUnitVerbiage;
            if (latestData?.isUpdateSuccessful) {
                this.openSnackBar('Your change is saved successfully.');
            }
        }
    }

    saveVerbiage(data: IBizUnitVerbiage, userData: string, source: 'document' | 'appComplete' | 'email' | 'emailSubject'): void {
        let dtoData = { ...data };

        if (source === 'email') {
            dtoData.forEmail = userData;
        }
        else if (source === 'emailSubject') {
            dtoData.forEmailSubject = userData;
        }
        else if (source === 'document') {
            dtoData.forDocument = userData;
        }
        else if (source === 'appComplete') {
            dtoData.forApplicationComplete = userData;
        }
        else {
            //unknow source.
            return;
        }

        this.saveVerbiageEmitter.next(dtoData);
    }

    openSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            verticalPosition: 'top'
        });
    }

}
