import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PobAppointment, RegedCreateOrderLogInDetail } from '../../shared/models/pob/appointing-license';
import { UserRoles } from '../../shared/models/user';

@Component({
    selector: 'pv-appt-term-rejections',
    templateUrl: './appt-term-rejections.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class ApptTermRejectionsComponent implements OnInit, OnChanges {

    @Input() appointments: RegedCreateOrderLogInDetail[];

    @Input() userRoles: UserRoles;

    @Output() clearAlertIdEmitter = new EventEmitter<RegedCreateOrderLogInDetail>();

    appts: RegedCreateOrderLogInDetail[];
    terms: RegedCreateOrderLogInDetail[];

    showAppts = false;
    showTerms = false;

    canClearAlerts: boolean;

    constructor() { }

    ngOnInit(): void {
        this.canClearAlerts = this.userRoles.onboard || this.userRoles.applicationManager || this.userRoles.owner;
    }

    ngOnChanges(): void {
        if (this.appointments) {
            this.appts = this.appointments.filter(a => a.filingType === "Appointment");
            this.terms = this.appointments.filter(a => a.filingType === "Termination");
        }
    }

    toggle(section: string) {
        if (section === "appts")
            this.showAppts = !this.showAppts;
        else
            this.showTerms = !this.showTerms;
    }

    clearAlert(order: RegedCreateOrderLogInDetail) {
        this.clearAlertIdEmitter.next(order);
    }
}
