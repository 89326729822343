import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IClientListDTO, IClientItemDTO } from '../shared/models/dto/common-result.dto'
import { IPdbSearchByLicense } from './pdb-search.input';
import { RestService } from '../services/rest.service';
import { SearchProducer, Producer } from '../shared/models/pob/producer';

@Injectable({
    providedIn: 'root'
})
export class SearchService extends RestService {

    searchInput: SearchProducer;
    errorMessage: string = '';

    searchProducerByInput(input: SearchProducer): Observable<IClientListDTO<Producer>> {
        let relativeUrl = 'pob/Producer/Search';
        return this.postWrapped<IClientListDTO<Producer>>(relativeUrl, input);
    }

    searchProducerByFein(input: string): Observable<IClientListDTO<Producer>> {
        let relativeUrl = 'nipr/NpnByFein';
        return this.postWrapped<IClientListDTO<Producer>>(relativeUrl, [input]);
    }

    getProducerByNpn(npn: number): Observable<IClientItemDTO<Producer>> {
        let relativeUrl = 'pob/Producer/npn/' + npn;
        return this.getWrapped<IClientItemDTO<Producer>>(relativeUrl);
    }

    // Get Biographic data by License number
    getProducersByLicense(input: IPdbSearchByLicense): Observable<IClientListDTO<Producer>> {
        let relativeUrl = 'pob/ProducerByLicense';
        return this.postWrapped<IClientListDTO<Producer>>(relativeUrl, input);
    }
}
