import { Component, Input, OnInit } from '@angular/core';
import { SearchProducer } from '../../shared/models/pob/producer';

@Component({
    selector: 'pv-removed-producers',
    templateUrl: './removed-producers.component.html',
    styleUrls: ['./removed-producers.component.css']
})
export class RemovedProducersComponent implements OnInit {

    @Input() producers: SearchProducer[];

    constructor() { }

    ngOnInit(): void {
    }

}
