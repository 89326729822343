import { createAction, props } from '@ngrx/store';
import { IBizUnitVerbiage } from '../../../shared/models/general';
import { DocuSignTemplate, DocumentTemplateUpload } from '../../../shared/models/document';


//for api call: getBizUnitVerbiage()
export const getBizUnitVerbiageSuccess = createAction(
    `[Admin API] Get bizUnit verbiage success`,
    props<{ buVerbiage: IBizUnitVerbiage}>()
);
export const getBizUnitVerbiageFailure = createAction(
    `[Admin API] Get bizUnit verbiage failure`,
    props<{ error: string }>()
);


//for api call: saveBizUnitVerbiage()
export const saveBizUnitVerbiageSuccess = createAction(
    `[Admin API] Update bizUnit verbiage success`,
    props<{ buVerbiage: IBizUnitVerbiage }>()
);
export const saveBizUnitVerbiageFailure = createAction(
    `[Admin API] Update bizUnit verbiage failure`,
    props<{ error: string }>()
);


//for api call: getDocumentListsByBizUnit()
export const getDocumentListsByBizUnitSuccess = createAction(
    `[Admin API] Get bizUnit template document list success`,
    props<{ buDocumentTemplates: DocuSignTemplate[]  }>()
);
export const getDocumentListsByBizUnitFailure = createAction(
    `[Admin API] Get bizUnit template document list failure`,
    props<{ error: string }>()
);


//for api call: updateDocumentTemplates()
export const updateDocumentTemplatesSuccess = createAction(
    `[Admin API] Update bizUnit template document list success`,
    props<{ buDocumentTemplates: DocuSignTemplate[]  }>()
);
export const updateDocumentTemplatesFailure = createAction(
    `[Admin API] Update bizUnit template document lsit failure`,
    props<{ error: string }>()
);


//for api call: uploadDocusignTemplateFile()
export const uploadDocusignTemplateFileSuccess = createAction(
    `[Admin API] Upload docusign template file success`,
    props<{ docusignTemplate: DocuSignTemplate }>()
);
export const uploadDocusignTemplateFileFailure = createAction(
    `[Admin API] Upload docusign template file failure`,
    props<{ error: string }>()
);


//for api call: deleteDocusignTemplateFile()
export const deleteDocusignTemplateFileSuccess = createAction(
    `[Admin API] Delete docusign template file success`,
    props<{ deletedID: number }>()
);
export const deleteDocusignTemplateFileFailure = createAction(
    `[Admin API] Delete docusign template file failure`,
    props<{ error: string }>()
);


