import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';

import { ProducerOnboardingComponent } from './onboarding.component';
import { ProducerDashboardComponent } from './dashboard/dashboard.component';
import { ApplicationComponent } from './application/application.component';
import { StateSelectModule } from '../shared/modules/state-select/state-select.module';
import { CommentsModule } from '../shared/modules/comments/app-comments.module';
import { InProgressAppComponent } from './dashboard/in-progress-app/in-progress-app.component';
import { CompletedAppComponent } from './dashboard/completed-app/completed-app.component';
import { ApprovedAppComponent } from './dashboard/approved-app/approved-app.component';
import { RejectedAppComponent } from './dashboard/rejected-app/rejected-app.component';
import { DisplayTableComponent } from './dashboard/display-table/display-table.component';
import { ApplicationDocumentsModule } from '../shared/modules/app-documents/app-documents.module';
import { ExternalAppComponent } from './dashboard/external/external-app.component';
import { SsnMaskModule } from '../shared/modules/ssn-mask/ssn-mask.module';

export const routes: Routes = [
    {
        path: 'ProducerOnboarding', component: ProducerOnboardingComponent, children: [
            {
                path: 'Dashboard', component: ProducerDashboardComponent, children: [
                    { path: 'External', component: ExternalAppComponent },
                    { path: 'Completed', component: CompletedAppComponent },
                    { path: 'InProgress', component: InProgressAppComponent },
                    { path: 'Approved', component: ApprovedAppComponent },
                    { path: 'Rejected', component: RejectedAppComponent },
                    { path: '', redirectTo: 'External', pathMatch: 'full' }
                ]
            },
            { path: 'Application/:id', component: ApplicationComponent },
            { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },

            //{
            //    path: 'CreateProducer', component: CreateComponent, data: { title: 'Create Producer' }
            //},
            { path: '', redirectTo: 'ProducerOnboarding', pathMatch: 'full' }
        ]
    }
];
@NgModule({
    declarations: [ProducerOnboardingComponent,
        ProducerDashboardComponent,
        ApplicationComponent,
        ExternalAppComponent,
        InProgressAppComponent,
        CompletedAppComponent,
        ApprovedAppComponent,
        RejectedAppComponent,
        DisplayTableComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        CdkTableModule,
        StateSelectModule,
        CommentsModule,
        SsnMaskModule,
        ApplicationDocumentsModule,
        RouterModule.forChild(routes)
    ]
})
export class OnboardingModule { }
