import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable } from 'rxjs';
import { IClientListDTO, IClientItemDTO } from '../shared/models/dto/common-result.dto';
import { DocuSignTemplate } from '../shared/models/document';
import { CreateApplication } from '../shared/models/application';
import { TerminationReason } from '../producer/appoint/terminate/terminate.component';
import { Carrier, CarrierRegedLicenseTypeDTO, CarrierRegedLoaDTO, RegedLicenseType, RegedLOA } from '../shared/models/pob/carrier';
import { License } from '../shared/models/pob/license';
import { PobAppointment } from '../shared/models/pob/appointing-license';

@Injectable({
    providedIn: 'root'
})
export class DataService extends RestService {

    //all Distinct Carriers from the Appointments table
    allCarriers: Carrier[];

    getDocumentListsByBizUnit(bizUnitId: number): Observable<IClientListDTO<DocuSignTemplate>> {
        let relativeUrl = 'POB/DocuSignTemplate/' + bizUnitId;
        return this.get<IClientListDTO<DocuSignTemplate>>(relativeUrl);
    }

    updateDocumentTemplates(docTemplates: DocuSignTemplate[]): Observable<IClientListDTO<DocuSignTemplate>> {
        let relativeUrl = 'POB/DocuSignTemplate/AddOrUpdate';
        return this.post<IClientListDTO<DocuSignTemplate>>(relativeUrl, docTemplates);
    }

    createNewApplication(onboardApplication: CreateApplication): Observable<IClientItemDTO<string>> {
        let relativeUrl = 'POB/Application/New';
        return this.post<IClientItemDTO<string>>(relativeUrl, onboardApplication);
    }

    getAllCarriers(): Observable<IClientListDTO<Carrier>> {
        let relativeUrl = 'PDB/GetAllCarriers';

        return this.get<IClientListDTO<Carrier>>(relativeUrl);
    }

    getTerminationReasons(state: string): Observable<IClientListDTO<TerminationReason>> {
        let relativeUrl = 'POB/RegEd/TerminationReason/' + state;
        return this.get<IClientListDTO<TerminationReason>>(relativeUrl);
    }

    //getCarrierPreferences(): Observable<IClientListDTO<CarrierPreference>> {
    //    let relativeUrl = 'POB/GetCarrierPreferences';

    //    return this.get<IClientListDTO<CarrierPreference>>(relativeUrl);
    //}

    getCarrierAllowedRegedLicenseTypes(carrierId: number): Observable<IClientListDTO<CarrierRegedLicenseTypeDTO>> {
        const relativeUrl = 'POB/GetRegedLicenseTypeCarrierAllowed/' + carrierId;

        return this.get<IClientListDTO<CarrierRegedLicenseTypeDTO>>(relativeUrl);
    }

    getCarrierAllowedRegedLoas(carrierId: number): Observable<IClientListDTO<CarrierRegedLoaDTO>> {
        const relativeUrl = 'POB/GetRegedLOACarrierAllowed/' + carrierId;

        return this.get<IClientListDTO<CarrierRegedLoaDTO>>(relativeUrl);
    }

    initPobAppointments(licenses: License[], pobAppointments: PobAppointment[]): void {
        licenses.forEach(l => {
            pobAppointments.forEach(a => {
                if (a.licenseId === l.id) {
                    a.licenseNumber = l.licenseNumber;
                    a.class = l.class;
                    if (a.licenseDetailId === 0) {
                        a.loaDescription = "LOA Not Required";
                    }
                    else {
                        l.details.forEach(d => {
                            if (d.id === a.licenseDetailId) {
                                a.loaDescription = d.description;
                                return;
                            }
                        });
                    }
                }
            });
        })
    }
}
