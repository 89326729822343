import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../services/user.service';
import { AuthorizedSigner, Carrier, CarrierDetail, CarrierRegedLicenseTypeDTO, CarrierRegedLoaDTO, RegedLicenseType, RegedLOA } from '../../../shared/models/pob/carrier';
import { AdminDataService } from '../../admin.data.service';
import { BizUnit, BizUnitCarrier } from '../../../shared/models/general';
import { NgForm } from '@angular/forms';
import { InputStateColorCode } from '../../../shared/utils/ui-mod';
import { ModalService } from '../../../modal/modal.service';
import { GlobalLists } from '../../../shared/lists';

@Component({
    selector: 'pv-edit-carrier',
    templateUrl: './edit-carrier.component.html',
    styleUrls: ['./edit-carrier.component.css']
})
export class EditCarrierComponent implements OnInit {

    @Input() carriers: Carrier[];

    activeBU: BizUnit;
    activeCarrier: Carrier;
    activeBUIsAssoctiatedWithActiveCarrier: boolean;
    //carrierDetails: CarrierDetail[] = [];
    selectedState: string;
    filteredLTPs: CarrierRegedLicenseTypeDTO[];
    filteredLOAs: CarrierRegedLoaDTO[];
    carrierStateDetails: CarrierDetail;
    authorizedSigners: AuthorizedSigner[];
    activeAuthorizedSigner: AuthorizedSigner;
    editingAuthorizedSigner = false;

    stateRegedLTPs: RegedLicenseType[];
    stateRegedLOAs: RegedLOA[];

    allowedLicenseTypeEditor: string;
    allowedLicenseTypeEditTime: Date;
        
    allowedLoaEditor: string;
    allowedLoaEditTime: Date;

    editHoldLOA: CarrierRegedLoaDTO;
    editHoldLTP: CarrierRegedLicenseTypeDTO;

    stateMessage = "";

    //forms
    defaultBgColor = 'transparent';
    @ViewChild('carrierAddress') carrierAddressForm: NgForm;
    @ViewChild('authSigner') authSignerForm: NgForm;
    @ViewChild('carrierStateContact') carrierStateContactForm: NgForm;
    
    constructor(private userService: UserService, private dataService: DataService, private adminService: AdminDataService, private modal: ModalService) { }

    ngOnInit(): void {
        
        this.activeBU = this.userService.activeBusinessUnit;
        const cid = localStorage.getItem('activeCarrier');
        if (cid) {
            this.activeCarrier = this.carriers.find(c => c.id === +cid);
        }
        else {
            this.activeCarrier = this.carriers[0];
        }
        console.log('edit carrier');
        this.carrierChanged();
        this.getAuthorizedSigners();
    }

    associateCarrier() {
        this.adminService.associateCarrierWithBU(this.activeCarrier, this.activeBU.id).subscribe(resData => {
            if (resData.status === 0) {
                localStorage.setItem('activeCarrier', this.activeCarrier.id.toString());
                window.location.reload();
            }
        });
    }

    disassociateCarrier() {
        this.adminService.disassociateCarrierWithBU(this.activeCarrier, this.activeBU.id).subscribe(resData => {
            if (resData.status === 0) {
                this.activeBUIsAssoctiatedWithActiveCarrier = false;
            }
        });
    }

    getAuthorizedSigners(): void {
        this.adminService.getAuthorizedSigners().subscribe(resData => {
            if (resData.status === 0) {
                this.authorizedSigners = resData.dataList.map(a => new AuthorizedSigner(a));
                this.adminService.authorizedSigners = this.authorizedSigners;
            }
        });
    }

    authSignerChanged(): void {
        this.carrierStateDetails.authorizedSignerId = this.activeAuthorizedSigner.id;
        
        this.adminService.updateCarrierDetails(this.carrierStateDetails).subscribe(resData => {
            InputStateColorCode(document.getElementById('auth-signer-selection'), resData);
        });
    }

    addNewAuthorizedSigner(): void {
        const signer = new AuthorizedSigner({ fullName: 'New Authorized Signer' });
        this.activeAuthorizedSigner = signer;
        this.editingAuthorizedSigner = true;
    }

    updateAuthorizedSigner(): void {
        //validate form
        if (this.activeAuthorizedSigner.firstName && this.activeAuthorizedSigner.lastName && this.activeAuthorizedSigner.title && this.activeAuthorizedSigner.email
            && this.activeAuthorizedSigner.address.line1
            && this.activeAuthorizedSigner.address.city
            && this.activeAuthorizedSigner.address.state
            && this.activeAuthorizedSigner.address.zip
            && this.activeAuthorizedSigner.address.phone) {
            this.authSignerForm.form.markAsPristine();
            this.adminService.updateAuthorizedSigner(this.activeAuthorizedSigner).subscribe(resData => {
                if (resData.status === 0) {
                    //if added a new signer
                    if (!this.activeAuthorizedSigner.id) {
                        this.activeAuthorizedSigner.id = resData.item.id;
                        this.activeAuthorizedSigner.addressId = resData.item.addressId;
                        this.activeAuthorizedSigner.address.id = resData.item.addressId;
                        this.carrierStateDetails.authorizedSignerId = resData.item.id;
                        this.authorizedSigners.push(this.activeAuthorizedSigner);
                        this.adminService.updateCarrierDetails(this.carrierStateDetails);
                    }
                }
                else {
                    this.authSignerForm.form.markAsDirty();
                }

                InputStateColorCode(document.getElementById('state-auth-signer'), resData);
            });
        }
        //this.activeAuthorizedSigner.fullName = this.activeAuthorizedSigner.firstName + " " + this.activeAuthorizedSigner.lastName;
    }

    filterAllowedLists(): void {
        if (this.activeCarrier && this.activeCarrier.allowedLicenseTypes) {
            this.filteredLTPs = this.activeCarrier.allowedLicenseTypes.filter(l => l.regedLicenseType.stateCode === this.selectedState);
            this.filteredLOAs = this.activeCarrier.allowedLoas.filter(l => l.regedLOA.stateCode === this.selectedState);

            let ltpEdits = this.filteredLTPs.filter(a => a.editor).sort((x, y) => {
                if (x.editTime > y.editTime) return 1; else return -1;
            });

            if (ltpEdits.length > 0) {
                this.allowedLicenseTypeEditor = ltpEdits[0].editor;
                this.allowedLicenseTypeEditTime = ltpEdits[0].editTime;
            }

            let loaEdits = this.filteredLOAs.filter(a => a.editor).sort((x, y) => {
                if (x.editTime > y.editTime) return 1; else return -1;
            });

            if (loaEdits.length > 0) {
                this.allowedLoaEditor = loaEdits[0].editor;
                this.allowedLoaEditTime = loaEdits[0].editTime;
            }
        }
    }

    stateChanged(): void {
        this.stateMessage = '';
        if (this.selectedState) {
            this.adminService.getRegedLicenseTypes(this.selectedState).subscribe(resData => {
                if (resData.status === 0) {
                    this.stateRegedLTPs = resData.dataList.map(l => new RegedLicenseType(l)).sort((x, y) => {
                        if (x.licenseType > y.licenseType) return 1; else return -1;
                    });
                }
            });
            this.adminService.getRegedLOAs(this.selectedState).subscribe(resData => {
                if (resData.status === 0) {
                    this.stateRegedLOAs = resData.dataList.map(l => new RegedLOA(l)).sort((x, y) => {
                        if (x.loa > y.loa) return 1; else return -1;
                    });
                }
            });

            this.carrierStateDetails = this.activeCarrier.carrierDetailByState.find(d => d.licenseState === this.selectedState && d.carrierId === this.activeCarrier.id);
            if (!this.carrierStateDetails) {
                this.carrierStateDetails = new CarrierDetail({});
                this.carrierStateDetails.carrierId = this.activeCarrier.id;
                this.carrierStateDetails.licenseState = this.selectedState;
                this.carrierStateDetails.licenseTypeState = this.selectedState;
                this.carrierStateDetails.companyState = this.selectedState;
                this.activeCarrier.carrierDetailByState.push(this.carrierStateDetails);
            }

            this.activeAuthorizedSigner = this.authorizedSigners.find(s => s.id === this.carrierStateDetails.authorizedSignerId);

            let statesAgent = GlobalLists.localOnlyStatesAgent.indexOf(this.selectedState) >= 0;
            let statesAgency = GlobalLists.localOnlyStatesAgency.indexOf(this.selectedState) >= 0;

            if (statesAgent && statesAgency) {
                this.stateMessage = "Record Only State";
            }
            else if (statesAgency) {
                this.stateMessage = "Record Only for Agencies";
            }
            else if (statesAgent) {
                this.stateMessage = "Record Only for Agents";
            }

            this.filterAllowedLists();
        }
    }

    checkAssociation(): void {
        if (this.activeCarrier && this.activeBU.bizUnitCarriers.find(b => b.carrier.id === this.activeCarrier.id)) {
            this.activeBUIsAssoctiatedWithActiveCarrier = true;
        }
        else {
            this.activeBUIsAssoctiatedWithActiveCarrier = false;
        }
    }

    carrierChanged(): void {
        this.checkAssociation();
        if (this.activeCarrier && this.activeBUIsAssoctiatedWithActiveCarrier) {
            console.log(this.activeCarrier);
            localStorage.setItem('activeCarrier', this.activeCarrier.id.toString());
            if (this.activeCarrier.allowedLicenseTypes === null) {
                forkJoin(
                    this.dataService.getCarrierAllowedRegedLicenseTypes(this.activeCarrier.id),
                    this.dataService.getCarrierAllowedRegedLoas(this.activeCarrier.id)
                )
                    .subscribe(([ltpData, loaData]) => {
                        if (ltpData && ltpData.status === 0 && ltpData.dataList) {
                            this.activeCarrier.allowedLicenseTypes = ltpData.dataList.map(x => new CarrierRegedLicenseTypeDTO(x));
                        }
                        else {
                            this.activeCarrier.allowedLicenseTypes = [];
                        }

                        if (loaData && loaData.status === 0 && loaData.dataList) {
                            this.activeCarrier.allowedLoas = loaData.dataList.map(x => new CarrierRegedLoaDTO(x));
                        }
                        else {
                            this.activeCarrier.allowedLoas = [];
                        }

                        this.stateChanged();
                    });
            }
            else {
                this.stateChanged();
            }

            if (!this.activeCarrier.address) {
                this.adminService.getCarrierDetails(this.activeCarrier.id).subscribe(resData => {
                    if (resData.status === 0 && resData.item) {
                        this.userService.updateCarrierDetails(resData.item);
                    }
                })
            }
        }
    }


    updateAddress(): void {
        if (this.activeCarrier.address.line1 && this.activeCarrier.address.city && this.activeCarrier.address.state && this.activeCarrier.address.zip && this.activeCarrier.address.phone) {
            this.carrierAddressForm.form.markAsPristine();
            this.adminService.updateCarrierAddress(this.activeCarrier).subscribe(resData => {
                if (resData.status !== 0) {
                    this.carrierAddressForm.form.markAsDirty();
                }

                InputStateColorCode(document.getElementById('carrier-address'), resData);
            })
        }
        else {
            this.modal.message("Address, City, State, Zip, and Phone are required!");
        }
    }

    updateContact(): void {
        if (this.carrierStateDetails.contactName && this.carrierStateDetails.contactTitle && this.carrierStateDetails.contactPhone) {
            this.carrierStateContactForm.form.markAsPristine();
            this.adminService.updateCarrierDetails(this.carrierStateDetails).subscribe(resData => {
                if (resData.status !== 0) {
                    this.carrierStateContactForm.form.markAsDirty();
                }

                InputStateColorCode(document.getElementById('carrier-state-contact'), resData);
            });
        }
    }

    compareLtpLoaFn(c1: RegedLicenseType | RegedLOA, c2: RegedLicenseType | RegedLOA): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    addLtpAllowed(): void {
        const newLtp = new CarrierRegedLicenseTypeDTO({ carrierId: this.activeCarrier.id });
        //newLtp.isEditing = true;
        newLtp.regedLicenseType.stateCode = this.selectedState;
        this.activeCarrier.allowedLicenseTypes.push(newLtp);
        this.editLTP(newLtp);
        this.filterAllowedLists();
    }

    addLoaAllowed(): void {
        const newLoa = new CarrierRegedLoaDTO({ carrierId: this.activeCarrier.id });
        //newLoa.isEditing = true;
        newLoa.regedLOA.stateCode = this.selectedState;
        this.activeCarrier.allowedLoas.push(newLoa);
        this.editLOA(newLoa);
        this.filterAllowedLists();
    }

    updateCarrierRegedLPT(ltpAllowed: CarrierRegedLicenseTypeDTO, target: any): void {
        if (!(ltpAllowed.regedLicenseType && ltpAllowed.producerType)) {
            alert("Please Select both License Type and Producer Type!");
            return;
        }
        if (this.filteredLTPs.filter(l => l.regedLicenseType.id === ltpAllowed.regedLicenseType.id).length > 1) {
            alert("Only one entry per License Type Allowed!\r\nPlease edit the existing " + ltpAllowed.regedLicenseType.licenseType + "row");
            return;
        }
        target.disabled = true;
        ltpAllowed.regedLicenseTypeId = ltpAllowed.regedLicenseType.id;
        this.adminService.updateCarrierAllowedLicenseType(ltpAllowed).subscribe(resData => {
            if (resData.status === 0) {
                ltpAllowed.isEditing = false;
                target.disabled = false;
            }
            else {
                ltpAllowed.isEditing = true;
            }
            InputStateColorCode(target.parentElement.parentElement, resData);
        });
    }
    editLTP(ltp: CarrierRegedLicenseTypeDTO): void {
        this.editHoldLTP = Object.assign({}, ltp);
        ltp.isEditing = true;
    }
    cancelCarrierRegedLTP(ltp: CarrierRegedLicenseTypeDTO): void {
        ltp.isEditing = false;
        if (this.editHoldLTP && this.editHoldLTP.regedLicenseTypeId > 0) {
            ltp.regedLicenseType = this.editHoldLTP.regedLicenseType;
            ltp.regedLicenseTypeId = this.editHoldLTP.regedLicenseTypeId;
            ltp.producerType = this.editHoldLTP.producerType;
        }
        else {
            const ltps = this.activeCarrier.allowedLicenseTypes.slice();
            const index = ltps.findIndex(f => f.regedLicenseTypeId === ltp.regedLicenseTypeId);
            ltps.splice(index, 1);
            this.activeCarrier.allowedLicenseTypes = ltps;
            this.filterAllowedLists();
        }
    }

    deleteCarrierRegedLTP(ltp: CarrierRegedLicenseTypeDTO, target: any): void {
        target.disabled = true;
        this.adminService.deleteCarrierAllowedLicenseType(ltp).subscribe(resData => {
            if (resData.status === 0) {
                const index = this.activeCarrier.allowedLicenseTypes.findIndex(f => f.regedLicenseTypeId === ltp.regedLicenseTypeId);
                if (index >= 0) {
                    this.activeCarrier.allowedLicenseTypes.splice(index, 1);
                    this.filterAllowedLists();
                }
            }
            else {
                target.disabled = false;
            }
        });
    }

    updateCarrierRegedLOA(loaAllowed: CarrierRegedLoaDTO, target: any): void {
        if (!(loaAllowed.regedLOA && loaAllowed.producerType)) {
            alert("Please Select both LOA and Producer Type!");
            return;
        }
        if (this.filteredLOAs.filter(l => l.regedLOA.id === loaAllowed.regedLOA.id).length > 1) {
            alert("Only one entry per LOA Allowed!\r\nPlease edit the existing " + loaAllowed.regedLOA.loa + "row");
            return;
        }
        target.disabled = true;
        loaAllowed.regedLOAId = loaAllowed.regedLOA.id;
        this.adminService.updateCarrierAllowedLOA(loaAllowed).subscribe(resData => {
            if (resData.status === 0) {
                loaAllowed.isEditing = false;
                target.disabled = false;
            }
            else {
                loaAllowed.isEditing = true;
            }
            InputStateColorCode(target.parentElement.parentElement, resData);
        });
    }
    editLOA(loa: CarrierRegedLoaDTO): void {
        this.editHoldLOA = Object.assign({}, loa);
        loa.isEditing = true;
    }
    cancelCarrierRegedLOA(loa: CarrierRegedLoaDTO): void {
        loa.isEditing = false;
        if (this.editHoldLOA && this.editHoldLOA.regedLOAId > 0) {
            loa.regedLOA = this.editHoldLOA.regedLOA;
            loa.regedLOAId = this.editHoldLOA.regedLOAId;
            loa.producerType = this.editHoldLOA.producerType;
        }
        else {
            const loas = this.activeCarrier.allowedLoas.slice();
            const index = loas.findIndex(f => f.regedLOAId === loa.regedLOAId);
            loas.splice(index, 1);
            this.activeCarrier.allowedLoas = loas;
            this.filterAllowedLists();
        }
    }

    deleteCarrierRegedLOA(loa: CarrierRegedLoaDTO, target: any): void {
        target.disabled = true;
        this.adminService.deleteCarrierAllowedLOA(loa).subscribe(resData => {
            if (resData.status === 0) {
                const index = this.activeCarrier.allowedLoas.findIndex(f => f.regedLOAId === loa.regedLOAId);
                if (index >= 0) {
                    this.activeCarrier.allowedLoas.splice(index, 1);
                    this.filterAllowedLists();
                }
            }
            else {
                target.disabled = false;
            }
        });
    }
}
