
<ng-container *ngIf="loading">
    <div class="loading-spinner"></div>
</ng-container>
<div *ngIf="editingSSN">
    <div class="pv-modal">
        <div class="pv-modal-body">
            <div>
                <a class="button warning-btn float-right" (click)="editingSSN = false;">Cancel</a>
                <h3 style="margin-bottom: 25px;">Update SSN</h3>
                <form #producerSSN="ngForm">
                    <mat-form-field class="full-width">
                        <mat-label>New SSN</mat-label>
                        <input name="taxId" matInput type="text" (blur)="showMask = true" (focus)="showMask = false" required (ngModelChange)="newSSN = $event" [ngModel]="newSSN | ssnMask:showMask" />
                    </mat-form-field>
                    <button class="button" (click)="updateSSN()" style="margin: 5px 0;">Update</button>
                </form>
            </div>
        </div>
    </div>
    <div class="pv-modal-background"></div>
</div>
<ng-container *ngIf="producerService.producer && (currentUserSecurityRole$ | async) as securityRole">

    <ng-container *ngIf="producerService.producerResultFromNipr">
        <div class="message center" style="margin-bottom: 10px;">
            <h4>Data below has been returned directly from NIPR and is not present in our local database!</h4>
        </div>
    </ng-container>
    <ng-container *ngIf="producerService.producer.trackedInNIPR === false">
        <div class="alert" style="margin-bottom: 25px;">
            <h4>Producer has been removed from NIPR Alerts. Data displayed for Producer is not current.<br />Select "Add Producer" to pull the most recent PDB information and add the Producer to NIPR Alerts</h4>
        </div>
    </ng-container>
    <div id="producer-controls">
        <h3 class="inline middle">Producer Profile</h3>
        <div id="producer-options">
            <button *ngIf="!producerService.producerResultFromNipr && producerService.producer.id != 0" class="button" [routerLink]="['/ProducerPdb/' + producerService.producer.id]">View PDB</button>
            <button *ngIf="canReSeed && producerService.producer.trackedInNIPR && !producerService.producerResultFromNipr" class="button elevated-btn" title="To fix occasional missing data issues" (click)="reseedProducer()">Refresh PDB</button>
            <button *ngIf="!producerService.producerResultFromNipr && producerService.producer.id != 0" class="button elevated-btn" [ngClass]="{'disabled': !(securityRole.onboard || securityRole.applicationManager || securityRole.owner)}" [disabled]="!(securityRole.onboard || securityRole.applicationManager || securityRole.owner)" [routerLink]="['/OnboardProducer/' + producerService.producer.id]" title="">Onboard Producer</button>
            <button *ngIf="!producerService.producerResultFromNipr && producerService.producer.id != 0" class="button elevated-btn" [ngClass]="{'disabled': !(securityRole.onboard || securityRole.applicationManager || securityRole.owner) }" [disabled]="!(securityRole.onboard || securityRole.applicationManager || securityRole.owner)" [routerLink]="['/AppointProducer/' + producerService.producer.id]" title="">Manage Appointments</button>
            <button *ngIf="producerService.producerResultFromNipr || producerService.producer.trackedInNIPR === false" class="button elevated-btn" [ngClass]="{'disabled': !securityRole.seed}" [disabled]="!securityRole.seed" (click)="seedNpn()" title="{{securityRole.producerSeedMessage}}">Add Producer</button>
            <button *ngIf="producerService.producer.trackedInNIPR && (securityRole.owner || securityRole.applicationManager)" class="button warning-btn" (click)="untrackProducer()" title="Remove the Producer from NIPR tracking - Will no longer keep data updated">Remove From NIPR Tracking</button>
        </div>
    </div>

    <div id="producer-info">
        <div id="profile-details">
            <div>
                <span class="label">{{producerService.producer.type}}</span>
                <span class="proper-name">{{producerService.producer.fullName}}</span>
            </div>
            <div>
                <span class="label">NPN</span>
                <span>{{producerService.producer.npn}}</span>
            </div>
            <!--<div>
                <span class="label">Type</span>
                <span class="proper-name">{{producerService.producer.type}}</span>
            </div>-->
            <div class="nowrap">
                <span class="label">E-mail</span>
                <span><a class="external-link" href="mailto:{{producerService.producer.contact.email}}" title="{{producerService.producer.contact.email}}">{{producerService.producer.contact.email}}</a></span>
            </div>

            <div *ngIf="producerService.producer.type == 'Agent'">
                <span class="label">SSN</span>
                <span class="ssn">{{producerService.producer.ssn | ssnMask:true}}</span>
                <a *ngIf="producerService.producer.ssn && (securityRole.owner || securityRole.applicationManager || securityRole.onboard)" class="delete-btn" title="Clear the SSN and enter a new one" (click)="editingSSN = true;" style="margin-left: 10px;"></a>
            </div>

            <div *ngIf="producerService.producer.type == 'Agency'">
                <span class="label">FEIN</span>
                <span>{{producerService.producer.fein}}</span>
            </div>
            <span class="label" *ngIf="producerService.producer.type == 'Agent'">
                <label style="display: inline-block; width: 150px;">
                    <span style="margin-right: 20px;">Employee</span>
                    <mat-checkbox name="company-employee-toggle" [disabled]="isEmpLock()" [(ngModel)]="isEmployee" (change)="isEmployeeToggled($event)"></mat-checkbox>
                </label>
            </span>
        </div>
        <div id="profile-personal">
            <span class="as-of-date" *ngIf="producerService.producer.contact.editTime" title="Data Updated on {{producerService.producer.contact.editTime | date:'mediumDate'}}"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
            <div class="table-cells nowrap">
                <span class="label">Address</span>
                <span><span class="proper-name">{{producerService.producer.contact.businessAddress.oneLineAddress}};</span>&nbsp;<span class="proper-name">{{producerService.producer.contact.businessAddress.city}}</span>, {{producerService.producer.contact.businessAddress.state}} {{producerService.producer.contact.businessAddress.zip}}</span>
            </div>
            <div class="table-cells nowrap" *ngIf="producerService.producer.contact.mailingAddress.id">
                <!--&& producerService.producer.contact.mailingAddress.id != producerService.producer.contact.businessAddress.id-->
                <span class="label">Mailing</span>
                <span><span class="proper-name">{{producerService.producer.contact.mailingAddress.oneLineAddress}};</span>&nbsp;<span class="proper-name">{{producerService.producer.contact.mailingAddress.city}}</span>, {{producerService.producer.contact.mailingAddress.state}} {{producerService.producer.contact.mailingAddress.zip}}</span>
            </div>
            <div class="table-cells nowrap" *ngIf="producerService.producer.contact.residentialAddress.id">
                <!--&& producerService.producer.contact.residentialAddress.id != producerService.producer.contact.mailingAddress.id-->
                <span class="label">Residential</span>
                <span><span class="proper-name">{{producerService.producer.contact.residentialAddress.oneLineAddress}}, </span>&nbsp;<span class="proper-name">{{producerService.producer.contact.residentialAddress.city}}</span>, {{producerService.producer.contact.residentialAddress.state}} {{producerService.producer.contact.residentialAddress.zip}}</span>
            </div>
            <div id="phone-numbers">
                <ng-container *ngIf="producerService.producer.contact.bizPhone">
                    <span class="label">Phone</span>
                    <span style="margin-right: 40px;">{{producerService.producer.contact.bizPhone}}</span>
                </ng-container>
                <ng-container *ngIf="producerService.producer.contact.cellPhone">
                    <span class="label">Cell</span>
                    <span style="margin-right: 40px;">{{producerService.producer.contact.cellPhone}}</span>
                </ng-container>
                <ng-container *ngIf="producerService.producer.contact.otherPhone">
                    <span class="label" style="width: 60px;">Other</span>
                    <span>{{producerService.producer.contact.otherPhone}}</span>
                </ng-container>
            </div>
            <div>
                <ng-container *ngIf="producerService.producer.contact.fax">
                    <span class="label">Fax</span>
                    <span class="proper-name" style="margin-right: 80px;">{{producerService.producer.contact.fax}}</span>
                </ng-container>
                
            </div>
        </div>
    </div>

    <div id="producer-details">
        <nav class="sub-navbar">
            <ul>
                <li><span><a routerLinkActive="active" [routerLink]="['./Details']">Details</a></span></li>
                <li><span><a routerLinkActive="active" [routerLink]="['./LicenseInfo']">Licenses</a></span></li>
                <li><span><a routerLinkActive="active" [routerLink]="['./Appointments']">Appointments</a></span></li>
                <li><span><a routerLinkActive="active" [routerLink]="['./Regulatory']">Regulatory & Disciplinary</a></span></li>
                <li><span><a routerLinkActive="active" [routerLink]="['./Documents']">Documents</a></span></li>
                <li><span><a routerLinkActive="active" [routerLink]="['./Applications']">Applications</a></span></li>
            </ul>
        </nav>
        <router-outlet></router-outlet>
    </div>

</ng-container>
