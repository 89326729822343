import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../search.service';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { LiveNiprDataService } from '../../live-nipr-data.service';
import { Producer, SearchProducer } from '../../../shared/models/pob/producer';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'pv-by-fein',
    templateUrl: './by-fein.component.html',
    styleUrls: ['../../search.component.css']
})
export class ByFeinComponent implements OnInit {

    npnPassThrough: number;

    constructor(public searchService: SearchService, public userService: UserService, private producerService: ProducerDataService, private niprService: LiveNiprDataService, private modal: ModalService) { }

    ngOnInit() {
        this.searchService.searchInput = new SearchProducer({
            fein: null,
            stateCode: null,
            type: "Agency"
        });
        this.searchService.errorMessage = '';
        console.log('fein');
    }

    searchByFein() {
        this.producerService.clearProducerData();
        this.searchService.errorMessage = "";
        if (!this.searchService.searchInput.fein) {
            this.searchService.errorMessage = "Please enter an FEIN";
        }
        else {
            this.modal.loading(true);
            this.searchService.searchInput.fein = this.searchService.searchInput.fein.trim();
            this.searchService.searchProducerByInput(this.searchService.searchInput).subscribe({
                next: resData => {
                    if (resData && resData.dataList && resData.dataList.length > 0) {
                        //call router link to the new NPN
                        this.producerService.producer = new Producer(resData.dataList[0]);
                        this.producerService.updateId(resData.dataList[0].id);
                        this.modal.loading(false);
                    }
                    else {
                        if (!this.searchService.searchInput.stateCode && this.userService.activeRoles.seed) {

                            this.searchService.searchProducerByFein(this.searchService.searchInput.fein).subscribe(resData => {
                                if (resData && resData.status === 0) {
                                    if (resData.dataList && resData.dataList.length === 1 && resData.dataList[0].npn < 0) {
                                        //call nipr service, producer doesn't have an NPN to seed
                                        this.callNiprService();
                                    }
                                    else {
                                        this.npnPassThrough = resData.dataList[0].npn;
                                    }
                                }
                                else {
                                    this.searchService.errorMessage = resData.message;
                                }
                                this.modal.loading(false);
                            });
                        }
                        else {
                            //call nipr service
                            this.callNiprService();
                        }
                    }
                }
            });
        }
    }

    private callNiprService() {
        if (!this.searchService.searchInput.stateCode) {
            this.searchService.errorMessage = "No results found in the database, Please enter a State to call NIPR";
            return;
        }
        this.modal.loading(true);
        this.niprService.getDataByFein(this.searchService.searchInput).subscribe({
            next: resData => {
                let msg = this.producerService.populateDataFromNipr(resData);
                if (msg) {
                    this.searchService.errorMessage = msg.toLowerCase();
                }
                this.modal.loading(false);
            }
        });
    }

    close(): void {
        this.npnPassThrough = null;
    }

    seedProducer(): void {
        this.modal.loading(true);
        this.niprService.submitForSeeding(this.npnPassThrough).subscribe({
            next: resData => {
                if (resData && resData.status == 0 && resData.dataList && resData.dataList.length > 0) {
                    window.location.href = window.location.protocol + "//" + window.location.host + "/Producer/" + resData.dataList[0];
                }
                else {
                    this.modal.message("NPN Failed to import" + (resData ? ": " + resData.message : ''));
                }
            }
        });
    }
}
