
export enum RegedOrderStatus {
    NotAppointed,
    Appointed,
    Terminated,

    OrderSentToQueue,
    IPAInternalError,
    RegedOrderSubmited,
    UnknownRegedStatus,
    UnknownLicenseDetailId,
    RegedError,
    RegedValidationError,
    NiprValidationError,
    SentToNiprAndWaiting,
    CompletedAsNiprAccepted,
    CompletedAsNiprRejected,
    PaperSubmissionAccepted,

    Initiated,
    AttachedToApplication,




    Loading //only used in the UI, keep at the bottom to not interfere with the server side enum
}
