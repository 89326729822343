import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../search.service';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { LiveNiprDataService } from '../../live-nipr-data.service';
import { Producer, SearchProducer } from '../../../shared/models/pob/producer';
import { UserService } from '../../../services/user.service';
import { GetHttpErrorMessage } from '../../../shared/utils/error-handling';

@Component({
    selector: 'pv-by-npn',
    templateUrl: './by-npn.component.html',
    styleUrls: ['../../search.component.css']
})
export class ByNpnComponent implements OnInit {

    seedNpnDirectly = false;

    constructor(public searchService: SearchService, public userService: UserService, private producerService: ProducerDataService, private niprService: LiveNiprDataService, private modal: ModalService) { }

    ngOnInit() {
        this.searchService.searchInput = new SearchProducer({
            npn: null,
            stateCode: null,
            lastName: null,
            type: 'Agency'
        });
        this.searchService.errorMessage = '';
    }

    searchByNpn() {
        this.producerService.clearProducerData();
        this.searchService.errorMessage = "";
        if (!this.searchService.searchInput.npn) {
            this.searchService.errorMessage = "Please enter an NPN";
        }
        else {
            this.modal.loading(true);
            this.searchService.getProducerByNpn(this.searchService.searchInput.npn).subscribe({
                next: resData => {
                    if (resData.item == null) { //check status prop here probably
                        this.modal.loading(false);
                        if (this.searchService.searchInput.type == "Agency" && !this.searchService.searchInput.stateCode) {
                            //enter state to continue
                            this.searchService.errorMessage = "The NPN entered was not found in the MasterDB. Please enter the resident state of the producer.";
                            this.seedNpnDirectly = true;
                        }
                        else if (this.searchService.searchInput.type == "Agent" && (!this.searchService.searchInput.stateCode || !this.searchService.searchInput.lastName)) {
                            //enter state AND last name to continue
                            this.searchService.errorMessage = "The NPN entered was not found in the MasterDB. Please enter the resident state and last name of the producer.";
                            this.seedNpnDirectly = true;
                        }
                        else {
                            //call nipr service
                            this.callNiprService();
                        }
                    }
                    else {
                        //call router link to the new NPN
                        this.producerService.producer = new Producer(resData.item);
                        this.producerService.updateId(resData.item.id);
                        this.modal.loading(false);
                    }
                },
                error: err => {
                    this.modal.message(GetHttpErrorMessage(err));
                }
            });
        }
    }

    private callNiprService() {
        this.modal.loading(true);
        this.niprService.getDataByNpn(this.searchService.searchInput).subscribe({
            next: resData => {
                let msg = this.producerService.populateDataFromNipr(resData);
                if (msg) {
                    this.searchService.errorMessage = msg.toLowerCase();
                }
                this.modal.loading(false);
            }
        });
    }

    close(): void {
        this.seedNpnDirectly = false;
    }

    seedProducer(): void {
        this.modal.loading(true);
        this.niprService.submitForSeeding(this.searchService.searchInput.npn).subscribe({
            next: resData => {
                if (resData && resData.status == 0 && resData.dataList && resData.dataList.length > 0) {
                    window.location.href = window.location.protocol + "//" + window.location.host + "/Producer/" + resData.dataList[0];
                }
                else {
                    this.modal.message("NPN Failed to import" + (resData ? ": " + resData.message : ''));
                }
            }
        });
    }
}
