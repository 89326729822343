import { Component, OnInit, Input } from '@angular/core';
import { ApplicationDocument } from '../models/document';
import { ModalService } from '../../modal/modal.service';
import { FileService } from '../../services/file.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'pv-app-documents',
    templateUrl: './app-documents.component.html',
    styleUrls: ['./app-documents.component.css']
})
export class ApplicationDocumentsComponent implements OnInit {

    canViewDocuments: boolean = false;

    constructor(private userService: UserService, private fileService: FileService, private modal: ModalService) { }

    ngOnInit() {
        //console.log("userService.activeBusinessUnit 1222", this.userService.activeBusinessUnit);
        this.canViewDocuments = this.userService.activeRoles.documents;
    }

    @Input() documents: ApplicationDocument[];

    @Input() appId: string;

    @Input() bizUnitId: number = null;

    getDocumentLink(doc: ApplicationDocument) {
        if (doc.uri) {
            window.open(doc.uri, '_blank');
        }
        else {
            this.fileService.getDocumentLink(doc, this.appId, this.bizUnitId).subscribe(resData => {
                if (resData && resData.status == 0 && resData.dataList[0] != "about:blank") {
                    doc.uri = resData.dataList[0];
                    window.open(doc.uri, '_blank');
                }
            });
        }
    }
}
