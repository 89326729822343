<ng-container *ngIf="currentUserSecurityRole$ | async as securityRole">
    <h3>Add New Producer</h3>
    <div class="panel">
        <div class="panel-contain">
            <form (submit)="seedNpn()">
                <mat-form-field class="full-width">
                    <input name="npns" matInput type="text" [(ngModel)]="producerNpn" placeholder="Producer NPN(s), comma separated" title="Enter multiple using a comma separated list" />
                </mat-form-field>
                <button type="submit" class="button" [ngClass]="{'disabled': !securityRole.seed}" [disabled]="!securityRole.seed" title="{{securityRole.producerSeedMessage}}">Add Producer</button>
            </form>
            <ng-container *ngIf="results.length > 0">
                <div *ngFor="let res of results;" class="align-left {{res.class}}">
                    <p>{{res.npn}}: {{res.msg}}</p>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
