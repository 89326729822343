import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { License } from '../../../shared/models/pob/license';

@Component({
    selector: 'pv-license-information',
    templateUrl: './license-information.component.html',
    styleUrls: ['./license-information.component.css']
})
export class LicenseInformationComponent implements OnInit, OnDestroy  {

    private idSubscription;
    columns: string[] = ['licenseNumber', 'state', 'class', 'status', 'loaCode', 'termination', 'effectiveDate', 'expirationDate'];
    loading: boolean = false;
    dataSource;

    filterValues = {
        licenseNumber: '',
        status: '',
        state: '',
        loa: null,
        classInput: '',
        termination: ''
    };

    showFilter: boolean = true;
    stateList: string[] = [];
    lineOfAuthList: string[] = [];
    staticStatusList: string[] = ["[X]", "Active", "Inactive", "Cancelled"];

    licenseNumberFilter = new FormControl('');
    classFilter = new FormControl('');
    terminationFilter = new FormControl('');
    statusFilter = new FormControl('');
    stateFilter = new FormControl('');
    loaFilter = new FormControl<string|null>(null);

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(public producerService: ProducerDataService, private modal: ModalService) {
        if (producerService.producer) {
            if (!producerService.licenses) {
                this.getLicenses(producerService.producer.npn);
            }
            else {
                this.dataSource = new MatTableDataSource(this.producerService.licenses);
            }
        }
        else {
            this.idSubscription = producerService.id$.subscribe(x => {
                let npn = this.producerService.producer.npn;
                this.getLicenses(npn);
            });
        }
    }

    ngOnInit() {
        if (this.dataSource) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.tableFilter();

            this.fillLists(this.dataSource._data._value);
        }

        this.licenseNumberFilter.valueChanges
            .subscribe(
                license => {
                    this.filterValues.licenseNumber = license.toLowerCase();
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
        
        this.stateFilter.valueChanges
            .subscribe(
                state => {
                    if (state === "[X]") {
                        this.filterValues.state = "";
                        this.stateFilter.setValue("");
                    }
                    else {
                        this.filterValues.state = state;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.statusFilter.valueChanges
            .subscribe(
                status => {
                    if (status === "[X]") {
                        this.filterValues.status = "";
                        this.statusFilter.setValue("");
                    }
                    else {
                        this.filterValues.status = status;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
        );

        this.classFilter.valueChanges
            .subscribe(
                classInput => {
                    this.filterValues.classInput = classInput.toLowerCase();
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
        );

        this.terminationFilter.valueChanges
            .subscribe(
                termination => {
                    this.filterValues.termination = termination.toLowerCase();
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );

        this.loaFilter.valueChanges
            .subscribe(
                loa => {
                    if (loa === "[X]") {
                        this.filterValues.loa = null;
                        this.loaFilter.setValue(null);
                    }
                    else {
                        this.filterValues.loa = loa;
                    }
                    this.dataSource.filter = JSON.stringify(this.filterValues);
                }
            );
    }
    
    ngOnDestroy() {
        if (this.idSubscription) {
            this.idSubscription.unsubscribe();
        }
    }

    getLicenses(npn: number): void {
        if (npn) {
            this.loading = true;
            this.producerService.getLicensesByNpn(npn).subscribe({
                next: resData => {
                    if (resData && resData.status == 0 && resData.dataList) {
                        this.producerService.licenses = resData.dataList.map(x => new License(x));

                        this.fillLists(this.producerService.licenses);

                        this.dataSource = new MatTableDataSource(this.producerService.licenses);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.filterPredicate = this.tableFilter();
                    }
                    else {
                        console.log(resData.message);
                    }

                    this.loading = false;
                },
                error: err => {
                    this.loading = false;
                }
            });
        }
    }

    tableFilter(): (data: License, filter: string) => boolean {
        let filterFunction = function (data: License, filter: string): boolean {
            let searchTerms = JSON.parse(filter);
            
            return data.licenseNumber.indexOf(searchTerms.licenseNumber) !== -1
                && (searchTerms.classInput == '' || data.class.toLowerCase().indexOf(searchTerms.classInput) >= 0)
                && (searchTerms.termination == '' || data.terminationReason.toLowerCase().indexOf(searchTerms.termination) >= 0)
                && (searchTerms.state == '' || data.stateCode == searchTerms.state)
                && (searchTerms.status == '' || data.statusArray.indexOf(searchTerms.status) >= 0)
                && (searchTerms.loa == null || data.loaDescription.indexOf(searchTerms.loa) >= 0);
        }
        return filterFunction;
    }

    fillLists(data: License[]): void {
        this.stateList = data.map(item => item.stateCode).filter((value, index, self) => self.indexOf(value) === index);
        this.stateList.sort();
        this.stateList.unshift("[X]");//clear filter val

        let loas = [];
        data.forEach(item => item.details.forEach(x => loas.push(x.description)));
        this.lineOfAuthList = loas.filter((value, index, self) => self.indexOf(value) === index);
        this.lineOfAuthList.sort();
        this.lineOfAuthList.unshift("[X]");
    }
}
