import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { BizUnit } from '../../../shared/models/general';
import { BusinessUnitRoles } from '../../../shared/models/user';

@Component({
    selector: 'pv-biz-unit-multiselect',
    templateUrl: './biz-unit-multiselect.component.html',
    styleUrls: []
})
export class BizUnitMultiselectComponent implements OnInit {

    @Input() fullBuList: BusinessUnitRoles[];
    @Input() bizUnits: BizUnit[];

    @Input() fromShell = false;

    @Output() bizUnitsChange: EventEmitter<BizUnit[]> = new EventEmitter<BizUnit[]>();

    showBUList = false;
    list: BizUnit[];

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        if (this.fullBuList && this.fullBuList.length > 0) {
            this.list = this.fullBuList.filter(l => l.businessUnit.id !== this.userService.corporateBuId).map(l => new BizUnit(l.businessUnit));
        }

        if (this.fromShell) {
            this.showBUList = true;
        }
    }

    updateBuList() {
        if (this.showBUList) {
            this.bizUnits = this.list.filter(l => l.selected);
        }
        else {
            this.bizUnits = [];
    }

        this.bizUnitsChange.emit(this.bizUnits);
    }
}
