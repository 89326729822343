import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { Producer, SearchProducer, ProducerRelationship } from '../../../shared/models/pob/producer';
import { SearchService } from '../../search.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { BizUnitProducer, CheckboxOption } from '../../../shared/models/general';
import { InputStateColorCode } from '../../../shared/utils/ui-mod';
import { BigOrderLog } from '../../../shared/models/big/order-log';
import { forkJoin } from 'rxjs';
import { Address } from '../../../shared/models/pob/address';
import { LiveNiprDataService } from '../../live-nipr-data.service';

@Component({
    selector: 'pv-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {

    childBranches: Producer[];
    childAgents: Producer[];

    addingParentLink = false;
    addingChildLink = false;

    searchInput: SearchProducer;
    searchResults: Producer[];
    selectedProducer: Producer;
    isPrimaryAgent = false;

    loading: boolean;
    searchLoading = false;

    buAssociatedWithProducer = false;

    canRunBackgroundCheck = false;
    producerHasResStateReq = false;
    needSsn = false;
    inputSsn: string;
    showMask = true
    backgroundCheck: BigOrderLog;
    previousBackgroundChecks: BigOrderLog[];
    packageNames: CheckboxOption[];

    private npnSubscription;
    private npn;

    constructor(public producerService: ProducerDataService, public userService: UserService, private searchService: SearchService, private niprService: LiveNiprDataService, private router: Router, private modal: ModalService) {
        //this.canRunBackgroundCheck = (this.userService.activeRoles.onboard || this.userService.isApplicationManager || this.userService.isOwnerOrCorporate);
        if (!producerService.producerHierarchy) {
            if (producerService.producer && !this.producerService.producerResultFromNipr) {
                this.loadProducerData();
                this.npn = producerService.producer.npn;
            }
        }
        else {
            this.updateChildLists();
        }

        this.npnSubscription = producerService.id$.subscribe(newNpn => {
            if (this.producerService.producer && this.npn !== newNpn) {
                this.npn = newNpn;
                this.loadProducerData();
            }
        });
    }

    ngOnInit() {
        this.searchInput = new SearchProducer();
        this.canRunBackgroundCheck = this.producerService.producer.type === "Agent"
                         && (this.userService.activeRoles.onboard || this.userService.isApplicationManager || this.userService.isOwnerOrCorporate);

        //want to ensure this always gets run (producer loaded already or just now)
        if (this.canRunBackgroundCheck) {
            forkJoin(this.producerService.getBigBackgroundLogs(this.userService.activeBusinessUnit.id, this.producerService.producer.id),
                this.producerService.getBigPackages(this.userService.activeBusinessUnit.id))
                .subscribe(([logData, packData]) => {
                    if (logData && logData.status === 0) {
                        this.previousBackgroundChecks = logData.dataList.filter(x => x.bizUnitId === this.userService.activeBusinessUnit.id).map(x => new BigOrderLog(x)).filter(x => !x.isOutdated);
                        if (this.previousBackgroundChecks) {
                            this.backgroundCheck = this.previousBackgroundChecks.pop();
                        }
                    }
                    if (packData && packData.status === 0) {
                        this.packageNames = packData.dataList.map(x => new CheckboxOption(x));
                        if (this.packageNames.length === 1) {
                            this.packageNames[0].selected = true;
                        }
                    }
                });

            if (this.producerService.producer.contact.residentialAddress && this.producerService.producer.contact.residentialAddress.id) {
                this.producerHasResStateReq = true;
            }
        }

        console.log('details');
    }

    ngOnDestroy() {
        if (this.npnSubscription) {
            this.npnSubscription.unsubscribe();
        }
    }

    loadProducerData(): void {
        this.loading = true;
        if (!this.producerService.producer.ssn) {
            this.needSsn = true;
        }
        this.producerService.getProducerHierarchy().subscribe(resData => {
            if (resData && resData.status == 0) {
                this.producerService.producerHierarchy = new Producer(resData.item);
                this.updateChildLists();
            }
            this.loading = false;
        });

        const checkBu = this.producerService.producer.bizUnitProducers.find(b => b.bizUnitId === this.userService.activeBusinessUnit.id);
        if (checkBu) {
            this.buAssociatedWithProducer = true;
        }
    }

    associateBuWithProducer() {
        this.producerService.addBuProducerAssociation(this.userService.activeBusinessUnit.id, this.producerService.producer.id).subscribe(resData => {
            if (resData.status === 0) {
                const buProd = new BizUnitProducer();
                buProd.bizUnit = this.userService.activeBusinessUnit;
                buProd.bizUnitId = buProd.bizUnit.id;
                buProd.producerId = this.producerService.producer.id;
                buProd.externalProducerId = "";

                this.producerService.producer.bizUnitProducers.push(buProd);
                this.buAssociatedWithProducer = true;
            }
        });
    }

    disassociateBuWithProducer(bup: BizUnitProducer) {
        this.producerService.removeBuProducerAssociation(this.userService.activeBusinessUnit.id, this.producerService.producer.id).subscribe(resData => {
            if (resData.status === 0) {
                const bups = this.producerService.producer.bizUnitProducers.slice();
                const index = bups.indexOf(bup);
                bups.splice(index, 1);
                this.producerService.producer.bizUnitProducers = bups;
                this.buAssociatedWithProducer = false;
            }
        });
    }

    updateChildLists() {
        this.childBranches = this.producerService.producerHierarchy.childProducerList.filter(x => x.type == "Agency");
        this.childAgents = this.producerService.producerHierarchy.childProducerList.filter(x => x.type == "Agent");
    }

    get hasPrimaryAgent(): Producer {
        let childs = this.producerService.producerHierarchy.childProducerList;
        if (childs) {
            let primary = this.producerService.producerHierarchy.childProducerList.find(x => x.isPrimaryAgent);
            if (primary) {
                return primary;
            }
        }
        return null;
    }

    updateExternalId(bizUnitProducer: BizUnitProducer) {
        const el = document.getElementById('ext-prod-id-' + bizUnitProducer.bizUnitId);
        el.style.backgroundColor = '#ffe8c6';
        this.producerService.updateBizUnitProducerExternalId(bizUnitProducer).subscribe(resData => {
            if (resData && resData.status == 0 && resData.item) {
                //success
            }

            InputStateColorCode(el, resData);
        });
    }

    toggleParentLinking() {
        this.searchInput.type = "Agency";
        this.addingParentLink = !this.addingParentLink;
    }
    toggleChildLinking(type: string) {
        this.searchInput.type = type;
        this.addingChildLink = !this.addingChildLink;
    }

    closeSearch() {
        this.addingParentLink = false;
        this.addingChildLink = false;
        this.searchInput = new SearchProducer();
        this.searchResults = null;
        this.selectedProducer = null;
        this.isPrimaryAgent = false;
        this.searchLoading = false;
    }

    resetSearch() {
        this.selectedProducer = null;
        this.searchResults = null;
    }

    search() {
        this.searchResults = null;
        this.searchLoading = true;
        this.searchService.searchProducerByInput(this.searchInput).subscribe(resData => {
            if (resData) {
                if (resData.dataList) {
                    this.searchResults = resData.dataList.map(x => new Producer(x));
                }
            }
            this.searchLoading = false;
        });
    }

    linkProducer() {
        this.searchLoading = true;
        let input = new ProducerRelationship();
        if (this.addingChildLink) {
            input.parentProducerId = this.producerService.producer.id;
            input.childProducerId = this.selectedProducer.id;
            input.isPrimaryAgent = this.isPrimaryAgent;
        }
        else {
            input.parentProducerId = this.selectedProducer.id;
            input.childProducerId = this.producerService.producer.id;
        }

        if (input.childProducerId === input.parentProducerId) {
            this.modal.message("Parent Agency selected is the same as the Child Agency. Please choose another parent agency.");
            this.searchLoading = false;
            return;
        }

        this.producerService.addProducerRelationship(input).subscribe(res => {
            if (res) {
                if (this.isPrimaryAgent) {
                    if (this.hasPrimaryAgent) {
                        this.hasPrimaryAgent.isPrimaryAgent = false;
                    }
                    this.selectedProducer.isPrimaryAgent = true;
                }
                if (this.addingChildLink) {
                    let list = this.producerService.producerHierarchy.childProducerList.slice();
                    list.push(this.selectedProducer);
                    this.producerService.producerHierarchy.childProducerList = list;
                    this.updateChildLists();
                }
                else {
                    let list = this.producerService.producerHierarchy.parentProducerList.slice();
                    list.push(this.selectedProducer);
                    this.producerService.producerHierarchy.parentProducerList = list;
                }
            }
            this.closeSearch();
        });
    }

    removeRelationship(producer: Producer, collection: string): void {
        if (this.producerService.producer.npn && this.userService.isOwnerOrCorporate) {
            if (confirm("Are you sure you want to remove the relationship with the producer: " + producer.fullName + "?")) {
                let input = new ProducerRelationship();
                input.parentProducerId = this.producerService.producer.id;
                input.childProducerId = producer.id;
                this.producerService.removeProducerRelationship(input).subscribe(res => {
                    if (res) {
                        if (collection == "parent") {
                            let list = this.producerService.producerHierarchy.parentProducerList;
                            let index = list.indexOf(producer);
                            list.splice(index, 1);
                            this.producerService.producerHierarchy.parentProducerList = list;
                        }
                        else {
                            let list = this.producerService.producerHierarchy.childProducerList;
                            let index = list.indexOf(producer);
                            list.splice(index, 1);
                            this.producerService.producerHierarchy.childProducerList = list;
                            this.updateChildLists();
                        }
                    }
                });
            }
        }
        else {
            this.modal.message("Need permission to delete producer relationships!");
        }
    }

    eoExpirationUpdated(event) {
        const el = document.getElementById('eo-expiration');
        this.updateProducer(el);
    }

    getLikelyResidentialAddress(el: HTMLElement) {
        el.style.visibility = "hidden";
        this.modal.loading(true);
        this.producerService.getProducerMostRecentAddress(this.producerService.producer.id, "Residence").subscribe(resData => {
            if (resData && resData.item) {
                resData.item.id = null; //this was a PDB id, not POB
                const resAddress = new Address(resData.item);
                this.producerService.producer.contact.addresses.push(resAddress);
                this.producerService.producer.contact.residentialAddress = resAddress;
                this.modal.message("We found a likely Residential Address.<br /><br />Please Confirm and click Update Producer Address if it is correct!");
            }
            else {
                this.modal.message("We were unable to find any potential Residential Address for this Producer.")
            }
        });
    }

    updateProducer(el: HTMLElement): void {
        el.style.backgroundColor = '#ffe8c6';
        this.producerService.updateProducer(this.producerService.producer).subscribe(resData => {
            InputStateColorCode(el, resData);
        });
    }

    updateProducerContact(el: HTMLElement): void {
        this.modal.loading(true);
        el.style.visibility = "hidden";
        this.producerService.updateContact(this.producerService.producer.contact, this.producerService.producer.id).subscribe(resData => {
            //success
            if (resData && resData.status === 0) {
                this.producerHasResStateReq = true;
                this.producerService.producer.contact.residentialAddress.id = -1; //won't be saving again, just fool the address needed check
            }
            else {
                el.style.visibility = "visible";
            }
            this.modal.loading(false);
        },
            error => {
                el.style.visibility = "visible";
            });
    }

    updateSsn(): void {
        if (this.inputSsn) {
            const regex = /^\d{3}-?\d{2}-?\d{4}$/;
            const valid = regex.test(this.inputSsn);
            if (!valid) {
                alert('SSN format is incorrect');
                return;
            }
            this.modal.loading(true);
            this.producerService.producer.ssn = this.inputSsn;
            this.producerService.updateProducer(this.producerService.producer).subscribe(resData => {
                if (resData && resData.item) {
                    this.needSsn = false;
                }
                this.modal.loading(false);
            });
        }
    }

    sendBackgroundCheck(): void {
        const packages = this.packageNames.filter(x => x.selected === true).map(x => x.item);
        if (packages.length === 0) {
            alert("Please select at least one Background Check package!");
            return;
        }
        if (confirm("By sending this information to BIG, you have verified that we have signed consent from the Producer to perform a background check")) {
            this.producerService.submitBigBackgroundOrder(this.userService.activeBusinessUnit.id, this.producerService.producer.id, this.userService.getUserEmail, packages).subscribe(resData => {
                if (resData && resData.status === 0) {
                    this.backgroundCheck = new BigOrderLog(resData.item);
                }
            });
        }
    }

    //createProducer(): void {
    //    this.searchService.searchInput = this.searchInput;
    //    if (this.searchInput.type == "Agency") {
    //        this.searchService.searchInput.fein = this.producerService.producer.fein;
    //    }
    //    this.router.navigate(['./Management/CreateProducer']);
    //}

    
    redirect(id: number) {
        this.producerService.updateId(id);
        this.router.navigate(['/Producer', id]);
    }
}
