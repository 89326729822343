import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../services/rest.service';
import { IClientItemDTO, IClientListDTO } from '../shared/models/dto/common-result.dto';
import { Alert } from '../shared/models/dto/dashboard';
import { RegedCreateOrderLog, RegedCreateOrderLogInDetail } from '../shared/models/pob/appointing-license';
import { SearchProducer } from '../shared/models/pob/producer';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends RestService {

    getSystemAlerts(): Observable<IClientListDTO<Alert>> {
        let relativeUrl = 'POB/GetAlerts';
        return this.get<IClientListDTO<Alert>>(relativeUrl);
    }

    addNewAlert(input: Alert): Observable<IClientItemDTO<Alert>> {
        let relativeUrl = 'POB/AddOrUpdateAlert';

        return this.post<IClientItemDTO<Alert>>(relativeUrl, input);
    }

    deleteAlert(alertId: number): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/DeleteAlert/' + alertId;

        return this.delete<IClientItemDTO<number>>(relativeUrl);
    }

    getNiprAlertStatus(): Observable<IClientItemDTO<string>> {
        let relateiveUrl = 'Nipr/AlertStatus';
        return this.get<IClientItemDTO<string>>(relateiveUrl, false);
    }

    getApplicationsReadyReviewing(): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'POB/Application/ByBizUnit/ReadyOrReviewing';

        return this.get<IClientItemDTO<number>>(relativeUrl);
    }

    getRegedRejections(): Observable<IClientListDTO<RegedCreateOrderLogInDetail>> {
        let relativeUrl = 'PDB/GetBizUnitAppointmentRegedOrderRejections';
        return this.get<IClientListDTO<RegedCreateOrderLogInDetail>>(relativeUrl);
    }

    clearRegedAlert(order: RegedCreateOrderLogInDetail): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'POB/Reged/ClearBizUnitRegedOrderAlert';
        return this.post<IClientItemDTO<boolean>>(relativeUrl, order);
    }

    getRemovedProducers(): Observable<IClientListDTO<SearchProducer>> {
        let relativeUrl = 'PDB/RecentlyRemovedProducers';
        return this.get<IClientListDTO<SearchProducer>>(relativeUrl);
    }
}
