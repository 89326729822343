import { Component, Input, OnInit } from '@angular/core';
import { IClientItemDTO } from '../../shared/models/dto/common-result.dto';
import { Alert } from '../../shared/models/dto/dashboard';


@Component({
    selector: 'pv-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['../dashboard.component.css']
})
export class AlertsComponent implements OnInit {

    @Input() alerts: Alert[];

    constructor() { }

    ngOnInit(): void {
        
    }

}
