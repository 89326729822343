import { RegedOrderStatus } from '../enums/reged';


export function AppointmentStatusDisplayFromEnum(apptStatus: RegedOrderStatus | string, filingType: string): RegedOrderStatus | string {

    if (!apptStatus) {
        return "";
    }

    const status: RegedOrderStatus = (RegedOrderStatus as any)[apptStatus];
    if (status === RegedOrderStatus.IPAInternalError) {
        return "Error - Failed";
    }

    if (status === RegedOrderStatus.Loading) {
        return "Loading...";
    }

    if (filingType === "Termination") {
        switch (status) {
            case RegedOrderStatus.CompletedAsNiprAccepted: return "Termination Completed";
            case RegedOrderStatus.CompletedAsNiprRejected: return "Termination Rejected";
            case RegedOrderStatus.SentToNiprAndWaiting: return "Termination Pending - Sent to NIPR";
            case RegedOrderStatus.NiprValidationError: return "Termination Error - NIPR Validation";
            case RegedOrderStatus.RegedValidationError: return "Termination Error - Validation";
            case RegedOrderStatus.RegedError: return "Termination Error";
            default: return "Termination Pending";
        }
        
    }

    switch (status) {
        case RegedOrderStatus.Initiated: return "Submitted";
        case RegedOrderStatus.RegedOrderSubmited: return "Submitted";
        case RegedOrderStatus.OrderSentToQueue: return "Submitted";
        case RegedOrderStatus.SentToNiprAndWaiting: return "Pending - Sent to NIPR";
        case RegedOrderStatus.CompletedAsNiprAccepted: return "Completed";
        case RegedOrderStatus.PaperSubmissionAccepted: return "Submitted - Pending State Response";
        case RegedOrderStatus.CompletedAsNiprRejected: return "Rejected";
        case RegedOrderStatus.NiprValidationError: return "Error - NIPR Validation";
        case RegedOrderStatus.RegedValidationError: return "Error - Validation";
        case RegedOrderStatus.RegedError: return "Reged Error";
        default: return RegedOrderStatus[status];
    }
}

export function PhoneNumberFormat(value: string): string {
    if (value) {
        let newVal = value.replace(' ', '').replace(' ', '');//replace the first two spaces, leave the rest
        newVal = newVal.replace(/[()\-]/g, '');
        let len = newVal.length;
        if (len === 10 && value.endsWith(' ')) {
            newVal = newVal + ' '; //add back any final space (they're trying to add an extension)
        }

        let ext = "";
        if (len > 10) {
            ext = newVal.slice(-(len - 10));
            newVal = newVal.substr(0, 10).replace(/\D/g, '') + ext;
        }
        else {
            newVal = newVal.replace(/\D/g, '');
        }

        //len = newVal.length;
        if (len === 0) {
            newVal = '';
        } else if (len <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (len <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else if (len <= 10) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
        } else {
            let ext = newVal.slice(-(len - 10));
            newVal = newVal.slice(0, 3) + '-' + newVal.slice(3, 6) + '-' + newVal.slice(6, 10);
            newVal = newVal + " " + ext;
        }
        return newVal;
    }
    return '';
}
