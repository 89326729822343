<ng-container *ngIf="documents && canViewDocuments">
    <p *ngFor="let doc of documents">
        <ng-container *ngIf="canViewDocuments">
            <a (click)="getDocumentLink(doc)"><span title="{{doc.createdTime | date:'mediumDate'}}">{{doc.title}}</span></a>
        </ng-container>
        <ng-container *ngIf="!canViewDocuments">
            <span title="{{doc.createdTime | date:'mediumDate'}}">{{doc.title}}</span>
        </ng-container>
    </p>
    <p *ngIf="documents.length == 0">
        <span class="italics">None</span>
    </p>
</ng-container>
