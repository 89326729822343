import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApplicationDocument, DocuSignTemplate, DocumentTemplateUpload } from '../../shared/models/document';
import { FileService } from '../../services/file.service';
import { BusinessUnitRoles } from '../../shared/models/user';
import { ClonerService } from '../../services/clone.service';

@Component({
    selector: 'pv-onboarding-documents',
    templateUrl: './onboarding-documents.component.html',
    styleUrls: ['./onboarding-documents.component.css']
})
export class OnboardingDocumentsComponent implements OnInit, OnChanges {

    @Input() errorMessage: string;
    @Input() currentUserSecurityRole: BusinessUnitRoles;
    @Input() allDbDocumentTemplates: DocuSignTemplate[];

    @Output() updateDbDocuSignTemplateEmitter = new EventEmitter<DocuSignTemplate[]>();
    @Output() deleteDocuSignTemplateEmitter = new EventEmitter<DocuSignTemplate>();

    documents: DocuSignTemplate[];
    bizUnitId: number;
    username: string;

    constructor(private cloneService: ClonerService, private fileService: FileService) { }

    ngOnInit() {
        this.username = this.currentUserSecurityRole.userIdentifier;
        this.bizUnitId = this.currentUserSecurityRole.businessUnit.id;
    }

    ngOnChanges(changes: SimpleChanges): void {

        // get latest data from the store
        if (changes.allDbDocumentTemplates) {
            let incomingData = changes.allDbDocumentTemplates.currentValue as DocuSignTemplate[];
            this.documents = this.cloneService.deepClone<DocuSignTemplate[]>(incomingData);
            if (this.documents) {
                this.documents = this.documents.sort((x, y) => x.documentOrder > y.documentOrder ? 1 : -1);
            }
        }
    }

    getDocumentLink(docTemp: DocuSignTemplate) {
        if (docTemp.uri) {
            window.open(docTemp.uri, '_blank');
        }
        else {
            let doc = ApplicationDocument.FromDocuSignTemplate(docTemp, docTemp.editor);
            this.fileService.getDocumentLink(doc, '', this.bizUnitId).subscribe(resData => {
                if (resData && resData.status == 0 && resData.dataList[0] != "about:blank") {
                    doc.uri = resData.dataList[0];
                    window.open(doc.uri, '_blank');
                }
                else {
                    console.log(resData.message);
                }
            });
        }
    }

    documentsReordered(event: CdkDragDrop<DocuSignTemplate[]>) {
        if (event.previousIndex == event.currentIndex) {
            return;
        }
        this.reorderDocuments(event.previousIndex, event.currentIndex);

        this.updateDbDocuSignTemplateEmitter.next(this.documents);
    }

    reorderDocuments(prevIndex: number, currentIndex: number) {
        moveItemInArray(this.documents, prevIndex, currentIndex);
        this.documents.forEach((x, index) => x.documentOrder = index);
    }

    updateDocument(updatedDoc: DocuSignTemplate) {
        this.updateDbDocuSignTemplateEmitter.next([updatedDoc]);
    }

    removeDocumentTemplate(doc: DocuSignTemplate) {
        if (!confirm("Are you sure you want to DELETE this document? This CANNOT be undone!")) {
            return;
        }

        this.deleteDocuSignTemplateEmitter.next(doc);
    }
}
