import { Component, OnInit } from '@angular/core';
import { State } from '../state/app.state';
import { Store } from '@ngrx/store';
import { getSelectedSecurityRolesSelector } from '../user/state';
import { Observable } from 'rxjs';
import { UserRoles } from '../shared/models/user';

@Component({
    selector: 'pv-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    currentUserSecurityRole$: Observable<UserRoles>;

    //let AdminAuthGuard take care of security.
    //Yes, has refresh issue for now, will discuss and fix later.
    constructor(private store: Store<State>) {}

    ngOnInit() {
        this.currentUserSecurityRole$ = this.store.select(getSelectedSecurityRolesSelector);
        document.title = "IPA - Admin";
    }
}
