import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Carrier } from '../../shared/models/pob/carrier';

import { BusinessUnitRoles, User } from '../../shared/models/user';

//Security role state
export interface ISecurityRolesState {
    currentUserRoleId: number;
    currentUserRoles: BusinessUnitRoles[];
    buUsersRoles: BusinessUnitRoles[];
    allCarriers: Carrier[];
    currentBizUnitCarrierIds: number[];
    userPreferences: User;
    error: string;
}

// Selector functions
const getSecurityRolesFeatureState = createFeatureSelector<ISecurityRolesState>('securityRoles');


export const getCurrentUserSelectedSecurityRolesIdSelector = createSelector(
    getSecurityRolesFeatureState,
    state => state.currentUserRoleId
);

export const getCurrentBizUnitSelector = createSelector(
    getSecurityRolesFeatureState,
    getCurrentUserSelectedSecurityRolesIdSelector,
    (state, currentRoleId) => {
        let currentRole = state.currentUserRoles.find(x => x.id === currentRoleId);
        return currentRole ? currentRole.businessUnit : null;
    }
);

export const getCurrentBizUnitIdSelector = createSelector(
    getSecurityRolesFeatureState,
    getCurrentBizUnitSelector,
    (state, currentBizUnit) => currentBizUnit ? currentBizUnit.id : null
);


export const getAllBizUnitCarriersSelector = createSelector(
    getSecurityRolesFeatureState,
    state => state.allCarriers
);

export const getCurrentBizUnitCarrierIdsSelector = createSelector(
    getSecurityRolesFeatureState,
    state => state.currentBizUnitCarrierIds
);


export const getCurrentBizUnitCarriersSelector = createSelector(
    getSecurityRolesFeatureState,
    getAllBizUnitCarriersSelector,
    getCurrentBizUnitCarrierIdsSelector,
    (state, allCarriers, buCarrierIds) => {
        let retData: Carrier[] = null;
        if (state.currentBizUnitCarrierIds) {
            retData = allCarriers.filter(r => buCarrierIds.findIndex(x => x === r.id) > -1);
        }
        return retData;
    }
);


export const getSelectedSecurityRolesSelector = createSelector(
    getSecurityRolesFeatureState,
    getCurrentUserSelectedSecurityRolesIdSelector,
    (state, currentRoleId) => {
        let currentRole = state.currentUserRoles.find(x => x.id === currentRoleId);
        return currentRole ? currentRole.userRoles : null;
    }
);


export const getSelectedBizUnitSecurityRolesSelector = createSelector(
    getSecurityRolesFeatureState,
    getCurrentUserSelectedSecurityRolesIdSelector,
    (state, currentRoleId) => state.currentUserRoles.find(x => x.id === currentRoleId)
);


export const getCurrentUserAllBizUnitRolesSelector= createSelector(
    getSecurityRolesFeatureState,
    state => state.currentUserRoles
);

export const getBizUnitAllUserRolesSelector = createSelector(
    getSecurityRolesFeatureState,
    state => state.buUsersRoles
);

export const getCurrentUserPreferencesSelector = createSelector(
    getSecurityRolesFeatureState,
    state => state.userPreferences
);

export const getErrorSelector = createSelector(
    getSecurityRolesFeatureState,
    state => state.error
);
