import { Address } from './address';

export class Carrier {
    id: number;
    name: string;
    fein: string;
    admin: string;
    insurerDomicileState: string;
    naic: string;
    cocode: string;
    licenseNumber: string;

    address: Address;
    addressId: number;
    phone: string;

    //tracking in the UI if selected for the package
    selected: boolean;

    allowedLicenseTypes: CarrierRegedLicenseTypeDTO[] = null;
    allowedLoas: CarrierRegedLoaDTO[] = null;

    carrierDetailByState: CarrierDetail[];

    constructor(init?: Partial<Carrier>) {
        this.id = init.id;
        this.name = init.name;
        this.fein = init.fein;
        this.admin = init.admin;
        this.insurerDomicileState = init.insurerDomicileState;
        this.naic = init.naic;
        this.cocode = init.cocode;
        this.licenseNumber = init.licenseNumber;
        this.selected = init.selected !== undefined ? init.selected : false;
        this.allowedLicenseTypes = null;
        this.allowedLoas = null;

        this.phone = init.phone;
        //this.address = new Address({});
        this.addressId = init.addressId;

        this.carrierDetailByState = init.carrierDetailByState ? init.carrierDetailByState.map(x => new CarrierDetail(x)) : null;
    }
}

export class CarrierDetail {
    id: number;
    carrierId: number;

    authorizedSignerId: number;

    licenseNumber: string;
    licenseState: string;

    contactName: string;
    contactPhone: string;
    contactTitle: string;

    companyCode: string;

    //not really used, keep same as licenseState
    licenseTypeState: string;
    companyState: string;

    editor: string;
    editTime: Date;

    constructor(init?: Partial<CarrierDetail>) {
        this.id = init.id;
        this.carrierId = init.carrierId;
        this.authorizedSignerId = init.authorizedSignerId;
        this.licenseNumber = init.licenseNumber;
        this.licenseState = init.licenseState;
        this.contactName = init.contactName;
        this.contactPhone = init.contactPhone;
        this.contactTitle = init.contactTitle;
        this.licenseTypeState = init.licenseTypeState;
        this.companyState = init.companyState;
        this.companyCode = init.companyCode;

        this.editor = init.editor;
        this.editTime = init.editTime;
    }
}


export class RegedLicenseType {
    id: number;
    stateCode: string;
    licenseType: string;
    niprClassCode: number;
    defaultLOAText: string;
    isEditing: boolean;
    //producerType: string;

    constructor(init?: Partial<RegedLicenseType>) {
        this.id = init.id;
        this.stateCode = init.stateCode;
        this.licenseType = init.licenseType;
        this.niprClassCode = init.niprClassCode;
        this.defaultLOAText = init.defaultLOAText;
    }
}

export class RegedLOA {
    id: number;
    stateCode: string;
    loa: string;
    niprCode: number;
    niprCodeReturned: number;
    niprAppointmentCode: number[];
    //producerType: string;

    niprApptCodesCsv: string;
    isEditing: boolean;

    constructor(init?: Partial<RegedLOA>) {
        this.id = init.id;
        this.stateCode = init.stateCode;
        this.loa = init.loa;
        this.niprCode = init.niprCode;
        this.niprCodeReturned = init.niprCodeReturned;
        this.niprAppointmentCode = init.niprAppointmentCode;

        this.niprApptCodesCsv = init.niprAppointmentCode ? init.niprAppointmentCode.join(',') : "";
    }

    get(): RegedLOA {
        const strCodes = this.niprApptCodesCsv.trim();
        if (strCodes) {
            this.niprAppointmentCode = strCodes.split(',').map(c => +(c.trim()));
        }
        else {
            this.niprAppointmentCode = null;
        }
        this.niprCodeReturned = this.niprCode;
        return this;
    }
}

export class CarrierRegedLicenseTypeDTO {
    carrierId: number;
    regedLicenseTypeId: number;
    regedLicenseType: RegedLicenseType;
    producerType: string;
    editor: string;
    editTime: Date;

    isEditing = false;

    constructor(init?: Partial<CarrierRegedLicenseTypeDTO>) {
        this.carrierId = init.carrierId;
        this.regedLicenseTypeId = init.regedLicenseTypeId;
        this.regedLicenseType = init.regedLicenseType ? new RegedLicenseType(init.regedLicenseType) : new RegedLicenseType({});
        this.producerType = init.producerType;
        this.editor = init.editor;
        this.editTime = init.editTime;
    }
}

export class CarrierRegedLoaDTO {
    carrierId: number;
    regedLOAId: number;
    regedLOA: RegedLOA;
    producerType: string;
    editor: string;
    editTime: Date;

    isEditing = false;

    constructor(init?: Partial<CarrierRegedLoaDTO>) {
        this.carrierId = init.carrierId;
        this.regedLOAId = init.regedLOAId;
        this.regedLOA = init.regedLOA ? new RegedLOA(init.regedLOA) : new RegedLOA({});
        this.producerType = init.producerType;
        this.editor = init.editor;
        this.editTime = init.editTime;
    }
}


export class AuthorizedSigner {
    id: number;
    addressId: number;
    address: Address;
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    editor: string;
    editTime: Date;

    get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    };

    constructor(init?: Partial<AuthorizedSigner>) {
        this.id = init.id;
        this.addressId = init.addressId;
        this.address = init.address ? new Address(init.address) : new Address({});
        this.firstName = init.firstName;
        this.lastName = init.lastName;
        this.title = init.title;
        //this.fullName = init.fullName;
        this.email = init.email;
        this.editor = init.editor;
        this.editTime = init.editTime;
    }
}
