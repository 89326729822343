<div *ngIf="producerService.producer && (currentUserSecurityRole$ | async) as securityRole">
    <button type="button" class="button" [routerLink]="['/Producer/' + producerService.producer.id]" title="">Back to Producer Profile</button>

    <div style="margin-top: 10px;">
        <h2 class="inline">Onboard Producer</h2>
        <h2 *ngIf="userService.activeRoles" class="float-right alert-color" title="To change, modify the selection in the header above">Onboarding for Business Unit: {{userService.activeBusinessUnit.name}}</h2>
    </div>

    <ng-container *ngIf="securityRole.onboard || securityRole.applicationManager || securityRole.owner">


        <form *ngIf="dataLoaded" name="application" (submit)="submitApplication()">
            <div class="half-width inline top">
                <div>
                    <h3>Contact Information</h3>
                    <mat-form-field class="full-width">
                        <mat-label>First Name</mat-label>
                        <input name="firstName" matInput type="text" required [(ngModel)]="onboardApplication.login.firstName" />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>Last Name</mat-label>
                        <input name="lastName" matInput type="text" required [(ngModel)]="onboardApplication.login.lastName" />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>e-Mail</mat-label>
                        <input name="email" matInput type="text" required [(ngModel)]="onboardApplication.login.email" />
                    </mat-form-field>
                </div>
            </div>

            <div class="half-width inline top">
                <div>
                    <h3>Onboarding Package</h3>
                    <mat-label>Select Documents</mat-label>
                    <p class="full-width faux-mat-form-field" *ngFor="let doc of availableDocs">
                        <label>
                            <mat-checkbox [(ngModel)]="doc.selected" name="document-{{doc.id}}"></mat-checkbox>
                            {{doc.title}}
                        </label>
                    </p>
                </div>
            </div>

            <div class="half-width inline top">
                <div>
                    <h3>Application Details</h3>

                    <div class="full-width faux-mat-form-field">
                        <mat-label>Producer Type</mat-label>
                        <p class="proper-name">{{onboardApplication.producerType}}</p>
                    </div>

                    <div class="faux-mat-form-field" style="margin-top: -15px;">
                        <label *ngIf="onboardApplication.producerType == 'Agency'">
                            <mat-checkbox name="allow-add-agents" [disabled]="!onboardApplication.needAppointment" [(ngModel)]="onboardApplication.allowToAddAgent"></mat-checkbox>
                            Allow to Add Agents?
                        </label>
                    </div>

                    <div class="faux-mat-form-field" style="margin-top: 15px;">
                        <label>
                            <mat-checkbox name="allow-select-appt" [disabled]="!onboardApplication.needAppointment" [(ngModel)]="onboardApplication.allowToSelectAppointmentStates"></mat-checkbox>
                            Allow Selection of Appointment States
                        </label>
                    </div>
                </div>
            </div>
            <div class="half-width inline top">
                <div>
                    <h3>Select Carriers</h3>
                    <p class="full-width faux-mat-form-field" *ngFor="let carrier of localCopyOfBizUnitCarriers">
                        <label>
                            <mat-checkbox [(ngModel)]="carrier.selected" name="carrier-{{carrier.id}}"></mat-checkbox>
                            {{carrier.name}}
                        </label>
                    </p>
                </div>
            </div>
            <button type="submit" class="button float-right" style="margin-bottom: -20px;">Send Application Invitation</button>

            <div style="padding-left: 20px; margin-top: 15px;" *ngIf="!onboardApplication.allowToSelectAppointmentStates && !trackingOnly">
                <div *ngIf="appointments.length > 0" style="margin-bottom: 30px;">
                    <h3>Selected Appointments</h3>
                    <p *ngFor="let appt of appointments">
                        <span>{{appt.carrier.name}}</span> - <span class="resident-info" [ngClass]="(appt.residencyStatus == 'R') ? 'resident-state' : ''">{{appt.stateCode}} - <span>{{appt.licenseNumber}} - </span><span class="proper-name" style="display:inline;">{{appt.loaDescription}}</span></span>
                    </p>
                </div>

                <h3 style="margin-bottom: 10px;">Select Appointments</h3>
                <span class="italics" *ngIf="selectedCarriers.length === 0">Please select a Carrier...</span>
                <mat-form-field style="margin: 15px 50px 20px 0; width: 350px;" *ngIf="selectedCarriers.length > 0">
                    <mat-label>Appoint to Carrier</mat-label>
                    <mat-select name="selected-carrier" [(ngModel)]="selectedCarrier" (selectionChange)="carrierChanged()">
                        <mat-option *ngFor="let carrier of selectedCarriers" [value]="carrier">{{carrier.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            <ng-container *ngIf="selectedCarrier">
                <pv-new-appointment [licenses]="producerService.licenses"
                                    [carrier]="updatedCarrier"
                                    [carrierAppointments]="producerService.carrierAppointments"
                                    [pobAppointments]="producerService.pobAppointments"
                                    [producer]="producerService.producer"
                                    [buttonText]="'Add Selected to Application'"
                                    [onboarding]="true"
                                    (appointmentsSelected)="handleApptSelection($event)">
                </pv-new-appointment>
            </ng-container>

                <p *ngIf="producerService.producer.licenses.length == 0">
                    No Licenses Found
                </p>
            </div>

        </form>

    </ng-container>
    <ng-container *ngIf="!(securityRole.onboard || securityRole.applicationManager || securityRole.owner)">
        <h4>Access Denied due to Security Role settings, please contact your manager.</h4>
    </ng-container>
</div>

<ng-container *ngIf="!dataLoaded">
    <div class="loading-spinner"></div>
</ng-container>
