import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
    selector: 'pv-producer-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.css']
})
export class ProducerOnboardingComponent implements OnInit {

    constructor(public userService: UserService) { }

    ngOnInit() {
        document.title = "IPA - Producer Onboarding";
    }
}
