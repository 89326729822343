<div style="margin-bottom: 40px;">
    <span class="label">CE Requirements</span>
    <ng-container *ngIf="reqs">
        <div class="details-list" style="width: 100%;" *ngIf="reqs.length > 0">
            <mat-table [dataSource]="reqs" style="margin-bottom: 10px;">

                <ng-container matColumnDef="reqJurisdiction">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Jurisdiction</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req">
                        <ng-container *ngIf="req.isEditing">
                            <select name="reqJurisdictionSelect" [(ngModel)]="req.jurisdiction">
                                <option value="National">National</option>
                                <option value="State">State</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="!req.isEditing">
                            {{req.jurisdiction}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqState">
                    <mat-header-cell *matHeaderCellDef style="flex: .5;">
                        <span>State</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req" style="flex: .5;">
                        <ng-container *ngIf="req.jurisdiction === 'State'">
                            <ng-container *ngIf="req.isEditing">
                                <select name="reqStateSelect" [(ngModel)]="req.state">
                                    <option *ngFor="let state of states" value="{{state}}">{{state}}</option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="!req.isEditing">
                                {{req.state}}
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqRequirementType">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Requirement Type</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req">
                        <ng-container *ngIf="req.isEditing">
                            <select name="reqReqTypeSelect" [(ngModel)]="req.requirementType">
                                <option *ngFor="let key of reqTypeKeys" value="{{key}}">{{requirementTypes[key]}}</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="!req.isEditing">
                            {{requirementTypes[req.requirementType]}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqAssignedReviewDate">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Assigned Review Date</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req">
                        <ng-container *ngIf="req.isEditing">
                            <mat-form-field class="date-picker">
                                <mat-label></mat-label>
                                <input name="req-assigned-review-date" matInput [(ngModel)]="req.assignedReviewDate" [matDatepicker]="assignedReviewDatePicker" />
                                <mat-datepicker-toggle matSuffix [for]="assignedReviewDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #assignedReviewDatePicker></mat-datepicker>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!req.isEditing">
                            {{req.assignedReviewDate | date:'mediumDate'}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqRequiredHourQuantity">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Required Hour Quantity</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req">
                        <ng-container *ngIf="req.isEditing">
                            <input type="text" [(ngModel)]="req.requiredHourQuantity" />
                        </ng-container>
                        <ng-container *ngIf="!req.isEditing">
                            {{req.requiredHourQuantity}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqStatus">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Status</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req">
                        <ng-container *ngIf="req.isEditing">
                            <select name="reqStatusSelect" [(ngModel)]="req.status" (change)="statusUpdated(req)">
                                <option value="Pending">Pending</option>
                                <option value="Completed">Completed</option>
                                <option value="Incomplete">Incomplete</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="!req.isEditing">
                            {{req.status}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqStatusDate">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Status Date</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let req">{{req.statusDate | date:'mediumDate'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="reqAction">
                    <mat-header-cell *matHeaderCellDef style="flex: .85;">Action</mat-header-cell>
                    <mat-cell *matCellDef="let req" style="flex: .85;">
                        <ng-container *ngIf="req.isEditing">
                            <input type="button" class="button" value="Save" (click)="updateRequirement(req, $event.target)" />
                            <input type="button" class="button" value="Cancel" style="margin-left: 10px;" (click)="cancelEditReq(req)" />
                        </ng-container>
                        <ng-container *ngIf="!req.isEditing">
                            <input type="button" class="button" value="Edit" style="margin-right: 10px;" (click)="editReq(req)" />
                            <input type="button" class="button" value="Delete" (click)="deleteRequirement(req, $event.target)" />
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

            </mat-table>

        </div>

        <button class="button" (click)="addRequirement()">Add Requirement</button>
    </ng-container>
</div>
