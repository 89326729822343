<ng-container *ngIf="loading">
  <div class="loading-spinner"></div>
</ng-container>

<ng-container *ngIf="!loading && producerService.applications && producerService.applications.length == 0">
  <p class="italics center" style="margin-top: 50px;">No Applications Found</p>
</ng-container>

<ng-container *ngIf="producerService.applications">
  <div id="short-applications">
    <mat-table *ngIf="producerService.applications && producerService.applications.length > 0" [dataSource]="producerService.applications">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>Link</mat-header-cell>
        <mat-cell *matCellDef="let app"><a [routerLink]="['/ProducerOnboarding/Application/', app.displayID]">Application ID: {{app.displayID}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.displayStatus}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastEditor">
        <mat-header-cell *matHeaderCellDef>Last Editor</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.editor}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="editTime">
        <mat-header-cell *matHeaderCellDef>Last Edited On</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.editTime | date:'mediumDate'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="documents">
        <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
        <mat-cell *matCellDef="let app"><pv-app-documents [documents]="app.documents" [appId]="app.id"></pv-app-documents></mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>

  </div>
</ng-container>
