import { DocumentRequirementType } from '../enums/general';

export class ApplicationDocument {
    id: number;

    title: string;
    type: string | DocumentRequirementType;
    editor: string;
    originalCopy: string;
    updatedCopy: string;
    createdTime: Date;
    signedTime: Date;
    uri: string;

    constructor(init?: Partial<ApplicationDocument>) {
        Object.assign(this, init);
    }

    static FromDocuSignTemplate(obj: Partial<DocuSignTemplate>, username: string) {
        let doc: ApplicationDocument = new ApplicationDocument();
        doc.title = obj.title;
        doc.originalCopy = obj.fileName;
        doc.type = obj.requirement
        doc.editor = username;

        return doc;
    }

    static FromShortApplication(obj: Partial<ShortDocumentDTO>): ApplicationDocument {
        let doc: ApplicationDocument = new ApplicationDocument();
        doc.originalCopy = obj.fileName;
        doc.title = obj.fileName;
        doc.uri = obj.fileUri;

        return doc;
    }
}

export class DocumentUpload {
    bizUnitId: number;
    applicationID: string;
    files: DocumentFileBody[];
}

export class DocumentFileBody {
    document: ApplicationDocument;
    data: string;

    constructor(init?: Partial<DocumentFileBody>) {
        Object.assign(this, init);
    }
}

export class DocumentTemplateUpload {
    bizUnitId: number;
    files: DocumentTemplateFileBody[];
}

export class DocumentTemplateFileBody {
    document: DocuSignTemplate;
    data: string;

    constructor(init?: Partial<DocumentTemplateFileBody>) {
        Object.assign(this, init);
    }
}

export class ShortDocumentDTO {
    fileName: string;
    fileUri: string;
}

export class DocuSignTemplate {
    id: number;
    bizUnitId: number;
    //bizUnit: BizUnit;

    title: string;
    description: string;
    fileName: string;
    requirement: string | DocumentRequirementType;

    editor: string;
    editTime: Date;

    roleCCNames: string[];
    roleCCEmails: string[];

    documentOrder: number;
    selectByDefault: boolean;

    uri: string;

    //tracking in the UI if selected for the package
    selected: boolean;

    constructor(init?: Partial<DocuSignTemplate>) {
        Object.assign(this, init);

        this.selected = this.selectByDefault;
    }
}

export class ProducerDocument {
    id: number;

    producerId: number;
    bizUnitId: number;

    name: string;
    note: string;
    editor: string;
    editTime: Date;

    uri: string;

    constructor(init?: Partial<ProducerDocument>) {
        if (init) {
            this.id = init.id;
            this.producerId = init.producerId;
            this.bizUnitId = init.bizUnitId;
            this.name = init.name;
            this.note = init.note;
            this.editor = init.editor;
            this.editTime = init.editTime;
            this.uri = init.uri;
        }
    }
}

export class ProducerDocumentUpload {
    document: ProducerDocument;
    data: string;

    constructor(init?: Partial<ProducerDocumentUpload>) {
        Object.assign(this, init);
    }
}
