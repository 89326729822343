export enum ProducerType {
    Agency = 0,
    Agent = 1
}

export enum ApplicationProgress {
    None = "Application Sent",
    Opened = "Opened",
    ProducerInformation = "Contact Information Entered",
    StateLicense = "Application States Selected",
    AddAgents = "Agents Added",
    AddDocuments = "Ready for eSigning",
    ESignature = "DocuSign Process Started",
    Completed = "Application Completed"
}

export enum DocumentRequirementType {
    Optional = 0,
    All = 1,
    MustSign = 2,
    MustHave = 3,
    DocuSignSummary = 4, //informational
    UserUploaded = 5,
    InternalUserUploaded = 6,
}

//export enum FederalTaxClass {
//    CCorporation = "C-Corporation",
//    SCorporation = "S-Corporation",
//    Partnership = "Partnership",
//    TrustEstate = "Trust/Estate",
//    IndividualSole = "Individual/Sole",
//    ProprietorSinglememberLLC = "Proprietor/Single-member LLC"
//}

export enum PreferenceType {
    PreferredCarriers = "PreferredCarriers"
}

export enum PdbAppointmentDataSource {
    Local,
    Nipr
}

export enum CERequirementType {
    LifeAndHealth = "Life & Health",
    LongTermCare = "Long Term Care",
    PropertyAndCasualty = "Property & Casualty",
    Total = "Total"
}

export enum CECourseCategory {
    Life = "Life",
    Disability = "Disability",
    LongTermCare = "Long Term Care",
    PropertyAndCasualty = "Property & Casualty",
}
