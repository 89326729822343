
<ng-container *ngIf="(currentUserSecurityRole$ | async) as securityRole">
    <ng-container *ngIf="alerts">
        <pv-alerts [alerts]="alerts"></pv-alerts>
    </ng-container>

    <div class="inline half-width first-half-option">
        <div class="box {{appointments && appointments.length === 0 ? 'good-box' : 'alert-box'}}">
            <pv-appt-term-rejections [appointments]="appointments" [userRoles]="securityRole" (clearAlertIdEmitter)="clearRegedOrderAlert($any($event))"></pv-appt-term-rejections>
        </div>

        <pv-background-checks></pv-background-checks>
    </div>

    <div class="inline half-width second-half-option top">
        <div class="box {{applicationsCount === 0 ? 'good-box' : 'alert-box'}}">
            <pv-onboarding-ready-review [count]="applicationsCount"></pv-onboarding-ready-review>
        </div>

        <div class="box alert-box" *ngIf="removedProducers">
            <pv-removed-producers [producers]="removedProducers"></pv-removed-producers>
        </div>
    </div>
</ng-container>
