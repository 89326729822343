
<pv-modal id="external-links-overlay">

    <div id="links">
        <div class="link">
            <h4><a href="https://nipr.com/licensing-center/apply" target="_blank">Apply for a New License</a></h4>
            <span>Use this link to apply for a New Licnese</span>
        </div>

        <div class="link">
            <h4><a href="https://nipr.com/licensing-center/renew" target="_blank">Renew an Existing License</a></h4>
            <span>Use this link to apply for a New License</span>
        </div>

        <div class="link">
            <h4><a href="https://nipr.com/help/print-your-license" target="_blank">Print a License</a></h4>
            <span>Use this link to print a License</span>
        </div>

        <div class="link">
            <h4><a href="https://nipr.com/help/continuing-education-requirements" target="_blank">Look-up Continuing Education Requirements</a></h4>
            <span>Use this link to review continuing education requirements for a producer</span>
        </div>

        <div class="link">
            <h4><a href="https://nipr.com/licensing-center/state-requirements" target="_blank">State Requirements</a></h4>
            <span>Use this link to determine state requirements ex. Adding a line of business</span>
        </div>

        <div class="link">
            <h4><a href="https://nipr.com/products-and-services/nipr-gateway/appointment-renewals" target="_blank">Company Appointment and Renewal Invoices (NIPR)</a></h4>
            <span>Use this link to pay your renewal invoices for states w/ NIPR</span>
        </div>

        <!--<div class="link">
            <h4><a href="http://www.google.com" target="_blank">Company Appointment and Renewal Invoices (Sircon)</a></h4>
            <span>Use this link to pay your renewal invoices for states w/ Sircon</span>
        </div>-->

        <div class="link">
            <h4><a href="https://ext.xchange.reged.com/CTIACOM/default.aspx" target="_blank">Log into SRI Pro</a></h4>
            <span>Use this link to log into SRI Pro. NOTE: There is an annual fee associated with this. Please contact <a href="mailto:tcrawford@tmhcc.com">TCrawford&#64;tmhcc.com</a> to sign up</span>
        </div>

        <div class="link">
            <h4><a href="https://ext.xchange.reged.com/CTIACOM/default.aspx" target="_blank">RegEd Exchange Login</a></h4>
            <span>Use this link to Log into RegEd to process an Appointment - to be used in case IPA is down</span>
        </div>

        <div class="link">
            <h4><a href="https://nipr.com/products-and-services/nipr-gateway/appointment-renewals" target="_blank">Appointment Renewal Dates</a></h4>
            <span>Use this link to view the Appointment Renewal Dates</span>
        </div>

        <div class="link">
            <h4><a href="https://www.mybig.com" target="_blank">BIG</a></h4>
            <span>Use this link to access BIG</span>
        </div>
    </div>
</pv-modal>
