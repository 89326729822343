import { createAction, props } from '@ngrx/store';
import { Carrier } from '../../../shared/models/pob/carrier';
import { BusinessUnitRoles, User, UserPreference } from '../../../shared/models/user';

//for api call: getCurrentUserRoles()
export const getCurrentUserRolesSuccess = createAction(
    `[SecurityRoles API] Get current user roles success`,
    props<{ userRoles: BusinessUnitRoles[], currentRoleId: number }>()
);
export const getCurrentUserRolesFailure = createAction(
    `[SecurityRoles API] Get current user roles failure`,
    props<{ error: string }>()
);


//for api call: getAllCarriersList()
export const getAllCarriersListSuccess = createAction(
    `[SecurityRoles API] Get all available carriers success`,
    props<{ allCarriers: Carrier[] }>()
);
export const getAllCarriersListFailure = createAction(
    `[SecurityRoles API] Get all available carriers failure`,
    props<{ error: string }>()
);


//for api call: getCurrentUserPreferences()
export const getCurrentUserPreferencesSuccess = createAction(
    `[SecurityRoles API] Get current user preferences success`,
    props<{ userPreferences: User }>()
);
export const getCurrentUserPreferencesFailure = createAction(
    `[SecurityRoles API] Get current user preferences failure`,
    props<{ error: string }>()
);


//for api call: updateUserPreference()
export const updateUserPreferenceSuccess = createAction(
    `[SecurityRoles API] Update current user preferences success`,
    props<{ userPreference: UserPreference }>()
);
export const updateUserPreferenceFailure = createAction(
    `[SecurityRoles API] Update current user preferences failure`,
    props<{ error: string }>()
);


//for api call: getBizUnitCarrierIds()
export const getBizUnitCarrierIdsSuccess = createAction(
    `[SecurityRoles API] Get current biz unit linked carrier IDs success`,
    props<{ buCarrierIds: number[] }>()
);
export const getBizUnitCarrierIdsFailure = createAction(
    `[SecurityRoles API] Get current biz unit linked carrier IDs failure`,
    props<{ error: string }>()
);

//for api call: addBizUnitCarrier()
export const addBizUnitCarrierSuccess = createAction(
    `[SecurityRoles API] Link a carrier to biz unit success`,
    props<{ addedCarrierId: number }>()
);
export const addBizUnitCarrierFailure = createAction(
    `[SecurityRoles API] Link a carrier to biz unit failure`,
    props<{ error: string }>()
);

//for api call: deleteBizUnitCarrier()
export const deleteBizUnitCarrierSuccess = createAction(
    `[SecurityRoles API] Unlink a carrier to biz unit success`,
    props<{ deletedCarrierId: number }>()
);
export const deleteBizUnitCarrierFailure = createAction(
    `[SecurityRoles API] Unlink a carrier to biz unit failure`,
    props<{ error: string }>()
);


//for api call: getUserList()
export const getUserListSuccess = createAction(
    `[SecurityRoles API] Get a biz unit all user's security roles success`,
    props<{ userRoles: BusinessUnitRoles[] }>()
);
export const getUserListFailure = createAction(
    `[SecurityRoles API] Get a biz unit all user's security roles failure`,
    props<{ error: string }>()
);


//for api call: updateUserRoles() -- add a new user
export const addUserRolesSuccess = createAction(
    `[SecurityRoles API] Add a new user roles success`,
    props<{ addedUsersRoles: BusinessUnitRoles }>()
);
export const addUserRolesFailure = createAction(
    `[SecurityRoles API] Add a new user roles failure`,
    props<{ error: string }>()
);


//for api call: updateUserRoles() -- update existing user
export const updateUserRolesSuccess = createAction(
    `[SecurityRoles API] Update user roles success`,
    props<{ updatedUsersRoles: BusinessUnitRoles[] }>()
);
export const updateUserRolesFailure = createAction(
    `[SecurityRoles API] Update user roles failure`,
    props<{ error: string }>()
);



//for api call: deleteUserFromBU()
export const deleteUserFromBUSuccess = createAction(
    `[SecurityRoles API] Delete a user from BizUnit success`,
    props<{ deletedUserId: number }>()
);
export const deleteUserFromBUFailure = createAction(
    `[SecurityRoles API] Delete a user from BizUnit failure`,
    props<{ error: string }>()
);
