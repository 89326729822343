import { Injectable } from "@angular/core";
import { AdminDataService } from '../admin.data.service';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from '../../state/app.state';
import { AdminPageActions, AdminApiActions } from './actions';
import { withLatestFrom, switchMap, map, catchError, concatMap } from 'rxjs/operators';
import { getCurrentBizUnitIdSelector } from '../../user/state';
import { of } from 'rxjs';

@Injectable()
export class AdminEffects {

    constructor(private actions$: Actions, private store: Store<State>, private dataService: AdminDataService) { }

    getBizUnitVerbiage$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AdminPageActions.getBizUnitVerbiageAction),
                withLatestFrom(this.store.select(getCurrentBizUnitIdSelector)),
                switchMap(([action, selectedBizUnit]) => {
                    return this.dataService.getBizUnitVerbiage(selectedBizUnit);
                }),
                map(resData => {
                    return AdminApiActions.getBizUnitVerbiageSuccess({ buVerbiage: resData });
                }),
                catchError(err => of(AdminApiActions.getBizUnitVerbiageFailure({ error: err })))
            );
    });


    saveBizUnitVerbiage$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AdminPageActions.saveBizUnitVerbiageAction),
                concatMap(action => {
                    return this.dataService.saveBizUnitVerbiage(action.buVerbiage);
                }),
                map(resData => {
                    return AdminApiActions.saveBizUnitVerbiageSuccess({ buVerbiage: resData });
                }),
                catchError(err => of(AdminApiActions.saveBizUnitVerbiageFailure({ error: err })))
            );
    });


    getDocumentListsByBizUnit$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AdminPageActions.getDocumentListsByBizUnitAction),
                withLatestFrom(this.store.select(getCurrentBizUnitIdSelector)),
                switchMap(([action, selectedBizUnit]) => {
                    return this.dataService.getDocumentListsByBizUnit(selectedBizUnit);
                }),
                map(resData => {
                    return AdminApiActions.getDocumentListsByBizUnitSuccess({ buDocumentTemplates: resData });
                }),
                catchError(err => of(AdminApiActions.getDocumentListsByBizUnitFailure({ error: err })))
            );
    });


    updateDocumentTemplates$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AdminPageActions.updateDocumentTemplatesAction),
                concatMap(action => {
                    return this.dataService.updateDocumentTemplates(action.buDocumentTemplates);
                }),
                map(resData => {
                    return AdminApiActions.updateDocumentTemplatesSuccess({ buDocumentTemplates: resData });
                }),
                catchError(err => of(AdminApiActions.updateDocumentTemplatesFailure({ error: err })))
            );
    });


    uploadDocusignTemplateFile$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AdminPageActions.uploadDocusignTemplateFileAction),
                concatMap(action => {
                    return this.dataService.uploadDocusignTemplateFile(action.documentTemplate);
                }),
                map(resData => {
                    return AdminApiActions.uploadDocusignTemplateFileSuccess({ docusignTemplate: resData });
                }),
                catchError(err => of(AdminApiActions.uploadDocusignTemplateFileFailure({ error: err })))
            );
    });


    deleteDocusignTemplateFile$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(AdminPageActions.deleteDocusignTemplateFileAction),
                concatMap(action => {
                    return this.dataService.deleteDocusignTemplateFile(action.documentTemplate);
                }),
                map(resData => {
                    return AdminApiActions.deleteDocusignTemplateFileSuccess({ deletedID: resData });
                }),
                catchError(err => of(AdminApiActions.deleteDocusignTemplateFileFailure({ error: err })))
            );
    });

}
