import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';

import { SearchService } from './search.service';
import { ProducerDataService } from './producer-data.service';

import { Producer } from '../shared/models/pob/producer';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pv-biographic',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit, OnDestroy {
    
    errorMessage: string = '';
    liveNiprServerHeartBeat: string[] = [];

    pdbSearchResults: Producer[] = [];
    hasSearched: boolean = false;

    searchSubscribe: Subscription;
    idSubscribe: Subscription;

    constructor(public searchService: SearchService, private producerData: ProducerDataService, private router: Router) { }

    ngOnInit() {
        this.searchService.searchInput = null;
        this.producerData.clearProducerData();

        this.searchSubscribe = this.producerData.searchResults$.subscribe(x => this.populateSearchResults(x));
        this.idSubscribe = this.producerData.id$.subscribe(x => { if (!isNaN(x)) { this.navigateToProducer(x); } });
              
        document.title = "IPA - Producer Search";
    }

    ngOnDestroy() {
        if (this.searchSubscribe) {
            this.searchSubscribe.unsubscribe();
        }
        if (this.idSubscribe) {
            this.idSubscribe.unsubscribe();
        }
    }

    resetSearch() {
        this.hasSearched = false;
        this.pdbSearchResults = [];
    }

    populateSearchResults(resData: Producer[]): void {
        this.resetSearch();
        if (resData == null || resData.length == 0) {
            return;
        }

        this.pdbSearchResults = resData.map(x => new Producer(x));

        this.hasSearched = true;
    }

    navigateToProducer(npn: number) {
        let route = ['Producer/' + npn];
        let params = {};
        if (this.producerData.producerResultFromNipr) {
            route = ['Producer/FromNipr']
        }
        this.router.navigate(route, { queryParams: params });
    }
}
