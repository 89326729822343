import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RegedOrderStatus } from '../../../shared/enums/reged';
import { PobAppointment } from '../../../shared/models/pob/appointing-license';
import { ProducerDataService } from '../../producer-data.service';

@Component({
    selector: 'pv-in-progress-appt',
    templateUrl: './in-progress.component.html',
    styleUrls: ['./in-progress.component.css']
})
export class InProgressApptComponent implements OnInit {

    appointments: PobAppointment[] = [];
    showMessage: PobAppointment;
    dataSource;
    columns: string[] = ['carrierName', 'creator', 'createTime', 'licenseNumber', 'state', 'class', 'loaCode', 'status'];

    refreshing = 0;

    constructor(private producerService: ProducerDataService) { }

    ngOnInit(): void {
        if (this.producerService.pobAppointments) {
            //filter out appointments for carriers unconnected to this BU
            this.appointments = this.producerService.pobAppointments.filter(a => a.stateCode !== 'FC' && a.carrier && a.status !== RegedOrderStatus[RegedOrderStatus.AttachedToApplication]);

            const flCounty = this.producerService.pobAppointments.filter(a => a.stateCode === 'FC' && a.carrier && a.status !== RegedOrderStatus[RegedOrderStatus.AttachedToApplication]);
            const flCounties: PobAppointment[] = [];
            if (flCounty && flCounty.some(f => f.regedCreateOrderLog)) {
                flCounty.forEach(l => {
                    const fc = new PobAppointment(l);
                    fc.floridaCountyName = l.floridaCountyName;
                    fc.creator = l.creator;
                    fc.createTime = new Date(l.createTime);
                    fc.status = l.status;
                    fc.message = l.message;
                    flCounties.push(fc);
                });
            }

            this.appointments = this.appointments.concat(flCounties);

            this.appointments.sort((x, y) => (x.stateCode > y.stateCode) ? 1 : -1);

            this.refreshStatuses();
        }

        this.dataSource = new MatTableDataSource(this.appointments);
        console.log('prog');
    }

    refreshStatuses(): void {
        this.refreshing = 0;
        //Check for updated status on any Appointments that are "Sent to NIPR and Waiting" or Queued for sending to RegEd (or just initiated from the UI)
        this.appointments.forEach(a => {
            if (a.status === RegedOrderStatus[RegedOrderStatus.SentToNiprAndWaiting]
                || a.status === RegedOrderStatus[RegedOrderStatus.Initiated]
                || a.status === RegedOrderStatus[RegedOrderStatus.OrderSentToQueue]
                || a.status === RegedOrderStatus[RegedOrderStatus.RegedOrderSubmited]
                || a.status === RegedOrderStatus[RegedOrderStatus.UnknownRegedStatus]) {
                this.refreshing += 1;
                const prevStatus = a.status;
                this.producerService.getAppointmentStatus(a).subscribe(resData => {
                    if (resData && resData.status === 0 && resData.item) {
                        a.status = resData.item.status;
                        a.message = resData.item.statusDescription;
                    }
                    else {
                        a.status = prevStatus;
                    }
                    this.refreshing -= 1;
                });
                a.status = RegedOrderStatus[RegedOrderStatus.Loading];
            }
        });
    }

    getEmailMessageBody(): string {
        var msg = "Producer ID - " + this.showMessage.producerId;
        msg += "%0D%0AProducer NPN - " + this.producerService.producer.npn;
        if (this.showMessage.regedCreateOrderLog) {
            msg += "%0D%0ARegEd ID - " + this.showMessage.regedCreateOrderLog[0].id;
        }
        if (this.showMessage.licenseId) {
            msg += "%0D%0ALicense ID - " + this.showMessage.licenseId;
        }
        if (this.showMessage.stateCode) {
            msg += "%0D%0AState - " + this.showMessage.stateCode;
        }

        msg += "%0D%0A%0D%0AMessage - " + this.showMessage.message;
        return msg;
    }
}
