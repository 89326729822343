import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationDocumentsComponent } from '../../app-documents/app-documents.component';


@NgModule({
    declarations: [ApplicationDocumentsComponent],
    imports: [
        CommonModule
    ],
    exports: [
        ApplicationDocumentsComponent
    ]
})
export class ApplicationDocumentsModule { }
