export class Address {
    id: number;
    line1: string;
    line2: string;
    city = "";
    state = "";
    zip = "";
    isBusinessAddress = false;
    isMailingAddress = false;
    isResidentialAddress = false;

    //for carriers
    phone: string;

    editor: string;
    editTime: Date;
    dataSource: string;

    get oneLineAddress() {
        let display = '';

        if (this.line1) {
            display += this.line1;
        }
        if (this.line2) {
            display += "; " + this.line2;
        }
        return display.toLowerCase();
    }

    constructor(api?: Partial<Address>) {
        if (api) {
            this.id = api.id;
            this.line1 = api.line1 ? api.line1.toLocaleLowerCase() : null;
            this.line2 = api.line2 ? api.line2.toLocaleLowerCase() : null;
            this.city = api.city ? api.city.toLocaleLowerCase() : null;
            this.state = api.state;
            this.zip = api.zip;
            this.isBusinessAddress = api.isBusinessAddress;
            this.isMailingAddress = api.isMailingAddress;
            this.isResidentialAddress = api.isResidentialAddress;

            this.phone = api.phone;

            this.editor = api.editor;
            this.editTime = api.editTime;
        }
    }
}

