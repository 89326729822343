import { BizUnit, AuditLog, AppointmentState } from './general';
import { ApplicationDocument, ShortDocumentDTO } from './document';
import { AuditLogCategory, AuditLogStatus } from '../enums/audit-log';
import { Producer } from './pob/producer';
import { ApplicationProgress } from '../enums/general';
import { Carrier } from './pob/carrier';
import { PobAppointment } from './pob/appointing-license';

export class Application {
    id: string;
    displayID: number;
    loginId: number;

    progressStatus: string | ApplicationProgress;
    status: string | AuditLogStatus;

    editTime: Date;

    bizUnit: BizUnit;
    carriers: Carrier[];
    appointmentStates: AppointmentState[];
    appointments: PobAppointment[] = [];
    documents: ApplicationDocument[];
    logs: AuditLog[];

    producer: Producer;

    needAppointment = true;
    allowToAddAgent: boolean;
    allowToSelectAppointmentStates = true;

    isArchived: boolean;

    createdDate: Date;

    get createdBy(): string {
        const createdLog = this.logs.find(x => x.category === AuditLogCategory.Status && x.message === AuditLogStatus[AuditLogStatus.AppCreate]);
        if (createdLog) {
            this.createdDate = createdLog.editTime;
            return createdLog.editor;
        }
    }

    get licenseDisplay(): string {
        let license: AppointmentState;
        if (this.appointmentStates && this.appointmentStates.length > 0) {
            license = this.appointmentStates[0];
        }
        if (license) {
            return license.licenseId + ' / ' + license.stateCode;
        }
        return '';
    }

    get displayStatus() {
        switch (this.status) {
            case AuditLogStatus.AppCreate: {
                if (this.progressStatus === null) {
                    return "New";
                }
                else {
                    return this.progressStatus;
                }
            }
            case AuditLogStatus.AppReturn: return "Returned";
            case AuditLogStatus.AppReviewing: return "In Progress";
            case AuditLogStatus.AppTransmit: return "Transmitted To State";
            case AuditLogStatus.AppDecline: return "Declined";
            case AuditLogStatus.AppApprove: return "Approved";
            default: return this.status;
        }
    };

    constructor(api?: Partial<Application>) {
        this.id = api.id;
        this.displayID = api.displayID;
        this.loginId = api.loginId;
        this.progressStatus = ApplicationProgress[api.progressStatus];
        this.status = AuditLogStatus[api.status];
        this.editTime = api.editTime;
        this.bizUnit = new BizUnit(api.bizUnit);
        this.carriers = api.carriers ? api.carriers.map(x => new Carrier(x)) : null;
        this.appointmentStates = api.appointmentStates ? api.appointmentStates.map(x => new AppointmentState(x)) : [];
        this.documents = api.documents ? api.documents.map(x => new ApplicationDocument(x)) : null;
        this.logs = api.logs ? api.logs.map(x => new AuditLog(x)) : null;
        this.producer = new Producer(api.producer);

        this.allowToAddAgent = api.allowToAddAgent;
        this.allowToSelectAppointmentStates = api.allowToSelectAppointmentStates;
        this.needAppointment = api.needAppointment;
        this.isArchived = api.isArchived;
    }
}

export class ShortApplication {
    id: string;
    displayID: number;
    loginId: number;

    status: string | AuditLogStatus;

    editTime: Date;
    editor: string;

    bizUnit: BizUnit;

    applicationDocuments: ShortDocumentDTO[];
    documents: ApplicationDocument[];

    get displayStatus() {
        switch (this.status) {
            case AuditLogStatus.AppCreate: return "New / With Producer";
            case AuditLogStatus.AppReturn: return "Returned";
            case AuditLogStatus.AppReviewing: return "In Progress";
            case AuditLogStatus.AppTransmit: return "Transmitted To State";
            case AuditLogStatus.AppDecline: return "Declined";
            case AuditLogStatus.AppApprove: return "Approved";
            default: return this.status;
        }
    };

    constructor(api?: Partial<ShortApplication>) {
        this.id = api.id;
        this.displayID = api.displayID;
        this.loginId = api.loginId;
        this.status = AuditLogStatus[api.status];
        this.editTime = api.editTime;
        this.editor = api.editor;
        this.bizUnit = new BizUnit(api.bizUnit);
        this.documents = api.applicationDocuments ? api.applicationDocuments.map(x => ApplicationDocument.FromShortApplication(x)) : null;
    }
}

export class CreateApplication {

    producerType: string;

    //external user's contact info
    login: ExternalLogin;

    creator: string;

    bizUnitId: number;
    carrierIds: number[];
    //appointments: PobAppointment[];
    documents: ApplicationDocument[];
    logs: AuditLog[];

    producer: Producer;

    allowToSelectAppointmentStates = false;
    needAppointment = true;
    allowToAddAgent = false;

    constructor(init?: Partial<CreateApplication>) {
        Object.assign(this, init);
    }
}

export class ExternalLogin {
    id: number;
    firstName: string;
    lastName: string;
    email: string;

    constructor(init?: Partial<ExternalLogin>) {
        Object.assign(this, init);
    }
}
