import { Component, OnInit, Input } from '@angular/core';
import { AuditLog } from '../models/general';
import { AuditLogCategory } from '../enums/audit-log';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../services/user.service';


@Component({
    selector: 'pv-comments',
    templateUrl: './app-comments.component.html',
    styleUrls: ['./app-comments.component.css']
})
export class ApplicationCommentsComponent implements OnInit {

    private _comments = new BehaviorSubject<AuditLog[]>([]);
    public set comments(v: AuditLog[]) { this._comments.next(v); }
    public get comments(): AuditLog[] { return this._comments.value; }

    canComment: boolean = false;

    comment: AuditLog;

    constructor(private onboardService: OnboardingService, private userService: UserService) { }

    ngOnInit() {
        this.resetComment();

        this.comments = this.logs.filter(x => x.category == AuditLogCategory.Comment);
        this.comment.editor = this.userEmail;

        this.canComment = this.userService.activeRoles.comment;
    }

    @Input() logs: AuditLog[];

    @Input() appId: string;

    @Input() userEmail: string;

    addComment() {
        this.onboardService.addCommentLog(this.comment, this.appId).subscribe(resData => {
            if (resData && resData.status == 0) {
                this.comment.id = resData.item;
                let comments = this.comments.slice();
                comments.push(this.comment);
                this.comments = comments;
                this.resetComment();
            }
        });
    }

    resetComment() {
        this.comment = new AuditLog({ message: '', editor: this.userEmail, category: AuditLogCategory.Comment });
    }
}
