<div style="margin: 10px 0 20px;">
    <span class="float-right" title="The Business Unit relationship to a Producer must be maintained by users and is therefore not guaranteed to be correct. Note that IPA cannot automatically record a relationship between a Business Unit and a Producer when a Producer is appointed using a tool other than IPA.">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg>
    </span>
    <ng-container *ngIf="!fromShell">
        <div class="faux-mat-form-field">
            <label>
                <mat-checkbox name="bu-select-toggle" [(ngModel)]="showBUList" (change)="updateBuList()"></mat-checkbox>
                <span>Filter on Business Unit</span>
            </label>
        </div>
    </ng-container>

    <div *ngIf="showBUList" style="margin: 10px 0 0 20px;">
        <div *ngFor="let bu of list" class="form-list-item">
            <label>
                <mat-checkbox name="bu-{{bu.name}}" (change)="updateBuList()" [(ngModel)]="bu.selected"></mat-checkbox>
                <span>{{bu.name}}</span>
            </label>
        </div>
    </div>
</div>
