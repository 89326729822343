import { Component, Optional, Self, OnInit } from '@angular/core';
import { SearchService } from '../../search.service';
import { ModalService } from '../../../modal/modal.service';
import { ProducerDataService } from '../../producer-data.service';
import { NgControl } from '@angular/forms';
import { LiveNiprDataService } from '../../live-nipr-data.service';
import { Producer, SearchProducer } from '../../../shared/models/pob/producer';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'pv-by-name',
    templateUrl: './by-name.component.html',
    styleUrls: ['../../search.component.css']
})
export class ByNameComponent implements OnInit {

    constructor(public searchService: SearchService, public userService: UserService, private producerService: ProducerDataService, private niprService: LiveNiprDataService, private modal: ModalService, @Optional() @Self() public ngControl: NgControl) { }

    ngOnInit() {
        this.searchService.searchInput = new SearchProducer({
            stateCode: null,
            firstName: null,
            lastName: null,
            ssn: null,
            type: "Agent"
        });
        this.searchService.errorMessage = '';
    }

    searchByName() {
        this.producerService.clearProducerData();
        this.producerService.updateSearchResults([]);
        this.searchService.errorMessage = '';
        if (!this.searchService.searchInput.firstName &&
            !this.searchService.searchInput.lastName) {
            this.searchService.errorMessage = "Please enter a Name";
            return;
        }
        this.modal.loading(true);
        this.searchService.searchProducerByInput(this.searchService.searchInput).subscribe({
            next: resData => {
                if (resData && resData.status == 0 && resData.dataList && resData.dataList.length > 0) {
                    if (resData.dataList.length == 1 && this.checkForUniqueResultCombination()) {
                        this.producerService.producer = new Producer(resData.dataList[0]);
                        this.producerService.updateId(resData.dataList[0].id);
                    }
                    else {
                        this.producerService.updateSearchResults(resData.dataList);
                    }
                    this.modal.loading(false);
                }
                else if (resData.status < 0) {
                    this.searchService.errorMessage = resData.message;
                    this.modal.loading(false);
                }
                else {
                    //Call NIPR if we can
                    if (this.checkForRequiredNiprFields()) {
                        this.niprService.getDataBySSN4(this.searchService.searchInput).subscribe({
                            next: resData => {
                                let msg = this.producerService.populateDataFromNipr(resData);
                                if (msg) {
                                    this.searchService.errorMessage = msg.toLowerCase();
                                }
                                this.modal.loading(false);
                            }
                        });
                    }
                    else {
                        this.searchService.errorMessage = "No results found locally, please provide more search criteria to search NIPR";
                        this.modal.loading(false);
                    }
                }
            }
        });
    }

    checkForUniqueResultCombination() {
        return this.searchService.searchInput.firstName && this.searchService.searchInput.lastName && this.searchService.searchInput.stateCode;
    }

    checkForRequiredNiprFields() {
        return this.searchService.searchInput.firstName && this.searchService.searchInput.lastName && this.searchService.searchInput.stateCode && this.searchService.searchInput.ssn;
    }
}
