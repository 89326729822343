import { Component, OnInit } from '@angular/core';
import { RegedLicenseType, RegedLOA } from '../../shared/models/pob/carrier';
import { InputStateColorCode } from '../../shared/utils/ui-mod';
import { AdminDataService } from '../admin.data.service';

@Component({
    selector: 'pv-reged',
    templateUrl: './reged.component.html',
    styleUrls: ['./reged.component.css']
})
export class RegedComponent implements OnInit {

    selectedState: string;
    stateRegedLTPs: RegedLicenseType[];
    stateRegedLOAs: RegedLOA[];

    editHoldLOA: RegedLOA;
    editHoldLTP: RegedLicenseType;

    newId = -1;

    constructor(private adminService: AdminDataService) { }

    ngOnInit(): void {
        console.log('reged');
    }

    stateChanged(): void {
        if (this.selectedState) {
            this.adminService.getRegedLicenseTypes(this.selectedState).subscribe(resData => {
                if (resData.status === 0) {
                    this.stateRegedLTPs = resData.dataList.map(l => new RegedLicenseType(l)).sort((x, y) => {
                        if (x.licenseType > y.licenseType) return 1; else return -1;
                    });
                }
            });
            this.adminService.getRegedLOAs(this.selectedState).subscribe(resData => {
                if (resData.status === 0) {
                    this.stateRegedLOAs = resData.dataList.map(l => new RegedLOA(l)).sort((x, y) => {
                        if (x.loa > y.loa) return 1; else return -1;
                    });
                }
            });
        }
    }

    addLtpAllowed(): void {
        const newLtp = new RegedLicenseType({ stateCode: this.selectedState, id: this.newId-- });
        newLtp.isEditing = true;
        const ltps = this.stateRegedLTPs.slice();
        ltps.unshift(newLtp);
        this.stateRegedLTPs = ltps;
    }

    addLoaAllowed(): void {
        const newLoa = new RegedLOA({ stateCode: this.selectedState, id: this.newId-- });
        newLoa.isEditing = true;
        const loas = this.stateRegedLOAs.slice();
        loas.unshift(newLoa);
        this.stateRegedLOAs = loas;
    }

    updateRegedLPT(ltpAllowed: RegedLicenseType, target: HTMLElement): void {
        if (!(ltpAllowed && ltpAllowed.licenseType && ltpAllowed.niprClassCode)) {
            alert("Please Enter both License Type and Producer Type!");
            return;
        }
        if (isNaN(+ltpAllowed.niprClassCode)) {
            alert("Please enter only Numeric values for the NIPR License Code!");
            return;
        }
        if (this.stateRegedLTPs.filter(l => l.licenseType === ltpAllowed.licenseType).length > 1 || this.stateRegedLTPs.filter(l => l.stateCode === this.selectedState && l.niprClassCode === ltpAllowed.niprClassCode).length > 1) {
            alert("Only one entry per License Type Allowed!\r\nPlease edit the existing NIPR Code " + ltpAllowed.niprClassCode + "row");
            return;
        }
        ltpAllowed.isEditing = false;
        this.adminService.updateRegedLicenseType(ltpAllowed).subscribe(resData => {
            if (resData.status !== 0) {
                ltpAllowed.isEditing = true;
            }
            else {
                if (ltpAllowed.id < 0) {
                    ltpAllowed.id = resData.item.id;
                }
            }
            InputStateColorCode(target, resData);
        });
    }

    editLTP(ltp: RegedLicenseType): void {
        this.editHoldLTP = Object.assign({}, ltp);
        ltp.isEditing = true;
    }
    cancelEditLTP(ltp: RegedLicenseType): void {
        ltp.isEditing = false;
        if (ltp.id > 0) {
            ltp.licenseType = this.editHoldLTP.licenseType;
            ltp.niprClassCode = this.editHoldLTP.niprClassCode;
            ltp.defaultLOAText = this.editHoldLTP.defaultLOAText;
        }
        else {
            const ltps = this.stateRegedLTPs.slice();
            const index = ltps.findIndex(f => f.id === ltp.id);
            ltps.splice(index, 1);
            this.stateRegedLTPs = ltps;
        }

    }
    deleteRegedLTP(ltp: RegedLicenseType, target: any): void {
        if (confirm("Are you sure you want to DELETE this License Type?")) {
            target.disabled = true;
            this.adminService.deleteRegedLicenseType(ltp).subscribe(resData => {
                if (resData.status === 0) {
                    const ltps = this.stateRegedLTPs.slice();
                    const index = ltps.findIndex(f => f.id === ltp.id);
                    ltps.splice(index, 1);
                    this.stateRegedLTPs = ltps;
                }
                else {
                    target.disabled = false;
                }
            });
        }
    }

    updateRegedLOA(loaAllowed: RegedLOA, target: HTMLElement): void {
        if (!(loaAllowed && loaAllowed.loa && loaAllowed.niprCode)) {
            alert("Please Enter both LOA and NIPR Code!");
            return;
        }
        if (isNaN(+loaAllowed.niprCode)) {
            alert("Please enter only Numeric values for the NIPR LOA Code!");
            return;
        }

        const loaInput = loaAllowed.get();
        if (loaInput.niprAppointmentCode && loaInput.niprAppointmentCode.some(a => {
            if (isNaN(+a)) { return true; }
        })) {
            alert("Please enter only comma separated Numeric values for the NIPR Appointment Codes!");
            return;
        }
        if (this.stateRegedLOAs.filter(l => l.niprCode === loaAllowed.niprCode).length > 1) {
            alert("Only one entry per LOA Allowed!\r\nPlease edit the existing " + loaAllowed.loa + "row");
            return;
        }
        loaAllowed.isEditing = false;
        this.adminService.updateRegedLOA(loaInput).subscribe(resData => {
            if (resData.status !== 0) {
                loaAllowed.isEditing = true;
            }
            else {
                if (loaAllowed.id < 0) {
                    loaAllowed.id = resData.item.id;
                }
            }
            InputStateColorCode(target, resData);
        });
    }

    editLOA(loa: RegedLOA): void {
        this.editHoldLOA = Object.assign({}, loa);
        loa.isEditing = true;
    }
    cancelEditLOA(loa: RegedLOA): void {
        loa.isEditing = false;
        if (loa.id > 0) {
            loa.loa = this.editHoldLOA.loa;
            loa.niprCode = this.editHoldLOA.niprCode;
            loa.niprApptCodesCsv = this.editHoldLOA.niprApptCodesCsv;
        }
        else {
            const loas = this.stateRegedLOAs.slice();
            const index = loas.findIndex(f => f.id === loa.id);
            loas.splice(index, 1);
            this.stateRegedLOAs = loas;
        }
    }

    deleteRegedLOA(loa: RegedLOA, target: any): void {
        if (confirm("Are you sure you want to DELETE this LOA?")) {
            target.disabled = true;
            this.adminService.deleteRegedLOA(loa).subscribe(resData => {
                if (resData.status === 0) {
                    const loas = this.stateRegedLOAs.slice();
                    const index = loas.findIndex(f => f.id === loa.id);
                    loas.splice(index, 1);
                    this.stateRegedLOAs = loas;
                }
                else {
                    target.disabled = false;
                }
            });
        }
    }
}
