import { createAction, props } from '@ngrx/store';
import { Carrier } from '../../../shared/models/pob/carrier';

import { UserRolesDto, UserPreference } from '../../../shared/models/user';



export const changeUserBizUnitRoleAction = createAction(
    `[SecurityRoles Page] Change user security role to another BU`,
     props<{ bizUnitUserRoleId: number }>()
);

export const getAllCarriersListAction = createAction(
    `[SecurityRoles Page] Get all available carriers`
);

export const getBizUnitCarrierIdsAction = createAction(
    `[SecurityRoles Page] Get current biz unit linked carrier IDs`
);

export const addBizUnitCarrierAction = createAction(
    `[SecurityRoles Page] Link a carrier to biz unit`,
    props<{ carrierToAdd: Carrier }>()
);

export const deleteBizUnitCarrierAction = createAction(
    `[SecurityRoles Page] Unlink a carrier from biz unit`,
    props<{ carrierToDelete: Carrier }>()
);

export const getUserSecurityRolesAction = createAction(
    `[SecurityRoles Page] Get current user security roles from all BU`,
    props<{ input: string }>()
);

export const getAllUserRolesAction = createAction(
    `[SecurityRoles Page] Get a biz unit all user's security roles`
);


export const AddUserRolesAction = createAction(
    `[SecurityRoles Page] Add a new user`,
    props<{ input: UserRolesDto }>()
);

export const UpdateUserRolesAction = createAction(
    `[SecurityRoles Page] Update user roles`,
    props<{ input: UserRolesDto[] }>()
);


export const deleteUserFromBUAction = createAction(
    `[SecurityRoles Page] Delete a user from BizUnit`,
    props<{ input: number }>()
);

export const getCurrentUserPreferencesAction = createAction(
    `[SecurityRoles Page] Get current user preferences`,
    props<{ input: string }>()
);

export const updateUserPreferenceAction = createAction(
    `[SecurityRoles Page] Update current user preferences`,
    props<{ input: UserPreference }>()
);


