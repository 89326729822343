<ng-container *ngIf="loading">
    <div class="loading-spinner"></div>
</ng-container>

<div id="details-container" *ngIf="producerService.producerHierarchy && producerService.producer && !producerService.producerResultFromNipr">
    <div>
        <div class="half-width">
            <div>
                <span class="label">Business Units</span>
                <div class="details-list">
                    <p *ngIf="!producerService.producer.bizUnitProducers || producerService.producer.bizUnitProducers.length == 0"><span class="italics">None</span></p>
                    <p *ngFor="let bu of producerService.producer.bizUnitProducers"><input type="text" id="ext-prod-id-{{bu.bizUnitId}}" [(ngModel)]="bu.externalProducerId" [disabled]="bu.bizUnitId != userService.activeBusinessUnit.id && !userService.isOwnerOrCorporate" (change)="updateExternalId(bu)" placeholder="External ID" class="biz-unit-producer-id" /><span class="biz-unit-producer">{{bu.bizUnit.name}}</span><a *ngIf="bu.bizUnitId === userService.activeBusinessUnit.id" class="delete-btn" title="Remove Association with this Producer" (click)="disassociateBuWithProducer(bu)"></a></p>
                    <p *ngIf="!buAssociatedWithProducer && !userService.activeBizUnitIsCorporate"><input type="button" class="button" value="Associate BU with Producer" (click)="associateBuWithProducer()" /></p>
                </div>
            </div>
            <div>
                <span class="label">Parent Agencies</span>
                <div class="details-list">
                    <p class="producer-list-item" *ngFor="let p of producerService.producerHierarchy.parentProducerList">
                        <a class="delete-btn" (click)="removeRelationship(p, 'parent')"></a><a class="producer-list-item-name proper-name" (click)="redirect(p.id)" href="javascript:void(0);">{{p.fullName}}</a><span *ngIf="p.isPrimaryAgent" class="primary-agent-item">DLRP</span>
                    </p>
                    <a class="button" *ngIf="producerService.producer.npn" (click)="toggleParentLinking()">Add Parent Link</a>
                </div>
            </div>
            <ng-container *ngIf="producerService.producer.type == 'Agency'">
                <div>
                    <span class="label">Branches</span>
                    <div class="details-list">
                        <p class="producer-list-item" *ngFor="let p of childBranches">
                            <a class="delete-btn" (click)="removeRelationship(p, 'child')"></a><a class="producer-list-item-name proper-name" (click)="redirect(p.id)" href="javascript:void(0);">{{p.fullName}}</a><span *ngIf="p.isPrimaryAgent" class="primary-agent-item">DLRP</span>
                        </p>
                        <a class="button" (click)="toggleChildLinking('Agency')">Add Branch Link</a>
                    </div>
                </div>
                <div>
                    <span class="label">Agents</span>
                    <div class="details-list">
                        <p class="producer-list-item" *ngFor="let p of childAgents">
                            <a class="delete-btn" (click)="removeRelationship(p, 'child')"></a><a class="producer-list-item-name proper-name" (click)="redirect(p.id)" href="javascript:void(0);">{{p.fullName}}</a><span *ngIf="p.isPrimaryAgent" class="primary-agent-item">DLRP</span>
                        </p>
                        <a class="button" (click)="toggleChildLinking('Agent')">Add Agent Link</a>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="half-width top">
            <div class="eo-exp">
                <span class="label">E&O Expiration Date</span>
                <mat-form-field class="date-picker" style="">
                    <input name="eo-expiration-date" id="eo-expiration" matInput [(ngModel)]="producerService.producer.eoExpiration" [matDatepicker]="eoExpDatePicker" (dateChange)="eoExpirationUpdated($event)" />
                    <mat-datepicker-toggle matSuffix [for]="eoExpDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #eoExpDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="canRunBackgroundCheck && packageNames && packageNames.length > 0" style="margin-bottom: 40px;">
        <span class="label">Background Check</span>
        <div class="details-list" style="width: 70%;">
            <ng-container *ngIf="needSsn">
                <p>Producer is missing SSN required for background check</p>
                <mat-form-field>
                    <mat-label>SSN</mat-label>
                    <input name="taxId" matInput type="text" (blur)="showMask = true" (focus)="showMask = false" required (ngModelChange)="inputSsn = $event" [ngModel]="inputSsn | ssnMask:showMask" />
                </mat-form-field>
                <input type="button" class="button" style="font-size: 1em; margin-left: 30px;" value="Update SSN" (click)="updateSsn()" />
            </ng-container>
            <ng-container *ngIf="!producerHasResStateReq">
                <h4 class="inline">Producer is missing Resident Address for their Domicile State</h4><input id="auto-fill-address" type="button" class="button" style="margin-left: 20px;" value="Attempt Auto-Fill" (click)="getLikelyResidentialAddress($event.target)" title="Will attempt to populate with likely Residential Address if possible" />
                <mat-form-field class="full-width mat-form-field-no-padding">
                    <input name="ra-line1" matInput type="text" [(ngModel)]="producerService.producer.contact.residentialAddress.line1" class="proper-name" placeholder="Street Address" required />
                </mat-form-field>
                <mat-form-field class="full-width mat-form-field-no-padding">
                    <input name="ra-line2" matInput type="text" [(ngModel)]="producerService.producer.contact.residentialAddress.line2" placeholder="Address Line 2" />
                </mat-form-field>
                <mat-form-field class="half-width first-half-option mat-form-field-no-padding">
                    <input name="ra-city" matInput type="text" [(ngModel)]="producerService.producer.contact.residentialAddress.city" class="proper-name" placeholder="City" />
                </mat-form-field>
                <div class="half-width second-half-option">
                    <pv-state-select [(state)]="producerService.producer.contact.residentialAddress.state" class="half-width first-half-option inline mat-form-field-no-padding"></pv-state-select>
                    <mat-form-field class="half-width second-half-option mat-form-field-no-padding">
                        <input name="ra-zip" matInput type="text" [(ngModel)]="producerService.producer.contact.residentialAddress.zip" placeholder="Zip" />
                    </mat-form-field>
                </div>
                <input type="button" class="button" value="Update Producer Address" (click)="updateProducerContact($event.target)" />
            </ng-container>
            <ng-container *ngIf="producerHasResStateReq && !needSsn">
                <div *ngIf="!backgroundCheck || (backgroundCheck.isErrored && !backgroundCheck.isDuplicate) || backgroundCheck.isOutdated">
                    <a class="button inline" (click)="sendBackgroundCheck()">Order from BIG</a>
                    <span *ngIf="packageNames.length > 1">
                        <span style="margin: 0 0 0 15px;">Select background check package:</span>
                        <span *ngFor="let package of packageNames" style="margin-left: 15px;"><label><mat-checkbox name="package-{{package.item}}" [(ngModel)]="package.selected"></mat-checkbox> {{package.item}} </label></span>
                    </span>
                </div>
                <div *ngIf="backgroundCheck" style="margin-top: 7px;">
                    <div *ngIf="backgroundCheck.inProgress">
                        <p>The background check status is currently {{backgroundCheck.displayStatus}}. Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}</p>
                    </div>
                    <div *ngIf="backgroundCheck.needsReview">
                        <p>The background check has returned as "Needs Review". Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}</p>
                    </div>
                    <div *ngIf="backgroundCheck.isFailed">
                        <p>The background check has returned as "Failed". Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}</p>
                    </div>
                    <div *ngIf="backgroundCheck.isErrored">
                        <p style="margin-top: 10px;">
                            The previous background check resulted in an Error. Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}:<br /><span class="alert-color">{{backgroundCheck.errorDescription}}</span>
                        </p>
                        <p>If you continue to have this issue, please contact support.</p>
                    </div>
                    <div *ngIf="backgroundCheck.isPassed">
                        <p>The background check has returned as "Passed". Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}</p>
                    </div>
                    <div *ngIf="backgroundCheck.unmappedStatus">
                        <p>The background check has returned as "{{backgroundCheck.result}} - {{backgroundCheck.status}}". Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}</p>
                    </div>
                    <div *ngIf="backgroundCheck.unmappedStatus">
                        <p>The background check has returned as "{{backgroundCheck.result}} - {{backgroundCheck.status}}". Requested on {{backgroundCheck.createTime | date: 'medium'}} by {{backgroundCheck.requesterEmail}}</p>
                    </div>
                    <p *ngIf="backgroundCheck.redirectLink"><a href="{{backgroundCheck.redirectLink}}" target="_blank">View in BIG</a></p>
                    <div *ngIf="previousBackgroundChecks && previousBackgroundChecks.length > 0" style="margin-top: 15px;">
                        Previous Background Check Results:
                        <p *ngFor="let bg of previousBackgroundChecks">
                            <span>{{bg.createTime | date: 'medium'}}</span> - <span>{{bg.displayStatus}}</span>
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div>
        <span class="label">Comments</span>
        <div class="details-list" style="width: 500px;">
            <pv-prod-comments></pv-prod-comments>
        </div>
    </div>

    <pv-continuing-education-courses></pv-continuing-education-courses>

    <pv-continuing-education-reqs></pv-continuing-education-reqs>

    <div *ngIf="addingParentLink || addingChildLink">
        <div class="pv-modal">
            <div class="pv-modal-body">
                <a class="button warning-btn float-right" (click)="closeSearch()">Cancel</a>
                <div *ngIf="!searchResults && !searchLoading">
                    <form class="search-link" (submit)="search()">
                        <div class="faux-mat-form-field">
                            <mat-label>Producer Type:</mat-label>
                            <mat-radio-group name="producerType" [disabled]="addingParentLink" [(ngModel)]="searchInput.type">
                                <mat-radio-button value="Agent">Agent</mat-radio-button>
                                <mat-radio-button value="Agency">Agency</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="searchInput.type === 'Agent'">
                            <mat-form-field class="full-width inline">
                                <mat-label>First Name</mat-label>
                                <input name="firstName" matInput type="text" [(ngModel)]="searchInput.firstName" />
                            </mat-form-field>
                            <mat-form-field class="full-width inline">
                                <mat-label>Last Name</mat-label>
                                <input name="lastName" matInput type="text" [(ngModel)]="searchInput.lastName" />
                            </mat-form-field>
                        </div>
                        <div *ngIf="searchInput.type === 'Agency'">
                            <mat-form-field class="full-width inline">
                                <mat-label>Company Name</mat-label>
                                <input name="companyName" matInput type="text" [(ngModel)]="searchInput.companyName" />
                            </mat-form-field>
                        </div>
                        <mat-form-field class="full-width inline">
                            <mat-label>NPN</mat-label>
                            <input name="npn" matInput type="text" [(ngModel)]="searchInput.npn" />
                        </mat-form-field>
                        <button class="button" type="submit">Search</button>
                    </form>
                </div>
                <div *ngIf="searchResults && !searchLoading" class="search-link">
                    <a class="button" (click)="resetSearch()" style="margin: .1em 0 1.4em; display: inline-block;">Reset</a>
                    <h3 style="margin-bottom: 15px;">Search Results</h3>
                    <mat-form-field class="full-width">
                        <mat-label>Producers</mat-label>
                        <mat-select class="proper-name" name="producers" [(ngModel)]="selectedProducer">
                            <mat-option *ngFor="let prod of searchResults" class="proper-name" [value]="prod">{{prod.fullName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="searchInput.type === 'Agent'">
                        <mat-checkbox class="med-gray" name="link-primary" [(ngModel)]="isPrimaryAgent">DLRP?</mat-checkbox>
                        <ng-container *ngIf="hasPrimaryAgent && isPrimaryAgent">
                            <h4 class="alert-color">Note: This will replace the existing DLRP!</h4>
                        </ng-container>
                    </ng-container>
                    <button class="button" (click)="linkProducer()" style="margin: 30px 0 5px;">Link Producer</button>
                </div>
                <ng-container *ngIf="searchLoading">
                    <div class="loading-spinner"></div>
                </ng-container>
                <!--<ng-container *ngIf="addingChildLink">
                    <p style="margin: 25px 0 15px;">
                        If the producer doesn't exist yet, create a new Producer
                    </p>
                    <button class="button elevated-btn" (click)="createProducer()">Create Producer</button>
                </ng-container>-->
            </div>
        </div>
        <div class="pv-modal-background"></div>
    </div>

    <span *ngIf="canReSeed && producerService.producer.trackedInNIPR && !producerService.producerResultFromNipr">
        <span class="label"></span>
        <input type="button" class="button elevated-btn" value="Refresh PDB" title="To fix occasional missing data issues" (click)="reseedProducer()" />
    </span>
</div>

<ng-container *ngIf="producerService.producerResultFromNipr">
    <div class="panel-no-results">
        <h2>Results are from NIPR, information not available.</h2>
        <h2>Add producer to update relationship details.</h2>
    </div>
</ng-container>
