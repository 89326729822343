import { Component, OnInit } from '@angular/core';
import { ModalService } from './modal/modal.service';
import { UserService } from './services/user.service';
import { BusinessUnitRoles } from './shared/models/user';

import { Store } from '@ngrx/store';
import { State } from './state/app.state';
import { Observable } from 'rxjs';
import { getSelectedBizUnitSecurityRolesSelector, getCurrentUserAllBizUnitRolesSelector, getErrorSelector } from './user/state'
import { SecurityRolePageActions } from './user/state/actions';
import { Router, NavigationEnd } from '@angular/router';
import { tap, filter } from 'rxjs/operators';
import { securityRolesReducer } from './user/state/security-role.reducer';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: []
})
export class AppComponent implements OnInit {

    pageTitle = 'IPA';

    currentUserSecurityRole$: Observable<BusinessUnitRoles>;
    userAllBizUnitRoles$: Observable<BusinessUnitRoles[]>;
    errorMessage$: Observable<string>;

    currentUrl: string = null;


    constructor(private store: Store<State>, private router: Router, public userService: UserService, private modal: ModalService) { }

    ngOnInit() {
        this.currentUserSecurityRole$ = this.store.select(getSelectedBizUnitSecurityRolesSelector)
            .pipe(
                tap((selectedBURoles) => this.userService.OnUserSwitchBizUnit(selectedBURoles))
            );
        this.userAllBizUnitRoles$ = this.store.select(getCurrentUserAllBizUnitRolesSelector);
        this.errorMessage$ = this.store.select(getErrorSelector);

        //To capture the current url for later use of reloading in case of BU switch.
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.currentUrl = event.url;
        });
    }

    public get message() {
        return this.modal.messageText;
    }

    businessUnitChanged(val: BusinessUnitRoles) {
        //console.log("businessUnitChanged, val", val);
        localStorage.setItem('bizUnitId', val.businessUnit.id.toString());
        this.store.dispatch(SecurityRolePageActions.changeUserBizUnitRoleAction({ bizUnitUserRoleId: val.id }));

        this.store.dispatch(SecurityRolePageActions.getAllCarriersListAction());

        //so wierd, directly call navigate with variable, does not work, have to use a funciton.
        this.reLoadCurrentPage(this.currentUrl);
    }

    private reLoadCurrentPage(currUrl: string) {
        
        //some special url
        if (currUrl === '/Admin/NoPermission') {
            currUrl = '/Admin/CarrierSetup';
        }

        //Home won't trigger the reload since there's no dummy in the middle
        if (currUrl === '/Home' || currUrl === '/') {
            window.location.reload();
        }

        //can NOT directly navigate to the same url, has to go through a dummy url in the middle.
        this.router.navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate([currUrl]));
    }

    showExternalLinks() {
        this.modal.open('external-links-overlay');
    }
}
