import { AuditLogCategory } from '../enums/audit-log';
import { GlobalConsts } from '../utils/constants';
import { Carrier } from './pob/carrier';
import { License } from './pob/license';

export class Company {
    id: number;
    name: string;
    bizUnits: BizUnit[];
    constructor(init?: Partial<Company>) {
        Object.assign(this, init);
        if (init.bizUnits) {
            this.bizUnits = init.bizUnits.map(b => new BizUnit(b));
        }
    }
}

export class BizUnit {
    id: number;
    name: string;
    admin: string;
    email: string;
    bizUnitCarriers: BizUnitCarrier[] = [];
    company: Company;
    companyId: number;

    selected = false;

    get isCorporate() {
        if (GlobalConsts.corpIdsByCompany[this.companyId]) {
            if (this.id === GlobalConsts.corpIdsByCompany[this.companyId]) {
                return true;
            }
        }
        return false;
    }

    constructor(init?: Partial<BizUnit>) {
        this.id = init.id;
        this.name = init.name;
        this.admin = init.admin;
        this.email = init.email;
        this.companyId = init.companyId;
        this.company = init.company ? new Company(init.company) : null;

        if (init.bizUnitCarriers) {
            this.bizUnitCarriers = init.bizUnitCarriers.map(x => new BizUnitCarrier(x)).sort((x, y) => {
                if (x.carrier.name > y.carrier.name) return 1; else return -1;
            });
        }
    }
}

export class BizUnitProducer {
    bizUnit: BizUnit;
    bizUnitId: number;
    producerId: number;
    externalProducerId: string = "";

    constructor(init?: Partial<BizUnitProducer>) {
        Object.assign(this, init);
    }
}

export class BizUnitCarrier {
    bizUnitId: number;
    carrier: Carrier;
    carrierId: number;
    preference?: boolean = true;
    editor: string;
    editTime: Date;

    constructor(init?: Partial<BizUnitCarrier>) {
        Object.assign(this, init);
    }
}

export class Agency {
    id: number;
    name: string;
}

export class Agent {
    id: number;
    name: string;
}

export class AppointmentState {
    licenseId: number;
    stateCode: string;
    isAppointed: boolean;
    //property updated by code, or by license if FromLicense
    loaDescription: string;
    constructor(init?: Partial<AppointmentState>) {
        Object.assign(this, init);
    }
    static fromLicense(obj: Partial<License>) {
        let res = new AppointmentState({
            licenseId: obj.id,
            stateCode: obj.stateCode,
            loaDescription: obj.loaDescription
        });
        return res;
    }
}

export class AuditLog {
    id: number;
    category: string | AuditLogCategory;
    message: string;
    editor: string;
    editTime: Date;
    details: string;

    constructor(init?: Partial<AuditLog>) {
        if (init) {
            this.id = init.id;
            this.category = AuditLogCategory[init.category];
            this.message = init.message;
            this.editor = init.editor;
            this.editTime = new Date(init.editTime + "Z");
            this.details = init.details;
        }
    }
}

export interface IBizUnitVerbiage {
    id: number;
    bizUnitId: number;
    forEmail: string;
    forEmailSubject: string;
    forDocument: string;
    forApplicationComplete: string;
    isUpdateSuccessful: boolean;
}

export class CheckboxOption {
    item: string;

    selected: boolean;

    constructor(value: string) {
        this.item = value;
    }
}
