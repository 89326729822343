
<h3 style="margin-bottom: 20px;">Manage Onboarding Documents</h3>
<div class="flex">
    <div style="width: 60%;">
        <p>
            <a href="./assets/Naming Conventions Forms.xlsx">Download Naming Conventions File Here</a>
        </p>
        <p style="margin: 15px 0;">
            A check indicates a default selected file during application creation. Drag and drop to reorder.
        </p>
        <div cdkDropList (cdkDropListDropped)="documentsReordered($event)" id="doc-list-contain">
            <div data-cy="divDraggableDoc" *ngFor="let doc of documents" cdkDrag class="draggable-list-item">
                <span></span>
                <input data-cy="checkBoxDefaultSelection" type="checkbox" [(ngModel)]="doc.selectByDefault" name="selectedByDefault" (change)="updateDocument(doc)" />
                <a data-cy="hrefDocUrl" (click)="getDocumentLink(doc)" class="mng-doc-title"><span title="{{doc.editTime | date:'mediumDate'}}">{{doc.title}}</span></a>
                <a data-cy="hrefRemoveDoc" class="delete-btn" (click)="removeDocumentTemplate(doc)"></a>
            </div>
        </div>
    </div>
    <div>
        <pv-document-upload [(documentsList)]="documents" [bizUnitId]="bizUnitId" [username]="username"></pv-document-upload>
    </div>
</div>
