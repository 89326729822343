import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { ReportConfigComponent } from './report-config.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { StartEndDateComponent } from './shared/start-end-date/start-end-date.component';
import { BizUnitMultiselectComponent } from './shared/biz-unit-multiselect/biz-unit-multiselect.component';
import { CarrierMultiselectComponent } from './shared/carrier-multiselect/carrier-multiselect.component';
import { BuCarrierShellComponent } from './shared/bu-carrier-shell/bu-carrier-shell.component';
import { StateSelectModule } from '../shared/modules/state-select/state-select.module';

@NgModule({
    declarations: [ReportConfigComponent, StartEndDateComponent, BizUnitMultiselectComponent, CarrierMultiselectComponent, BuCarrierShellComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatTableModule,
        CdkTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        StateSelectModule,
        RouterModule.forChild([
            { path: 'Reports', component: ReportConfigComponent }
        ])
    ]
})
export class ReportsModule { }
