import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';

import { ProducerDataService } from '../../producer-data.service';
import { InputForOneRegedCreateOrder, LocalPobAppointmentCreateInput, RegedCreateOrderLog } from '../../../shared/models/pob/appointing-license';
import { UserService } from '../../../services/user.service';
import { DataService } from '../../../services/data.service';
import { Carrier, CarrierRegedLicenseTypeDTO, CarrierRegedLoaDTO, RegedLicenseType, RegedLOA } from '../../../shared/models/pob/carrier';
import { RegedOrderStatus } from '../../../shared/enums/reged';
import { NewAppointmentComponent } from './new-appointment.component';
import { IPdbAppointment } from '../../../shared/models/dto/pdb-result.dto';
import { BizUnitProducer } from '../../../shared/models/general';

@Component({
    selector: 'pv-new-appointment-shell',
    templateUrl: './new-appointment-shell.component.html',
})
export class NewAppointmentShellComponent implements OnInit {

    @ViewChild(NewAppointmentComponent, { static: false }) child: NewAppointmentComponent;
    public bizUnitCarriers$: Observable<Carrier[]>;

    bizUnitId: number;
    selectedCarrier: Carrier;
    updatedCarrier: Carrier;
    effectiveDate: Date = new Date();
    showInactiveLicenses = false;

    constructor(public producerService: ProducerDataService, private dataService: DataService, private userService: UserService) { }

    ngOnInit(): void {
        console.log('shell');
        this.bizUnitCarriers$ = of(this.userService.activeBizUnitCarriers);
        const cid = localStorage.getItem('activeCarrier');
        if (cid) {
            this.selectedCarrier = this.userService.activeBizUnitCarriers.find(c => c.id === +cid);
        }
        if(!this.selectedCarrier) {
            this.selectedCarrier = this.userService.activeBizUnitCarriers[0];
        }
        this.carrierChanged();
        this.bizUnitId = this.userService.activeBusinessUnit.id;
    }

    handleApptSelection(selectedAppointments: InputForOneRegedCreateOrder[]) {
        this.callSubmitAppointment(selectedAppointments, "Queued for sending to RegEd", this.effectiveDate);
    }

    handleTermination(termAppointment: InputForOneRegedCreateOrder) {
        this.callSubmitAppointment([termAppointment], "Termination has been submitted", new Date());
    }

    callSubmitAppointment(appointments: InputForOneRegedCreateOrder[], message: string, effectiveDate: Date) {
        if (this.producerService.pobAppointments === null) {
            this.producerService.pobAppointments = [];
        }

        appointments.forEach(a => {
            a.regedOrderData.status = RegedOrderStatus.Initiated;
            a.regedOrderData.message = message;
            a.regedOrderData.effectiveDate = effectiveDate.toLocaleDateString("en-US");
            a.regedOrderData.creator = this.userService.userIdentifier;
            a.regedOrderData.createDateTime = new Date();
            a.regedOrderData.createTime = a.regedOrderData.createDateTime.toISOString();
            a.regedOrderData.bizUnitId = this.bizUnitId;
            a.carrierLOAsMatrix.forEach(l => {
                l.creator = this.userService.userIdentifier;
                l.createTime = effectiveDate;
                //l.regedCreateOrderLog = [a.regedOrderData];
            });
            
            //need to have this in place so UI can update and not show the checkbox
            this.populateAppointmentsArrayStatuses(a, a.regedOrderData.status, message);
        });

        this.producerService.submitPobAppointmentInBulk(appointments).subscribe(resData => {
            if (resData && resData.status === 0) {
                appointments.forEach(a => {
                    this.populateAppointmentsArrayStatuses(a, RegedOrderStatus.OrderSentToQueue, "Submission added to Queue");
                });

                this.checkBUProdRelationship();
            }

            //this.child.ngOnChanges();
        });
    }

    //handles both local appointments and local terminations
    handleLocalAppt(selectedAppointments: LocalPobAppointmentCreateInput[]) {
        if (this.producerService.pdbAppointments === null) {
            this.producerService.pdbAppointments = [];
        }
        selectedAppointments.forEach(a => {
            a.effectiveDate = this.effectiveDate;
            a.createDate = new Date();
            a.creator = this.userService.getUserEmail;
            this.producerService.AddOrUpdateLocalPobAppointment(a, this.bizUnitId).subscribe(resData => {
                if (resData && resData.status === 0) {
                    const existing = this.producerService.carrierAppointments.find(appt => appt.producerId === a.producerId && appt.carrierId === a.carrierId && appt.state === a.stateCode && appt.loaDescription === a.loaDescription);

                    if (!existing) {
                        if (a.filingType === "Appointed") {
                            const newAppt = new IPdbAppointment(resData.item);
                            newAppt.isLocal = true;
                            this.producerService.pdbAppointments.push(newAppt);
                            this.producerService.carrierAppointments.push(newAppt);
                        }
                    }
                    else {
                        existing.status = resData.item.status;
                    }

                    this.checkBUProdRelationship();
                }

                this.child.ngOnChanges();
            });
        });
    }

    checkBUProdRelationship(): void {
        let relationship = this.producerService.producer.bizUnitProducers.find(b => b.bizUnitId === this.bizUnitId);
        if (!relationship) {
            this.producerService.addBuProducerAssociation(this.bizUnitId, this.producerService.producer.id).subscribe(resData => {
                if (resData.status === 0) {
                    relationship = new BizUnitProducer();
                    relationship.bizUnit = this.userService.activeBusinessUnit;
                    relationship.bizUnitId = this.bizUnitId;
                    relationship.producerId = this.producerService.producer.id;
                    relationship.externalProducerId = "";
                    this.producerService.producer.bizUnitProducers.push(relationship);
                }
            });
        }
    }

    populateAppointmentsArrayStatuses(order: InputForOneRegedCreateOrder, status: RegedOrderStatus, message: string) {
        order.carrierLOAsMatrix.forEach(loa => {
            const check = this.producerService.pobAppointments.find(a => a.stateCode === loa.stateCode && a.licenseId === loa.licenseId && a.licenseDetailId === loa.licenseDetailId && a.carrierId === loa.carrierId)
            if (check) {
                if (check.stateCode === 'FC') {
                    if (!check.floridaCountyCode) {
                        check.floridaCountyCode = [];
                    }

                    if (check.floridaCountyCode.indexOf(loa.floridaCountyCode[0]) < 0) {
                        check.floridaCountyCode.push(loa.floridaCountyCode[0]);
                        const log = new RegedCreateOrderLog(Object.assign({}, order.regedOrderData));
                        log.status = RegedOrderStatus[log.status];
                        check.regedCreateOrderLog.push(log);
                    }
                }
                check.status = RegedOrderStatus[status];
                check.message = message;
            }
            else {
                loa.status = RegedOrderStatus[status];
                loa.message = message;
                this.producerService.pobAppointments.push(loa);
            }
        });
    }

    carrierChanged(): void {
        this.updateCarrierForChild(null);
        
        if (this.selectedCarrier) {
            localStorage.setItem('activeCarrier', this.selectedCarrier.id.toString());
            if (this.selectedCarrier.allowedLicenseTypes === null) {
                forkJoin(
                    this.dataService.getCarrierAllowedRegedLicenseTypes(this.selectedCarrier.id),
                    this.dataService.getCarrierAllowedRegedLoas(this.selectedCarrier.id)
                )
                    .subscribe(([ltpData, loaData]) => {
                        if (ltpData.status === -1) {
                            alert("Unable to get Carrier Allowed License Types. API error: \n\r" + ltpData.message);
                            return;
                        }
                        if (loaData.status === -1) {
                            alert("Unable to get Carrier Allowed LOAs. API error: \n\r" + loaData.message);
                            return;
                        }
                        if (ltpData && ltpData.status === 0 && ltpData.dataList) {
                            this.selectedCarrier.allowedLicenseTypes = ltpData.dataList.map(x => new CarrierRegedLicenseTypeDTO(x));
                        }
                        else {
                            this.selectedCarrier.allowedLicenseTypes = [];
                        }

                        if (loaData && loaData.status === 0 && loaData.dataList) {
                            this.selectedCarrier.allowedLoas = loaData.dataList.map(x => new CarrierRegedLoaDTO(x));
                        }
                        else {
                            this.selectedCarrier.allowedLoas = [];
                        }

                        this.updateCarrierForChild(this.selectedCarrier);
                    });
            }
            else {
                this.updateCarrierForChild(this.selectedCarrier);
            }
        }
    }

    updateCarrierForChild(carrier: Carrier) {
        this.updatedCarrier = carrier;
    }
}
