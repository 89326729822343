import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IVerbiageData {
    type: 'document' | 'email';
    verbiage: string;
    bizUnitName?: string;
}

@Component({
  selector: 'pv-verbiage-preview-dialog',
  templateUrl: './verbiage-preview-dialog.component.html',
  styleUrls: ['./verbiage-preview-dialog.component.css']
})
export class VerbiagePreviewDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: IVerbiageData) { }
}
