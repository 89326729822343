import { Contact } from './contact';
import { License } from './license';
import { BizUnitProducer } from '../general';
import { ProducerDocument } from '../document';
import { CERequirementType } from '../../enums/general';

export class Producer {

    id: number;
    contact: Contact;
    licenses: License[];
    bizUnitProducers: BizUnitProducer[];

    npn: number;
    type: string | number;
    stateCode: string;

    parentProducerList: Producer[];

    documents: ProducerDocument[];

    eoExpiration: Date;

    //Agency
    childProducerList: Producer[];
    fein: string;
    branchId: string;
    taxClass: string | number;
    companyName: string;
    isBranchAgency: boolean;

    //Agent
    ssn: string;
    firstName: string;
    lastName: string;
    middleName: string;
    isPrimaryAgent = false;
    dateOfBirth: Date;

    comments: ProducerComment[];
    trackedInNIPR: boolean;

    companyId: number;

    get fullName(): string {
        let name = '';
        if (this.type == "Agent") {
            name = this.firstName;
            if (this.middleName) {
                name += " " + this.middleName;
            }
            name += " " + this.lastName;
        }
        else {
            name = this.companyName;
        }
        return name ? name.toLowerCase() : null;
    }

    get license(): License {
        if (this.stateCode) {
            const stateLic = this.licenses.find(x => x.residencyStatus === "R");
            if (stateLic) {
                return stateLic;
            }
        }
        return null;
    }

    get licenseType(): string {
        const lic = this.license;

        if (lic) {
            return lic.class;
        }
    }

    get licenseNumber(): string {
        const lic = this.license;

        if (lic) {
            return lic.licenseNumber;
        }
        else {
            return "No License";
        }
    }

    constructor(api: Partial<Producer>) {
        this.id = api.id;
        this.contact = new Contact(api.contact);
        this.licenses = api.licenses ? api.licenses.map(x => new License(x)).sort((x, y) => {
            if (x.stateCode > y.stateCode) return 1; else return -1;
        }) : null;
        this.npn = api.npn;
        this.companyName = api.companyName;
        this.firstName = api.firstName;
        this.lastName = api.lastName;
        this.middleName = api.middleName;
        this.dateOfBirth = api.dateOfBirth;
        this.type = api.type;
        this.stateCode = api.stateCode;
        this.parentProducerList = api.parentProducerList ? api.parentProducerList.map(x => new Producer(x)) : null;
        this.childProducerList = api.childProducerList ? api.childProducerList.map(x => new Producer(x)) : null;
        this.fein = api.fein;
        this.branchId = api.branchId;
        this.taxClass = api.taxClass;
        this.ssn = api.ssn;
        this.isPrimaryAgent = api.isPrimaryAgent;
        this.isBranchAgency = api.isBranchAgency;
        this.eoExpiration = api.eoExpiration;

        this.comments = api.comments ? api.comments.map(x => new ProducerComment(x)) : null;
        this.bizUnitProducers = api.bizUnitProducers ? api.bizUnitProducers.map(x => new BizUnitProducer(x)) : null;

        this.documents = api.documents ? api.documents.map(x => new ProducerDocument(x)) : [];
        this.trackedInNIPR = api.trackedInNIPR;

        this.companyId = api.companyId;
    }

    static shortProducer(api: Partial<Producer>): Producer {
        let prod = new Producer({});

        prod.id = api.id;
        prod.contact = new Contact(api.contact);
        prod.npn = api.npn;
        prod.companyName = api.companyName;
        prod.firstName = api.firstName;
        prod.lastName = api.lastName;
        prod.middleName = api.middleName;
        prod.dateOfBirth = api.dateOfBirth;
        prod.type = api.type;
        prod.stateCode = api.stateCode;
        prod.fein = api.fein;
        prod.branchId = api.branchId;
        prod.taxClass = api.taxClass;
        prod.ssn = api.ssn;
        prod.isPrimaryAgent = api.isPrimaryAgent;
        prod.isBranchAgency = api.isBranchAgency;
        prod.eoExpiration = api.eoExpiration;

        return prod;
    }
}

export class SearchProducer {
    id: number;
    npn: number;
    type: string | number;
    stateCode: string;

    //Agency
    companyName: string;
    fein: string;

    //Agent
    firstName: string;
    lastName: string;
    ssn: string;

    constructor(init?: Partial<SearchProducer>) {
        Object.assign(this, init);
    }
}

export class UntrackedProducer {
    producerId: number;
    npn: number;
    name: string;

    dateRemoved: Date;
    removedBy: string;

    constructor(init?: Partial<UntrackedProducer>) {
        Object.assign(this, init);
    }
}

export class ProducerCompanyLog {
    producerId: number;
    preference: boolean;

    constructor(init?: Partial<ProducerCompanyLog>) {
        Object.assign(this, init);
    }
}

export class ProducerRelationship {
    parentProducerId: number;
    childProducerId: number;
    isPrimaryAgent: boolean;
}

export class ProducerComment {
    id: number;
    producerId: number;
    bizUserId: number;
    
    //user email
    bizUserIdentifier: string;

    message: string;
    editTime: Date;

    constructor(init?: Partial<ProducerComment>) {
        this.id = init.id;
        this.producerId = init.producerId;
        this.bizUserId = init.bizUserId;
        this.bizUserIdentifier = init.bizUserIdentifier;
        this.message = init.message;
        this.editTime = new Date(init.editTime+"Z");
    }
}

export class CECourse {
    id: number;
    producerId: number;

    courseNumber: string;
    courseName: string;
    courseHours: number;
    status: string;
    courseCategory: string;
    completionDate: Date;
    providerId: string;
    providerName: string;

    isEditing: boolean;

    constructor(init?: Partial<CECourse>) {
        this.assign(init);
    }

    assign(init?: Partial<CECourse>) {
        this.id = init.id;
        this.producerId = init.producerId;
        this.courseNumber = init.courseNumber;
        this.courseName = init.courseName;
        this.courseHours = init.courseHours;
        this.status = init.status;
        this.courseCategory = init.courseCategory;
        this.completionDate = init.completionDate;
        this.providerId = init.providerId;
        this.providerName = init.providerName;
        this.isEditing = false;
    }
}

export class CERequirement {
    id: number;
    producerId: number;

    jurisdiction: string;
    state: string;
    requirementType: string | CERequirementType;
    assignedReviewDate: Date;
    requiredHourQuantity: number;
    status: string;
    statusDate: Date;

    isEditing: boolean;

    constructor(init?: Partial<CERequirement>) {
        this.assign(init);
    }

    assign(init?: Partial<CERequirement>) {
        this.id = init.id;
        this.producerId = init.producerId;
        this.jurisdiction = init.jurisdiction;
        this.state = init.state;
        this.requirementType = init.requirementType;
        this.assignedReviewDate = init.assignedReviewDate;
        this.requiredHourQuantity = init.requiredHourQuantity;
        this.status = init.status;
        this.statusDate = init.statusDate;
        this.isEditing = false;
    }
}
