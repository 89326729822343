import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnboardingService } from '../../onboarding.service';
import { Application } from '../../../shared/models/application';
import { AuditLogStatus } from '../../../shared/enums/audit-log';
import { ApplicationProgress } from '../../../shared/enums/general';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pv-external-app',
    templateUrl: './external-app.component.html',
})
export class ExternalAppComponent implements OnInit, OnDestroy {

    applications: Application[];

    appSubscription: Subscription;

    constructor(private onboardService: OnboardingService) { }

    ngOnInit() {
        this.appSubscription = this.onboardService.onboardingApplications$.subscribe(apps => {
            this.applications = apps.filter(x => x.status === AuditLogStatus.AppCreate && x.progressStatus !== ApplicationProgress.Completed);
        });
    }

    ngOnDestroy() {
        if (this.appSubscription) {
            this.appSubscription.unsubscribe();
        }
    }
}
