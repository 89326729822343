import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '../../../services/file.service';
import { DocuSignTemplate, DocumentTemplateUpload, DocumentTemplateFileBody } from '../../../shared/models/document';
import { DocumentRequirementType } from '../../../shared/enums/general';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { AdminPageActions } from '../../state/actions';
import { getDbDocumentTemplatesSelector } from '../../state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReadFile } from '../../../shared/utils/file';

@Component({
    selector: 'pv-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {

    percentDone: number = 0;
    uploading: boolean = false;
    uploadMsg: string;

    constructor(private fileService: FileService, private store: Store<State>) { }

    @Input() bizUnitId: number;
    @Input() documentsList: DocuSignTemplate[];
    @Input() username: string;

    @Output() documentsListChange: EventEmitter<DocuSignTemplate[]> = new EventEmitter<DocuSignTemplate[]>();

    ngOnInit(): void {
        this.store.select(getDbDocumentTemplatesSelector)
            .pipe(
                tap((data) => {
                    if (data && this.uploading) {
                        this.uploading = false;
                        let lastUploadedDoc = data.reduce((x, y) => x.documentOrder > y.documentOrder ? x : y)
                        this.uploadMsg = lastUploadedDoc.fileName + " Uploaded Successfully!";
                        document.getElementById('drop-target').style.backgroundColor = "#92ce8b";

                        setTimeout(() => {
                            document.getElementById('drop-target').style.backgroundColor = "#DDD";
                            this.uploadMsg = "";
                        }, 3000);
                    }
                })
            ).subscribe(
                //(data) => console.log("wwwwwwwwwwwwwwwwwww OK", data),
                //(error) => console.log("wwwwwwwwwwww error", error),
            );
    }

    // At the drag drop area
    // (drop)="onDropFile($event)"
    onDropFile(event: DragEvent) {
        event.preventDefault();
        this.uploading = true;
        document.getElementById('drop-target').style.backgroundColor = "#ffe062";
        this.uploadFile(event.dataTransfer.files);
    }

    // At the drag drop area
    // (dragover)="onDragOverFile($event)"
    onDragOverFile(event) {
        event.stopPropagation();
        event.preventDefault();
        document.getElementById('drop-target').style.backgroundColor = "#bce2f7";
    }

    onDragOutFile(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();
        document.getElementById('drop-target').style.backgroundColor = "#ddd";
    }

    // At the file input element
    // (change)="selectFile($event)"
    selectFile(event) {
        this.uploadFile(event.target.files);
    }

    browseFile(event: any) {
        this.uploadFile(event.target.files);
    }

    uploadFile(files: FileList) {
        if (files.length == 0) {
            console.log("No file selected!");
            return
        }
        if (!files[0].name.endsWith(".pdf")) {
            alert("Only PDF files can be uploaded!");
            document.getElementById('drop-target').style.backgroundColor = "#DDD";
            this.uploading = false;
            return;
        }

        this.uploadMsg = "";
        this.uploading = true;

        let upload: DocumentTemplateUpload = new DocumentTemplateUpload();
        upload.bizUnitId = this.bizUnitId;
        upload.files = [];

        ReadFile(files[0]).then(data => {
            let name = files[0].name;
            let check = this.documentsList.find(x => x.fileName == name);
            if (check) {
                if (confirm('There is already a file with this name, are you sure you want to overwrite it?')) {
                    check.editor = this.username;
                    upload.files.push(new DocumentTemplateFileBody({ document: check, data: data }))
                    this.doFileUpload(upload, true);
                }
            }
            else {
                let doc = new DocuSignTemplate();
                doc.title = name;
                doc.fileName = name;
                doc.description = name;
                doc.bizUnitId = this.bizUnitId;
                doc.requirement = DocumentRequirementType.MustSign;
                doc.editor = this.username;
                doc.editTime = new Date();
                //set as the last in the list
                doc.documentOrder = Math.max.apply(Math, this.documentsList.map(function (d) { return d.documentOrder; })) + 1;
                doc.selectByDefault = false;

                upload.files.push(new DocumentTemplateFileBody({ document: doc, data: data }));
                this.doFileUpload(upload, false);
            }
        });
    }

    doFileUpload(docUpload: DocumentTemplateUpload, overwrite: boolean) {
        this.store.dispatch(AdminPageActions.uploadDocusignTemplateFileAction({ documentTemplate: docUpload }));
    }
}
