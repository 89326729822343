import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Carrier } from '../../../shared/models/pob/carrier';

@Component({
    selector: 'pv-carrier-multiselect',
    templateUrl: './carrier-multiselect.component.html',
    styleUrls: ['./carrier-multiselect.component.css']
})
export class CarrierMultiselectComponent implements OnInit {

    @Input() fullCarrierList: Carrier[];
    @Input() carriers: Carrier[];

    @Input() fromShell = false;

    @Output() carriersChange: EventEmitter<Carrier[]> = new EventEmitter<Carrier[]>();

    showCarrierList = false;
    carrierList: Carrier[];

    constructor() {
    }

    ngOnInit(): void {
        this.carrierList = this.fullCarrierList.map(c => new Carrier(c));

        if (this.fromShell) {
            this.showCarrierList = true;
        }
    }


    updateCarrierList() {
        if (this.showCarrierList) {
            this.carriers = this.carrierList.filter(c => c.selected);
        }
        else {
            this.carriers = [];
        }

        this.carriersChange.emit(this.carriers);
    }
}
