import { Directive, HostListener, NgModule, Pipe, PipeTransform } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhoneNumberFormat } from '../utils/string-transform';

//@Directive({
//    selector: '[phoneMask]'
//})
//export class PhoneMaskDirective {

//    constructor(public ngControl: NgControl, private phonePipe: PhoneMaskPipe) { }

//    @HostListener('ngModelChange', ['$event'])
//    onModelChange(event) {
//        this.onInputChange(PhoneNumberFormat(event));
//    }

//    //@HostListener('keydown.backspace', ['$event'])
//    //keydownBackspace(event) {
//    //    this.onInputChange(event.target.value);
//    //}

//    //@HostListener('onPaste', ['$event'])
//    //onPaste(event) {
//    //    this.onInputChange(PhoneNumberFormat(event.target.value));
//    //}

//    onInputChange(event) {
//        this.ngControl.valueAccessor.writeValue(event);
//    }
//}


@Pipe({
    name: 'phoneMaskPipe'
})
export class PhoneMaskPipe implements PipeTransform {

    transform(value: string): string {
        return PhoneNumberFormat(value);
    }
}

//@NgModule({
//    declarations: [PhoneMaskDirective],
//    exports: [PhoneMaskDirective],
//    providers: [PhoneMaskPipe]
//})
//export class PhoneMaskDirectiveModule { }

@NgModule({
    declarations: [PhoneMaskPipe],
    exports: [PhoneMaskPipe]
})
export class PhoneMaskPipeModule { }
