import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { License } from '../../../shared/models/pob/license';
import { LicenseDetail } from '../../../shared/models/pob/license-details';
import { ModalService } from '../../../modal/modal.service';
import { DataService } from '../../../services/data.service';
import { RegedCreateOrderLog } from '../../../shared/models/pob/appointing-license';
import { Producer } from '../../../shared/models/pob/producer';

@Component({
    selector: 'pv-terminate-appt',
    templateUrl: './terminate.component.html',
    styleUrls: ['./terminate.component.css']
})
export class TerminateComponent implements OnInit, AfterViewInit {

    @Input() license: License;
    @Input() detail: LicenseDetail;
    @Input() carrierName: string;
    @Input() order: RegedCreateOrderLog;
    @Input() producer: Producer;
    @Input() bizUnitId: number;

    @Output() terminateConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() terminating: EventEmitter<boolean> = new EventEmitter<boolean>();

    terminationReasons: TerminationReason[];
    trNeeded = true;

    constructor(private dataService: DataService, private modal: ModalService) { }

    ngOnInit(): void {
        this.dataService.getTerminationReasons(this.license.stateCode).subscribe(resData => {
            if (resData && resData.status === 0) {
                this.terminationReasons = resData.dataList;
            }
            else if (resData.status === -404) {
                this.trNeeded = false;
            }
        });

        this.order.filingType = "Termination";
    }

    ngAfterViewInit(): void {
        this.modal.open("terminate-popup");
    }

    modalClosed(event): void {
        this.terminating.emit(false);
        this.modal.remove("terminate-popup");
    }

    terminateEnabled(): boolean {
        return ((this.trNeeded === false || this.order.terminationCode) && this.order.isTerminationForCause);
    }

    terminate() {
        const bus = this.producer.bizUnitProducers.filter(b => b.bizUnitId !== this.bizUnitId);
        let message = "Are you sure you want to TERMINATE this Appointment?";
        if (bus.length > 0) {
            const names = bus.map(b => b.bizUnit.name);
            message = "This producer is currently associated with these other Business Units:\r\n\r\n" + names.join(',\r\n') + "\r\n\r\n" + message;
        }
        if (confirm(message)) {
            this.terminateConfirmed.emit(true);
            this.modalClosed(null);
        }
    }
}

export class TerminationReason {
    id: number;
    stateCode: string;
    terminationReason: string;
}
