import { Component, OnInit } from '@angular/core';
import { CERequirementType } from '../../../../shared/enums/general';
import { GlobalLists } from '../../../../shared/lists';
import { CERequirement } from '../../../../shared/models/pob/producer';
import { InputStateColorCode } from '../../../../shared/utils/ui-mod';
import { ProducerDataService } from '../../../producer-data.service';

@Component({
    selector: 'pv-continuing-education-reqs',
    templateUrl: './continuing-education-reqs.component.html',
    styleUrls: ['../details.component.css']
})
export class ContinuingEducationReqsComponent implements OnInit {

    reqs: CERequirement[];
    columns: string[] = ['reqJurisdiction', 'reqState', 'reqRequirementType', 'reqAssignedReviewDate', 'reqRequiredHourQuantity', 'reqStatus', 'reqStatusDate', 'reqAction'];
    states = GlobalLists.stateList;

    requirementTypes = CERequirementType;
    reqTypeKeys: string[];

    editReqHold: CERequirement;

    constructor(private producerService: ProducerDataService) {
        this.reqTypeKeys = Object.keys(this.requirementTypes);
    }

    ngOnInit(): void {
        if (!this.producerService.producerCECourses) {
            this.producerService.loadProducerCERequirements().subscribe(resData => {
                if (resData.status === 0) {
                    this.producerService.producerCEReqs = resData.dataList.map(c => new CERequirement(c));
                }
                else if (resData.status === -404) {
                    this.producerService.producerCEReqs = [];
                }

                this.reqs = this.producerService.producerCEReqs.slice();
            });
        }
        else {
            this.reqs = this.producerService.producerCEReqs.slice();
        }
    }

    addRequirement(): void {
        let req = new CERequirement({});
        req.isEditing = true;
        req.jurisdiction = "National";
        this.producerService.producerCEReqs.push(req);
        this.reqs = this.producerService.producerCEReqs.slice();
    }

    editReq(req: CERequirement): void {
        req.isEditing = true;
        this.editReqHold = Object.assign({}, req);
    }

    cancelEditReq(req: CERequirement): void {
        req.isEditing = false
        if (!req.id) {
            //never saved, just delete local
            const index = this.producerService.producerCEReqs.findIndex(c => c.id === req.id);
            if (index >= 0) {
                this.producerService.producerCEReqs.splice(index, 1);
                this.reqs = this.producerService.producerCEReqs.slice();
            }
        }
        else {
            req.assign(this.editReqHold);
        }
    }

    updateRequirement(req: CERequirement, target: any): void {
        if (!(req.requirementType)) {
            alert("Please enter a Requirement Type!");
            return;
        }

        target.disabled = true;
        if (!req.producerId) {
            req.producerId = this.producerService.producer.id;
        }

        this.producerService.updateProducerCERequirement(req).subscribe(resData => {
            if (resData.status === 0) {
                req.id = resData.item.id;
                req.isEditing = false;
            }
            else {
                req.isEditing = true;
            }

            target.disabled = false;

            InputStateColorCode(target.parentElement.parentElement, resData);
        });
    }

    statusUpdated(req: CERequirement): void {
        req.statusDate = new Date();
    }

    deleteRequirement(req: CERequirement, target: any): void {
        target.disabled = true;

        this.producerService.deleteProducerCERequirement(req.id).subscribe(resData => {
            if (resData.status === 0) {
                const index = this.producerService.producerCEReqs.findIndex(r => r.id === req.id);
                if (index >= 0) {
                    this.producerService.producerCEReqs.splice(index, 1);
                    this.reqs = this.producerService.producerCEReqs.slice();
                }
            }
        });
    }
}
