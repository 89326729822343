import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { Alert } from '../shared/models/dto/dashboard';
import { RegedCreateOrderLogInDetail } from '../shared/models/pob/appointing-license';
import { IClientItemDTO } from '../shared/models/dto/common-result.dto';
import { Store } from '@ngrx/store';
import { getSelectedBizUnitSecurityRolesSelector, getSelectedSecurityRolesSelector } from '../user/state';
import { State } from '../state/app.state';
import { forkJoin, Observable, of } from 'rxjs';
import { UserRoles } from '../shared/models/user';
import { SearchProducer } from '../shared/models/pob/producer';
import { ModalService } from '../modal/modal.service';

@Component({
    selector: 'pv-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    alerts: Alert[] = null;

    applicationsCount: number = null;

    appointments: RegedCreateOrderLogInDetail[] = null;

    removedProducers: SearchProducer[] = null;

    currentUserSecurityRole$: Observable<UserRoles>;

    constructor(private dashService: DashboardService, private store: Store<State>, private modal: ModalService) {
        this.currentUserSecurityRole$ = this.store.select(getSelectedSecurityRolesSelector);
    }

    ngOnInit(): void {
        this.store.select(getSelectedBizUnitSecurityRolesSelector).subscribe(resData => {
            if (resData) {
                forkJoin(this.dashService.getSystemAlerts(), this.getNiprAlertStatus(resData.userRoles)).subscribe(([alertData, niprAlertData]) => {
                    let alerts: Alert[] = [];
                    if (alertData && alertData.status === 0) {
                        alerts = alertData.dataList.map(a => new Alert(a)).filter(a => a.startDate <= new Date());
                    }

                    if (niprAlertData && niprAlertData.status <= 0) {
                        const alert: Alert = new Alert();
                        if (niprAlertData.status === 0) {
                            alert.category = "Notification";
                        }
                        else {
                            alert.category = "Alert";
                        }
                        alert.message = niprAlertData.item;
                        alert.startDate = new Date();

                        alerts.push(alert);
                    }

                    this.alerts = alerts;
                });

                this.dashService.getApplicationsReadyReviewing().subscribe(resData => {
                    if (resData && resData.status === 0) {
                        this.applicationsCount = resData.item;
                    }
                });

                this.dashService.getRegedRejections().subscribe(resData => {
                    if (resData && resData.status === 0) {
                        this.appointments = resData.dataList.map(a => new RegedCreateOrderLogInDetail(a));
                    }
                    else {
                        this.appointments = [];
                    }
                });

                this.dashService.getRemovedProducers().subscribe(resData => {
                    if (resData && resData.status === 0) {
                        this.removedProducers = resData.dataList.map(p => new SearchProducer(p));
                    }
                });
            }
        });
    }

    getNiprAlertStatus(roles: UserRoles): Observable<IClientItemDTO<string>> {
        if (roles.systemAdmin) {
            return this.dashService.getNiprAlertStatus();
        }
        else {
            return of({ status: 1, message: "", item: "" });
        }
    }

    clearRegedOrderAlert(order: RegedCreateOrderLogInDetail) {
        this.modal.loading(true);
        this.dashService.clearRegedAlert(order).subscribe(resData => {
            if (resData && resData.status === 0 && resData.item) {
                let list = this.appointments.slice();
                let index = list.findIndex(l => l.orderId === order.orderId
                    && l.producerId === order.producerId
                    && l.licenseId === order.licenseId
                    && l.licenseDetailId === order.licenseDetailId
                    && l.carrierId === order.carrierId);
                list.splice(index, 1);
                this.appointments = list;
            }
            this.modal.loading(false);
        });
    }
}
