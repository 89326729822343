import { Injectable } from '@angular/core';
import { OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';
import { PVAuthService, DiscoveryDocumentConfig } from '../auth.config';
import { BizUnit, BizUnitCarrier } from '../shared/models/general';
import { UserRoles, BusinessUnitRoles, User } from '../shared/models/user';
import { AppConfigService } from './app-config.service';
import { State } from '../state/app.state';
import { Store } from '@ngrx/store';
import { SecurityRolePageActions } from '../user/state/actions';
import { getCurrentUserAllBizUnitRolesSelector, getCurrentUserPreferencesSelector, getCurrentBizUnitCarriersSelector, getAllBizUnitCarriersSelector } from '../user/state';
import { Carrier, CarrierDetail } from '../shared/models/pob/carrier';
import { Address } from '../shared/models/pob/address';
import { GlobalLists } from '../shared/lists';
import { GlobalConsts } from '../shared/utils/constants';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public userRolesLoaded = false;

    private _isCorporate = false;
    public get isCorporate(): boolean { return this._isCorporate };

    private _activeBusinessUnitRoles: BusinessUnitRoles;
    public get activeBusinessUnitRoles(): BusinessUnitRoles { return this._activeBusinessUnitRoles };
    public get activeBusinessUnit(): BizUnit { return this._activeBusinessUnitRoles?.businessUnit };
    public get activeRoles(): UserRoles { return this._activeBusinessUnitRoles?.userRoles };
    public get userIdentifier(): string { return this._activeBusinessUnitRoles?.userIdentifier };

    //private _activeBizUnitCarriers: Carrier[];
    public get activeBizUnitCarriers(): Carrier[] {
        let carriers;
        if (this.allCarriers && this.activeBusinessUnit) {
            const buCarrierIds = this.activeBusinessUnit.bizUnitCarriers.map(buc => buc.carrierId);
            carriers = this.allCarriers.filter(c => buCarrierIds.indexOf(c.id) >= 0)
        }
        return carriers;
    }

    private _allCarriers: Carrier[];
    public get allCarriers(): Carrier[] {
        if (!this._allCarriers) {
            this.store.select(getAllBizUnitCarriersSelector)
                .subscribe((currBizUnitCarriers: Carrier[]) => {
                    //one time deal for very first time.
                    this._allCarriers = currBizUnitCarriers.map(x => new Carrier(x));
                    return this._allCarriers;
                });
        }
        return this._allCarriers
    };

    public updateCarrierDetails(carrier: Carrier): void {
        if (this.allCarriers) {
            const existingCarrier = this._allCarriers.find(c => c.id === carrier.id);
            existingCarrier.address = new Address(carrier.address);
            existingCarrier.carrierDetailByState = carrier.carrierDetailByState.map(c => new CarrierDetail(c));
        }
    }

    public corporateBuId: number;


    constructor(private oauthService: OAuthService, private store: Store<State>, private pvAuthService: PVAuthService, private configService: AppConfigService) {
        this.configure();
        let bizId = localStorage.getItem('bizUnitId');
        if (bizId) {
            configService.activeBizUnitId = +bizId;
        }

        this.oauthService.tryLoginCodeFlow().then(() => {
            if (this.isAuthenticated) {
                console.log("user login Success.");
                //console.log("User Email", this.getUserEmail);
                this.store.dispatch(SecurityRolePageActions.getUserSecurityRolesAction({ input: this.getUserEmail }));
                this.store.dispatch(SecurityRolePageActions.getAllCarriersListAction());
                this.store.dispatch(SecurityRolePageActions.getCurrentUserPreferencesAction({ input: this.getUserEmail }));

                this.store.select(getCurrentUserAllBizUnitRolesSelector)
                    .subscribe((userAllBURoles: BusinessUnitRoles[]) => {
                        //one time deal for very first time.
                        this.loadUserData(userAllBURoles);
                    });

                this.allCarriers;
                
            }
        });

        this.claims;
        
    }

    private loadUserData(userAllBURoles: BusinessUnitRoles[]) {
        //console.log("loadUserData 123123", userAllBURoles);
        if (userAllBURoles && userAllBURoles.length > 0) {
            let role = userAllBURoles[0];
            let bizId = localStorage.getItem('bizUnitId');
            if (bizId) {
                let checkRole = userAllBURoles.find(x => x.businessUnit.id == +bizId);
                if (checkRole) {
                    role = checkRole;
                }
            }
            this.OnUserSwitchBizUnit(role);

            //Check if user is also a member of Corporate.
            this.corporateBuId = GlobalConsts.corpIdsByCompany[this.activeBusinessUnit.companyId];

            let corp = userAllBURoles.filter(x => x.businessUnit.id == this.corporateBuId); //Corporate Compliance
            this._isCorporate = corp.length == 1;    

            this.userRolesLoaded = true;
        }
    }

    public OnUserSwitchBizUnit(userBizUnitRoles: BusinessUnitRoles) {
        if (userBizUnitRoles && userBizUnitRoles.businessUnit && userBizUnitRoles.userRoles) {
            this._activeBusinessUnitRoles = userBizUnitRoles;
            this.configService.activeBizUnitId = userBizUnitRoles.businessUnit.id;
        }
    }


    public login() {
        this.oauthService.initLoginFlow();
    }

    public logout() {
        this.oauthService.logOut();
    }

    public get isAuthenticated(): boolean {
        let claims = this.claims;
        return (claims !== null && !this.isExpiredSession);
    }

    public get isExpiredSession(): boolean {
        let claims = this.claims;
        let time = Date.now() / 1000; // time in seconds
        if (claims.exp < time) {
            console.log("Session Expired!");
            return true;
        }
        else {
            return false;
        }
    }

    public get getUserPerferredName(): string {
        let retData = '';
        let claims = this.claims;
        if (claims) {
            retData = claims.name;
            if (retData.indexOf(',')) {
                let parts = retData.split(',');
                retData = parts[1];
            }
        }
        return retData;
    }

    public get getUserEmail(): string {
        let retData = '';
        let claims = this.claims;
        if (claims && claims.preferred_username) {
            retData = claims.preferred_username.toLowerCase();
        }
        return retData;
    }

    public get claims(): any {
        return this.oauthService.getIdentityClaims();
    }

    public get validUser(): boolean {
        return (this.activeRoles && this.activeBusinessUnit != null);
    }

    public get activeBizUnitIsCorporate(): boolean {
        return this.activeBusinessUnit && (this.activeBusinessUnit.id == this.corporateBuId);
    }

    public get isApplicationManager(): boolean {
        return this.activeRoles && (this.activeRoles.applicationManager || this.isCorporate || this.activeRoles.owner);
    }

    public get isOwnerOrCorporate(): boolean {
        return this.activeRoles && (this.activeRoles.owner || this.isCorporate);
    }

    private configure() {
        this.oauthService.configure(this.pvAuthService.getAuthConfig());
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.oauthService.loadDiscoveryDocument(DiscoveryDocumentConfig.url);

        this.oauthService.setupAutomaticSilentRefresh();
    }
}
