
<div class="alert alert-danger" *ngIf="errorMessage">
    {{ errorMessage }}
</div>

<div *ngIf="bizUnitVerbiage">
    <mat-accordion multi>
        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>Onboarding Document Upload Verbiage</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="bizUnitVerbiage.id > 0">
                <div>
                    <p>This will appear on the Producer Onboarding site's Document Upload step, where Producers can supply additional documents</p>
                    <mat-form-field style="width:50vw" appearance="outline">
                        <textarea data-cy="textareaDocument" #docVerbiage matInput rows="3" [ngModel]="bizUnitVerbiage.forDocument" required maxlength="8000"></textarea>
                    </mat-form-field>
                    <div>
                        <button mat-button class="button" data-cy="buttonDocumentSave"
                                [disabled]="docVerbiage.value === '' || docVerbiage.value === bizUnitVerbiage.forDocument"
                                (click)="saveVerbiage(bizUnitVerbiage, docVerbiage.value, 'document')">
                            Save
                        </button>
                        <button mat-button data-cy="buttonDocumentCancel" class="button" (click)="docVerbiage.value=bizUnitVerbiage.forDocument">Cancel</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>Onboarding Application Completed Verbiage</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="bizUnitVerbiage.id > 0">
                <div>
                    <p>This will appear on the Producer Onboarding site at the end of the Application process</p>
                    <mat-form-field style="width:50vw" appearance="outline">
                        <textarea data-cy="textareaAppComplete" #appCompleteVerbiage matInput rows="3" [ngModel]="bizUnitVerbiage.forApplicationComplete" required maxlength="8000" placeholder="{{appCompletePlaceholder}}"></textarea>
                    </mat-form-field>
                    <div>
                        <button class="button" data-cy="buttonAppCompleteSave"
                                [disabled]="appCompleteVerbiage.value === '' || appCompleteVerbiage.value === bizUnitVerbiage.forApplicationComplete"
                                (click)="saveVerbiage(bizUnitVerbiage, appCompleteVerbiage.value, 'appComplete')">
                            Save
                        </button>
                        <button data-cy="buttonAppCompleteCancel" class="button" (click)="appCompleteVerbiage.value=bizUnitVerbiage.forApplicationComplete">Cancel</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>Onboarding Application Email Subject Line</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="bizUnitVerbiage.id > 0">
                <div>
                    <mat-form-field style="width:50vw" appearance="outline">
                        <textarea #emailSubjectVerbiage data-cy="textareaEmailSubject" matInput rows="2" [ngModel]="bizUnitVerbiage.forEmailSubject" required maxlength="100" placeholder="Default Subject: Tokio Marine Producer Onboarding"></textarea>
                    </mat-form-field>
                    <div>
                        <button mat-button class="button" data-cy="buttonEmailSubjectSave"
                                [disabled]="emailSubjectVerbiage.value === '' || emailSubjectVerbiage.value === bizUnitVerbiage.forEmailSubject"
                                (click)="saveVerbiage(bizUnitVerbiage, emailSubjectVerbiage.value, 'emailSubject')">
                            Save
                        </button>
                        <button mat-button data-cy="buttonEmailSubjectCancel" class="button" (click)="emailSubjectVerbiage.value=bizUnitVerbiage.forEmailSubject">Cancel</button>
                    </div>
                </div>
            </div>

        </mat-expansion-panel>

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>Onboarding Application Email Verbiage</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="bizUnitVerbiage.id > 0">
                <div>
                    <mat-form-field style="width:50vw" appearance="outline">
                        <textarea #emailVerbiage data-cy="textareaEmail" matInput rows="3" [ngModel]="bizUnitVerbiage.forEmail" required maxlength="8000"></textarea>
                    </mat-form-field>
                    <div>
                        <button mat-button class="button" data-cy="buttonEmailSave"
                                [disabled]="emailVerbiage.value === '' || emailVerbiage.value === bizUnitVerbiage.forEmail"
                                (click)="saveVerbiage(bizUnitVerbiage, emailVerbiage.value, 'email')">
                            Save
                        </button>
                        <button mat-button data-cy="buttonEmailCancel" class="button" (click)="emailVerbiage.value=bizUnitVerbiage.forEmail">Cancel</button>
                    </div>
                </div>
            </div>

        </mat-expansion-panel>

    </mat-accordion>
</div>



