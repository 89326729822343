import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BizUnit } from '../../../shared/models/general';
import { Carrier } from '../../../shared/models/pob/carrier';
import { BusinessUnitRoles } from '../../../shared/models/user';

@Component({
    selector: 'pv-bu-carrier-shell',
    templateUrl: './bu-carrier-shell.component.html',
    styleUrls: ['./bu-carrier-shell.component.css']
})
export class BuCarrierShellComponent implements OnInit {

    @Input() fullBuList: BusinessUnitRoles[];
    @Input() bizUnits: BizUnit[];

    @Output() bizUnitsChange: EventEmitter<BizUnit[]> = new EventEmitter<BizUnit[]>();


    @Input() fullCarrierList: Carrier[];
    @Input() carriers: Carrier[];

    @Output() carriersChange: EventEmitter<Carrier[]> = new EventEmitter<Carrier[]>();


    showBUList = true;
    //showCarrierList = false;

    showList: string;

    constructor() { }

    ngOnInit(): void {
        console.log('shell');
        if (this.fullBuList.length <= 1) {
            this.showBUList = false;
        }
    }

    updateLists(buSel: boolean) {
        
        if (buSel) {
            //this.showCarrierList = false;
            this.carriersChange.emit([]);
            if (this.showList === 'bu') {
                this.bizUnitsChange.emit([]);
            }
        }
        else {
            //this.showBUList = false;
            this.bizUnitsChange.emit([]);
            if (this.showList === 'carrier') {
                this.carriersChange.emit([]);
            }
        }
    }
}
