<h3>Search Results</h3>
<mat-table *ngIf="hasResults" [dataSource]="results">

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let result" class="proper-name">{{result.fullName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
        <mat-cell *matCellDef="let result">{{result.stateCode}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="npn">
        <mat-header-cell *matHeaderCellDef>NPN</mat-header-cell>
        <mat-cell *matCellDef="let result">{{result.npn}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tax">
        <mat-header-cell *matHeaderCellDef>Tax ID</mat-header-cell>
        <ng-container *matCellDef="let result">
            <mat-cell *ngIf="result.type === 'Agency'">{{result.fein}}</mat-cell>
            <mat-cell *ngIf="result.type === 'Agent'">{{result.ssn | ssnMask:true}}</mat-cell>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>License Type</mat-header-cell>
        <mat-cell *matCellDef="let result" class="proper-name">{{result.type}}</mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="['name', 'state', 'npn', 'tax', 'type']"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: ['name', 'state', 'npn', 'tax', 'type']; index as i" class="clickable-row {{(i%2 == 0) ? 'even' : 'odd'}}-row" (click)="selectedRow(row)"></mat-row>

</mat-table>
