<h3>Edit RegEd Tables</h3>

<pv-state-select [(state)]="selectedState" class="inline top" (stateChange)="stateChanged()"></pv-state-select>
<div *ngIf="selectedState && stateRegedLTPs">
    <div style="margin-top:20px;">
        <h4 class="inline">License Types</h4>
        <input type="button" class="button float-right" value="Add" style="margin-bottom: 6px;" (click)="addLtpAllowed()" />
    </div>
    <mat-table [dataSource]="stateRegedLTPs">
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>License Type</mat-header-cell>
            <mat-cell *matCellDef="let ltpMapping" class="proper-name">
                <ng-container *ngIf="ltpMapping.isEditing">
                    <input type="text" [(ngModel)]="ltpMapping.licenseType" />
                    <span style="margin-left: 5px;" title="Enter the LTP/Class text string as provided by RegEd"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </ng-container>
                <ng-container *ngIf="!ltpMapping.isEditing">
                    {{ltpMapping.licenseType}}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="niprClassCode">
            <mat-header-cell *matHeaderCellDef>NIPR License Code</mat-header-cell>
            <mat-cell *matCellDef="let ltpMapping">
                <ng-container *ngIf="ltpMapping.isEditing">
                    <input type="text" [(ngModel)]="ltpMapping.niprClassCode" />
                    <span style="margin-left: 5px;" title="Enter the LTP/Class code directly from the NIPR License, not the LTP code provided by RegEd"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </ng-container>
                <ng-container *ngIf="!ltpMapping.isEditing">
                    {{ltpMapping.niprClassCode}}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="defaultLOAText">
            <mat-header-cell *matHeaderCellDef title="Some states have a single LOA value accepted for all Appointments">Default LOA Text <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="margin-left: 5px;"><path fill="none" d="M0 0h24v24H0V0z" /><path fill="#FFF" d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></mat-header-cell>
            <mat-cell *matCellDef="let ltpMapping">
                <ng-container *ngIf="ltpMapping.isEditing">
                    <input type="text" [(ngModel)]="ltpMapping.defaultLOAText" />
                    <span style="margin-left: 5px;" title="For some LTPs in some states, RegEd requires a single LOA value to be passed regardless of the LOA selected in the UI"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </ng-container>
                <ng-container *ngIf="!ltpMapping.isEditing">
                    {{ltpMapping.defaultLOAText}}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let ltpMapping">
                <ng-container *ngIf="ltpMapping.isEditing">
                    <input type="button" class="button" value="Save" (click)="updateRegedLPT(ltpMapping, $event.target.parentElement.parentElement)" />
                    <input type="button" class="button" value="Cancel" (click)="cancelEditLTP(ltpMapping)" />
                </ng-container>
                <ng-container *ngIf="!ltpMapping.isEditing">
                    <input type="button" class="button" value="Edit" (click)="editLTP(ltpMapping)" /> <input type="button" class="button" value="Remove" (click)="deleteRegedLTP(ltpMapping, $event.target)" />
                </ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="['type', 'niprClassCode', 'defaultLOAText', 'action']"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: ['type', 'niprClassCode', 'defaultLOAText', 'action']; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>
</div>
<div *ngIf="selectedState && stateRegedLOAs">
    <div style="margin-top: 20px;">
        <h4 class="inline">Lines of Authority</h4>
        <input type="button" class="button float-right" value="Add" style="margin-bottom: 6px;" (click)="addLoaAllowed()" />
    </div>
    <mat-table [dataSource]="stateRegedLOAs">

        <ng-container matColumnDef="loa">
            <mat-header-cell *matHeaderCellDef>LOA</mat-header-cell>
            <mat-cell *matCellDef="let loaMapping" class="proper-name">
                <ng-container *ngIf="loaMapping.isEditing">
                    <input type="text" [(ngModel)]="loaMapping.loa" />
                    <span style="margin-left: 5px;" title="Enter the LOA text string provided by RegEd"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </ng-container>
                <ng-container *ngIf="!loaMapping.isEditing">
                    {{loaMapping.loa}}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="niprCode">
            <mat-header-cell *matHeaderCellDef>NIPR LOA Code</mat-header-cell>
            <mat-cell *matCellDef="let loaMapping">
                <ng-container *ngIf="loaMapping.isEditing">
                    <input type="text" [(ngModel)]="loaMapping.niprCode" />
                    <span style="margin-left: 5px;" title="Enter the LOA code directly from the NIPR License, not the LOA code provided by RegEd"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </ng-container>
                <ng-container *ngIf="!loaMapping.isEditing">
                    {{loaMapping.niprCode}}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="niprAppointmentCode">
            <mat-header-cell *matHeaderCellDef title="The LOA Code attached to Appointments can differ from the LOA Code on the License Detail">NIPR Appointment Code <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="margin-left: 5px;"><path fill="none" d="M0 0h24v24H0V0z" /><path fill="#FFF" d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></mat-header-cell>
            <mat-cell *matCellDef="let loaMapping">
                <ng-container *ngIf="loaMapping.isEditing">
                    <input type="text" [(ngModel)]="loaMapping.niprApptCodesCsv" />
                    <span style="margin-left: 5px;" title="If the LOA code on the NIPR Appointment does not match the NIPR License LOA, enter the Appointment LOA code here"><svg title="" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </ng-container>
                <ng-container *ngIf="!loaMapping.isEditing">
                    {{loaMapping.niprApptCodesCsv}}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let loaMapping">
                <ng-container *ngIf="loaMapping.isEditing">
                    <input type="button" class="button" value="Save" (click)="updateRegedLOA(loaMapping, $event.target.parentElement.parentElement)" />
                    <input type="button" class="button" value="Cancel" (click)="cancelEditLOA(loaMapping)" />
                </ng-container>
                <ng-container *ngIf="!loaMapping.isEditing">
                    <input type="button" class="button" value="Edit" (click)="editLOA(loaMapping)" /> <input type="button" class="button" value="Remove" (click)="deleteRegedLOA(loaMapping, $event.target)" />
                </ng-container>
            </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="['loa', 'niprCode', 'niprAppointmentCode', 'action']"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: ['loa', 'niprCode', 'niprAppointmentCode', 'action']; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>
</div>
