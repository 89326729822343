import { Address } from './address';

export class Contact {
    id: number;
    bizPhone: string;
    cellPhone: string;
    otherPhone: string;
    fax: string;
    email: string;
    website: string;
    preferredContact: string;
    editor: string;
    editTime: Date;

    addresses: Address[] = []; //direct from API

    businessAddress: Address = new Address({ isBusinessAddress: true });
    mailingAddress: Address = new Address({ isMailingAddress: true });
    residentialAddress: Address = new Address({ isResidentialAddress: true });

    constructor(api?: Partial<Contact>) {
        if (api) {
            this.id = api.id;
            this.bizPhone = api.bizPhone;
            this.cellPhone = api.cellPhone;
            this.otherPhone = api.otherPhone;
            this.fax = api.fax;
            this.email = api.email;
            this.website = api.website;
            this.preferredContact = api.preferredContact;
            this.editor = api.editor;
            this.editTime = api.editTime;

            if (api.addresses && api.addresses.length > 0) {
                //this.addresses = api.addresses;
                let addresses = api.addresses;
                let biz = addresses.find(x => x.isBusinessAddress == true);
                let mail = addresses.find(x => x.isMailingAddress == true);
                let res = addresses.find(x => x.isResidentialAddress == true);

                if (biz) {
                    this.businessAddress = new Address(biz);
                    this.addresses.push(this.businessAddress);
                }

                if (mail) {
                    this.mailingAddress = new Address(mail);
                    this.addresses.push(this.mailingAddress);
                }

                if (res) {
                    this.residentialAddress = new Address(res);
                    this.addresses.push(this.residentialAddress);
                }
            }
        }
    }
}
