
export class Alert {
    id: number;
    message: string;
    category: string;

    editor: string;
    createTime: Date;
    updateTime: Date;
    startDate: Date;
    endDate: Date;

    get class(): string {
        switch (this.category) {
            case "Alert": return "warn-box";
            case "Notification": return "good-box";
            default: return "alert-box";
        }
    }

    heading(): string {
        switch (this.category) {
            case "Alert": return "Alert";
            case "Maintenance": return "Scheduled Maintenance";
            default: return this.category;
        }
    };

    constructor(init?: Partial<Alert>) {
        if (init) {
            this.id = init.id;
            this.message = init.message;
            this.category = init.category;
            this.editor = init.editor;
            this.createTime = init.createTime ? new Date(init.createTime) : null;
            this.updateTime = init.updateTime ? new Date(init.updateTime) : null;
            this.startDate = init.startDate ? new Date(init.startDate) : null;
            this.endDate = init.endDate ? new Date(init.endDate) : null;
        }
    }
}
