<h2 mat-dialog-title>Verbiage Preview</h2>
<mat-dialog-content>
    <div *ngIf="data.type === 'document'">
        <div><img src="./assets/images/email/verbiage-preview-doc-01.png" alt="Document verbiage preview 01" /></div>
        <div>
                <textarea readonly matInput>{{data.verbiage}}</textarea>
        </div>
        <div>
            <img src="./assets/images/email/verbiage-preview-doc-02.png" alt="Document verbiage preview 02" />
        </div>
    </div>
    <div *ngIf="data.type === 'email'">
        <div>
            <img src="./assets/images/email/verbiage-preview-email-01.png" alt="Email verbiage preview 01" />
        </div>
        <div>
            <p>Thank you for your interest in becoming a Producer with Tokio Marine.</p>
            <textarea readonly matInput>{{data.verbiage}}</textarea>
        </div>
        <div>
            <img src="./assets/images/email/verbiage-preview-email-02.png" alt="Email verbiage preview 02" />
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close class="button" style="width:10em;">OK</button>
</mat-dialog-actions>

