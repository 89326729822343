import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Application } from '../../../shared/models/application';
import { Router } from '@angular/router';
import { AuditLogStatus } from '../../../shared/enums/audit-log';
import { ApplicationProgress } from '../../../shared/enums/general';
import { UserService } from '../../../services/user.service';
import { OnboardingService } from '../../onboarding.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'pv-display-table',
    templateUrl: './display-table.component.html',
    styleUrls: ['./display-table.component.css']
})
export class DisplayTableComponent {

    columns: string[] = ['id', 'name', 'licenseState', 'initiator', 'createdDate', 'lastUpdatedDate', 'status'];
    dataSource;
    filterValues = {
        name: '',
        licenseState: '',
        createdBy: '',
        status: ''
    };

    statusList: string[] = [];

    nameFilter = new FormControl('');
    licenseStateFilter = new FormControl('');
    createdByFilter = new FormControl('');
    statusFilter = new FormControl<string | null>(null);

    constructor(private userService: UserService, private onboardService: OnboardingService, private router: Router) { }

    @Input() applications: Application[];

    ngOnChanges() {
        if (this.applications) {
            this.dataSource = new MatTableDataSource(this.applications);
            this.dataSource.filterPredicate = this.tableFilter();

            this.statusList = this.applications.map(item => item.displayStatus.toString()).filter((value, index, self) => self.indexOf(value) === index);
            this.statusList.sort();
            this.statusList.unshift("[X]");//clear filter val

            this.nameFilter.valueChanges
                .subscribe(
                    name => {
                        this.filterValues.name = name.toLowerCase();
                        this.dataSource.filter = JSON.stringify(this.filterValues);
                    }
                );
            this.licenseStateFilter.valueChanges
                .subscribe(
                    licState => {
                        this.filterValues.licenseState = licState.toLowerCase();
                        this.dataSource.filter = JSON.stringify(this.filterValues);
                    }
                );
            this.createdByFilter.valueChanges
                .subscribe(
                    name => {
                        this.filterValues.createdBy = name.toLowerCase();
                        this.dataSource.filter = JSON.stringify(this.filterValues);
                    }
                );
            this.statusFilter.valueChanges
                .subscribe(
                    status => {
                        if (status === "[X]") {
                            this.filterValues.status = "";
                            this.statusFilter.setValue("");
                        }
                        else {
                            this.filterValues.status = status;
                        }
                        this.dataSource.filter = JSON.stringify(this.filterValues);
                    }
                );
        }
    }

    tableFilter(): (data: Application, filter: string) => boolean {
        let filterFunction = function (data: Application, filter: string): boolean {
            let searchTerms = JSON.parse(filter);
            let licCompString = data.producer.licenseNumber + " - " + data.producer.stateCode;

            return (searchTerms.name = '' || data.producer.fullName.toLowerCase().indexOf(searchTerms.name) >= 0)
                && (searchTerms.licenseState == '' || licCompString.toLowerCase().indexOf(searchTerms.licenseState) >= 0)
                && (searchTerms.createdBy == '' || data.createdBy.toLowerCase().indexOf(searchTerms.createdBy) >= 0)
                && (searchTerms.status == '' || data.displayStatus.toString() == searchTerms.status);
        }
        return filterFunction;
    }

    openApplication(row: Application) {
        if (row.status == AuditLogStatus.AppCreate && row.progressStatus == ApplicationProgress.Completed && this.userService.isApplicationManager) {
            this.onboardService.updateApplicationStatus(AuditLogStatus.AppReviewing, row.id, this.userService.getUserEmail).subscribe(resData => {
                this.navigate(row.displayID);
            });
        }
        else {
            this.navigate(row.displayID);
        }
    }

    navigate(appDisplayId: number) {
        this.router.navigate(['ProducerOnboarding/Application', appDisplayId]);
    }
}
