import { Injectable } from '@angular/core';
import { BizUnit, BizUnitCarrier } from '../shared/models/general';
import { RestService } from '../services/rest.service';
import { Observable, Subject, BehaviorSubject, throwError, of } from 'rxjs';
import { IClientListDTO, IClientItemDTO } from '../shared/models/dto/common-result.dto';
import { User, BusinessUnitRoles, UserRolesDto, UserPreference, UserRoles } from '../shared/models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError, mergeMap, tap, shareReplay } from 'rxjs/operators';
import { Carrier } from '../shared/models/pob/carrier';

@Injectable({
    providedIn: 'root'
})
export class SecurityRoleService extends RestService {

    getCurrentUserPreferences(userEmail:string): Observable<User> {
        let relativeUrl = `pob/GetUserPreferences/${userEmail}`;
        return this.get<IClientItemDTO<User>>(relativeUrl)
            .pipe(
                map((resData: IClientItemDTO<User>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getCurrentUserPreferences(): ${resData.message}`);
                    }
                    return new User(resData.item);
                }),
                catchError(this.handleError)
            );
    }

    updateUserPreference(data: UserPreference): Observable<UserPreference> {
        let relativeUrl = 'POB/UpdateUserPreference';
        return this.post<IClientItemDTO<number>>(relativeUrl, data)
            .pipe(
                map((resData: IClientItemDTO<number>) => {
                    if (resData.status !== 0 || resData.item < 0) {
                        throw new Error(`updateUserPreference(): ${resData.message}`);
                    }
                    //best practice is to update api server side to return the latest preference data,
                    //Take a shortcut here, use input parameter as return data.
                    let retData = { ...data};
                    retData.id = resData.item;
                    return retData;
                }),
                catchError(this.handleError)
            );
    }


    getAllCarriersList(): Observable<Carrier[]> {
        let relativeUrl = 'POB/Carriers';
        return this.get<IClientListDTO<Carrier>>(relativeUrl)
            .pipe(
                map((resData: IClientListDTO<Carrier>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getAllCarriersList(): ${resData.message}`);
                    }
                    return resData.dataList.map(c => new Carrier(c)).sort((x, y) => x.name > y.name ? 1 : -1);
                }),
                shareReplay(1),
                catchError(this.handleError)
            );
    }

    getBizUnitCarrierIds(bizUnitId: number): Observable<number[]> {
        let relativeUrl = `POB/BizUnits/${bizUnitId}`;
        return this.get<IClientListDTO<BizUnit>>(relativeUrl)
            .pipe(
                map((resData: IClientListDTO<BizUnit>) => {
                    if (resData.status !== 0 || resData.dataList.length < 0) {
                        throw new Error(`getBizUnitCarrierIds(): ${resData.message}`);
                    }
                    return resData.dataList[0].bizUnitCarriers.map(r => r.carrier.id);
                }),
                catchError(this.handleError)
            );
    }


    addBizUnitCarrier(data: BizUnitCarrier): Observable<number> {
        let relativeUrl = 'POB/BizUnitCarriers';
        let input = {
            bizUnitId: data.bizUnitId,
            carrierId: data.carrier.id,
            preference: true
        };
        return this.post<IClientItemDTO<boolean>>(relativeUrl, [input])
            .pipe(
                map((resData: IClientItemDTO<boolean>) => {
                    if (resData.status !== 0) {
                        throw new Error(`addBizUnitCarrier(): ${resData.message}`);
                    }
                    return input.carrierId;
                }),
                catchError(this.handleError)
            );
    }


    deleteBizUnitCarrier(bizUnitCarrier: BizUnitCarrier): Observable<number> {
        let relativeUrl = 'POB/BizUnitCarriers/Delete';
        let input = {
            bizUnitId: bizUnitCarrier.bizUnitId,
            carrierId: bizUnitCarrier.carrier.id,
        }
        return this.post<IClientItemDTO<boolean>>(relativeUrl, [input])
            .pipe(
                map((resData: IClientItemDTO<boolean>) => {
                    if (resData.status !== 0) {
                        throw new Error(`deleteBizUnitCarrier(): ${resData.message}`);
                    }
                    return input.carrierId;
                }),
                catchError(this.handleError)
            );
    }


    getBizUnits(): Observable<BizUnit[]> {
        let relativeUrl = 'pob/BizUnits';
        return this.get<IClientListDTO<BizUnit>>(relativeUrl)
            .pipe(
                map((resData: IClientListDTO<BizUnit>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getBizUnits(): ${resData.message}`);
                    }
                    return resData.dataList;
                }),
                catchError(this.handleError)
            );
    }

    getUserList(bizUnit: BizUnit): Observable<UserRolesDto[]> {
        let relativeUrl = 'pob/SecurityRoles/BizUnit/' + bizUnit.id;
        return this.get<IClientListDTO<UserRolesDto>>(relativeUrl)
            .pipe(
                map((resData: IClientListDTO<UserRolesDto>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getUserList(): ${resData.message}`);
                    }
                    //console.log("api getUserList 123", resData.dataList);
                    return resData.dataList;
                }),
                catchError(this.handleError)
            );
    }

    getCurrentUserRoles(userEmail:string): Observable<UserRolesDto[]> {
        let relativeUrl = `pob/SecurityRoles/${userEmail}`;
        return this.get<IClientListDTO<UserRolesDto>>(relativeUrl)
            .pipe(
                map((resData: IClientListDTO<UserRolesDto>) => {
                    if (resData.status !== 0) {
                        throw new Error(`getCurrentUserRoles(): ${resData.message}`);
                    }
                    return resData.dataList;
                }),
                catchError(this.handleError)
            );
    }

    updateUserRoles(user: UserRolesDto[]): Observable<UserRolesDto[]> {
        let relativeUrl = 'pob/UpdateSecurityRoles';
        return this.post<IClientListDTO<UserRolesDto>>(relativeUrl, user)
            .pipe(
                map((resData: IClientListDTO<UserRolesDto>) => {
                    if (resData.status !== 0 || resData.dataList.length <= 0) {
                        throw new Error(`updateUserRoles(): ${resData.message}`);
                    }
                    return resData.dataList;
                }),
                catchError(this.handleError)
            );
    }

    deleteUserFromBU(userId: number): Observable<number> {
        let relativeUrl = 'POB/DeleteBizUser';
        return this.post<IClientItemDTO<number>>(relativeUrl, [userId])
            .pipe(
                map((resData: IClientItemDTO<number>) => {
                    if (resData.status !== 0) {
                        throw new Error(`deleteUserFromBU(): ${resData.message}`);
                    }
                    return userId;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(err: any) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            errorMessage = `An error occurred: ${err.error.message}`;
        }
        else if (err instanceof HttpErrorResponse) {
            if (err.status === 403) {
                errorMessage = `${err.status}, Access defined.`;
            }
        }
        else if (err instanceof Error) {
            errorMessage = err.message;
        }
        else {
            errorMessage = `Backend returned error ${err?.status}: ${err?.body?.error}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }

}

