import { Component, OnInit } from '@angular/core';
import { ProducerDataService } from '../producer-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Producer } from '../../shared/models/pob/producer';
import { ModalService } from '../../modal/modal.service';
import { UserService } from '../../services/user.service';
import { License } from '../../shared/models/pob/license';
import { Observable, forkJoin, of } from 'rxjs';
import { IClientListDTO } from '../../shared/models/dto/common-result.dto';
import { IPdbAppointment, IPdbRegulatory } from '../../shared/models/dto/pdb-result.dto';
import { DataService } from '../../services/data.service';
import { map } from 'rxjs/operators';
import { PdbContact } from '../../shared/models/pdb/pdb-contact';
import { PdbAddress } from '../../shared/models/pdb/pdb-address';

@Component({
    selector: 'pv-pdb',
    templateUrl: './pdb.component.html',
    styleUrls: ['./pdb.component.css']
})
export class PdbComponent implements OnInit {

    paramId = '';
    id: number = null;

    public loaded = false;
    producerCopy: Producer;

    public appointments: IPdbAppointment[];
    public contacts: PdbContact[];
    public addresses: PdbAddress[];
    public regulatory: IPdbRegulatory[];

    public activeLicenseStates: string[];
    residentLicenseStates: string[] = [];
    nonResLicStates: string[] = [];

    currentDate = new Date();
    licenseDate: Date;
    appointmentDate: Date;
    regulatoryDate: Date;
    
    //top level key is StateCode, inner level key is CompanyName of the appointments
    stateArray: Record<string, { licenses: License[]; contact: PdbContact; bizAddress: PdbAddress; mailAddress: PdbAddress; resAddress: PdbAddress; appointments: Record<string, { companyAppts: IPdbAppointment[] }>; appointmentKeys: string[] }> = {};

    constructor(private producerService: ProducerDataService, public userService: UserService, private dataService: DataService, private modal: ModalService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        document.title = "IPA - Producer PDB Report";
        console.log('pdb');
        this.paramId = this.route.snapshot.paramMap.get('id');
        const numberId = +this.paramId;
        this.id = numberId;
        
        if (this.producerService.producer && this.id === this.producerService.producer.id) {
            this.getAllDataLists(this.producerService.producer.npn);
        }
        else {
            this.producerService.updateId(this.id);
            this.producerService.getProducer(this.id).pipe(
                map(value => value)).subscribe({
                    next: resData => {
                        if (resData) {
                            this.producerService.producer = new Producer(resData.item);
                            this.getAllDataLists(resData.item.npn);
                        }
                        else {
                            this.modal.message("Producer data not found");
                        }
                    }
                });
        }
    }

    getAllDataLists(npn: number) {
        forkJoin(this.getLicenses(npn), this.getAppointments(), this.getContacts(), this.getAddresses(), this.getRegulatory()).subscribe(([licData, appData, contactData, addData, regData]) => {
            if (licData && licData.status === 0 && licData.dataList) {
                this.producerService.producer.licenses = licData.dataList.map(x => new License(x));
            }

            if (appData && appData.status === 0 && appData.dataList) {
                this.producerService.pdbAppointments = appData.dataList.map(a => new IPdbAppointment(a));
                this.producerService.pdbAppointmentsFullyLoaded = true;
            }

            if (contactData && contactData.status === 0 && contactData.dataList) {
                this.contacts = contactData.dataList.map(x => new PdbContact(x));
            }

            if (addData && addData.status === 0 && addData.dataList) {
                this.addresses = addData.dataList.map(x => new PdbAddress(x));
            }

            if (regData && regData.status === 0) {
                this.producerService.pdbRegulatories = regData.dataList;
            }

            this.setupValues();
        });
    }

    getContacts(): Observable<IClientListDTO<PdbContact>> {
        return this.producerService.getContacts(this.producerService.producer.npn);
    }

    getAddresses(): Observable<IClientListDTO<PdbAddress>> {
        return this.producerService.getAddresses(this.producerService.producer.npn);
    }

    getLicenses(npn: number): Observable<IClientListDTO<License>> {
        if (!this.producerService.producer.licenses) {
            if (npn) {
                return this.producerService.getLicensesByNpn(npn);
            }
        }
        else {
            return of({ status: -1, message: "", dataList: [] });
        }
    }

    getAppointments(): Observable<IClientListDTO<IPdbAppointment>> {
        if (this.producerService.pdbAppointmentsFullyLoaded) {
            return of({ status: -1, message: "", dataList: [] });
        }
        else {
            return this.producerService.getAppointmentsByProdId(this.id, []);
        }
    }

    getRegulatory(): Observable<IClientListDTO<IPdbRegulatory>> {
        if (this.producerService.pdbRegulatories) {
            return of({ status: -1, message: "", dataList: [] });
        }
        else {
            return this.producerService.getRegulatory(this.producerService.producer.npn);
        }
    }

    setupValues() {
        this.producerCopy = this.producerService.producer;
        this.licenseDate = this.producerCopy.licenses.map(l => l.editTime).reduce((a, b) => (a > b ? a : b));
        let appts = this.producerService.pdbAppointments.filter(a => a.asOfDateTime !== null);
        if (appts.length > 0) {
            this.appointmentDate = appts.map(a => a.asOfDateTime).reduce((a, b) => (a > b ? a : b));
        }
        if (this.producerService.pdbRegulatories && this.producerService.pdbRegulatories.length > 0) {
            this.regulatory = this.producerService.pdbRegulatories;
            this.regulatoryDate = this.regulatory.map(r => r.asOfDate).reduce((a, b) => (a > b ? a : b));
        }

        this.residentLicenseStates = this.producerCopy.licenses.filter(l => l.residencyStatus === 'R' && l.active === true).map(l => l.stateCode).filter((v, i, a) => a.indexOf(v) === i);
        this.nonResLicStates = this.producerCopy.licenses.filter(l => l.residencyStatus === 'NR').map(l => l.stateCode).filter((v, i, a) => a.indexOf(v) === i);
        this.activeLicenseStates = this.residentLicenseStates.concat(this.nonResLicStates).filter((v, i, a) => a.indexOf(v) === i);

        this.appointments = this.producerService.pdbAppointments.sort((a, b) => (a.companyName.toLowerCase() < b.companyName.toLowerCase() ? -1 : 1));

        this.activeLicenseStates.forEach(s => {
            this.stateArray[s] = { licenses: [], contact: null, bizAddress: null, mailAddress: null, resAddress: null, appointments: {}, appointmentKeys: [] };
        });

        this.producerCopy.licenses.forEach(l => {
            if (this.stateArray[l.stateCode]) {
                this.stateArray[l.stateCode].licenses.push(l);
            }
        });

        this.appointments.forEach(a => {
            if (this.stateArray[a.state]) {
                if (!this.stateArray[a.state].appointments[a.companyCode]) {
                    this.stateArray[a.state].appointments[a.companyCode] = { companyAppts: [] };
                    this.stateArray[a.state].appointmentKeys.push(a.companyCode);
                }

                this.stateArray[a.state].appointments[a.companyCode].companyAppts.push(a);
            }
        });

        this.contacts.forEach(c => {
            if (this.stateArray[c.contactStateCode]) {
                if (!this.stateArray[c.contactStateCode].contact || this.stateArray[c.contactStateCode].contact.phoneUpdateDate < c.phoneUpdateDate) {
                    this.stateArray[c.contactStateCode].contact = c;
                }
            }
        });

        this.addresses.forEach(a => {
            if (this.stateArray[a.stateCode]) {
                switch (a.addressType) {
                    case 1: this.stateArray[a.stateCode].resAddress = a; break;
                    case 2: this.stateArray[a.stateCode].bizAddress = a; break;
                    case 3: this.stateArray[a.stateCode].mailAddress = a; break;
                }
            }
        })
    }
}
