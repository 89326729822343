import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

/* NgRx */
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { tap } from 'rxjs/operators';
import { getSelectedBizUnitSecurityRolesSelector } from '../../user/state';
import { AdminPageActions } from '../state/actions';
import { getAdminErrorSelector, getDbDocumentTemplatesSelector } from '../state';
import { DocuSignTemplate } from '../../shared/models/document';
import { BusinessUnitRoles } from '../../shared/models/user';

@Component({
    selector: 'pv-onboarding-documents-shell',
    templateUrl: './onboarding-documents-shell.component.html',
})
export class OnboardingDocumentsShellComponent implements OnInit {

    errorMessage$: Observable<string>;
    currentUserSecurityRole$: Observable<BusinessUnitRoles>;
    allDbDocumentTemplates$: Observable<DocuSignTemplate[]>;

    constructor(private store: Store<State>) { }

    ngOnInit(): void {

        this.store.dispatch(AdminPageActions.getDocumentListsByBizUnitAction());

        this.currentUserSecurityRole$ = this.store.select(getSelectedBizUnitSecurityRolesSelector);

        this.errorMessage$ = this.store.select(getAdminErrorSelector);

        this.allDbDocumentTemplates$ = this.store.select(getDbDocumentTemplatesSelector);
    }

    updateDbDocuSignTemplate(data: DocuSignTemplate[]) {
        this.store.dispatch(AdminPageActions.updateDocumentTemplatesAction({buDocumentTemplates: data }));
    }

    deleteDocuSignTemplate(data: DocuSignTemplate) {
        this.store.dispatch(AdminPageActions.deleteDocusignTemplateFileAction({documentTemplate: data }));
    }

}
