<mat-table *ngIf="applications && applications.length > 0" [dataSource]="dataSource" style="margin-top: 30px;">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef style="padding-left: 20px;">ID</mat-header-cell>
    <mat-cell *matCellDef="let app">{{app.displayID}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter" style="padding: 0 10px;">
              <mat-label class="white">Name</mat-label>
              <input matInput [formControl]="nameFilter" />
          </mat-form-field>
      </mat-header-cell>
    <mat-cell *matCellDef="let app" style="padding: 0 0 0 10px; margin-right: -10px;" class="proper-name">{{app.producer.fullName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="licenseState">
      <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
              <mat-label class="white">License # / State</mat-label>
              <input matInput [formControl]="licenseStateFilter" />
          </mat-form-field>
      </mat-header-cell>
    <mat-cell *matCellDef="let app">{{app.producer.licenseNumber}} - {{app.producer.stateCode}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="initiator">
      <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter" style="padding: 0 10px;">
              <mat-label class="white">Created By</mat-label>
              <input matInput [formControl]="createdByFilter" />
          </mat-form-field>
      </mat-header-cell>
    <mat-cell *matCellDef="let app" style="padding: 0 0 0 10px; margin-right: -10px;">{{app.createdBy}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef>Created On</mat-header-cell>
    <mat-cell *matCellDef="let app">{{app.createdDate | date:'mediumDate'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastUpdatedDate">
    <mat-header-cell *matHeaderCellDef>Last Updated</mat-header-cell>
    <mat-cell *matCellDef="let app">{{app.editTime | date:'mediumDate'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
          <mat-form-field class="filter">
              <mat-label class="white">Status</mat-label>
              <mat-select [formControl]="statusFilter">
                  <mat-option *ngFor="let status of statusList" [value]="status">{{status}}</mat-option>
              </mat-select>
          </mat-form-field>
      </mat-header-cell>
    <mat-cell *matCellDef="let app">{{app.displayStatus}}</mat-cell>
  </ng-container>

  <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
  <mat-row *cdkRowDef="let row; columns: columns; index as i" class="clickable-row {{(i%2 == 0) ? 'even' : 'odd'}}-row" (click)="openApplication(row)"></mat-row>

</mat-table>

<ng-container *ngIf="applications && applications.length == 0">
  <p class="italics center" style="margin-top: 50px;">No Applications Found</p>
</ng-container>
