import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

/* NgRx */
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import { BusinessUnitRoles, UserRoles, UserRolesDto } from '../../shared/models/user';
import { getBizUnitAllUserRolesSelector, getSelectedBizUnitSecurityRolesSelector } from '../../user/state';
import { SecurityRolePageActions } from '../../user/state/actions';


@Component({
    templateUrl: './user-shell.component.html'
})
export class UsersShellComponent implements OnInit {


    bizUnitAllUsers$: Observable<BusinessUnitRoles[]>;
    currentUserRole$: Observable<BusinessUnitRoles>;
    errorMessage$: Observable<string>;

    constructor(private store: Store<State>) { }

    ngOnInit(): void {

        this.store.dispatch(SecurityRolePageActions.getAllUserRolesAction());
        this.bizUnitAllUsers$ = this.store.select(getBizUnitAllUserRolesSelector);

        this.currentUserRole$ = this.store.select(getSelectedBizUnitSecurityRolesSelector)
    }


    addUser(data: UserRolesDto): void {
        this.store.dispatch(SecurityRolePageActions.AddUserRolesAction({ input: data }));
    }

    updateUser(data: UserRolesDto[]): void {
        this.store.dispatch(SecurityRolePageActions.UpdateUserRolesAction({ input: data }));
    }

    removeUser(userId: number): void {
        this.store.dispatch(SecurityRolePageActions.deleteUserFromBUAction({ input: userId }));
    }
}
