import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../search.service';
import { ProducerDataService } from '../../producer-data.service';
import { ModalService } from '../../../modal/modal.service';
import { SearchProducer } from '../../../shared/models/pob/producer';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'pv-by-agency',
    templateUrl: './by-agency.component.html',
})
export class ByAgencyComponent implements OnInit {

    constructor(public searchService: SearchService, public userService: UserService, private producerService: ProducerDataService, private modal: ModalService) { }

    ngOnInit() {
        this.searchService.searchInput = new SearchProducer({
            companyName: '',
            stateCode: null,
            type: "Agency"
        });
        this.searchService.errorMessage = '';
    }

    searchByAgencyName() {
        this.searchService.errorMessage = "";
        this.producerService.clearProducerData();
        if (!this.searchService.searchInput.companyName) {
            this.searchService.errorMessage = "Please enter an Agency Name";
        }
        else {
            this.modal.loading(true);
            this.searchService.searchProducerByInput(this.searchService.searchInput).subscribe({
                next: resData => {
                    if (resData && resData.dataList && resData.dataList.length > 0) {
                        this.producerService.updateSearchResults(resData.dataList);
                    }
                    else {
                        this.searchService.errorMessage = "No Results Found! Please try another search option.\r\nTo Add a new branch to an existing Agency, locate the Master Agency first.";
                    }
                    this.modal.loading(false);
                }
            });
        }
    }
}
