<div class="">
    <mat-form-field class="date-picker">
        <mat-label>On or After Date</mat-label>
        <input name="start-date" matInput [(ngModel)]="startDate" [matDatepicker]="reportsStartDatePicker" (ngModelChange)="startDateChange.emit(startDate)">
        <mat-datepicker-toggle matSuffix [for]="reportsStartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #reportsStartDatePicker></mat-datepicker>
    </mat-form-field>
</div>
<div class="" style="margin-bottom: 20px;">
    <mat-form-field class="date-picker">
        <mat-label>On or Before Date</mat-label>
        <input name="end-date" matInput [(ngModel)]="endDate" [matDatepicker]="reportsEndDatePicker" (ngModelChange)="endDateChange.emit(endDate)">
        <mat-datepicker-toggle matSuffix [for]="reportsEndDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #reportsEndDatePicker></mat-datepicker>
    </mat-form-field>
</div>
