import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Producer } from '../../../shared/models/pob/producer';

@Component({
    selector: 'pv-biographic-search-results',
    templateUrl: './biographic-search-results.component.html'
})
export class BiographicSearchResultsComponent {

    @Input() results: Producer[];
    displayedColumns = [];

    constructor(private router: Router) { }

    get hasResults() {
        if (this.results) {
            return true;
        }

        return false;
    }

    selectedRow(row: Producer) {
        this.router.navigate(['/Producer', row.id]);
    }
}
